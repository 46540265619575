import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'afterActionField',
    'redirectToPageField',
    'redirectToFunnelField',
    'redirectToUrlField',
    'redirectableType',
  ]

  static classes = ['hidden']

  connect() {
    this.getAfterBookingAction()
  }

  /**
   * Retrieves the selected after booking action and updates the view accordingly.
   */
  getAfterBookingAction() {
    const selectedAfterBookingAction = this.afterActionFieldTarget.value
    this.updateView(selectedAfterBookingAction)
  }

  /**
   * Handles changes in after booking action select field.
   * @param {Event} event - The event object representing the select field change event.
   */
  handleBookingTypeChange(event) {
    const selectedAfterBookingAction = event.target.value
    this.updateView(selectedAfterBookingAction)
  }

  /**
   * Updates the view based on the selected after booking action.
   * @param {string} action - The selected after booking action.
   */
  updateView(action) {
    switch (action) {
      case 'display_confirmation_page':
        this.showDisplayConfirmation()
        break
      case 'redirect_to_a_page':
        this.showRedirectToPage()
        break
      case 'redirect_to_a_funnel':
        this.showRedirectToFunnel()
        break
      case 'redirect_to_a_custom_url':
        this.showRedirectToUrl()
        break
    }
  }

  /**
   * Displays the redirect to page field and hides others.
   */
  showRedirectToPage() {
    this.enableField(this.redirectToPageFieldTarget)
    this.disableField(this.redirectToUrlFieldTarget)
    this.disableField(this.redirectToFunnelFieldTarget)
    this.redirectableTypeTarget.value = 'Page'
  }

  /**
   * Displays the redirect to funnel field and hides others.
   */
  showRedirectToFunnel() {
    this.enableField(this.redirectToFunnelFieldTarget)
    this.disableField(this.redirectToPageFieldTarget)
    this.disableField(this.redirectToUrlFieldTarget)
    this.redirectableTypeTarget.value = 'Funnel'
  }

  /**
   * Displays the redirect to URL field and hides others.
   */
  showRedirectToUrl() {
    this.enableField(this.redirectToUrlFieldTarget)
    this.disableField(this.redirectToPageFieldTarget)
    this.disableField(this.redirectToFunnelFieldTarget)
    this.redirectableTypeTarget.value = null
  }

  /**
   * Hides all redirect fields.
   */
  showDisplayConfirmation() {
    this.disableField(this.redirectToUrlFieldTarget)
    this.disableField(this.redirectToPageFieldTarget)
    this.disableField(this.redirectToFunnelFieldTarget)
    this.redirectableTypeTarget.value = null
  }

  /**
   * Enables the given field.
   * @param {Element} field - The field to enable.
   */
  enableField(field) {
    if (field.querySelector('select, input').tomselect) {
      field.querySelector('select, input').tomselect.enable()
    } else {
      field.querySelector('select, input').disabled = false
    }

    field.classList.remove(this.hiddenClass)
  }

  /**
   * Disables the given field.
   * @param {Element} field - The field to disable.
   */
  disableField(field) {
    if (field.querySelector('select, input').tomselect) {
      field.querySelector('select, input').tomselect.disable()
    } else {
      field.querySelector('select, input').disabled = true
    }

    field.classList.add(this.hiddenClass)
  }
}
