import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['currentTrialEnd', 'trialEndToggle', 'radioChange', 'submitButton']

  connect() {
    const changeRenewalDateInput = $('input[name="order[change_renewal_date_to]"]')
    const currentTrialEndValue = this.currentTrialEndTarget.value

    changeRenewalDateInput.on('click', () => {
      this.radioChangeTarget.checked = true
      this.hideToggle()
    })

    changeRenewalDateInput.on('apply.daterangepicker', (ev, picker) => {
      if (currentTrialEndValue) {
        this.handleDateSelection(ev, picker)
      }
    })
  }

  compareTrialEndAndRenewal(changeRenewalValue) {
    const currentTrialEndValue = this.currentTrialEndTarget.value
    const selectedDate = new Date(changeRenewalValue)
    const trialDate = new Date(currentTrialEndValue)
    selectedDate > trialDate ? this.hideToggle() : this.showToggle()
  }

  handleRadioChange(event) {
    const selectedValue = event.target.value
    const datepickerField = this.element.querySelector('#order_change_renewal_date_to')
    const currentTrialEndValue = this.currentTrialEndTarget.value
    if (selectedValue === 'now') {
      datepickerField.value = ''
      if (currentTrialEndValue) {
        this.showToggle()
        this.uncheckEndTrial()
      }
    } else if (selectedValue === 'change') {
      this.hideToggle()
      if (datepickerField.value === '') {
        event.stopPropagation()
        this.submitButtonTarget.classList.add('disabled')
      }
    }
  }

  handleChange(event) {
    const changeRenewalValue = event.target.value
    const currentTrialEndValue = this.currentTrialEndTarget.value
    if (currentTrialEndValue) {
      this.compareTrialEndAndRenewal(changeRenewalValue)
    }
  }

  handleDateSelection(ev, picker) {
    const changeRenewalValue = picker.startDate.format('MM-DD-YYYY')
    this.compareTrialEndAndRenewal(changeRenewalValue)
  }

  showToggle() {
    this.trialEndToggleTarget.classList.remove('hidden')
  }

  hideToggle() {
    this.trialEndToggleTarget.classList.add('hidden')
  }

  uncheckEndTrial() {
    const checkbox = this.element.querySelector('#endTrial')
    checkbox.checked = false
  }
}
