import ApplicationController from '../application_controller'

/**
 * Observes content changes and applies a fade animation to the table row containing the element.
 * @extends ApplicationController
 */
export default class extends ApplicationController {
  connect() {
    this.observeContentChange()
  }

  /**
   * Observes changes to the content of the connected element.
   * Initializes a MutationObserver to track changes and triggers actions on content change.
   * @private
   */
  observeContentChange() {
    /**
     * MutationObserver callback function.
     * @param {MutationRecord[]} mutationsList - List of mutation records.
     */
    const observerCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          this.contentChanged()
        }
      }
    }

    // Create a MutationObserver instance and start observing changes
    const observer = new MutationObserver(observerCallback)
    observer.observe(this.element, { subtree: true, characterData: true, childList: true })
  }

  /**
   * Callback function triggered when content within the observed element changes.
   * Applies a fade animation to the closest ancestor table row of the element.
   * The animation class is added and then removed after a delay.
   * @private
   * @usage
   *   data-action="change->products--inventory#contentChanged"
   */
  contentChanged() {
    const tableRow = this.element.closest('tr')
    tableRow.classList.add('animate-fade')

    // Remove animation class after the fade-out animation completes
    // so that the animation can be applied again on the next content change
    setTimeout(() => {
      tableRow.classList.remove('animate-fade')
    }, 3000)
  }
}
