import ApplicationController from './application_controller'
import { post } from '@rails/request.js'
import { toast } from 'utils/toast'

import dragula from 'dragula'

export default class extends ApplicationController {
  static values = {
    reorderPath: String,
    saveOnReorder: { type: Boolean, default: true },
  }

  // will be reissued as native dom events name prepended with 'sortable:' e.g. 'sortable:drag', 'sortable:drop', etc
  static pluginEventsToReissue = ['drag', 'dragend', 'drop', 'cancel', 'remove', 'shadow', 'over', 'out', 'cloned']

  connect() {
    this.initPluginInstance()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  initPluginInstance() {
    this.plugin = dragula([this.element], {
      moves: (el, container, handle) => {
        var $handles = $(el).find('.reorder-handle')
        if ($handles.length) {
          return (
            !!$(handle).closest('.reorder-handle').length &&
            $(handle).closest(`[data-controller="${this.identifier}"]`)[0] == container
          )
        } else {
          if (!$(handle).closest('.undraggable').length) {
            return this.element === container
          } else {
            return false
          }
        }
      },
      accepts: (el, target, source, sibling) => {
        if ($(sibling).hasClass('undraggable') && $(sibling).prev().hasClass('undraggable')) {
          return false
        } else {
          return true
        }
      },
    })
      .on('drop', () => {
        // save order here.
        if (this.saveOnReorderValue) {
          this.saveSortOrder()
        }
      })
      .on('over', () => {
        // deselect any text fields, or else things go slow!
        $(document.activeElement).blur()
      })

    this.initReissuePluginEventsAsNativeEvents()
  }

  initReissuePluginEventsAsNativeEvents() {
    this.constructor.pluginEventsToReissue.forEach((eventName) => {
      this.plugin.on(eventName, (...args) => {
        this.dispatch(eventName, { detail: { plugin: 'dragula', type: eventName, args: args } })
      })
    })
  }

  teardownPluginInstance() {
    if (this.plugin === undefined) {
      return
    }

    // revert to original markup, remove any event listeners
    this.plugin.destroy()
  }

  async saveSortOrder() {
    if (!this.hasReorderPathValue) {
      return
    }

    const idsInOrder = Array.from(this.element.querySelectorAll('* > [data-id]')).map((el) => {
      return parseInt(el.dataset?.id)
    })

    const response = await post(this.reorderPathValue, { body: JSON.stringify({ ids_in_order: idsInOrder }) })

    if (response.ok) {
      this.dispatch('order-changed')
      toast('notice', 'Sort order saved')
    } else {
      toast('alert', 'Failed to save sort order')
    }
  }
}
