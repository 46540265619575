import ApplicationController from '../application_controller'
import numbro from 'numbro/dist/numbro.min'
import languages from 'numbro/dist/languages.min'
Object.values(languages).forEach((l) => {
  numbro.registerLanguage(l)
})
// register clickfunnels specific locales

const en = JSON.parse(JSON.stringify(numbro.languages()['en-US']))
en['languageTag'] = 'en'
en.ordinal = numbro.languages()['en-US'].ordinal.bind({})
numbro.registerLanguage(en)

const es = JSON.parse(JSON.stringify(numbro.languages()['es-ES']))
es['languageTag'] = 'es'
es.ordinal = numbro.languages()['es-ES'].ordinal.bind({})
numbro.registerLanguage(es)

export default class extends ApplicationController {
  static targets = ['input', 'inputData']

  static outlets = ['unsaved-changes']

  static values = {
    locale: String,
  }

  dataToLocaleFormat() {
    numbro.setLanguage(this.localeValue)
    // this.inputTarget.dataset.previousValue = this.inputTarget.value
    let localeFormat = numbro(this.inputDataTarget.valueAsNumber).format({
      thousandSeparated: false,
      mantissa: this.decimalPlaces,
    })
    if (localeFormat == 'NaN') {
      localeFormat = ''
    }
    $(this.inputTarget).val(localeFormat)
    this.inputTarget.dataset.previousValue = this.inputTarget.value
    this.inputDataTarget.dataset.previousValue = this.inputDataTarget.valueAsNumber
  }

  localeToDataFormat() {
    numbro.setLanguage(this.localeValue)
    // this.inputDataTarget.dataset.previousValue = this.inputDataTarget.valueAsNumber
    const newVal = numbro.unformat(this.inputTarget.value)
    const parsedNewVal = this.validateMinMax(newVal)
    if (parsedNewVal != newVal) {
      $(this.inputTarget).val(parsedNewVal)
    }
    $(this.inputDataTarget).val(parsedNewVal)
    this.inputTarget.dataset.previousValue = this.inputTarget.value
    this.inputDataTarget.dataset.previousValue = this.inputDataTarget.valueAsNumber
  }

  connect() {
    if (!['', null, undefined].includes(this.inputDataTarget.value)) {
      this.dataToLocaleFormat(this.inputDataTarget.valueAsNumber)
      this.inputTarget.dataset.previousValue = this.inputTarget.value
      this.inputDataTarget.dataset.previousValue = this.inputDataTarget.valueAsNumber

      // If we modified the input above and there is an unsaved-changes controller on the page then we
      // need to re-serialize the form so this number field doesn't think there are unsaved changes.
      if (this.hasUnsavedChangesOutlet) {
        this.unsavedChangesOutlet.serializeForm()
      }
    }
  }

  increment() {
    this.inputDataTarget.stepUp()
    this.dispatchChangeEvent()
    this.dataToLocaleFormat()
  }

  decrement() {
    this.inputDataTarget.stepDown()
    this.dispatchChangeEvent()
    this.dataToLocaleFormat()
  }

  validateMinMax(numberInput) {
    const max = this.inputDataTarget.max ? Number(this.inputDataTarget.max) : undefined
    const min = this.inputDataTarget.min ? Number(this.inputDataTarget.min) : undefined
    if (max !== undefined && numberInput > max) {
      numberInput = max
    }

    if (min !== undefined && numberInput < min) {
      numberInput = min
    }
    return numberInput
  }

  validateNumericality(event) {
    const regex = this.allowDecimal ? new RegExp(`[^0-9-${this.delimiter()}]`) : /[^0-9-]/
    const sanitizedInput = this.inputTarget.value.replace(regex, '')
    this.inputTarget.value = sanitizedInput
    const validatedInput = this.validateMinMax(Number(sanitizedInput))
    const min = this.inputDataTarget.min ? Number(this.inputDataTarget.min) : undefined

    if (this.inputTarget.value === '-' && min && min >= 0) {
      this.inputTarget.value = min
      this.localeToDataFormat()
      // Allow empty values
    } else if (isNaN(validatedInput) && this.inputTarget.value != '-') {
      this.inputTarget.value = this.inputTarget.dataset.previousValue || ''
      this.localeToDataFormat()
    } else if (['', '-'].includes(this.inputTarget.value)) {
      this.localeToDataFormat()
      // Allow negative values if min < 0 and allow decimals to be entered
    } else {
      this.localeToDataFormat()
    }
  }

  validateDecimal(event) {
    const input = this.inputTarget
    const sanitizedInput = input.value.replace(new RegExp(`[^-0-9${this.delimiter()}]`, 'g'), '')
    const parts = sanitizedInput.split(this.delimiter())
    const decimal_part_length = this.inputDataTarget.step.split('.')[1].length
    const min = this.inputDataTarget.min ? Number(this.inputDataTarget.min) : undefined

    // Check where there are more than two parts or if the decimal part has more than two digits
    // or if the sanitized version input is different from what the user input
    if (
      parts.length > 2 ||
      parts[1]?.length > decimal_part_length ||
      sanitizedInput !== input.value ||
      input.value.split('-').length > 2
    ) {
      input.value = input.dataset.previousValue || ''
      this.localeToDataFormat()
    } else if (event.data === '-' && min && min >= 0) {
      this.inputTarget.value = min
      this.localeToDataFormat()
    } else {
      this.localeToDataFormat()
    }
  }

  dispatchChangeEvent() {
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  get allowDecimal() {
    return this.inputTarget.step?.includes(this.delimiter())
  }

  get decimalPlaces() {
    if (!this.allowDecimal) {
      return 0
    }
    return this.inputTarget.step.split(this.delimiter())[1].length
  }

  handleKeydown(event) {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      this.increment()
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      this.decrement()
    }
  }

  delimiter() {
    return numbro.languages()[this.localeValue]['delimiters']['decimal']
  }
}
