import ApplicationController from '../application_controller'
import moment from 'moment'

export default class extends ApplicationController {
  static targets = ['startField', 'endField', 'previewText']
  static values = {
    momentFormat: { type: String, default: 'MMM D' },
    momentFormatWithYear: { type: String, default: 'MMM D, YYYY' },
  }

  connect() {
    this.updatePreviewText()
  }

  updatePreviewText() {
    if (!this.hasStartFieldTarget || !this.hasEndFieldTarget) {
      return
    }

    let start = moment(this.startFieldTarget?.value)
    let end = moment(this.endFieldTarget?.value)

    const now = moment()
    const rangeAllCurrentYear = start.isSame(now, 'year') && end.isSame(now, 'year')

    if (start.isSame(end, 'day')) {
      this.previewTextTarget.innerHTML = `
        ${rangeAllCurrentYear ? start.format(this.momentFormatValue) : start.format(this.momentFormatWithYearValue)}
      `
    } else {
      this.previewTextTarget.innerHTML = `
        ${rangeAllCurrentYear ? start.format(this.momentFormatValue) : start.format(this.momentFormatWithYearValue)}
        -
        ${rangeAllCurrentYear ? end.format(this.momentFormatValue) : end.format(this.momentFormatWithYearValue)}
      `
    }
  }
}
