/* eslint-disable no-undef */
import { post } from '@rails/request.js'

import ApplicationController from '../application_controller'
import { toast } from 'utils/toast'

export default class extends ApplicationController {
  static targets = [
    'cardForm',
    'cardMountElement',
    'paymentMethodForm',
    'rebillyToken',
    'paymentMethod',
    'submitButton',
  ]

  static values = {
    organizationId: String,
    publishableKey: String,
    liveMode: String,
    orderId: String,
    formAction: String,
    rebillyFirstName: String,
    rebillyLastName: String,
    rebillyAddress: String,
    rebillyAddress2: String,
    rebillyCity: String,
    rebillyCountry: String,
    rebillyRegion: String,
    rebillyPostalCode: String,
  }

  connect() {
    if (!Rebilly.initialized) {
      var config = {
        organizationId: this.organizationIdValue,
        publishableKey: this.publishableKeyValue,
        kountAccountId: '700000', // This is for capturing kount fraud sessions
      }

      Rebilly.initialize(config)
    }

    Rebilly.on('ready', () => {
      this.card = Rebilly.card.mount(this.cardMountElementTarget)
    })
  }

  async submit(event) {
    event.preventDefault()
    event.stopPropagation()

    const form = this.cardFormTarget
    const address = {
      firstName: this.rebillyFirstNameValue,
      lastName: this.rebillyLastNameValue,
      address: this.rebillyAddressValue,
      address2: this.rebillyAddress2Value,
      city: this.rebillyCityValue,
      country: this.rebillyCountryValue,
      region: this.rebillyRegionValue,
      postalCode: this.rebillyPostalCodeValue,
    }

    const extraData = {
      billingAddress: address,
      method: 'payment-card',
    }

    this.submitButtonTarget.setAttribute('disabled', '')

    try {
      const paymentToken = await Rebilly.createToken(form, extraData)

      const data = {
        billing_payment_method: {
          live_mode: this.liveModeValue,
          rebilly_token: paymentToken.id,
          order_id: this.orderIdValue,
        },
      }

      const action = this.formActionValue
      const response = await post(action, {
        body: JSON.stringify(data),
        responseKind: 'turbo-stream',
      })

      if (response.ok) {
        console.log('Response was successful')
      }
    } catch (error) {
      console.log('Create token error:', error)
      toast('alert', error.message)
      this.submitButtonTarget.removeAttribute('disabled', '')
    }
  }

  disconnect() {
    if (this.card) {
      this.card.unmount()
    }
  }
}
