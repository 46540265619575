import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['toggleContent', 'multi']

  connect() {
    useClickOutside(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  hideAllContent() {
    this.toggleContentTargets.forEach((el) => el.classList.add('hidden'))
  }

  showAllContent() {
    this.toggleContentTargets.forEach((el) => el.classList.remove('hidden'))
  }

  toggleAllContent() {
    this.toggleContentTargets.forEach((el) => el.classList.toggle('hidden'))
    this.dispatch('toggled', { detail: { sourceEl: this.element } })
  }

  toggleHiddenElements() {
    this.toggleAllContent()
  }

  toggleSpecificContent(event) {
    const selector = event.currentTarget.dataset.contentSelector
    const specificContent = Array.from(this.element.querySelectorAll(selector))

    this.toggleContentTargets.filter((el) => !specificContent.includes(el)).forEach((el) => el.classList.add('hidden'))
    specificContent.forEach((el) => el.classList.toggle('hidden'))
    // TODO: Also clear inner form fields that are being hidden
    this.dispatch('toggled', { detail: { sourceEl: this.element } })
  }

  toggleByValue(event) {
    this.multiTargets.forEach((el) => el.classList.add('hidden'))
    $(`#${event.currentTarget.value}`).removeClass('hidden')
    this.dispatch('toggled', { detail: { sourceEl: this.element } })
  }
}
