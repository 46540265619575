import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = [
    'productTypeCheckbox',
    'productTypeHiddenFieldset',
    'weightAndUnitHiddenFieldset',
    'weightAndUnitFieldset',
    'shippingOverrideFieldset',
  ]
  static values = {
    overrideEventSourceElementId: String,
    productTypeToggleEventSourceElementId: String,
  }

  handleOverrideToggle(event) {
    if (event.detail.sourceEl.id == this.overrideEventSourceElementIdValue) {
      $(this.shippingOverrideFieldsetTarget).prop('disabled', (_, val) => !val)
      $(this.productTypeHiddenFieldsetTarget).prop('disabled', (_, val) => !val)
      if (event.detail.visible && this.productTypeCheckboxTarget.checked) {
        $(this.weightAndUnitHiddenFieldsetTarget).prop('disabled', true)
        $(this.weightAndUnitFieldsetTarget).prop('disabled', false)
      } else {
        $(this.weightAndUnitHiddenFieldsetTarget).prop('disabled', false)
        $(this.weightAndUnitFieldsetTarget).prop('disabled', true)
      }
      this.element.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  handleProductTypeToggle(event) {
    if (event.detail.sourceEl.id == this.productTypeToggleEventSourceElementIdValue) {
      $(this.weightAndUnitHiddenFieldsetTarget).prop('disabled', (_, val) => !val)
      $(this.weightAndUnitFieldsetTarget).prop('disabled', (_, val) => !val)

      this.element.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
}
