import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['select']
  static values = {
    name: String,
    key: String,
  }

  connect() {
    const option = document.createElement('option')
    option.value = this.keyValue
    option.text = this.nameValue

    this.selectTarget.appendChild(option)
    option.setAttribute('selected', 'selected')
  }
}
