import BaseSearchController from './base_search_controller'

export default class extends BaseSearchController {
  static targets = ['closeElement', 'refineFilterModal']

  beforeConnect() {
    this.searchPathValue = window.location
  }

  _makeSearchRequest(url) {
    this.searchFrame.src = url
    if (this.hasCloseElementTarget) {
      this.closeElementTarget.classList.add('hidden')
    }

    const newUrl = new URL(url)
    Turbo.navigator.history.push(newUrl)

    this.dispatch('url-updated', { detail: { url: url } })
  }

  resetFilter() {
    // This resets the turbo-frame and allows it to reload from the original url
    const ogSrc = this.refineFilterModalTarget.src
    this.refineFilterModalTarget.src = null
    this.refineFilterModalTarget.src = ogSrc
  }
}
