import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['container', 'nonDraggable']

  oldX = null
  oldY = null
  isDragging = false

  connect() {
    this.containerTarget.style.cursor = 'grab'
  }

  drag(e) {
    if (this.isDragging) {
      var newX = this.oldX - e.clientX
      var newY = this.oldY - e.clientY
      var scrollLeft = this.containerTarget.scrollLeft
      var scrollTop = this.containerTarget.scrollTop
      this.containerTarget.scrollLeft = scrollLeft + newX
      this.containerTarget.scrollTop = scrollTop + newY
      this.oldX = e.clientX
      this.oldY = e.clientY
    }
  }

  startDrag(e) {
    if (
      this.nonDraggableTargets.includes(e.target) ||
      this.nonDraggableTargets.some((target) => target.contains(e.target))
    )
      return

    this.containerTarget.style.cursor = 'grabbing'
    this.oldX = e.clientX
    this.oldY = e.clientY
    this.isDragging = true
  }

  stopDrag() {
    this.containerTarget.style.cursor = 'grab'
    this.isDragging = false
  }
}
