import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['icon', 'spinner', 'searchInput']

  static classes = ['hidden']

  static values = {
    frame: { type: String, default: 'index_search' },
    disableSubmit: { type: Boolean, default: false },
  }

  showLoadingIndicator(event) {
    if (event.params.frame !== this.frameValue) return

    this.iconTarget.classList.add(...this.hiddenClasses)
    this.spinnerTarget.classList.remove(...this.hiddenClasses)

    if (this.disableSubmitValue == true && this.hasSearchInputTarget) {
      this.searchInputTarget.readOnly = true
    }
  }

  hideLoadingIndicator(event) {
    if ((event.target.id || event.detail?.newStream?.target) !== this.frameValue) return

    this.iconTarget.classList.remove(...this.hiddenClasses)
    this.spinnerTarget.classList.add(...this.hiddenClasses)
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.readOnly = false
    }
  }
}
