import ApplicationController from '../application_controller'
import Rails from '@rails/ujs'
import { get, post } from '@rails/request.js'
import { useDispatch } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['form']

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }

  async import(event) {
    if (event.target.tagName == 'A') return

    this.element.style.pointerEvents = 'none'
    event.preventDefault()
    this.element.dispatchEvent(new CustomEvent('stock-import:import-begin', { bubbles: true }))

    const importUrl = event.currentTarget.dataset.url
    const response = await post(importUrl, { responseKind: 'turbo-stream' })

    if (response.ok) {
      this.element.dispatchEvent(new CustomEvent('stock-import:import-end', { bubbles: true }))
    } else {
      this.element.dispatchEvent(new CustomEvent('stock-import:import-error', { bubbles: true }))
    }
    this.element.style.pointerEvents = 'auto'
  }
}
