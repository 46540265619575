import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['button']
  static classes = ['inactive', 'active']

  activeClasses = ['button-active']

  toggle(event) {
    let activeButton = event.currentTarget
    let inactiveButtons = this.buttonTargets.filter((button) => button !== activeButton)

    activeButton.classList.add(...this.activeClasses)
    inactiveButtons.forEach((button) => {
      button.classList.remove(...this.activeClasses)
    })

    this.dispatch('toggle', { detail: { activeButton: activeButton } })
  }
}
