import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['course', 'section']

  courseTargetConnected(element) {
    if (this.courseTarget.dataset.allAccess == 'true') {
      element.checked = true

      if (this.courseTarget.dataset.inherited == 'true') {
        element.disabled = true
      }
    } else if (this.allEnabledSectionsChecked()) {
      this.setAllSectionsCheckedState()
    } else if (this.noEnabledSectionsChecked()) {
      this.setNoEnabledSectionsCheckedState()
    } else {
      this.setSomeEnabledSectionsCheckedState()
    }
  }

  sectionTargetConnected(element) {
    if (element.dataset.checked == 'true') {
      element.checked = true

      if (element.dataset.inherited == 'true') {
        element.disabled = true
      }
    } else {
      element.checked = false
    }
  }

  toggleCourse(event) {
    this.sectionTargets.forEach((section) => {
      if (section.dataset.inherited == 'false') {
        section.checked = event.target.checked
      }
    })
  }

  toggleSection(event) {
    if (this.allEnabledSectionsChecked()) {
      this.setAllSectionsCheckedState()
    } else if (this.noEnabledSectionsChecked()) {
      this.setNoEnabledSectionsCheckedState()
    } else {
      this.setSomeEnabledSectionsCheckedState()
    }

    const section = event.target
    this.toggleDescendantSections(section, section.checked)
    this.toggleAncestorSections(section, section.checked)
  }

  enabledCourseSections() {
    let collection = []

    this.sectionTargets.forEach((section) => {
      if (section.dataset.inherited == 'false') {
        collection.push(section)
      }
    })

    return collection
  }

  noEnabledSectionsChecked() {
    let checkedCount = 0

    this.enabledCourseSections().forEach((section) => {
      if (section.checked) {
        checkedCount += 1
      }
    })

    return checkedCount == 0
  }

  allEnabledSectionsChecked() {
    let checkedCount = 0
    let sectionCount = 0

    this.enabledCourseSections().forEach((section) => {
      sectionCount += 1

      if (section.checked) {
        checkedCount += 1
      }
    })

    return checkedCount == sectionCount
  }

  setNoEnabledSectionsCheckedState() {
    this.courseTarget.checked = false
    this.courseTarget.indeterminate = false
  }

  setSomeEnabledSectionsCheckedState() {
    this.courseTarget.checked = true
    this.courseTarget.indeterminate = true
  }

  setAllSectionsCheckedState() {
    this.courseTarget.checked = true
    this.courseTarget.indeterminate = false

    if (this.courseTarget.dataset.inherited == 'true' && this.courseTarget.dataset.allAccess == 'true') {
      this.courseTarget.disabled = true
    } else {
      this.courseTarget.disabled = false
    }
  }

  toggleAncestorSections(section) {
    const parentId = section.dataset.parentId
    if (!parentId) return

    const parentSection = this.findSectionById(parentId)
    if (parentSection && !parentSection.checked) {
      parentSection.checked = true
      this.toggleAncestorSections(parentSection)
    }
  }

  toggleDescendantSections(section) {
    const sectionId = section.dataset.sectionId
    this.sectionTargets.forEach((sectionTarget) => {
      if (sectionTarget.dataset.parentId == sectionId) {
        sectionTarget.checked = section.checked
        this.toggleDescendantSections(sectionTarget)
      }
    })
  }

  findSectionById(sectionId) {
    return this.sectionTargets.find((section) => section.dataset.sectionId == sectionId)
  }
}
