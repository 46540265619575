import ApplicationController from '../application_controller'
import { useClickOutside } from 'stimulus-use'
import { Picker } from 'emoji-mart'
import Cookies from 'js-cookie'

export default class extends ApplicationController {
  static targets = ['input', 'display', 'clear']

  connect() {
    this.visible = false
    this.locale = Cookies.get('locale') || 'en'
    this.picker = {}

    this.initializePicker()
    useClickOutside(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  async initializePicker() {
    const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
    this.picker = new Picker({
      data: await response.json(),
      onEmojiSelect: (emoji) => {
        this.setEmoji(emoji.native)
        this.element.removeChild(this.picker)
        this.visible = false
      },
      locale: this.locale.slice(0, 2),
    })
  }

  clickOutside(event) {
    if (this.visible) {
      this.toggle(event)
    }
  }

  toggle(event) {
    event.preventDefault()
    if (this.visible) {
      this.element.removeChild(this.picker)
    } else {
      this.element.appendChild(this.picker)
    }
    this.visible = !this.visible
  }

  clear() {
    this.picker.update({ emoji: null })
    this.setEmoji()
  }

  setEmoji(emoji = '') {
    this.inputTarget.value = emoji
    this.displayTarget.innerHTML = emoji ? emoji : '<i class="fa-regular fa-face-smile"></i>'
    this.clearTarget.classList.toggle('hidden')
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }
}
