import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    attr: String,
  }

  static targets = ['tag']

  set({ params: { value } }) {
    this.tagTarget.setAttribute(this.attrValue, value)
  }
}
