import { useDispatch } from 'stimulus-use'
import axios from 'axios'
import { IntegryJS } from '@integry/sdk'
import IntegryController from './integry_controller'
import Bugsnag from '@bugsnag/browser'

class IntegryImportError extends Error {
  constructor(error) {
    super(error)
    this.name = 'IntegryImportError'
  }
}

export default class extends IntegryController {
  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
    this.loadIntegry()
    this.attachEvents()
    this.hideFeatureGatedImportConnectors()
    this.renderCfClassicHelperText()
  }

  attachEvents() {
    this.sdkInstance.init({
      containerId: 'cf-integry-container',
      renderMode: IntegryJS.RenderModes.INLINE,
      showApps: true,
    })

    this.sdkInstance.eventEmitter.on('did-save-integration', (data) => {
      if (process.env.NODE_ENV === 'development') console.log('did-save-integration', data)

      axios.post(data.callbackUrl, { data: { limit: 200000 } }).catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(new IntegryImportError(error))
        } else {
          Bugsnag.notify(new IntegryImportError(error), (event) => {
            event.addMetadata('original message', error.message)
          })
        }
      })
      document.getElementById('import-started').classList.toggle('hidden')
      document.getElementById('cf-integry-container').classList.toggle('hidden')
    })
  }

  renderCfClassicHelperText() {
    const helperTextObserver = new MutationObserver((_, observer) => {
      const renderedAppTitle = document.querySelector('div[class^="styles-module_appName"]')
      const parentElement = document.querySelector('#appPageContainer-COMFORTABLE--INLINE')

      if (renderedAppTitle && renderedAppTitle.innertext == 'ClickFunnels Classic' && parentElement) {
        observer.disconnect()

        const helpText = document.createElement('div')
        helpText.innerHTML =
          'If you are currently signed into one of the accounts listed above, you must sign out before attempting to add another account.' // eslint-disable-line
        helpText.style.padding = '20px 0'
        helpText.style.fontSize = '12px'
        parentElement[0].appendChild(helpText)
      }
    })

    helperTextObserver.observe(document, { childList: true, subtree: true })
  }

  // In the near future this can probably be managed with Tags as opposed to dom scripting
  hideFeatureGatedImportConnectors() {
    const integryObserver = new MutationObserver((_, observer) => {
      const renderedImportList = document.querySelector('#embed--appsForFlowsCardsComfortable__INLINE')

      if (renderedImportList) {
        observer.disconnect()
        const integryImportFlows = renderedImportList.children
        const importConnectorFeatureGates = {}

        importConnectorFeatureGates['ClickFunnels Classic'] = this.cfClassicImportEnabledValue
        importConnectorFeatureGates['Google Spreadsheet'] = this.googleSheetsImportEnabledValue

        const flowsToRemove = Object.keys(importConnectorFeatureGates).filter(
          (e) => importConnectorFeatureGates[e] === false
        )

        for (const importFlow of integryImportFlows) {
          const importFlowText = importFlow.innerText.replace('Not Connected', '').trim()

          if (flowsToRemove.includes(importFlowText)) {
            importFlow.style.display = 'none'
          }
        }
      }
    })

    integryObserver.observe(document, { childList: true, subtree: true })
  }
}
