import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['container', 'table']

  connect() {
    if (this.hasTableTarget && this.hasContainerTarget) {
      this.checkWidth()
    }
  }

  checkWidth() {
    let tableWidth = this.tableTarget.offsetWidth
    let containerWidth = this.containerTarget.offsetWidth

    if (tableWidth > containerWidth) {
      this.tableTarget.classList.add('sticky-shadow')
    } else {
      this.tableTarget.classList.remove('sticky-shadow')
    }
  }
}
