import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['submit', 'users', 'teams', 'workspaces']
  static values = {
    teamsUrl: String,
    workspacesUrl: String,
  }

  updateTeamsSearchUrl(event) {
    let replacedUrl = new URL(this.teamsUrlValue.replace(':id', event.target.value))
    this.teamsTarget.setAttribute('data-fields--super-select-search-url-value', replacedUrl)
  }

  updateWorkspacesSearchUrl(event) {
    let replacedUrl = new URL(this.workspacesUrlValue.replace(':id', event.target.value))
    this.workspacesTarget.setAttribute('data-fields--super-select-search-url-value', replacedUrl)
  }

  enableSubmit() {
    this.submitTargets.forEach((target) => {
      target.classList.remove('disabled')
    })
  }
}
