import { useDispatch } from 'stimulus-use'
import { IntegryJS } from '@integry/sdk'
import IntegryController from './integry_controller'
import { post } from '@rails/request.js'

export default class extends IntegryController {
  static values = {
    actionUrl: String,
    closeModalFunction: String,
    flowId: Number,
  }

  installationParams = {}
  description = ''

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
    this.loadIntegry()
    this.attachEvents()
    if (this.flowIdValue) {
      this.renderCfClassicFlow()
    }
  }

  renderCfClassicFlow() {
    this.sdkInstance.renderFlowSetupForm({
      flowContainerId: 'cf-integry-container',
      flowId: this.flowIdValue,
    })
  }

  attachEvents() {
    this.sdkInstance.init({
      containerId: 'cf-integry-container',
      renderMode: IntegryJS.RenderModes.DETACHED,
    })

    this.sdkInstance.eventEmitter.on('should-load-flow', (data) => {
      this.sdkInstance.renderFlowSetupForm({ flowId: `${data.templateId}`, flowContainerId: 'cf-integry-container' })
    })

    this.sdkInstance.eventEmitter.on('did-load-flow', (data) => {
      this.description = data.templateDescription
    })

    this.sdkInstance.eventEmitter.on('did-select-authorization', (data) => {
      this.update_connected_account_param(data)
    })

    this.sdkInstance.eventEmitter.on('did-save-integration', (data) => {
      // this is necessary to avoid Integry's next view which contains a
      // `Continue` button that we don't want the User to click
      const element = document.getElementById('fwb_select')
      element.outerHTML = '<div><h1>Saving to database...</h1></div>'

      this.saveInstance(data)
    })

    // call the super the super class to save this
    this.sdkInstance.eventEmitter.on('did-add-authorization', (data) => this.saveConnectedAccountReact(data))

    // call the super the super class to delete this
    this.sdkInstance.eventEmitter.on('did-remove-authorization', (data) =>
      this.deleteInstallationByConnectedAccountID(data.authorizationId)
    )
  }

  close() {
    parent.closeModalFunctions[this.closeModalFunctionValue]()
  }

  update_connected_account_param(data) {
    this.installationParams['integry_connected_account_id'] = data.authorizationId
  }

  async saveConnectedAccountReact(data) {
    const params = {
      integry_connected_account_id: data.authorizationId,
      integry_user_identity: data.identity,
      name: data.identity,
      active: true,
    }

    await post(this.installationPathValue, {
      body: { integrations_integry_installation: { ...params }, integry_app: { id: data.appId } },
      responseKind: 'json',
    })
  }

  async saveInstance(data) {
    const instanceParams = {
      integry_integration_id: data.integrationId,
      name: data.name,
      description: data.description,
      status: data.status,
      webhook_url: data.callbackUrl,
    }

    const response = await post(this.actionUrlValue, {
      body: {
        integrations_integry_installation_instance: { ...instanceParams },
        integrations_integry_installation: { ...this.installationParams },
      },
      responseKind: 'json',
    })

    if (response.ok) {
      const body = await response.json
      document.getElementById(
        'workflows_step_function_attributes_integrations_integry_installation_instance_id'
      ).value = body.id
      document.getElementById('workflows_step_name').value = this.description
    } else {
      console.log('Error saving installation instance')
    }

    document.querySelector('#integry-submit').click()
  }
}
