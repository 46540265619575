import ApplicationController from '../../application_controller'
/**
 * Controller for updating the selected count in a form displayed on the page based on checkbox states.
 *
 * @namespace shipping/service_groups
 * @class FormController
 * @extends ApplicationController
 * @example
 * // Example usage of shipping/service_groups/form_controller.js:
 * // <div data-controller="shipping--service-groups--form"></div>
 */
export default class extends ApplicationController {
  /**
   * The list of target elements this controller interacts with.
   *
   * @static
   * @memberof shipping/service_groups
   * @type {Array<string>}
   * @example
   * // Example usage of targets in HTML:
   * // <span data-shipping--service-groups--form-target="selectedCount"></span>
   * // <input type="checkbox" data-shipping--service-groups--form-target="checkbox">
   * // <div data-shipping--service-groups--form-target="alert"></div>
   */
  static targets = ['selectedCount', 'checkbox', 'alert']

  /**
   * Updates the selected count displayed in the form based on checkbox states.
   * It counts the number of checkboxes that are checked and updates the corresponding target element.
   *
   * @memberof shipping/service_groups
   * @example
   * // Example usage of updateCheckboxCount in HTML:
   * // <input type="checkbox" data-action="change->shipping--service-groups--form#updateCheckboxCount">
   */
  updateCheckboxCount() {
    /**
     * The target element displaying the selected count in the form.
     *
     * @name shipping/service_groups#selectedCountTarget
     * @type {Element}
     */
    this.selectedCountTarget.textContent = this.checkboxTargets.filter((checkbox) => checkbox.checked).length
  }

  /**
   * Verifies that at least one checkbox is checked before submitting the form.
   * If no checkboxes are checked, it prevents the default form submission and displays an alert.
   * Otherwise, it hides the alert.
   * @memberof shipping/service_groups
   * @example
   * // Example usage of verifyChecked in HTML:
   * // <input type="submit" data-action="click->shipping--service-groups--form#verifyChecked">
   */
  verifyChecked() {
    if (this.anyChecked()) {
      this.alertTarget.classList.add('hidden')
    } else {
      this.alertTarget.classList.remove('hidden')
      event.preventDefault()
    }
  }

  /**
   * Checks if any checkboxes are checked.
   * @memberof shipping/service_groups
   * @returns {boolean}
   */
  anyChecked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked).length !== 0
  }
}
