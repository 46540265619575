import ApplicationController from './application_controller'
import { useDispatch } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'field',
    'hiddenFieldsContainer',
    'hiddenFieldTemplate',
    'imagePreview',
    'imagePreviewContainer',
    'imageTemplate',
    'null',
    'label',
  ]
  static values = { fieldId: String }

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  showNullState() {
    if (!this.imagePreviewContainerTarget.classList.contains('hidden')) {
      this.imagePreviewContainerTarget.classList.add('hidden')
    }

    this.nullTarget.classList.remove('hidden')
  }

  showImagePreview(selectedItems) {
    if (!this.nullTarget.classList.contains('hidden')) {
      this.nullTarget.classList.add('hidden')
    }

    this.imagePreviewTarget.innerHTML = ''

    selectedItems.forEach(({ url, data: { label } }) => {
      let previewImage = this.imageTemplateTarget.content.cloneNode(true)
      previewImage.querySelector('img').src = url

      this.imagePreviewTarget.appendChild(previewImage)
      if (this.hasLabelTarget) {
        this.labelTarget.textContent = label
      }
    })

    if (this.imagePreviewContainerTarget.classList.contains('hidden')) {
      this.imagePreviewContainerTarget.classList.remove('hidden')
    }
  }

  handleSelected(event) {
    if (event.detail.fieldId !== this.fieldIdValue) {
      return
    }
    const selectedItems = event.detail.items
    this.fieldTargets.forEach((field) => field.remove())

    selectedItems.forEach(({ id }) => {
      let clonedHiddenField = this.hiddenFieldTemplateTarget.content.cloneNode(true)
      clonedHiddenField.querySelector('input').value = id
      this.hiddenFieldsContainerTarget.appendChild(clonedHiddenField)
    })

    if (selectedItems.length > 0) {
      this.showImagePreview(selectedItems)
      this.dispatchNewSelectionEvent(selectedItems)
    } else {
      this.showNullState()
    }

    this.emitChangeEvent()
  }

  reset() {
    this.fieldTargets.forEach((target) => (target.value = ''))

    this.emitChangeEvent()
    this.showNullState()
  }

  emitChangeEvent() {
    this.hiddenFieldsContainerTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }

  dispatchNewSelectionEvent(selectedItems) {
    this.dispatch('new-selection', {
      selectedItems,
      previousFieldId: this.fieldIdValue,
    })
  }
}
