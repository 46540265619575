import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'

/**
 * Event handler for the `sl-selected` event on the <sl-menu> element
 *
 * When the user selects an item in the menu, the `menuItemSelected` function is called.
 *
 * @see  ShoelaceHelper#sl_menu for usage
 * @see  https://shoelace.style/components/menu
 */

export default class extends ApplicationController {
  static targets = ['menu']
  static values = {
    autoClose: { type: Boolean, default: true },
  }
  /**
   * Handler for the `sl-selected` event on the <sl-menu> element
   *
   * @param [CustomEvent] event
   * @param [HTMLElement] event.detail.item - The selected menu item
   * @param [string] event.detail.item.dataset.href - The URL to visit when the user selects the item
   * @param [string] event.detail.item.dataset.turboFrame - The ID of the TurboFrame
   * @param [string] event.detail.item.dataset.turboMethod - The method of the turbo frame visit
   * @return [void]
   */
  menuItemSelected(event) {
    const { turboFrame, href, turboMethod, target } = event.detail.item.dataset

    if (href) {
      if (turboFrame) {
        let frame = document.querySelector(`turbo-frame#${turboFrame}`)
        frame.src = href
      } else if (turboMethod && turboMethod !== 'get') {
        this.performTurboMethodRequest(href, turboMethod)
      } else if (target) {
        window.open(href, target)
      } else {
        Turbo.visit(href)
      }
    } else if (event.detail.item.querySelector('form')) {
      event.detail.item.querySelector('form').requestSubmit()
    }
  }

  /**
   * Performs a turbo-stream request to a given href using a given turbo method.
   * This is native Turbo behavior via the data-turbo-method attr that we have to
   * include here in the event a menu item contains a turbo link with
   * a non-GET turbo-method.
   *
   * @param {string} href
   * @param {String} turboMethod (http request method, e.g. `GET` or `DELETE`)
   */
  async performTurboMethodRequest(href, turboMethod) {
    const req = new FetchRequest(turboMethod, href, { responseKind: 'turbo-stream' })
    await req.perform()
  }

  close() {
    this.element.hide()
  }

  handleClick(event) {
    if (this.menuTarget.contains(event.target) && this.autoCloseValue == true) {
      this.close()
    }
  }
}
