import Chart from 'stimulus-chartjs'

export default class extends Chart {
  static values = {
    data: Object,
  }

  connect() {
    // console.log('Chart Controller Initialized')

    super.connect()
    // The chart.js instance
    this.chart
    // Options from the data attribute.
    this.options
    // Default options for every charts.
    this.defaultOptions

    // console.log(this.chart)

    // Rewrite the ruby pattern command to eval JS
    for (const element of this.chart.config.data.datasets) {
      if (element?.backgroundColor?.includes('pattern')) {
        element.backgroundColor = new Function(`return ${element.backgroundColor}`)()
      } else if (Array.isArray(element.backgroundColor)) {
        element.backgroundColor.forEach((color, index) => {
          if (color.includes('pattern')) {
            element.backgroundColor[index] = new Function(`return ${color}`)()
          }
        })
      }
    }
    setTimeout(this.chart.update({ duration: 800, easing: 'easeOutBounce' }), 500)

    this.chart.update({
      duration: 800,
      easing: 'easeOutBounce',
    })
  }

  // Bind an action on this method
  async update() {
    // TODO dynamic updating in real time?  as data changes?  Turbo Streams?
    // const response = await fetch('https://example.com/chart_data.json')
    // const data = await response.json()
    // this.chart.data = data
    // this.chart.update()
  }

  // You can set default options in this getter for all your charts.
  get defaultOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,

      layout: {
        padding: {
          top: 0,
          right: 10,
          bottom: 10,
          left: 10,
        },
      },

      title: {
        display: true,
        fontSize: 16,
        padding: 10,
        text: this.data.get('title') || '',
        fontColor: '#151D28',
      },

      legend: {
        align: 'start',
        margin: 20,
        padding: 10,

        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 2,
          fontSize: 8,
          fullWidth: false,
          fontColor: '#516E86',
        },
      },

      elements: {
        line: {
          borderWidth: 1,
        },

        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          pointStyle: 'circle',
        },
      },

      scales: {
        x: {
          gridLines: {
            color: '#E8EDF1',
            display: false,
          },
          ticks: {
            beginAtZero: true,
            fontColor: '#B7C6CE',
            ...((this.typeValue === 'scatter' || this.typeValue === 'bubble') && {
              userCallback: function (value, index, ticks) {
                return this.chart.data.labels[index]
              },
            }),
          },
        },
        y: {
          gridLines: {
            color: '#E8EDF1',
          },
          ticks: {
            beginAtZero: true,
            fontColor: '#B7C6CE',
          },
        },
      },
      //if scatter or bubble, add tooltip cb.
      ...((this.typeValue === 'scatter' || this.typeValue === 'bubble') && {
        tooltips: {
          callbacks: {
            label: function (context) {
              return this._data.datasets[0].label + ': ' + context.yLabel
            },
          },
        },
      }),
      //if scatter or bubble, add tooltip cb.
      ...(this.typeValue === 'bar' && {
        tooltips: {
          // callbacks: {
          //   title: function(tooltipItem, data) {
          //     // return data.datasets[tooltipItem[0]['index']].label;
          //     return null;
          //   },
          //   label: function(tooltipItem, data) {
          //     // console.log(data)
          //     return data.datasets[tooltipItem['index']].label;
          //   },
          //   afterLabel: function(tooltipItem, data) {
          //     console.log(data.datasets[tooltipItem['index']].data[0]['y'])
          //     return "<b>"+data.datasets[tooltipItem['index']].data[0]['y']+"</b>";
          //   }
          // },
          // backgroundColor: '#FFF',
          // titleFontSize: 16,
          // titleFontColor: '#0066ff',
          // bodyFontColor: '#000',
          // bodyFontSize: 12,
          // displayColors: true
          enabled: false,
          custom: function (tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip')

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
              tooltipEl.innerHTML = '<table></table>'
              document.body.appendChild(tooltipEl)
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0
              return
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform')
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign)
            } else {
              tooltipEl.classList.add('no-transform')
            }

            function getBody(bodyItem) {
              return bodyItem.lines
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || []
              var bodyLines = tooltipModel.body.map(getBody)

              var innerHtml = '<thead>'

              titleLines.forEach(function (title) {
                innerHtml += '<tr><th>' + title + '</th><th></th></tr>'
              })
              innerHtml += '</thead><tbody>'

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i]

                var spanOne = document.createElement('span')
                spanOne.id = 'span_one'
                spanOne.style.borderColor = colors.borderColor
                spanOne.classList.add('pl-1')
                spanOne.classList.add('py-4')
                spanOne.classList.add('mr-2')
                spanOne.classList.add('ml-2')
                spanOne.classList.add('my-2')
                spanOne.classList.add('rounded')

                if (colors.backgroundColor instanceof CanvasPattern) {
                  // console.log(colors.borderColor);
                  // spanOne.style.borderColor = colors.borderColor;
                  // spanOne.style.borderWidth = '1px';
                  // console.log(spanOne.style.borderWidth);
                  spanOne.style.background =
                    'repeating-linear-gradient(-55deg,' +
                    colors.borderColor +
                    ',' +
                    colors.borderColor +
                    ' 1px,#fff 1px,#fff 3px)'
                  spanOne.style.color = 'white'
                } else {
                  spanOne.style.backgroundColor = colors.backgroundColor
                  spanOne.style.borderWidth = '0'
                }

                var bodyTitle = body[0].split(': ')[0]
                var bodyAmount = body[0].split(': ')[1]

                innerHtml +=
                  '<tr><td>' +
                  spanOne.outerHTML +
                  '</td><td><div class="text-sm text-coolGray-600 font-light mr-2">' +
                  bodyTitle +
                  '</div><div class="font-bold text-sm pt-1 mr-2">' +
                  bodyAmount +
                  '</div></td></tr>'
              })
              innerHtml += '</tbody>'

              var tableRoot = tooltipEl.querySelector('table')
              tableRoot.innerHTML = innerHtml
              console.log(tableRoot.innerHTML)
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect()

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1
            tooltipEl.style.backgroundColor = '#FFF'
            tooltipEl.classList.add('rounded-md')
            tooltipEl.classList.add('shadow')
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 20 + 'px'
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
            tooltipEl.style.pointerEvents = 'none'
          },
        },
      }),
    }
  }
}
