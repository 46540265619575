import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    // updateComplete() tells us Shoelace elements have finished hydrating
    if (typeof this.element.updateComplete !== 'undefined') {
      this.element.updateComplete.then(() => {
        this.element.focus()
      })
    } else {
      this.element.focus()
    }
  }
}
