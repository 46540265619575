import ApplicationController from '../../application_controller'
import Sortable from 'sortablejs'

/**
 * Stimulus controller to handle sortable functionality for the mutliple
 * selection variation of the ImageFieldComponent. Enables drag & drop
 * sorting and manages hidden input fields representing sort order.
 */
export default class extends ApplicationController {
  static targets = ['container', 'hiddenField']
  connect() {
    this.sortable = new Sortable.create(this.containerTarget, {
      dataIdAttr: 'data-image-id',
      filter: '[data-sortable-ignore]',
      onStart: this.onStart.bind(this),
      onEnd: this.onEnd.bind(this),
    })
  }

  /**
   * Event handler called when sorting starts.
   */
  onStart() {
    this.containerTarget.classList.add('is-sorting')
  }

  /**
   * Event handler called when sorting ends.
   * @param {Event} event - The event object.
   */
  onEnd() {
    const newSortOrder = this.sortable.toArray()
    this.updateHiddenFields(newSortOrder)
    this.containerTarget.classList.remove('is-sorting')
  }

  /**
   * Updates the hidden fields with the new sort order.
   * @param {Array<string>} newSortOrder - Array of image IDs representing the new sort order.
   */
  updateHiddenFields(newSortOrder = []) {
    newSortOrder.forEach((imageId, sortOrder) => {
      let field = this.hiddenFieldTargets.find((input) => input.dataset.imageId == imageId)
      if (field) {
        field.value = sortOrder
      }
    })
    this.element.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }
}
