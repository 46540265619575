import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['dateInput', 'submitButton', 'publishNowText', 'timezoneLabel']

  static values = {
    publishNowText: String,
  }

  connect() {
    this.originalDateValue = this.dateInputTarget.value
  }

  resetField() {
    // restore the original publish date.
    this.dateInputTarget.value = this.originalDateValue
    this.publishNowTextTarget.textContent = this.publishNowTextValue

    // re-enable the submit button
    this.enableSubmit()
  }

  updateTimezoneLabel(event) {
    this.timezoneLabelTarget.innerText = event.detail?.timezone
  }

  disableSubmit() {
    this.submitButtonTarget.disabled = true
    this.dispatch('submit-disabled')
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false
    this.dispatch('submit-enabled')
  }

  toggleSubmit(event) {
    if (event.target.id !== 'blogs/posts/publish_action_scheduled_for_display_wrapper') return
    this.dateInputTarget.value ? this.enableSubmit() : this.disableSubmit()
  }
}
