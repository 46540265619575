import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'preview']

  /**
   * Updates the preview content based on the input value.
   *
   * @returns {void}
   */
  updatePreview() {
    this.previewTarget.textContent = this.inputTarget.value
  }

  /**
   * Updates the domain preview based on the input value.
   *
   * @returns {void}
   */
  updateDomainPreview() {
    const pattern = /[^\w-]+/g

    this.previewTarget.textContent = this.inputTarget.value.toLowerCase().replace(pattern, '').replace(/-+/g, '')
  }
}
