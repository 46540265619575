/*
  <div data-controller="time-remaining">
    <input type="hidden" name="deadline" value="2022-08-19 15:40:30 UTC" data-time-remaining-target="deadline">
    <h2>Time remaining: <span data-time-remaining-target="display"><span></h2>
  </div>
*/
import ApplicationController from './application_controller'
import { formatLabelAndValue } from '../utils/format_label_and_value'
export default class extends ApplicationController {
  static targets = ['deadline', 'display', 'countdown', 'elapsedMessage']
  static values = {
    granularity: { type: String, default: 'days' },
    daysLabel: { type: String, default: 'days' },
    hoursLabel: { type: String, default: 'hours' },
    minutesLabel: { type: String, default: 'minutes' },
    secondsLabel: { type: String, default: 'seconds' },
    elapsedLabel: { type: String, default: 'time has elapsed' },
  }

  connect() {
    this.start()
  }

  disconnect() {
    clearInterval(this.intervalId)
  }

  start() {
    this.intervalId = setInterval(() => {
      this.calculateTimeRemaining()
    }, 1000)
  }

  calculateTimeRemaining() {
    const deadline = new Date(this.deadlineTarget.value).getTime()
    const now = new Date().getTime()
    const timeRemaining = deadline - now

    if (timeRemaining < 0) {
      this.dispatch('time-elapsed')
      clearInterval(this.intervalId)
      return
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

    this.generateDisplayString(days, hours, minutes, seconds)
  }

  generateDisplayString(days, hours, minutes, seconds) {
    let subString1Values = []
    let subString2Values = []

    switch (this.granularityValue) {
      case 'days':
        subString1Values = [this.days(days), this.hours(hours)]
        subString2Values = [this.minutes(minutes), this.seconds(seconds)]
        break
      case 'hours':
        subString1Values = [this.hours(hours)]
        subString2Values = [this.minutes(minutes), this.seconds(seconds)]
        break
      case 'minutes':
        subString2Values = [this.minutes(minutes), this.seconds(seconds)]
        break
      case 'seconds':
        subString2Values = [this.seconds(seconds)]
        break
      default:
        subString1Values = [this.days(days), this.hours(hours)]
        subString2Values = [this.minutes(minutes), this.seconds(seconds)]
        break
    }

    // Split displaySubStrings with Unicode &nbsp; in order to control wrapping
    const displaySubString1 = subString1Values.filter((v) => v).join(',\xa0')
    const displaySubString2 = subString2Values.filter((v) => v).join(',\xa0')

    const displayString = displaySubString1 ? `${displaySubString1}, ${displaySubString2}` : displaySubString2

    if (displayString) {
      this.displayTarget.innerText = displayString
    } else {
      if (this.hasCountdownTarget) this.countdownTarget.hidden = true
      if (this.hasElapsedMessageTarget) this.elapsedMessageTarget.hidden = false
      this.displayTarget.innerText = this.elapsedLabelValue
    }
  }

  days(value) {
    return formatLabelAndValue(this.daysLabelValue, value)
  }

  hours(value) {
    return formatLabelAndValue(this.hoursLabelValue, value)
  }

  minutes(value) {
    return formatLabelAndValue(this.minutesLabelValue, value)
  }

  seconds(value) {
    return formatLabelAndValue(this.secondsLabelValue, value)
  }
}
