import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['itemsList']

  connect() {
    new MutationObserver(this.autoOpenNewItem).observe(this.itemsListTarget, {
      childList: true,
      subtree: true,
    })
  }

  autoOpenNewItem(mutations) {
    for (let mutation of mutations) {
      if (mutation.type === 'childList') {
        let addedNode = $(mutation.addedNodes).find('a')

        if (addedNode.attr('data-auto-open') == 'false') {
          return
        } else {
          addedNode[0]?.click()
        }
      }
    }
  }
}
