import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['details', 'checkbox', 'fauxCheckbox']
  static values = {
    shouldUpdateAncestors: Boolean,
    shouldUpdateDescendants: Boolean,
    shouldUpdateRoot: Boolean,
    expandAllDetails: Boolean,
  }

  connect() {
    this.syncCheckboxesToFauxCheckboxes()

    if (this.expandAllDetailsValue) {
      this.expandAllDetails(true)
    }
  }

  /**
   * Synchronizes the state of the checkboxes with faux checkboxes.
   * @function
   * @name SharedCheckboxTreeController#syncCheckboxesToFauxCheckboxes
   * @returns {void}
   */
  syncCheckboxesToFauxCheckboxes() {
    this.fauxCheckboxTargets.forEach((fauxCheckbox) => {
      let checkboxId = fauxCheckbox.dataset.targetCheckboxId
      let checkbox = this.checkboxTargets.find((checkbox) => checkbox.id === checkboxId)
      fauxCheckbox.checked = checkbox.checked
    })
  }

  /**
   * Handles the change event for a faux checkbox element in the tree.
   * Conditionally updates the descendants and ancestors of the checkbox based on its new checked state.
   * @param {Event} event - The change event triggered by the checkbox.
   */
  handleChange(event) {
    const fauxCheckbox = event.target
    const isChecked = fauxCheckbox.checked
    const details = fauxCheckbox.closest('details')
    const checkbox = details.querySelector("[data-components--checkbox-tree-target='checkbox']")

    checkbox.checked = isChecked

    if (this.shouldUpdateDescendantsValue) {
      this.updateDescendents(fauxCheckbox, isChecked)
    }

    if (this.shouldUpdateAncestorsValue) {
      this.updateAncestors(fauxCheckbox, isChecked)
    }

    if (!this.shouldUpdateRootValue) {
      this.updateRoot()
    }
  }

  /**
   * Controls expansion of all the detail elements in the checkbox tree.
   */
  expandAllDetails(value) {
    this.detailsTargets.forEach((details) => {
      details.open = value
    })
  }

  /**
   * Expands all the details elements in the checkbox tree.
   * @param {Event} event - The event object.
   */
  expandAll(event) {
    event.preventDefault()
    this.expandAllDetails(true)
  }

  /**
   * Collapses all the details targets in the checkbox tree.
   * @param {Event} event - The event object.
   */
  collapseAll(event) {
    event.preventDefault()
    this.expandAllDetails(false)
  }

  /**
   * Determines whether a child checkbox should be updated.
   * @param {HTMLInputElement} parentCheckbox - The parent checkbox object.
   * @param {*} isChecked - The checked state of the checkbox.
   * @returns {Boolean} - Whether the checkbox should be updated.
   */
  shouldUpdateChildCheckbox(parentCheckbox, isChecked) {
    // This prevents the child checkbox from being updated if the parent checkbox is unchecked
    // in the scenario where we are not updating ancestors.
    return this.shouldUpdateAncestorsValue || !parentCheckbox?.checked || isChecked
  }

  /**
   * Updates the root input value to true of a given checkbox element based on the stimulus value.
   */
  updateRoot() {
    this.checkboxTarget.checked = true
  }

  /**
   * Updates all descendant checkboxes of a given checkbox element with the specified checked state.
   * @param {HTMLInputElement} fauxCheckbox - The checkbox element whose descendants will be updated.
   * @param {boolean} isChecked - The checked state to set for all descendant checkboxes.
   */
  updateDescendents(fauxCheckbox, isChecked) {
    const details = fauxCheckbox.closest('details')
    const childCheckboxes = details.querySelectorAll("input[type='checkbox']")

    childCheckboxes.forEach((cb) => {
      const parentDetails = details.parentElement.closest('details')
      const parentCheckbox = parentDetails?.querySelector("[data-components--checkbox-tree-target='checkbox']")

      // This is operating on both checkboxes and faux checkboxes
      cb.checked = this.shouldUpdateChildCheckbox(parentCheckbox, isChecked) ? isChecked : !isChecked
    })
  }

  /**
   * Updates the ancestors of a faux checkbox based on its current state.
   * @param {HTMLElement} fauxCheckbox - The faux checkbox element.
   * @param {boolean} isChecked - The current state of the faux checkbox.
   */
  updateAncestors(fauxCheckbox, isChecked) {
    const details = fauxCheckbox.closest('details')
    const parentDetails = details.parentElement.closest('details')
    const checkbox = details.querySelector("[data-components--checkbox-tree-target='checkbox']")

    if (parentDetails) {
      this.updateNonRootAncestors(fauxCheckbox, isChecked, parentDetails)
    } else {
      this.updateRootAncestor(fauxCheckbox, checkbox)
    }
  }

  /**
   * Updates the ancestors of a faux checkbox that is not a root node in a checkbox tree.
   * @param {HTMLInputElement} fauxCheckbox - The faux checkbox element to update.
   * @param {boolean} isChecked - Whether the faux checkbox is checked or not.
   * @param {HTMLElement} parentDetails - The parent details element of the faux checkbox.
   */
  updateNonRootAncestors(fauxCheckbox, isChecked, parentDetails) {
    // Get the immediate ancestor faux checkbox
    const parentFauxCheckbox = parentDetails.querySelector("[data-components--checkbox-tree-target='fauxCheckbox']")
    const parentCheckbox = parentDetails.querySelector("[data-components--checkbox-tree-target='checkbox']")

    parentCheckbox.checked = true
    parentFauxCheckbox.checked = true

    this.updateAncestors(parentFauxCheckbox, isChecked)
  }

  /**
   * Updates the root ancestor of a faux checkbox tree based on the state of its children.
   * @param {HTMLInputElement} fauxCheckbox - The faux checkbox element to update.
   * @param {HTMLInputElement} checkbox - The real checkbox element to update.
   */
  updateRootAncestor(fauxCheckbox, checkbox) {
    // This is the root node, so there are no ancestors to update.
    const closestDetails = fauxCheckbox.closest('details')
    const childrenFauxCheckboxes = Array.from(
      closestDetails.querySelectorAll('[data-components--checkbox-tree-target="fauxCheckbox"]')
    ).filter((cb) => cb.dataset.targetCheckboxId !== closestDetails.dataset.id)

    // Calculate the number of checked checkboxes in the array
    const totalCheckedFauxChildren = childrenFauxCheckboxes.reduce(
      (count, checkbox) => (checkbox.checked ? count + 1 : count),
      0
    )

    // If none of the children are checked, the root node should be unchecked.
    if (totalCheckedFauxChildren === 0) {
      fauxCheckbox.checked = false
      checkbox.checked = false

      // If some or all of the children are checked, the root node should be checked.
    } else {
      fauxCheckbox.checked = true
      checkbox.checked = true
    }
  }
}
