import ApplicationController from './application_controller'

// Responds to an event from the `refine--rails` gem indicating the filter has been changed
// and updates the URL to reflect the new filter state.
// Used in the filter_pill partial
export default class TurboVisitController extends ApplicationController {
  visit({ detail: { url: originalUrl }, params: { userFilter } }) {
    const url = new URL(originalUrl)

    if (userFilter) {
      url.searchParams.append('user_filter', userFilter)
    }

    window.Turbo.visit(url.href)
  }
}
