export const SmartFunnelsMixin = {
  getTabBySelection(step) {
    switch (step) {
      case 0:
        return 'type'
      case 1:
        return 'color'
      case 2:
        return 'image'
      case 3:
        return 'copy'
      case 4:
        return 'generate'
      default:
        return null
    }
  },
}
