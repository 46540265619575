import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'formatSelect']

  /**
   * Validates input based on the selected format
   *
   * @param {Event} event - The input change event
   * @description
   * Determines the validation method based on the selected format type
   * and applies the appropriate validation to the input
   */
  validateInput(event) {
    const input = this.inputTarget
    const selectedFormat = this.formatSelectTarget.value

    this.#validateInputByFormat(input, selectedFormat)
  }

  /**
   * Wrapper method for decimal validation triggered by an event
   *
   * @param {Event} event - The input change event
   */
  validateDecimal(event) {
    this.#validateInputByFormat(event.currentTarget, 'fixed')
  }

  /**
   * Wrapper method for percentage validation triggered by an event
   *
   * @param {Event} event - The input change event
   */
  validatePercentage(event) {
    this.#validateInputByFormat(event.currentTarget, 'percent')
  }

  // Private Methods

  /**
   * Centralized input validation method
   *
   * @param {HTMLInputElement} input - The input element to validate
   * @param {string} format - The validation format ('percent' or 'fixed')
   * @private
   */
  #validateInputByFormat(input, format) {
    const formatsMap = {
      percent: this.#validatePercentage,
      fixed: this.#validateDecimal,
    }

    const validationMethod = formatsMap[format]
    if (validationMethod) {
      validationMethod.call(this, input)
    }
  }

  /**
   * Validates decimal input, ensuring proper decimal format
   *
   * @param {HTMLInputElement} input - The input element to validate
   * @private
   * @description
   * - Sanitizes input to allow only numeric and decimal characters
   * - Enforces maximum of two decimal places
   * - Preserves last valid input if current input is invalid
   */
  #validateDecimal(input) {
    const sanitizedInput = this.#sanitizeInput(input.value, /[^0-9.]/g)
    const parts = sanitizedInput.split('.')

    // Validate decimal input
    if (this.#isValidDecimalInput(parts, sanitizedInput, input.value)) {
      input.value = sanitizedInput
      input.dataset.previousValue = sanitizedInput
    } else {
      input.value = input.dataset.previousValue || ''
    }
  }

  /**
   * Validates percentage input, ensuring only numeric input
   *
   * @param {HTMLInputElement} input - The input element to validate
   * @private
   * @description
   * - Sanitizes input to allow only numeric characters
   * - Limits input to a maximum of 4 digits
   */
  #validatePercentage(input) {
    const sanitizedInput = this.#sanitizeInput(input.value, /[^0-9]/g, 4)
    input.value = sanitizedInput
  }

  /**
   * Sanitizes input based on a given regex pattern
   *
   * @param {string} value - The input value to sanitize
   * @param {RegExp} pattern - Regex pattern to remove unwanted characters
   * @param {number} [maxLength] - Optional maximum length to truncate input
   * @returns {string} The sanitized input
   * @private
   */
  #sanitizeInput(value, pattern, maxLength) {
    const sanitized = value.replace(pattern, '')
    return maxLength ? sanitized.slice(0, maxLength) : sanitized
  }

  /**
   * Validates decimal input structure
   *
   * @param {string[]} parts - Split parts of the input
   * @param {string} sanitizedInput - Sanitized input string
   * @param {string} originalInput - Original input string
   * @returns {boolean} Whether the input is valid
   * @private
   */
  #isValidDecimalInput(parts, sanitizedInput, originalInput) {
    return parts.length <= 2 && (parts[1]?.length || 0) <= 2 && sanitizedInput === originalInput
  }
}
