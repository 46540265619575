import ApplicationController from '../application_controller.js'
import intlTelInput from 'intl-tel-input'

export default class extends ApplicationController {
  static targets = ['field']
  static values = {
    localizedCountries: { type: Object, default: {} },
    separateDialCode: { type: Boolean, default: false },
  }

  connect() {
    this.initPluginInstance()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  initPluginInstance() {
    let options = {
      hiddenInput: this.fieldTarget.dataset.method,
      customContainer: 'w-full',
    }
    // Make sure theres a
    //  <meta name="intl_tel_input_utils_path" content="<%= asset_path("intl-tel-input-utils.js") %>">
    // on the page using phone controllers
    const utilsScriptPath = metaContent('intl_tel_input_utils_path')
    if (utilsScriptPath) {
      options['utilsScript'] = utilsScriptPath
    }
    options['localizedCountries'] = this.localizedCountriesValue

    // This config option removes the 'national' prefix digit and shows the international code in the placeholder
    if (this.separateDialCodeValue) {
      options['separateDialCode'] = true
    }

    this.plugin = intlTelInput(this.fieldTarget, options)
  }

  teardownPluginInstance() {
    if (this.plugin === undefined) {
      return
    }

    // revert to original markup, remove any event listeners
    this.plugin.destroy()
  }
}

function metaContent(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element && element.content
}
