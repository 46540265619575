import ApplicationController from '../../application_controller'
import CableReady from 'cable_ready'
import consumer from 'cf-utils/application_cable'

export default class extends ApplicationController {
  static targets = ['scheduleButton', 'spammyWarning', 'noSpamCheckWarning']

  static classes = ['disabled', 'hidden']

  static values = {
    templateId: Number,
    disabledClass: String,
    schedule_button_text: String,
  }

  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: 'EmailsTemplateChannel',
        id: this.templateIdValue,
      },
      {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        },
      }
    )
    consumer.cable.hub.subscriptions.get(this.channel.identifier).ensureResubscribed()
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  noSpamCheck() {
    this.disableSchedule()
    if (this.hasSpammyWarningTarget) this.spammyWarningTarget.classList.add(...this.hiddenClasses)
    if (this.hasNoSpamCheckWarningTarget) this.noSpamCheckWarningTarget.classList.remove(...this.hiddenClasses)
  }

  isSpammy() {
    this.disableSchedule()
    if (this.hasSpammyWarningTarget) this.spammyWarningTarget.classList.remove(...this.hiddenClasses)
    if (this.hasNoSpamCheckWarningTarget) this.noSpamCheckWarningTarget.classList.add(...this.hiddenClasses)
  }

  isNotSpammy() {
    this.enableSchedule()
    if (this.hasSpammyWarningTarget) this.spammyWarningTarget.classList.add(...this.hiddenClasses)
    if (this.hasNoSpamCheckWarningTarget) this.noSpamCheckWarningTarget.classList.add(...this.hiddenClasses)
  }

  enableSchedule() {
    this.scheduleButtonTargets.forEach((target) => {
      target.classList.remove(...this.disabledClasses)
      target.disabled = false
    })
  }

  disableSchedule() {
    this.scheduleButtonTargets.forEach((target) => {
      target.classList.add(...this.disabledClasses)
      target.disabled = true
    })
  }

  updateScheduleButtonText(buttonText) {
    this.scheduleButtonTargets.forEach((target) => {
      target.value = buttonText
    })
  }

  handleSpamCheck(event) {
    const detail = event.detail

    if (detail.id !== this.templateIdValue) {
      return
    }

    if (detail.spammy === null) {
      this.noSpamCheck()
    } else {
      detail.spammy ? this.isSpammy() : this.isNotSpammy()
    }
    this.updateScheduleButtonText(detail.scheduleButtonText)
  }
}
