import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['viewport', 'actionForm', 'idRecipient']
  static values = {}

  connect() {
    // We do this so our different controller instances can reference one another.
    // https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    this.element[this.identifier] = this

    const originalController = () => {
      return $(this.element).parent().closest("[data-controller='react-modal']")[0]['react-modal']
    }
    const callback = () => $(this.element).parent().closest("[data-controller='react-modal']").attr('data-callback')

    // TODO There might be a way better way to do this and this feels like a total hack.
    // The idea here is that we're reusing this controller to help dismount once the user has completed their action.
    switch (this.element.dataset.role) {
      // This is after the ID has been stuffed into the the action form and submitted.
      case 'selected':
        // Using indirect eval by wrapping in parentheses
        ;(0, eval)(callback())(this.element.dataset.callbackValue)
        break

      // Close a modal after normal form submission with a close callback registered on the window
      case 'ok':
        parent.window.closeModalFunctions[callback()]()
        break

      // This is when they've added a new model and that ID needs to be stuffed into the action form.
      case 'created':
        // Get the controller instance of the original React Modal controller.
        originalController().injectIdIntoActionForm(this.element.dataset.id)
        break
    }
  }

  injectIdIntoActionForm(id) {
    // Stuff the ID of the clicked element into the ID recipient field on the action form.
    $(this.idRecipientTarget).val(id)

    // Submit the action form.
    $(this.actionFormTarget).find('form').find('input[type=submit]').first().click()
  }

  select(evt) {
    evt.preventDefault()
    this.injectIdIntoActionForm(evt.currentTarget.dataset.id)
  }
}
