import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    this.abortController = new AbortController()
    this.element.addEventListener('toggle', () => this.dispatch('toggle'), { signal: this.abortController.signal })
  }
  disconnect() {
    this.abortController.abort()
  }

  toggle(event) {
    let currentOpenValue = this.element.open
    this.element.open = !currentOpenValue
    event.target.setAttribute('data-details-open', !currentOpenValue)
  }
}
