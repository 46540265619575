import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['submit', 'form', 'subscriptionOption', 'variantId', 'productsPriceId', 'quantity']
  static values = {
    variantId: String,
    productsPriceId: String,
    quantity: String,
  }

  connect() {
    this.originalVariantId = this.variantIdValue
    this.originalProductsPriceId = this.productsPriceIdValue
    this.originalQuantity = this.quantityValue
    this.submitTarget.classList.add('disabled') // Setting disabled here allows CI test to pass
  }

  handleChange() {
    if (this.planChanged()) {
      this.enableSubscriptionOptions(true)
      this.submitTarget.classList.remove('disabled')
    } else {
      this.enableSubscriptionOptions(false)
      this.submitTarget.classList.add('disabled')
    }
  }

  planChanged() {
    // simple comment to test assets are updating on CI
    return (
      this.originalVariantId != this.variantIdTarget.value ||
      this.originalProductsPriceId != this.productsPriceIdTarget.value ||
      this.originalQuantity != this.quantityTarget.value
    )
  }

  enableSubscriptionOptions(enable) {
    this.subscriptionOptionTargets.forEach((element) => {
      enable ? element.classList.remove('disabled') : element.classList.add('disabled')
    })
  }
}
