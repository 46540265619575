import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'output']
  static classes = ['hidden']

  connect() {
    this.outputTarget.textContent = this.inputTarget.value
    this.toggleMessageContainer()
  }

  update(event) {
    this.outputTarget.textContent = event.currentTarget.value
    this.toggleMessageContainer()
  }

  toggleMessageContainer() {
    if (this.outputTarget.textContent.length > 0) {
      this.outputTarget.classList.remove(this.hiddenClass)
    } else {
      this.outputTarget.classList.add(this.hiddenClass)
    }
  }
}
