import ApplicationController from './application_controller'
import { hideAll } from 'tippy.js'

export default class extends ApplicationController {
  // Note: all other Tippy operations are handled in `app/javascript/controllers/tooltip_controller.js`
  hideAllTooltips() {
    setTimeout(() => {
      hideAll({ duration: 0 })
    })
  }
}
