import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = []

  clearUser() {
    if (window.fcWidget !== undefined) {
      window.fcWidget.user.isExists().then((resp) => {
        var data = resp && resp.data
        if (data == true) {
          window.fcWidget.user.clear()
          window.fcWidget.on('user:cleared', (resp) => {
            window.fcWidget.destroy()
          })
        }
        if (data == false) {
          window.fcWidget.destroy()
        }
      })
    }
  }
}
