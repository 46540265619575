/* 
  This controller is responsible for toggling the visibility of the table header
  and the bulk actions button group component, and updating the # of selected
  items in the table based on the bulk-actions:selection event dispatched by
  the bulk_actions_controller (this even triggers the #toggle method below).

  You can customize the bulk actions dropdown text based on whether one item
  or multiple items are selected:
  data-bulk-actions-button-group-singular-button-text-value='One thing'
  data-bulk-actions-button-group-plural-button-text-value='Many things'
*/
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['button', 'buttonText', 'thead', 'actions', 'selectionStatus', 'selectAllCheckbox']
  static values = {
    singularButtonText: { type: String, default: 'Actions' },
    pluralButtonText: { type: String, default: 'Bulk actions' },
    selectedButtonText: { type: String, default: '%{count} selected' },
    allSelectedButtonText: { type: String, default: 'All selected' },
    instanceId: String,
  }

  toggle({ detail: { ids, instanceId } }) {
    if (instanceId != this.instanceIdValue) {
      return
    }

    if (ids.length) {
      if (ids.length === 1) {
        this.buttonTextTarget.textContent = this.singularButtonTextValue
      } else {
        this.buttonTextTarget.textContent = this.pluralButtonTextValue
      }

      $(this.theadTarget).hide()
      $(this.actionsTarget).show()
      this.updateSelectionStatus(event.detail)
    } else {
      this.selectAllCheckboxTarget.checked = false
      $(this.theadTarget).show()
      $(this.actionsTarget).hide()
    }
  }

  updateSelectionStatus(selectionData) {
    if (selectionData?.all) {
      this.selectionStatusTarget.textContent = this.allSelectedButtonTextValue
    } else {
      this.selectionStatusTarget.textContent = this.selectedButtonTextValue.replace(
        '%{count}',
        `${selectionData?.ids.length}`
      )
    }
  }

  resetButtonFocus() {
    if (this.hasButtonTarget) {
      this.buttonTarget.blur()
    }
  }
}
