import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['index']

  update() {
    this.indexTargets.forEach((el, index) => {
      el.textContent = index + 1
    })
  }
}
