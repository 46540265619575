import ApplicationController from './application_controller'

export default class extends ApplicationController {
  download(event) {
    event.preventDefault() // Prevent default navigation

    const csvContent = event.currentTarget.getAttribute('data-csv-content')
    const fileName = event.currentTarget.getAttribute('data-csv-filename')

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)

    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = fileName

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
}
