import ApplicationController from '../application_controller'
export default class extends ApplicationController {
  static targets = [
    'audioPreview',
    'audioPreviewContainer',
    'audioTemplate',
    'emptyState',
    'field',
    'hiddenFieldTemplate',
    'hiddenFieldsContainer',
    'openBtn',
    'titleBar',
  ]

  static values = { fieldId: String }

  connect() {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('open_audio_gallery')) {
      this.openGallery()
    }
  }

  openGallery() {
    this.openBtnTarget.click()
  }

  handleSelected(event) {
    if (event.detail.fieldId !== this.fieldIdValue) {
      return
    }

    const selectedAudioFiles = event.detail.items
    this.fieldTargets.forEach((field) => field.remove())

    selectedAudioFiles.forEach(({ id }) => {
      let clonedHiddenField = this.hiddenFieldTemplateTarget.content.cloneNode(true)
      clonedHiddenField.querySelector('input').value = id
      this.hiddenFieldsContainerTarget.appendChild(clonedHiddenField)
    })

    if (selectedAudioFiles.length > 0) {
      this.showAudioPreview(selectedAudioFiles)
    } else {
      this.showEmptyState()
    }

    this.emitChangeEvent()
  }

  showEmptyState() {
    if (!this.audioPreviewContainerTarget.classList.contains('hidden')) {
      this.audioPreviewContainerTarget.classList.add('hidden')
    }
    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.classList.remove('hidden')
    }
    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.remove('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.add('hidden')
    }
  }

  showAudioPreview(selectedAudioFiles) {
    if (!this.emptyStateTarget.classList.contains('hidden')) {
      this.emptyStateTarget.classList.add('hidden')
    }

    this.audioPreviewTarget.innerHTML = ''

    selectedAudioFiles.forEach(({ filename }) => {
      let previewAudio = this.audioTemplateTarget.content.cloneNode(true)
      previewAudio.querySelector('p').textContent = filename

      this.audioPreviewTarget.appendChild(previewAudio)
    })

    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.add('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.remove('hidden')
    }
    if (this.audioPreviewContainerTarget.classList.contains('hidden')) {
      this.audioPreviewContainerTarget.classList.remove('hidden')
    }
  }

  reset() {
    this.fieldTargets.forEach((target) => (target.value = ''))

    this.emitChangeEvent()
    this.showEmptyState()
  }

  emitChangeEvent() {
    this.hiddenFieldsContainerTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }
}
