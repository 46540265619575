import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['filtersForm', 'widget']
  static values = {
    filtersUpdating: Boolean,
    filtersUpdatedEventName: {
      type: String,
      default: 'widget:filters-updated',
    },
  }

  watchForFilterUpdates() {
    this.filtersUpdatingValue = true
  }

  filtersFormTargetConnected() {
    if (this.filtersUpdatingValue === false) {
      return
    }

    this.updateWidgets()

    this.filtersUpdatingValue = false
  }

  updateWidgets() {
    if (!this.hasFiltersFormTarget) {
      return
    }
    if (!this.hasFiltersUpdatedEventNameValue) {
      return
    }

    const formData = new FormData(this.filtersFormTarget)
    this.widgetTargets.forEach((widgetTarget) => {
      widgetTarget.dispatchEvent(new CustomEvent(this.filtersUpdatedEventNameValue, { detail: { formData } }))
    })
  }
}
