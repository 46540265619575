import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static classes = ['hidden']
  static targets = ['checkbox', 'warning']

  validate(event) {
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.warningTarget.classList.add(this.hiddenClass)
      return true
    }

    event.preventDefault()

    this.warningTarget.classList.remove(this.hiddenClass)
    return false
  }
}
