import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  selectMergeTag(event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const name = selectedOption.getAttribute('data-name')
    const value = selectedOption.getAttribute('data-tag')
    const mergeTag = { name, value }
    const customEvent = new CustomEvent('mergeTagSelected', { detail: { mergeTag } })
    document.dispatchEvent(customEvent)
  }

  removeContentDialog() {
    const customEvent = new CustomEvent('removeContentDialog')
    document.dispatchEvent(customEvent)
  }
}
