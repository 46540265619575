import ApplicationController from '../../application_controller'

/*
 * this controller is responsible for responding to the fields--autocomplete:commit
 * and contact:created events. It will take data provided in the events and use it
 * to populate the contact pill avatar and name. It also coordinates
 * revealing/hiding the contact pill and the search field.
 */

export default class extends ApplicationController {
  static targets = ['contact', 'searchField', 'avatar', 'name', 'label', 'hiddenInput', 'prefill']
  static classes = ['hidden']

  connect() {
    const hasPrefillValue =
      this.prefillTarget.value != null && this.prefillTarget.value != '' && this.prefillTarget.value != undefined
    if (this.hasPrefillTarget && hasPrefillValue) {
      this.hiddenInputTarget.value = this.prefillTarget.value
      this.hiddenInputTarget.dispatchEvent(new Event('change', { bubbles: true }))

      this.contactTarget.classList.remove(this.hiddenClass)
      this.searchFieldTarget.classList.add(this.hiddenClass)
    }
  }

  revealContactPill(event) {
    const selectedData = event.detail.selected.dataset

    this.avatarTarget.src = selectedData.contactAvatarSrc
    this.nameTarget.innerText = selectedData?.contactName

    this.contactTarget.classList.remove(this.hiddenClass)
    this.searchFieldTarget.classList.add(this.hiddenClass)
  }

  revealSearchField() {
    this.contactTarget.classList.add(this.hiddenClass)
    this.searchFieldTarget.classList.remove(this.hiddenClass)
  }

  handleContactCreated(event) {
    const selectedData = event.detail

    this.avatarTarget.src = selectedData?.avatar
    this.nameTarget.innerText = !selectedData?.fullName ? selectedData?.email : selectedData?.fullName
    this.hiddenInputTarget.value = selectedData?.id

    this.contactTarget.classList.remove(this.hiddenClass)
    this.searchFieldTarget.classList.add(this.hiddenClass)

    this.hiddenInputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }
}
