import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    previewUrl: { type: String, default: '' },
    identifierParam: { type: String, default: ':id' },
    turboFrame: { type: String, default: 'device_previewer' },
    recordIdentifier: String,
    pageIdParam: { type: String, default: ':page_id' },
    pageId: String,
    styleIdParam: { type: String, default: ':style_id' },
    styleId: String,
    styleTypeParam: { type: String, default: ':style_type' },
    styleType: { type: String, default: 'installed' },
  }

  updateStyleProps({ detail: { id, type } }) {
    this.updateStyleId({ detail: { value: id } })
    this.updateStyleType({ detail: { value: type } })
  }

  updateStyleType({ detail: { value } }) {
    this.styleTypeValue = value
  }

  updateStyleId({ detail: { value } }) {
    this.styleIdValue = value
  }

  updatePageId({ detail: { value } }) {
    this.pageIdValue = value
  }

  pageIdValueChanged(value, previousValue) {
    if (!this.hasPageIdValue || previousValue === '') return

    this.updatePreview()
  }

  styleIdValueChanged(value, previousValue) {
    if (!this.hasStyleIdValue || previousValue === '') return

    this.updatePreview()
  }

  updatePreview() {
    let previewUrl = this.previewUrlValue

    if (this.hasPageIdValue) {
      previewUrl = previewUrl.replace(this.pageIdParamValue, this.pageIdValue)
    }

    if (this.hasStyleIdValue) {
      previewUrl = previewUrl.replace(this.styleIdParamValue, this.styleIdValue)
      previewUrl = previewUrl.replace(this.styleTypeParamValue, this.styleTypeValue)
    }

    previewUrl = new URL(previewUrl)

    this.refreshPreviewWindow(previewUrl)
  }

  preview({ detail: { previewStyleId } }) {
    let previewUrl = new URL(this.previewUrlValue.replace(this.identifierParamValue, this.recordIdentifierValue))
    previewUrl.searchParams.set('preview_style_id', previewStyleId)

    this.refreshPreviewWindow(previewUrl)
  }

  refreshPreviewWindow(previewUrl) {
    get(previewUrl, {
      responseKind: 'turbo-stream',
      headers: {
        'Turbo-Frame': `${this.turboFrameValue}`,
      },
    })
  }
}
