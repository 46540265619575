import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    submitUrl: String,
    currentStableId: String,
  }
  static targets = ['hiddenField']
  hiddenElement

  connect() {
    this.hiddenFieldTarget.value = this.currentStableIdValue
  }

  submitFilter(event) {
    if (this.filterView() !== null) {
      this.submitFilterToRefine(event)
    }
  }
  async submitFilterToRefine(event) {
    event.preventDefault()
    const { blueprint } = this.stateController
    const request = new FetchRequest('POST', this.submitUrlValue, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        refine_filters_builder: {
          filter_class: this.stateController.filterName,
          blueprint_json: JSON.stringify(blueprint),
          client_id: this.stateController.clientIdValue,
        },
      }),
    })
    await request.perform()
  }

  filterView() {
    return this.element.querySelector('[data-controller~="refine--state"]')
  }

  updateHiddenInput({ detail: { stableId } }) {
    this.hiddenFieldTarget.value = stableId
    this.hiddenFieldTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  submitForm({ detail: { stableId } }) {
    this.hiddenFieldTarget.value = stableId
    this.element.requestSubmit()
  }

  submitBroadcastRehydrateForm({ detail: { stableId } }) {
    this.hiddenFieldTarget.value = stableId
    this.addHiddenInput('rehydrate', true)
    this.element.requestSubmit()
    this.hiddenElement.remove()
  }

  updateHiddenInputBroadcastRehydrateForm({ detail: { stableId } }) {
    this.hiddenFieldTarget.value = stableId
    this.currentStableIdValue = stableId
    this.hiddenFieldTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  addHiddenInput(name, value) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.id = name
    input.name = name
    input.value = value
    this.element.appendChild(input)
    this.hiddenElement = input
  }

  get stateController() {
    return this.element.querySelector('[data-controller~="refine--state"]').refineStateController
  }
}
