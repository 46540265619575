import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['customDescription', 'descriptionToggle', 'descriptionHiddenFieldset', 'descriptionCustomFieldset']

  static values = {
    defaultText: String,
    customText: String,
    eventSourceElementId: String,
  }

  handleToggle(event) {
    if (event.detail.sourceEl.id != this.eventSourceElementIdValue) return
    this.descriptionToggleTarget.innerText = this.toggleText(this.descriptionToggleTarget.innerText)
    this.toggleDisabled()
    this.element.closest('form').dispatchEvent(new Event('change', { bubbles: true }))
  }

  toggleText(text) {
    const toggleTextMap = {}
    toggleTextMap[this.defaultTextValue] = this.customTextValue
    toggleTextMap[this.customTextValue] = this.defaultTextValue
    return toggleTextMap[text]
  }

  toggleDisabled() {
    $(this.descriptionCustomFieldsetTarget).prop('disabled', (_, val) => !val)
    $(this.descriptionHiddenFieldsetTarget).prop('disabled', (_, val) => !val)
  }
}
