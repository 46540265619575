import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['passwordField']

  toggleField(event) {
    if (event.target.defaultValue === 'password_protected') {
      this.passwordFieldTarget.classList.remove('hidden')
    } else {
      this.passwordFieldTarget.classList.add('hidden')
    }
  }
}
