import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['otherServerField', 'otherServerFieldInput']

  toggleOtherServerField(event) {
    const server = event.target.value
    if (server === 'other') {
      this.otherServerFieldTarget.classList.add('block')
      this.otherServerFieldTarget.classList.remove('hidden')
      this.otherServerFieldInputTarget.disabled = false
    } else {
      this.otherServerFieldTarget.classList.add('hidden')
      this.otherServerFieldTarget.classList.remove('block')
      this.otherServerFieldInputTarget.disabled = true
    }
  }
}
