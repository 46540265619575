import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['loader', 'form']
  static values = {
    redirectPath: String,
    autoCloseModal: Boolean,
    autoCloseTimeout: { type: Number, default: 30000 },
    notificationPath: String,
    customEvent: String,
  }

  connect() {
    if (this.hasAutoCloseModalValue) {
      setTimeout(() => {
        this.dispatch(this.customEventValue)
        if (this.hasNotificationPathValue) {
          get(this.notificationPathValue, { responseKind: 'turbo-stream' })
        }
      }, this.autoCloseTimeoutValue)
    }
  }

  redirectPathValueChanged() {
    if (this.hasRedirectPathValue) {
      location.replace(this.redirectPathValue)
    }
  }

  showLoader() {
    this.loaderTarget.classList.remove('hidden')
  }
}
