import ApplicationController from './application_controller'
import Chart from 'chart.js/auto'
import { verticalLinePlugin } from '../concerns/charts.js'

Chart.register(verticalLinePlugin)

export default class extends ApplicationController {
  static targets = ['canvas'] // Optional, in case you're targeting specific canvas elements
  static values = {
    data: Object,
    options: Object,
  }

  connect() {
    // Access the data and options values from the `data-*` attributes
    const chartData = this.dataValue
    const chartOptions = this.optionsValue

    // Find the canvas element
    const ctx = this.hasCanvasTarget ? this.canvasTarget : this.element

    // Initialize the Chart.js chart
    this.chart = new Chart(ctx, {
      type: 'line', // You can set this dynamically as well, if needed
      data: chartData,
      options: chartOptions,
    })
  }

  disconnect() {
    // Destroy the chart instance when the controller is disconnected
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
