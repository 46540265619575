/* eslint-disable no-undef */
import ApplicationController from './application_controller'
import { toast } from '../utils/toast'

export default class extends ApplicationController {
  static targets = [
    'paymentMethodForm',
    'rebillyToken',
    'selectedMethod',
    'paymentOption',
    'cardForm',
    'achForm',
    'cardNumberMountElement',
    'cardExpMountElement',
    'cardCvvMountElement',
    'bankTypeMountElement',
    'bankNumberMountElement',
    'bankRoutingMountElement',
    'submitPaymentButton',
    'countrySelect',
    'regionSelect',
  ]
  static values = {
    organizationId: String,
    publishableKey: String,
  }

  showNotification(detail) {
    toast('alert', detail.message)
  }

  submit(form, data) {
    form.submit()
  }

  paymentForm() {
    return this.paymentMethodFormTarget
  }

  hideOptions() {
    this.cardFormTarget.classList.add('hidden')
    this.achFormTarget.classList.add('hidden')
  }

  toggleForm() {
    this.hideOptions()
    if (this.selectedMethodTarget.value == 'ach') {
      this.achFormTarget.classList.remove('hidden')
    }
    if (this.selectedMethodTarget.value == 'payment-card') {
      this.cardFormTarget.classList.remove('hidden')
    }
  }

  unmountUnusedOption() {
    if (this.selectedMethodTarget.value == 'ach') {
      this.mountedFrames.cardElements.forEach((item) => item.unmount())
    } else if (this.selectedMethodTarget.value == 'payment-card') {
      this.mountedFrames.achElements.forEach((item) => item.unmount())
    }
  }

  mountUnusedOption() {
    if (this.selectedMethodTarget.value == 'ach') {
      this.mountedFrames.cardElements.forEach((item) => item.mount())
    } else if (this.selectedMethodTarget.value == 'payment-card') {
      this.mountedFrames.achElements.forEach((item) => item.mount())
    }
  }

  initRebilly() {
    console.debug('PaymentMethodForm Stimulus Controller initRebilly')

    if (!Rebilly.initialized) {
      var config = {
        publishableKey: this.publishableKeyValue,
        organizationId: this.organizationIdValue,
        kountAccountId: '700000', // This is for capturing kount fraud sessions
      }
      Rebilly.initialize(config)
    }
    const that = this // eslint-disable-line @typescript-eslint/no-this-alias
    const form = this.paymentForm()
    const rebillyToken = this.rebillyTokenTarget
    const paymentMethodSubmitted = { value: false }
    const paymentOptions = this.paymentOptionTargets
    const cardNumberElement = this.cardNumberMountElementTarget
    const cardExpElement = this.cardExpMountElementTarget
    const cardCvvElement = this.cardCvvMountElementTarget
    const bankTypeElement = this.bankTypeMountElementTarget
    const bankNumberElement = this.bankNumberMountElementTarget
    const bankRoutingElement = this.bankRoutingMountElementTarget
    const submitPaymentButton = this.submitPaymentButtonTarget
    const countrySelect = this.countrySelectTarget
    const regionSelect = this.regionSelectTarget
    this.mountedFrames = { achElements: [], cardElements: [] }

    paymentOptions.forEach((option) => {
      option.addEventListener('click', (event) => {
        this.selectedMethodTarget.value = event.target.dataset.value
        this.toggleForm()
      })
    })

    Rebilly.on('ready', () => {
      this.card = Rebilly.card.mount(cardNumberElement, 'cardNumber')
      this.exp = Rebilly.card.mount(cardExpElement, 'cardExpiration')
      this.cvv = Rebilly.card.mount(cardCvvElement, 'cardCvv')
      this.type = Rebilly.bankAccount.mount(bankTypeElement, 'bankAccountType')
      this.number = Rebilly.bankAccount.mount(bankNumberElement, 'bankAccountNumber')
      this.routing = Rebilly.bankAccount.mount(bankRoutingElement, 'bankRoutingNumber')

      this.mountedFrames.achElements = [this.type, this.number, this.routing]
      this.mountedFrames.cardElements = [this.card, this.exp, this.cvv]
    })

    form.onsubmit = (e) => {
      paymentMethodSubmitted.value = true
      submitPaymentButton.setAttribute('disabled', '')
      e.preventDefault()
      e.stopPropagation()
      this.unmountUnusedOption()

      const extraData = {
        method: this.selectedMethodTarget.value,
        billingAddress: {
          country: countrySelect.options[countrySelect.selectedIndex].dataset.iso2,
          region: regionSelect.options[regionSelect.selectedIndex].text,
        },
      }

      Rebilly.createToken(form, extraData)
        .then((result) => {
          window.parent.postMessage('success', '*')
          rebillyToken.value = result.id

          // NOTE: data is used on admin page (when turbo is true)
          // and goes to app/javascript/controllers/turbo_stream_payment_method_form_controller.js
          // otherwise data variable is ignored (customer center does not use data variable)
          const data = {
            payment_method: {
              live_mode: form.elements.live_mode.value,
              rebilly_token: rebillyToken.value,
              description: form.elements['billing/payment_method_description'].value,
            },
          }
          that.submit(form, data)
        })
        .catch((error) => {
          paymentMethodSubmitted.value = false
          submitPaymentButton.removeAttribute('disabled')
          this.mountUnusedOption()
          console.error('Framepay error', error)
          error.details.forEach(that.showNotification(error))
          window.parent.postMessage('error', '*')
        })
    }
  }

  connect() {
    console.debug('PaymentMethodForm Stimulus Controller connect')

    this.selectedMethodTarget.value = 'payment-card'

    if (typeof Rebilly !== 'undefined') {
      console.debug('PaymentMethodForm Stimulus Controller rebilly is defined')
      this.initRebilly()
    } else {
      setTimeout(() => {
        this.initRebilly()
      }, 500)
    }
  }

  disconnect() {
    if (this.card) {
      this.card.unmount()
      this.exp.unmount()
      this.cvv.unmount()
    }
    if (this.type) {
      this.type.unmount()
      this.number.unmount()
      this.routing.unmount()
    }
  }
}
/* eslint-enable no-undef */
