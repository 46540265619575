import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['submit', 'height']
  static values = {
    packageType: String,
  }

  connect() {
    this.toggleHeight(this.packageTypeValue)
  }

  setSubmitContext(event) {
    if (event.target.innerText === 'Carrier packages') {
      this.submitTarget.setAttribute('name', 'carrier_package')
    } else {
      this.submitTarget.setAttribute('name', 'custom_package')
    }
  }

  packgeTypeSelection(event) {
    this.toggleHeight(event.target.value)
  }

  toggleHeight(package_type) {
    if (package_type === 'envelope') {
      this.heightTarget.parentNode.parentNode.classList.add('hidden')
    } else {
      this.heightTarget.parentNode.parentNode.classList.remove('hidden')
    }
  }

  handleShow(event) {
    ;[...this.detailsInstances].map((details) => (details.open = event.target === details))
  }

  get detailsInstances() {
    return this.element.querySelectorAll('sl-details')
  }
}
