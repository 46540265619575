import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    newNotification: Boolean,
  }

  connect() {
    if (this.newNotificationValue) {
      setTimeout(() => {
        let notice = this.element
        notice.classList.add('bg-red-400')
        notice.classList.remove('animate-ping', 'bg-red-600')
      }, 5000)
    }
  }
}
