import BaseController from '../shoelace/base_controller'

export default class extends BaseController {
  static targets = ['anchor', 'popup']

  closeAll() {
    this.popupTargets.forEach((popup) => (popup.active = false))
  }

  toggleActive(elem) {
    this.popupTargets.forEach((popup) => {
      if (popup.anchor == elem.currentTarget.id) {
        popup.active = true
      } else {
        popup.active = false
      }
    })
  }
}
