import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['dependentField', 'select']
  static values = { categoryGoals: Object }

  connect() {
    $(this.selectTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event)
    })

    this.updateDependentFields()
  }

  disconnect() {
    $(this.selectTarget).off('select2:select')
  }

  updateDependentFields() {
    const categoryGoals = this.categoryGoalsValue
    const categoryId = $(this.selectTarget).val()
    const goalSelect = $(this.dependentFieldTarget)

    if (categoryId) {
      const choices = categoryGoals[categoryId].map((goal) => {
        return {
          id: goal[1],
          text: goal[0],
        }
      })

      goalSelect.prop('disabled', false)
      goalSelect.empty()
      goalSelect.select2({
        data: choices,
      })
    } else {
      goalSelect.prop('disabled', true)
      goalSelect.empty()
    }
  }
}
