import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    url: String,
    param: String,
  }
  static targets = ['target']

  change(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append('target', this.targetTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream',
    })
  }
}
