import ApplicationController from './application_controller'

export default class extends ApplicationController {
  filterEventType(event) {
    let eventsType = event.target.selectedOptions[0].value
    let url = this.data.get('url')

    window.location.href = `${url}?events_type=${eventsType}`
  }
}
