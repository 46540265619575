import ApplicationController from './application_controller'
import { debounce } from 'lodash'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['form', 'subdomainInput', 'nameInput', 'preview']

  static values = {
    originalSubdomain: String,
    subdomainValidationUrl: String,
  }

  connect() {
    this.hashedSubdomain = ''

    // Rendered as a part of the eva theme
    this.subdomainErrorElement = document.getElementById('workspace_subdomain-errors')
    this.nameInputChanged = debounce(this.nameInputChanged, 500)
    this.subdomainInputChanged = debounce(this.subdomainInputChanged, 500)
  }

  async nameInputChanged(event) {
    // We've shown the user errors and they're typing again, remove errors from ui until they need to be displayed
    this.hideSubdomainErrors()

    const value = event.target.value
    if (value !== this.nameInputTarget.value) return

    // Validate subdomain as the user types the business name as this autofills the subdomain field
    this.validateSubdomain(this.subdomainInputTarget.value)
  }

  hideSubdomainErrors() {
    this.subdomainErrorElement.hidden = true
  }

  showSubdomainErrors() {
    this.subdomainErrorElement.hidden = false
  }

  async subdomainInputChanged(event) {
    // We've shown the user errors and they're typing again, remove errors from ui until they need to be displayed
    this.hideSubdomainErrors()

    const value = event.target.value
    // The user continued typing and this value is no longer applicable
    if (value !== this.subdomainInputTarget.value) return

    this.validateSubdomain(value)
  }

  async validateSubdomain(value) {
    this.hashedSubdomain = '' // Remove any previously stored hashed subdomains
    if (!value) return

    const response = await get(`${this.subdomainValidationUrlValue}.json?subdomain=${value}`, { responseKind: 'json' })
    const { requested, subdomain, error } = await response.json

    // The user paused long enough for the request to fire, but continued typing. abort
    if (requested !== this.subdomainInputTarget.value) return

    // Validation failed.
    if (error) {
      this.handleFailedSubdomainValidation(subdomain, error)
    }
  }

  handleFailedSubdomainValidation(subdomain, error) {
    this.hashedSubdomain = subdomain
    this.previewTarget.innerHTML = subdomain
    this.subdomainErrorElement.innerHTML = error
    this.subdomainErrorElement.classList.replace('text-red', 'text-yellow')
    this.showSubdomainErrors()
  }

  formSubmission(event) {
    event.stopPropagation()
    // Lets attempt to maintain the expected hash value
    if (this.hashedSubdomain && this.hashedSubdomain.includes(this.subdomainInputTarget.value)) {
      this.subdomainInputTarget.value = this.hashedSubdomain
    }

    if (this.subdomainChanged()) {
      this.dispatch('subdomain-changed')
      this.formTarget.requestSubmit()
    }
  }

  subdomainChanged() {
    return this.originalSubdomainValue != this.subdomainInputTarget.value
  }
}
