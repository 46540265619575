import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['audience', 'targetBroadcast']

  connect() {
    this.toggleTargetBroadcast()
  }

  toggleTargetBroadcast() {
    const broadcastOptions = ['received', 'not_opened', 'opened', 'not_clicked', 'clicked']

    if (broadcastOptions.includes(this.audienceTarget.value)) {
      this.targetBroadcastTarget.classList.remove('hidden')
    } else {
      this.targetBroadcastTarget.classList.add('hidden')
    }
  }
}
