import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['amountField', 'submitButton']
  static values = {
    prefix: String,
    save: String,
  }

  connect() {
    // Fire on next tick to allow number field to format first.
    setTimeout(() => {
      this.updateSubmitButton()
    }, 1)
  }

  updateSubmitButton() {
    const amount = this.amountFieldTarget.value

    if (amount) {
      const buttonText = `${this.prefixValue}${amount}`
      this.submitButtonTarget.textContent = buttonText
    } else {
      this.submitButtonTarget.textContent = this.saveValue
    }
  }
}
