import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['button']

  connect() {
    useClickOutside(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  toggle(event) {
    const matchesTarget = event.target == this.buttonTarget

    if (matchesTarget) {
      this.element.toggleAttribute('active')
    }
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.element.removeAttribute('active')
    }
  }
}
