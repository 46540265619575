/**
 * Checks if an object is empty.
 *
 * @param {object} obj - The object to check.
 *
 * @returns {boolean} - `true` if the object is empty, `false` otherwise.
 */
export function isEmpty(obj) {
  for (const property in obj) {
    if (Object.hasOwn(obj, property)) {
      return false
    }
  }

  return true
}
