import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['fromAddress', 'fromAddressLoadingTemplate', 'items', 'itemsLoadingTemplate']

  update() {
    this.fromAddressTarget.innerHTML = this.fromAddressLoadingTemplateTarget.innerHTML
    this.itemsTarget.innerHTML = this.itemsLoadingTemplateTarget.innerHTML
  }

  addAdditionalItems() {
    this.itemsTarget.innerHTML = this.itemsLoadingTemplateTarget.innerHTML
  }
}
