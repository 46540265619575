import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['dialog']

  show() {
    this.dialogTarget.show()
  }

  hide() {
    this.dialogTarget.hide()
  }
}
