import ApplicationController from '../application_controller'

// requires jQuery, moment, might want to consider a vanilla JS alternative
import 'daterangepicker'
import moment from 'moment'

export default class extends ApplicationController {
  static targets = ['startField', 'endField', 'interactiveField']
  static values = {
    futureOnly: { type: Boolean, default: false },
    maxDate: String,
    startDate: String,
    endDate: String,
    autoApply: { type: Boolean, default: false },
    cancelLabel: { type: String, default: 'Clear' },
    dateFormat: String,
    timeFormat: String,
    pickerLocale: { type: Object, default: {} },
  }

  connect() {
    this.dispatchNativeEvent = this.dispatchNativeEvent.bind(this)
    this.initPluginInstance()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  clearDate(event) {
    // don't submit the form, unless it originated from the cancel/clear button
    event.preventDefault()

    this.startFieldTarget.value = ''
    this.endFieldTarget.value = ''
    $(this.interactiveFieldTarget).val('')
  }

  initPluginInstance() {
    const pickerLocale = this.pickerLocaleValue
    var localeValues = JSON.parse(JSON.stringify(pickerLocale))
    localeValues['format'] = this.includeTimeValue ? this.timeFormatValue : this.dateFormatValue
    localeValues['applyLabel'] = this.applyLabelValue
    localeValues['cancelLabel'] = this.cancelLabelValue
    let startDate = this.hasStartDateValue ? moment(this.startDateValue) : moment()
    let endDate = this.hasEndDateValue ? moment(this.endDateValue) : moment().add(1, 'days')
    $(this.interactiveFieldTarget).daterangepicker(
      {
        minDate: this.futureOnlyValue ? startDate : false,
        maxDate: this.hasMaxDateValue ? moment(this.maxDateValue) : false,
        autoUpdateInput: false,
        autoApply: this.autoApplyValue,
        startDate: startDate,
        endDate: endDate,
        locale: localeValues,
      },
      (start, end) => {
        this.updateFields(start, end)
      }
    )

    this.pluginMainEl = this.interactiveFieldTarget
    this.plugin = $(this.pluginMainEl).data('daterangepicker') // weird

    if (this.hasStartDateValue || this.hasEndDateValue) {
      this.updateFields(startDate, endDate)
    }

    $(this.pluginMainEl).on('apply.daterangepicker', this.applyUpdate.bind(this))
    $(this.pluginMainEl).on('cancel.daterangepicker', this.clearDate.bind(this))
  }

  // update data and display value
  updateFields(start, end) {
    this.startFieldTarget.value = start.format('YYYY-MM-DD')
    this.endFieldTarget.value = end.format('YYYY-MM-DD')
    $(this.interactiveFieldTarget).val(
      `${start.isValid() ? start.format('MM/DD/YYYY') : ''} - ${end.isValid() ? end.format('MM/DD/YYYY') : ''}`
    )
  }

  applyUpdate(event, picker) {
    this.updateFields(picker.startDate, picker.endDate)
    this.pluginMainEl.dispatchEvent(new Event('change', { detail: picker, bubbles: true }))
  }

  showWidget() {
    this.plugin.show() // undocumented
  }

  hideWidget() {
    this.plugin.hide() // undocumented
  }

  teardownPluginInstance() {
    if (this.plugin === undefined) {
      return
    }

    $(this.pluginMainEl).off('apply.daterangepicker')
    $(this.pluginMainEl).off('cancel.daterangepicker')

    // revert to original markup, remove any event listeners
    this.plugin.remove()
  }

  dispatchNativeEvent(event) {
    this.interactiveFieldTarget.dispatchEvent(
      new Event('change', { detail: { originalEvent: event }, bubbles: true, cancelable: true })
    )
  }
}
