import ApplicationController from './application_controller'
import dragula from 'dragula'

export default class extends ApplicationController {
  static targets = ['target', 'template', 'sorting']

  static values = {
    wrapperSelector: {
      default: '.sortable-has-many-association-wrapper',
    },
  }

  connect() {
    dragula([this.sortingTarget], {
      moves: () => {
        return true
      },
      accepts: () => {
        return true
      },
    })
      .on('drop', (el) => {
        this.setSortOrders()
      })
      .on('over', () => {
        // deselect any text fields, or else things go slow!
        document.activeElement.blur()
      })
  }

  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(e) {
    e.preventDefault()

    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }
  }

  setSortOrders() {
    Array.from(this.sortingTarget.querySelectorAll(this.wrapperSelectorValue))
      .filter((el) => el.style.display !== 'none')
      .forEach((el, index) => {
        const hiddenField = el.querySelector("input[type='hidden'][name*='sort_order']")
        if (hiddenField) {
          hiddenField.value = index
        }
      })
  }
}
