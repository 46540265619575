import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['checkbox', 'form', 'submit', 'selectedCount']
  static values = {
    storedCheckedProducts: Array,
    namePrefix: String,
  }

  initialize() {
    this.checkedProducts = new Set()
  }

  // Allows us to fire #applyStoredCheckedProducts when new target checkboxes
  // are applied to the DOM
  checkboxTargetConnected() {
    this.applyStoredCheckedProducts()
    this.updateSelectedCount()
    this.enableSubmitButton()
  }

  checked(event) {
    if (event.target.checked) {
      this.checkedProducts.add(event.target.value)
    } else {
      this.checkedProducts.delete(event.target.value)
    }

    this.updateStoredCheckedProducts()
    this.updateSelectedCount()
    this.enableSubmitButton()
  }

  updateStoredCheckedProducts() {
    this.storedCheckedProductsValue = Array.from(this.checkedProducts)
  }

  // Handles applying state of checked checkboxes on pagination
  applyStoredCheckedProducts() {
    this.checkboxTargets.forEach((c) => {
      if (this.storedCheckedProductsValue.includes(c.value)) {
        c.checked = true
      }
    })
  }

  // Handles appending checkbox inputs to the form for selected
  // products not displayed on the current page
  appendMissingCheckboxesToForm() {
    let currentPageCheckedProducts = this.currentPageCheckedProducts
    // Allows awareness of last nested delineator id so there is no collision
    // with below generated checkboxes
    let lastNestedFormDelineatorId = this.checkboxTargets.length - 1
    this.storedCheckedProductsValue.forEach((id) => {
      if (!currentPageCheckedProducts.includes(id)) {
        this.formTarget.appendChild(this.createCheckbox(id, lastNestedFormDelineatorId + 1))
        lastNestedFormDelineatorId = lastNestedFormDelineatorId + 1
      }
    })

    return true
  }

  // Build our checkbox input
  createCheckbox(id, nextDelineatorId) {
    var input = document.createElement('INPUT')
    input.setAttribute('type', 'checkbox')
    input.setAttribute('checked', true)
    input.setAttribute('value', id)
    input.setAttribute('name', `${this.namePrefixValue}[${nextDelineatorId}][product_id]`)
    input.classList.add('hidden')
    return input
  }

  get currentPageCheckedProducts() {
    return this.checkboxTargets.filter((c) => c.checked).map((c) => c.value)
  }

  updateSelectedCount() {
    this.selectedCountTarget.textContent = this.storedCheckedProductsValue.length
  }

  enableSubmitButton() {
    this.storedCheckedProductsValue.length > 0
      ? this.submitTarget.classList.remove('disabled')
      : this.submitTarget.classList.add('disabled')
  }
}
