import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['guidePageIdInput', 'guidePageNameInput']

  connect() {
    window.addEventListener('message', this.handleMessage.bind(this))
  }

  disconnect() {
    window.removeEventListener('message', this.handleMessage.bind(this))
  }

  handleMessage(event) {
    const message = event.data
    if (message.type !== 'type_page_guide') return

    this.guidePageIdInputTarget.value = message.id
    this.guidePageNameInputTarget.value = message.name
    this.element.dispatchEvent(new Event('change'))
    document.getElementById('guide-page-name').innerHTML = message.name
    document.getElementById('select-page-dialog').hide()
  }
}
