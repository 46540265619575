import ApplicationController from './application_controller'
import { saveSortOrder, SortError } from './sortable_tree_controller'

export default class extends ApplicationController {
  static targets = ['section', 'caret', 'moveable']

  move(event) {
    const { url, id, type, direction } = event.currentTarget.dataset
    const section = event.currentTarget.closest('[data-sortable-accordion-target]')

    if (direction == 'up') {
      if (section.previousElementSibling) {
        section.parentNode.insertBefore(section, section.previousElementSibling)
      }
    } else {
      if (section.nextElementSibling) {
        section.parentNode.insertBefore(section.nextElementSibling, section)
      }
    }

    const newIndex = this.moveableTargets.indexOf(section)

    saveSortOrder(url, type, id, newIndex)
      .then((response) => {
        if (response.ok) {
          return
        } else {
          throw new SortError(`Sort failed for ${url}`)
        }
      })
      .catch((error) => {
        this.handleError(error, error.message, {
          url,
          id,
          type,
        })
      })
  }

  toggle() {
    this.sectionTargets.forEach((el) => el.classList.toggle('hidden'))
    this.caretTargets.forEach((target) => {
      /* TODO: Extract these classes into configurable options */
      this.toggleCSSclasses(target, 'fa-caret-down', 'fa-caret-right', 'fa-cube', 'fa-cube')
    })
  }

  toggleAllChildren() {
    this.element.querySelectorAll('[data-sortable-accordion-target="section"]').forEach((section) => {
      section.classList.toggle('hidden')
    })
  }

  open() {
    this.sectionTargets.forEach((el) => el.classList.remove('hidden'))
  }

  toggleCSSclasses(el, ...cls) {
    cls.map((cl) => el.classList.toggle(cl))
  }
}
