import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = [
    'optionWrapper',
    'optionContainer',
    'optionFormContainer',
    'addOptionButton',
    'optionTemplate',
    'collapsedFormContainer',
  ]
  static values = {
    propertyCount: Number,
    optionLimit: { type: Number, default: 100 },
    persistedProperties: Boolean,
    noInventoryConfirmMessage: String,
    hasInventoryConfirmMessage: String,
  }

  initialize() {
    this.hideOrShowButton()
    if (this.persistedPropertiesValue == true) {
      this.collapseOptionForms()
      this.openIndexViews()
    }
  }

  addOption(event) {
    event.preventDefault()

    const optionCount = this.optionWrapperTargets.length
    const lastOption = this.optionWrapperTargets[optionCount - 1]
    let index = 1

    if (optionCount > 1) {
      index = parseInt(lastOption.dataset.index) + 1
    }

    const content = this.optionTemplateTarget.innerHTML.replace(/OPTION_INDEX/g, index)
    lastOption.insertAdjacentHTML('afterend', content)
    this.propertyCountValue++

    this.hideOrShowButton()
  }

  removeOption(event) {
    event.preventDefault()

    this.optionFormContainerTargets.forEach((option) => {
      if (option.dataset.index == event.params.index) {
        if (option.dataset.persisted == 'true') {
          const button = $('.product-form-submit__button')
          if (button.attr('data-turbo-confirm') == undefined) {
            button.attr(
              'data-turbo-confirm',
              option.dataset.hasInventory == 'true'
                ? this.hasInventoryConfirmMessageValue
                : this.noInventoryConfirmMessageValue
            )
            // Handles when the above message has already been set but without inventory warning
          } else if (option.dataset.hasInventory == 'true') {
            button.attr('data-turbo-confirm', this.hasInventoryConfirmMessageValue)
          }
        }

        this.hideElement(option)
      }
    })

    this.collapsedFormContainerTargets.forEach((collapsedOption) => {
      if (collapsedOption.dataset.index == event.params.index) {
        collapsedOption.remove()
      }
    })
  }

  collapseForm(event) {
    if (event.currentTarget.checked == false) {
      this.collapseOptionForms()
      this.openIndexViews()
    }
  }

  collapseOptionForms() {
    this.optionFormContainerTargets.forEach((el, index) => {
      if (!el.querySelector("select[name$='[name]']").value == '') {
        el.classList.add('hidden')
      } else if (index != 0) {
        this.hideElement(el)
      }
    })
  }

  openIndexViews() {
    this.collapsedFormContainerTargets.forEach((el, index) => {
      if (!el.getElementsByTagName('p')[0].innerText == '') {
        el.classList.remove('hidden')
      } else if (index != 0) {
        el.remove()
      }
    })
  }

  hideElement(el) {
    el.querySelector("input[name*='_destroy']").value = 1
    el.classList.add('hidden')
    this.propertyCountValue--
    this.hideOrShowButton()
  }

  hideOrShowButton() {
    if (this.propertyCountValue < this.optionLimitValue) {
      this.addOptionButtonTarget.classList.remove('hidden')
    } else {
      this.addOptionButtonTarget.classList.add('hidden')
    }
  }
}
