import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  handlePageClaimComplete({ detail: { url } }) {
    this.element.hide()
    window.Turbo.visit(url)
  }

  resetDialog() {
    this.element.remove()
  }
}
