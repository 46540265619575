import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'
export default class extends ApplicationController {
  static values = {
    sessionUuid: String,
    workspaceId: String,
    userName: String,
    backToUrl: String,
    context: String,
    templateJson: Object,
    workspaceCollaborationEnabled: Boolean,
    validateSyntaxUrl: String,
    tokenUrl: String,
    specialLinks: String,
    presignedPutUrls: String,
    presignedPutUrlsResponseValue: Object,
    updateSessionUrl: String,
    saveAsTemplateUrl: String,
    setHasUnpublishedChangesUrl: String,
    emailTemplateChanged: { type: Boolean, default: false },
    hasUnpublishedChanges: Boolean,
    savedText: String,
    publishedText: String,
    unsavedChangesText: String,
    savingText: String,
    publishingText: String,
    hasUnpublishedChangesText: String,
    failedToPublishText: String,
    refreshPutUrlsInterval: { type: Number, default: 270000 },
    refreshPutUrlsIntervalId: Number,
    countdownTimerUrl: String,
    countdownTimerEnabled: Boolean,
    mergeTagsMenuPath: String,
    siteDomainUrl: String,
    locales: { type: Object, default: {} },
  }
  static targets = ['savingText', 'unpublishedChangesStatus', 'discardButton']
  static classes = ['savedStatusMenuItem', 'unsavedStatusMenuItem', 'disabledButton']
  savedStatusMenuItemClasses = ['text-green-600']
  unsavedStatusMenuItemClasses = ['text-red-500']
  disabledButtonClasses = ['disabled', 'pointer-events-none']

  beefree = null
  exitOnSave = true

  setSavedStatus(text) {
    this.savingTextTarget.innerHTML = `${text} \
    <i class="ml-2 fa fa-circle-check text-green-600"></i>`
    this.savingTextTarget.classList.remove(...this.unsavedStatusMenuItemClasses)
    this.savingTextTarget.classList.add(...this.savedStatusMenuItemClasses)
  }

  setUnsavedStatus(text) {
    this.savingTextTarget.innerHTML = text
    this.savingTextTarget.classList.remove(...this.savedStatusMenuItemClasses)
    this.savingTextTarget.classList.add(...this.unsavedStatusMenuItemClasses)
  }

  setSavingStatus(text) {
    this.savingTextTarget.innerHTML = text
    this.savingTextTarget.classList.remove(...this.savedStatusMenuItemClasses)
    this.savingTextTarget.classList.remove(...this.unsavedStatusMenuItemClasses)
  }

  setUnpublishedChangesStatus() {
    this.unpublishedChangesStatusTarget.innerHTML = `${this.hasUnpublishedChangesTextValue} \
    <i class="ml-2 fa fa-exclamation-triangle text-yellow-600"/>`
  }

  hasUnpublishedChangesValueChanged() {
    if (this.hasUnpublishedChangesValue) {
      this.setUnpublishedChangesStatus()
      this.discardButtonTarget.classList.remove(...this.disabledButtonClasses)
    } else {
      this.unpublishedChangesStatusTarget.innerHTML = ''
      this.setSavedStatus(this.publishedTextValue)
      this.discardButtonTarget.classList.add(...this.disabledButtonClasses)
    }
  }

  emailTemplateChangedValueChanged() {
    if (this.emailTemplateChangedValue) {
      this.setUnsavedStatus(this.unsavedChangesTextValue)
    } else if (!this.hasUnpublishedChangesValue) {
      this.setSavedStatus(this.publishedTextValue)
    } else {
      this.setSavedStatus(this.savedTextValue)
    }
  }

  onSaveSuccessFunction = () => {
    return
  }

  async connect() {
    await this.start()
    // set put urls immediately
    await this.refreshPresignedPutUrlsResponse()
    // Refresh the put urls every 270 seconds (4.5 minutes) to account for 300 second expire rate
    this.refreshPutUrlsIntervalIdValue = setInterval(
      this.refreshPresignedPutUrlsResponse.bind(this),
      this.refreshPutUrlsIntervalValue
    )
  }

  async disconnect() {
    clearInterval(this.refreshPutUrlsIntervalIdValue)
  }

  async refreshPresignedPutUrlsResponse() {
    const response = await new FetchRequest('GET', this.presignedPutUrlsValue).perform()
    this.presignedPutUrlsResponseValue = await response.json
  }

  togglePreview(event) {
    event.preventDefault()
    this.beefree.togglePreview()
  }

  saveAsTemplate(event) {
    event.preventDefault()
    this.beefree.saveAsTemplate()
  }

  save(event) {
    event.preventDefault()
    this.beefree.save()
  }

  beefreeStartSession(join) {
    let sessionUuid = this.sessionUuidValue
    let workspaceCollaborationEnabled = this.workspaceCollaborationEnabledValue

    let iframe = document.querySelector('#bee-plugin-container iframe')
    if (iframe) {
      iframe.remove()
    }

    if (join) {
      if (!sessionUuid) {
        return
      }

      if (workspaceCollaborationEnabled) {
        this.beefree.join(this.beefreeGenerateConfig(), sessionUuid)
      } else {
        this.beefree.start(this.beefreeGenerateConfig(), this.templateJsonValue, null, { shared: true })
      }
    } else {
      this.beefree.start(this.beefreeGenerateConfig(), this.templateJsonValue, null, { shared: true })
    }
  }

  removeContentDialog = () => {
    let container = document.getElementById('content-dialog')
    container.remove()
  }

  beefreeGenerateConfig() {
    const onSave = async (jsonFile, htmlFile) => {
      if (this.hasUnpublishedChangesValue) {
        try {
          this.setSavingStatus(this.publishingTextValue)

          const valid = await this.validateSyntax(htmlFile)
          if (!valid) {
            window.showToast(this.localesValue.templateInvalid)
            this.onSaveSuccessFunction = () => {
              return
            }
            this.exitOnSave = true
            this.setUnsavedStatus(this.failedToPublishTextValue)
            return false
          }

          const htmlRequest = new FetchRequest('PUT', this.presignedPutUrlsResponseValue.template_html_put_url, {
            body: htmlFile,
          })

          const jsonRequest = new FetchRequest('PUT', this.presignedPutUrlsResponseValue.template_json_put_url, {
            body: jsonFile,
          })

          const draftHtmlRequest = new FetchRequest(
            'PUT',
            this.presignedPutUrlsResponseValue.draft_template_html_put_url,
            {
              body: htmlFile,
            }
          )

          const draftJsonRequest = new FetchRequest(
            'PUT',
            this.presignedPutUrlsResponseValue.draft_template_json_put_url,
            {
              body: jsonFile,
            }
          )

          const setHasUnpublishedChangesRequest = new FetchRequest('POST', this.setHasUnpublishedChangesUrlValue, {
            body: JSON.stringify({ has_unpublished_changes: false }),
            headers: { 'Content-Type': 'application/json' },
          })

          await Promise.all([
            htmlRequest.perform(),
            jsonRequest.perform(),
            draftHtmlRequest.perform(),
            draftJsonRequest.perform(),
            setHasUnpublishedChangesRequest.perform(),
          ])

          this.hasUnpublishedChangesValue = false
          this.emailTemplateChangedValue = false
          this.onSaveSuccessFunction()
        } catch (error) {
          console.error('beefree - Saving Error', error)
        }
      }

      if (this.contextValue !== 'fwb' && this.exitOnSave) {
        window.location.replace(this.backToUrlValue)
      }

      this.onSaveSuccessFunction = () => {
        return
      }
      this.exitOnSave = true
    }

    const onAutoSave = async (jsonFile) => {
      if (!this.emailTemplateChangedValue) return

      try {
        this.setSavingStatus(this.savingTextValue)
        const draftJsonRequest = new FetchRequest(
          'PUT',
          this.presignedPutUrlsResponseValue.draft_template_json_put_url,
          {
            body: jsonFile,
          }
        )

        const setHasUnpublishedChangesRequest = new FetchRequest('POST', this.setHasUnpublishedChangesUrlValue, {
          body: JSON.stringify({ has_unpublished_changes: true }),
          headers: { 'Content-Type': 'application/json' },
        })

        await Promise.all([draftJsonRequest.perform(), setHasUnpublishedChangesRequest.perform(), this.beefree.send()])
        this.hasUnpublishedChangesValue = true
        this.emailTemplateChangedValue = false
      } catch (error) {
        console.error('beefree - Saving Error', error)
      }
    }

    const onLoad = () => {
      document.querySelector('#bee-plugin-container iframe').style.display = 'block'
      window.templateHasUnsavedChanges = function () {
        return this.emailTemplateChangedValue || false
      }
      if (this.contextValue != 'fwb') {
        const $emailEditorBackButton = document.getElementById('email-editor-back-button')
        $emailEditorBackButton.classList.remove('hidden')
      }

      window.removeRailsLoader()
    }

    const onError = (errorMessage) => {
      console.log('beefree - onError ', errorMessage)
      // Session doesn't exist
      if (errorMessage.code == 5150) {
        this.beefreeStartSession(false)
      }
    }

    const onSessionStarted = async (sessionInfo) => {
      const requestBody = { session_uuid: sessionInfo.sessionId }
      const request = new FetchRequest('POST', this.updateSessionUrlValue, {
        body: JSON.stringify(requestBody),
        headers: { 'Content-Type': 'application/json' },
      })

      try {
        await request.perform()
        console.log('beefree - Session Updated')
      } catch (error) {
        console.error('beefree - Session Update Error', error)
      }
    }

    const getContentDialog = async (path) => {
      const request = new FetchRequest('GET', path, {
        headers: { 'Content-Type': 'application/json' },
      })

      const response = await request.perform()

      if (response.ok) {
        const modalContent = await response.html
        loadContentDialog(modalContent)
      } else {
        console.error('Failed to fetch the modal content')
      }
    }

    const loadContentDialog = (modalContent) => {
      let container = document.createElement('div')
      container.id = 'content-dialog'
      container.innerHTML = modalContent
      document.body.appendChild(container)
    }

    const openCountdownTimerGallery = async () => {
      await getContentDialog(this.countdownTimerUrlValue)

      return new Promise((resolve, reject) => {
        // One-time event listener for the countdownTimerSelected event
        const countdownTimerSelectedHandler = (event) => {
          document.removeEventListener('countdownTimerSelected', countdownTimerSelectedHandler)
          const mtUid = event.detail.mtUid
          resolve(mtUid)
          this.removeContentDialog()
        }

        document.addEventListener('countdownTimerSelected', countdownTimerSelectedHandler)

        // One-time event listener for the removeContentDialog event
        const removeContentDialogHandler = () => {
          document.removeEventListener('removeContentDialog', removeContentDialogHandler)
          reject()
          this.removeContentDialog()
        }

        document.addEventListener('removeContentDialog', removeContentDialogHandler)
      })
    }

    const openMergeTagMenu = async () => {
      await getContentDialog(this.mergeTagsMenuPathValue)

      return new Promise((resolve, reject) => {
        const mergeTagSelectedHandler = (event) => {
          document.removeEventListener('mergeTagSelected', mergeTagSelectedHandler)
          resolve(event.detail.mergeTag)
          this.removeContentDialog()
        }

        document.addEventListener('mergeTagSelected', mergeTagSelectedHandler)

        // One-time event listener for the removeContentDialog event
        const removeContentDialogHandler = () => {
          document.removeEventListener('removeContentDialog', removeContentDialogHandler)
          reject()
          this.removeContentDialog()
        }

        document.addEventListener('removeContentDialog', removeContentDialogHandler)
      })
    }

    return {
      uid: `workspace-${this.workspaceIdValue}`,
      container: 'bee-plugin-container',
      customCss: this.assetUrlValue,
      shared: true,
      username: this.userNameValue,
      userColor: 'black',
      language: 'en-US',
      autosave: 5,
      saveRows: false,
      trackChanges: true,
      onChange: () => {
        this.emailTemplateChangedValue = true
        this.hasUnpublishedChangesValue = true
      },
      onSave,
      onAutoSave,
      onTogglePreview: this.onTogglePreview,
      onLoad,
      onSaveAsTemplate: () => {
        window.open(this.saveAsTemplateUrlValue, '_blank')
      },
      onSend: async (htmlFile) => {
        // This is now responsible for saving the draft.
        // onAutoSave does not recieve the htmlFile, but onSend does.
        try {
          const draftHtmlRequest = new FetchRequest(
            'PUT',
            this.presignedPutUrlsResponseValue.draft_template_html_put_url,
            {
              body: htmlFile,
            }
          )
          await draftHtmlRequest.perform()
        } catch (error) {
          console.error('beefree - Saving Draft Error', error)
        }
      },
      onError,
      onSessionStarted,
      onLoadWorkspace: (workspace) => {
        console.log(`workspace: ${workspace} has been loaded`)
      },
      specialLinks: JSON.parse(this.specialLinksValue),
      contentDialog: {
        mergeTags: {
          label: this.localesValue.mergeTagsLabel,
          handler: (resolve, reject) => {
            openMergeTagMenu()
              .then((mergeTag) => {
                resolve(mergeTag)
              })
              .catch((e) => {
                reject()
              })
          },
        },
        addOn: {
          handler: (resolve, reject, args) => {
            if (args.contentDialogId == 'countdown_timer_dialog') {
              openCountdownTimerGallery()
                .then((countdownTimerMtUid) => {
                  const html =
                    `<img src="${this.siteDomainUrlValue}/deadline_timers/` +
                    `{{calendar_event.id}}/{{contact.id}}.gif?timer_id=${countdownTimerMtUid}" ` +
                    'border="0" alt="" style="max-width:100%;" />'
                  resolve({
                    type: 'html',
                    value: {
                      html,
                    },
                  })
                })
                .catch((e) => {
                  reject()
                })
            }
          },
        },
      },
      addOns: [
        {
          id: 'countdown_timer_dialog',
          enabled: this.countdownTimerEnabledValue,
        },
      ],
    }
  }

  async validateSyntax(htmlFile) {
    const requestBody = { html: htmlFile }
    const request = new FetchRequest('PUT', this.validateSyntaxUrlValue, {
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json' },
    })

    const response = await request.perform()

    return !!response.ok
  }

  async start() {
    const request = new FetchRequest('POST', this.tokenUrlValue)
    const response = await request.perform()

    if (response.ok) {
      const token = await response.text
      this.beefree = new window.Bee()
      this.beefree.token = JSON.parse(token)
      this.beefreeStartSession(this.sessionUuidValue !== '')
    } else {
      console.log('There was a problem with beefree request.', response.status)
    }
  }

  async abort(event) {
    event.preventDefault()
    const url = new URL(event.target.href)
    url.searchParams.set('context', 'fwb')
    await fetch(url.toString())
    window.top.dispatchEvent(new Event('templateEditor:discard-changes-and-exit'))
  }
}
