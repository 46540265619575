import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['accessAllAssetsCheckbox', 'assetCheckbox', 'inheritedAssetCheckbox', 'selectAllAssetsCheckbox']

  connect() {
    $('#right-side-panel').removeClass('px-6').removeClass('py-6').addClass('pt-6')
    $(document).on('turbo:frame-render', (event) => {
      $(event.target).addClass('h-full')
    })

    if (this.allAssetsAreInherited()) {
      this.selectAllAssetsCheckboxTarget.checked = false
      this.selectAllAssetsCheckboxTarget.indeterminate = false
    } else {
      this.updateSelectAllCheckboxState()
    }

    if (this.accessAllAssetsCheckboxTarget.checked) {
      this.selectAllAssetsCheckboxTarget.disabled = true
    }
  }

  updateSelectAllCheckboxState() {
    if (this.allAssetsSelected()) {
      this.selectAllAssetsCheckboxTarget.checked = true
      this.selectAllAssetsCheckboxTarget.indeterminate = false
    } else if (this.allAssetsAreUnchecked()) {
      this.selectAllAssetsCheckboxTarget.checked = false
      this.selectAllAssetsCheckboxTarget.indeterminate = false
    } else {
      this.selectAllAssetsCheckboxTarget.indeterminate = true
    }
  }

  checkAccessAllAssetBoxes() {
    if (this.accessAllAssetsCheckboxTarget.checked) {
      this.changeCheckboxStates({ checked: true, disabled: true })
      this.selectAllAssetsCheckboxTarget.indeterminate = false
      this.selectAllAssetsCheckboxTarget.checked = true
      this.selectAllAssetsCheckboxTarget.disabled = true
    } else {
      this.changeCheckboxStates({ checked: true, disabled: false })
      this.selectAllAssetsCheckboxTarget.disabled = false
    }
  }

  changeSelectAllState() {
    if (this.selectAllAssetsCheckboxTarget.checked) {
      this.changeCheckboxStates({ checked: true, disabled: false })
    } else {
      this.changeCheckboxStates({ checked: false, disabled: false })
    }
  }

  changeCheckboxStates(options = { checked: false, disabled: false }) {
    for (const index in this.assetCheckboxTargets) {
      this.assetCheckboxTargets[index].checked = options.checked
      this.assetCheckboxTargets[index].disabled = options.disabled
    }
  }

  allAssetsSelected() {
    return (
      this.assetCheckboxTargets.filter((checkbox) => checkbox.checked == false).length === 0 &&
      this.assetCheckboxTargets.length > 0
    )
  }

  allAssetsAreUnchecked() {
    return (
      this.assetCheckboxTargets.filter((checkbox) => checkbox.checked == true).length === 0 &&
      this.assetCheckboxTargets.length > 0
    )
  }

  allAssetsAreInherited() {
    return !!this.inheritedAssetCheckboxTargets.length && !this.assetCheckboxTargets.length
  }
}
