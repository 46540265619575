import ApplicationController from './application_controller'

function getOldTemplateId(el) {
  return el.dataset.oldTemplateId
}

function toggleHiddenCheckbox(el, selectedItems) {
  const oldTemplateId = getOldTemplateId(el)
  if (selectedItems.find(({ id }) => id === oldTemplateId) || !selectedItems.length) {
    el.classList.add('hidden')
  } else {
    el.classList.remove('hidden')
  }
}

export default class extends ApplicationController {
  static targets = ['hiddenCheckbox', 'checkboxForUpdatedTemplate']

  highlight(event) {
    this.clearHighlight()
    $(event.target).closest('.workflow-step-item').addClass('bg-gray-100')
  }

  clearHighlight() {
    $('.workflow-step-item').each((index, element) => {
      $(element).removeClass('bg-gray-100')
    })
  }

  showHiddenCheckboxes(event) {
    const { selectedItems } = event.detail
    this.hiddenCheckboxTargets.forEach((el) => toggleHiddenCheckbox(el, selectedItems))
    this.checkboxForUpdatedTemplateTargets.forEach((el) => {
      const oldTemplateId = getOldTemplateId(el)
      const editTemplateButton = document.querySelector('.FileSelectorField__PreviewContainer__EditButton')
      if (editTemplateButton?.classList.contains('always_hidden')) editTemplateButton.classList.remove('always_hidden')
      if (!oldTemplateId) return

      if (editTemplateButton) editTemplateButton.classList.add('always_hidden')
      toggleHiddenCheckbox(el, selectedItems)
    })
  }
}
