{
  "en": {
    "stimulus": {
      "application": null,
      "clipboard_copy": {
        "click_to_copy": "Click To Copy"
      },
      "commons": null,
      "index_sort": {
        "a_to_z": "A-Z",
        "false_first": "false first",
        "high_to_low": "high to low",
        "low_to_high": "low to high",
        "newest_first": "newest first",
        "oldest_first": "oldest first",
        "true_fist": "true first",
        "z_to_a": "Z-A"
      },
      "shoelace": {
        "confirm": {
          "cancel": "Cancel",
          "submit": "Proceed",
          "title": "Are you sure?",
          "type_to_confirm": "Type %{word} to confirm"
        }
      },
      "simonwep_pickr": {
        "aria:btncancel": "cancel and close",
        "aria:btnclear": "clear and close",
        "aria:btnsave": "save and close",
        "aria:hue": "hue selection slider",
        "aria:input": "color input field",
        "aria:opacity": "selection slider",
        "aria:palette": "color selection area",
        "btn:cancel": "Cancel",
        "btn:clear": "Clear",
        "btn:lastcolor": "use previous color",
        "btn:save": "Save",
        "btn:swatch": "color swatch",
        "btn:toggle": "toggle color picker dialog",
        "ui:dialog": "color picker dialog"
      }
    },
    "trix": {
      "GB": "GB",
      "KB": "KB",
      "MB": "MB",
      "PB": "PB",
      "TB": "TB",
      "attachFiles": "Attach Files",
      "bold": "Bold",
      "bullets": "Bullets",
      "byte": "Byte",
      "bytes": "Bytes",
      "captionPlaceholder": "Add a caption…",
      "code": "Code",
      "heading1": "Heading",
      "indent": "Increase Level",
      "italic": "Italic",
      "link": "Link",
      "numbers": "Numbers",
      "outdent": "Decrease Level",
      "quote": "Quote",
      "redo": "Redo",
      "remove": "Remove",
      "strike": "Strikethrough",
      "undo": "Undo",
      "unlink": "Unlink",
      "url": "URL",
      "urlPlaceholder": "Enter a URL…"
    },
    "unsaved_changes_controller": {
      "haveChanges": "You’ve made changes that haven’t been saved. Clicking 'OK' will discard them. Do you want to continue?",
      "noChanges": "No Unsaved Changes"
    }
  },
  "es": {
    "stimulus": {
      "clipboard_copy": {
        "click_to_copy": "Haz clic para copiar"
      },
      "index_sort": {
        "a_to_z": "A-Z",
        "false_first": "falso primero",
        "high_to_low": "de alto a bajo",
        "low_to_high": "bajo a alto",
        "newest_first": "el más reciente primero",
        "oldest_first": "los más antiguos primeros",
        "true_fist": "verdadero primero",
        "z_to_a": "Z-A"
      },
      "shoelace": {
        "confirm": {
          "cancel": "Cancelar",
          "submit": "Proceder",
          "title": "¿Estás seguro?",
          "type_to_confirm": "Escribe %{word} para confirmar"
        }
      },
      "simonwep_pickr": {
        "aria:btncancel": "cancelar y cerrar",
        "aria:btnclear": "limpiar y cerrar",
        "aria:btnsave": "guardar y cerrar",
        "aria:hue": "control de selección de tono",
        "aria:input": "campo de entrada de color",
        "aria:opacity": "control de selección",
        "aria:palette": "área de selección de color",
        "btn:cancel": "Cancelar",
        "btn:clear": "Borrar",
        "btn:lastcolor": "usar el color anterior",
        "btn:save": "Guardar",
        "btn:swatch": "muestra de color",
        "btn:toggle": "cambiar al cuadro de diálogo del selector de color",
        "ui:dialog": "cuadro de diálogo selector de color"
      }
    },
    "trix": {
      "GB": "GB",
      "KB": "KB",
      "MB": "MB",
      "PB": "PB",
      "TB": "TB",
      "attachFiles": "Adjuntar archivos",
      "bold": "Oscuro",
      "bullets": "Viñetas",
      "byte": "Byte",
      "bytes": "Bytes",
      "captionPlaceholder": "Agrega un subtítulo…",
      "code": "Código",
      "heading1": "Título",
      "indent": "Aumentar nivel",
      "italic": "Itálicas",
      "link": "Enlace",
      "numbers": "Números",
      "outdent": "Reducir nivel",
      "quote": "Citar",
      "redo": "Rehacer",
      "remove": "Quitar",
      "strike": "Tachado",
      "undo": "Deshacer",
      "unlink": "Desconectar",
      "url": "URL",
      "urlPlaceholder": "Introducir URL..."
    },
    "unsaved_changes_controller": {
      "haveChanges": "Tiene cambios no guardados, por favor confirme.",
      "noChanges": "Sin cambios no guardados"
    }
  }
}
