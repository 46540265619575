import ApplicationController from '../application_controller'

/**
 * Controller to handle the generation and updating of URLs based on selected
 * campaigns and affiliate codes.
 * @extends ApplicationController
 */
export default class extends ApplicationController {
  /**
   * Static values to hold campaign URLs and asset URLs.
   * @type {Object}
   */
  static values = {
    campaignUrls: Object,
    assetUrls: Object,
  }

  /**
   * Static targets to reference HTML elements within the controller's scope.
   * @type {string[]}
   */
  static targets = ['code', 'campaign', 'fullUrl', 'socials', 'assetUrl']

  /**
   * Updates the view when the controller is first loaded, primarily to set the
   * display of the assetUrlTarget button.
   */
  initialize() {
    this.updateUrl()
  }

  /**
   * Updates the URL based on the selected campaign and affiliate code.
   * Sets the full URL input value, updates social share URLs, and shows or
   * hides the asset URL link.
   */
  updateUrl() {
    const affiliateCode = this.codeTarget.value
    const selectedCampaign = this.campaignTarget.value
    const campaignUrl = this.campaignUrlsValue[selectedCampaign]
    const assetUrl = this.assetUrlsValue[selectedCampaign]

    if (campaignUrl) {
      const resultingUrl = `${campaignUrl}?aff=${affiliateCode}`
      this.fullUrlTarget.value = resultingUrl

      this.socialsTargets.forEach((social) => {
        social.setAttribute('data-share-url-param', resultingUrl)
      })
    }

    if (assetUrl) {
      this.assetUrlTarget.href = assetUrl
      this.assetUrlTarget.classList.remove('hidden')
    } else {
      this.assetUrlTarget.classList.add('hidden')
    }
  }
}
