import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['dialog', 'submit']

  showDialog() {
    this.dialogTarget.show()
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }
}
