import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['liveMode', 'form']

  setLiveMode(event) {
    const liveMode = event.target.dataset.liveMode
    this.liveModeTarget.value = liveMode
  }

  submitForm(event) {
    const liveMode = event.currentTarget.dataset.liveMode
    this.liveModeTarget.value = liveMode

    this.formTarget.requestSubmit()
  }
}
