const HELLO_EVENT = 'hello'
const REPLY_HELLO_EVENT = 'reply_hello'
const UNSUBSCRIBED_EVENT = 'unsubscribed'
const UPDATE_STATUS_EVENT = 'update_status'

const TRACKED_DOM_ELEMENTS = "input:not([type='hidden'])"

// TODO: use AnyCable class subscription which has the same unique init and reconnection mechanism.
export default class OnlineUsers {
  static identifier = 'OnlineUsers::Channel'

  constructor(consumer, turboEnabled = true, useListener = true) {
    this.consumer = consumer
    const listenTo = turboEnabled ? 'turbo:load' : 'load'
    const _this = this // eslint-disable-line @typescript-eslint/no-this-alias
    if (useListener) {
      window.addEventListener(listenTo, function () {
        _this.reconnect(window.location.pathname)
      })
    }
  }

  reconnect(path, id) {
    const $onlineUsersSection = document.getElementById('online-users-section')
    const $parentAnchor = document.querySelector('[data-append-online-users]')
    if ($parentAnchor) {
      const $onlineUsersContainer = document.getElementById('online-users-container')
      if (!$onlineUsersContainer) return

      $parentAnchor.appendChild($onlineUsersSection)
      $onlineUsersContainer.style.position = 'inherit'
    }
    if (!$onlineUsersSection) return
    this.userIdValue = $onlineUsersSection.getAttribute('data-user-id')
    this.routeNamespaceValue = $onlineUsersSection.getAttribute('data-route-namespace')
    this.routeActionValue = $onlineUsersSection.getAttribute('data-route-action')
    this.unsavedChangesValue = false
    this.activeUsersValue = []
    this._DOMcleanAvatars()

    this.consumer.cable.hub.channels
      .filter((channel) => channel.identifier.includes(this.constructor.identifier))
      .forEach((channel) => channel.subscription.unsubscribe())

    this._connect(path, id)
  }

  _connect(path, id) {
    this.channel = this.consumer.subscriptions.create(
      {
        channel: this.constructor.identifier,
        path: path,
        id: id,
        route_namespace: this.routeNamespaceValue,
        route_action: this.routeActionValue,
      },
      {
        connected: this._cableConnected.bind(this),
        received: this._cableReceived.bind(this),
      }
    )
    this.consumer.cable.hub.subscriptions.get(this.channel.identifier).ensureResubscribed()
  }

  _cableConnected() {
    const _this = this // eslint-disable-line @typescript-eslint/no-this-alias
    this.activeUsersValue = []
    this.channel.perform(HELLO_EVENT)

    window.addEventListener('online-users-controller:update:true', function () {
      if (_this.unsavedChangesValue) return
      _this.unsavedChangesValue = true
      _this.channel.perform(UPDATE_STATUS_EVENT, _this._myState())
    })

    window.addEventListener('online-users-controller:update:false', function () {
      if (!_this.unsavedChangesValue) return
      _this.unsavedChangesValue = false
      _this.channel.perform(UPDATE_STATUS_EVENT, _this._myState())
    })

    const currentPathArray = window.location.pathname.split('/')
    currentPathArray.pop()
    const actionForm = currentPathArray.join('/')
    if (actionForm) {
      const $form = document.querySelector(`form[action="${actionForm}"]`)
      if ($form) {
        $form.querySelectorAll(TRACKED_DOM_ELEMENTS).forEach(function ($el) {
          $el.addEventListener('change', function () {
            window.dispatchEvent(new CustomEvent('online-users-controller:update:true'))
          })
        })
      }
    }
  }

  _cableReceived(data) {
    switch (data.event) {
      case HELLO_EVENT:
        if (data.payload.id != this.userIdValue) {
          this._addOnlineUser(data.payload)
          this.channel.perform(REPLY_HELLO_EVENT, this._myState())
        }
        break
      case REPLY_HELLO_EVENT:
        if (data.payload.id != this.userIdValue) {
          this._addOnlineUser(data.payload)
        }
        break
      case UNSUBSCRIBED_EVENT:
        if (data.payload.id != this.userIdValue) {
          this._removeOnlineUser(data.payload)
        }
        break
      case UPDATE_STATUS_EVENT:
        if (data.payload.id != this.userIdValue) {
          this._updateOnlineUser(data.payload)
        }
        break
      default:
        console.warn('received unknown message')
    }
  }

  _myState() {
    return {
      unsavedChanges: this.unsavedChangesValue,
    }
  }

  _DOMgetUserAvatar(user) {
    return document.getElementById(`online-users-avatar-${user.id}`)
  }

  _DOMremoveUserAvatar(user) {
    const $imgDiv = this._DOMgetUserAvatar(user)
    if (!$imgDiv) return
    $imgDiv.style.opacity = '0'
    setTimeout(function () {
      $imgDiv.remove()
    }, 1000)
  }

  _DOMaddUserAvatar(user) {
    const $div = document.createElement('div')
    const $img = document.createElement('img')
    $div.setAttribute('class', 'online-users-avatar online-users-tooltip')
    $div.setAttribute('id', `online-users-avatar-${user.id}`)
    $div.setAttribute('data-tooltip', `${user.name}`)
    $div.appendChild($img)
    $img.setAttribute('alt', `${user.name}`)
    $img.setAttribute('title', `${user.name}`)
    $img.setAttribute('src', `${user.profile_photo_url}`)
    document.getElementById('online-users-avatars').appendChild($div)

    // Wait img source to be downloaded in order to start opacity transition
    $img.addEventListener('load', function () {
      $div.style.opacity = '1'
    })
  }

  _DOMcleanAvatars() {
    const allAvatars = document.querySelectorAll('.online-users-avatar')
    const userIdValue = this.userIdValue
    allAvatars.forEach(function (avatar) {
      if (avatar.getAttribute('data-id') != userIdValue) {
        avatar.remove()
      }
    })
  }

  _userExistsInActiveUsers(user) {
    return !!this.activeUsersValue.find((x) => x.id === user.id)
  }

  _addOnlineUser(newUser) {
    if (!this._userExistsInActiveUsers(newUser)) {
      this._DOMaddUserAvatar(newUser)
    }
    this.activeUsersValue = [...this.activeUsersValue.filter((user) => user.uuid !== newUser.uuid), newUser]
    document.getElementById('online-users-container').style.opacity = '1'
  }

  _removeOnlineUser(user) {
    this.activeUsersValue = [...this.activeUsersValue, user].filter((x) => x.uuid !== user.uuid)
    if (!this._userExistsInActiveUsers(user)) {
      this._DOMremoveUserAvatar(user)
    }
  }

  _updateOnlineUser(user) {
    const ret = this.activeUsersValue.slice(0)
    ret[this.activeUsersValue.findIndex((el) => el.uuid === user.uuid)] = user
    this.activeUsersValue = ret
  }
}
