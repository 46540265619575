import i18next from 'i18next'
import data from './locales/locales.json'

// translation key required by i18next
Object.keys(data).forEach((key) => (data[key] = { translation: data[key] }))

const availableLocales = ['en', 'es']
// IMPORTANT : these files requires to manually add new locales :
// app/javascript/utils/uppy.js

// https://www.i18next.com/overview/getting-started
i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  whitelist: availableLocales,
  preload: availableLocales,
  resources: data,
})

export { i18next as i18n, availableLocales }
