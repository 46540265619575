import ApplicationController from './application_controller'

export default class extends ApplicationController {
  /* Grab all links within the rendered trix content area, excluding turbo frame links,
     setting their target attribute to _blank.
  */
  connect() {
    let trixWrapper = this.element.querySelector('.trix-content')
    ;[...trixWrapper.querySelectorAll('a:not([data-turbo-frame])')].forEach((link) => {
      link.setAttribute('target', '_blank')
    })
  }

  // TODO: When old community is deprecated, we can remove this function
  setTrixContentLinkTargetBlank(event) {
    const el = event.target
    // Only set link target to blank if the link is inside a trix-content element
    if (el.tagName.toLowerCase() !== 'a:not([data-turbo-frame])') return
    if (el.closest('[class="trix-content"]')) el.target = '_blank'
  }
}
