import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static classes = ['selected', 'unselected']
  static targets = ['item']

  select({ target, params: { id, type } }) {
    this.unmarkSelected(this.findSelectedTarget())
    this.markSelected(target)

    this.broadcastSelected(id, type)
  }

  broadcastSelected(id, type) {
    this.dispatch('selected', {
      detail: {
        value: id,
      },
      prefix: type,
    })
  }

  unmarkSelected(target) {
    target.classList.remove(...this.selectedClasses)
    target.classList.add(...this.unselectedClasses)
  }

  markSelected(target) {
    target.classList.add(...this.selectedClasses)
    target.classList.remove(...this.unselectedClasses)
  }

  findSelectedTarget() {
    return this.itemTargets.find((item) => {
      return this.selectedClasses.every((className) => item.classList.contains(className))
    })
  }
}
