import ApplicationController from './application_controller'
import { patch } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['select']
  static values = {
    url: { type: String, default: '' },
  }

  broadcastChange(event) {
    this.dispatch('change', { detail: { previewStyleId: event.target.value }, bubbles: true })
  }

  async updatePreviewStyle(event) {
    event.preventDefault()

    const iframeElement = document.getElementById('react-editor-iframe')
    let previewStyleId = this.selectTarget.options[this.selectTarget.selectedIndex].value
    previewStyleId = isNaN(previewStyleId) ? null : previewStyleId
    const data = {
      action: 'change-style',
      style_id: previewStyleId,
    }
    iframeElement.contentWindow.postMessage(data, '*')
    this._updateUrlParams(previewStyleId)

    this.urlValue &&
      (await patch(this.urlValue, {
        body: { theme: { preview_style_id: previewStyleId } },
        contentType: 'application/json',
        responseKind: 'json',
      }))
  }

  _updateUrlParams(previewStyleId) {
    const urlParams = new URLSearchParams(window.location.search)
    if (previewStyleId) {
      urlParams.set('preview_style_id', previewStyleId)
    } else {
      urlParams.delete('preview_style_id')
    }
    const newurl = urlParams.toString().length ? '?' + urlParams.toString() : window.location.pathname
    window.history.pushState('', '', newurl)
  }
}
