import ApplicationController from './application_controller'

export default class extends ApplicationController {
  selectCountdownTimer(event) {
    const { mtUid } = event.params
    const customEvent = new CustomEvent('countdownTimerSelected', { detail: { mtUid } })
    document.dispatchEvent(customEvent)
  }

  removeContentDialog() {
    const customEvent = new CustomEvent('removeContentDialog')
    document.dispatchEvent(customEvent)
  }
}
