import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['content']

  toggle() {
    if (this.hasContentTarget) {
      this.contentTargets.forEach((target) => target.classList.toggle('hidden'))
    }
  }

  toggleCheck(event) {
    let checkboxInput = event.target.previousElementSibling
    checkboxInput.checked ? (checkboxInput.checked = false) : (checkboxInput.checked = true)
  }
}
