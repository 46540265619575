import { get } from '@rails/request.js'
import BaseSearchController from './base_search_controller'

export default class extends BaseSearchController {
  static targets = ['closeElement']

  connect() {
    super.connect()
  }

  _makeSearchRequest(url) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      get(url, {
        responseKind: 'turbo-stream',
      })
    }, 200)

    if (this.hasCloseElementTarget) {
      this.closeElementTarget.classList.add('hidden')
    }
  }
}
