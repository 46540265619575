export async function track(userId, key) {
  await fetch(`/account/users/${userId}/track`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    },
    body: JSON.stringify({ user: { tracking_key: key } }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch((error) => {
      console.error('There was a problem with the tracking request:', error)
      // Handle errors here
    })
}

export async function track_public(anonymousId, key) {
  await fetch('/track', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    },
    body: JSON.stringify({ anonymous_id: anonymousId, tracking_key: key }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch((error) => {
      console.error('There was a problem with the tracking request:', error)
      // Handle errors here
    })
}
