import ApplicationController from './application_controller'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export class SortError extends Error {
  constructor(message) {
    super(message)
    this.name = 'SortError'
  }
}

export const saveSortOrder = async (toSectionUrl, sortableItemType, sortableItemId, newIndex) => {
  const response = await patch(toSectionUrl, {
    body: {
      courses_section: {
        sort_order: newIndex,
        sortable_item_id: sortableItemId,
        sortable_item_type: sortableItemType,
      },
    },
    responseKind: 'turbo-stream',
  })

  return response
}

export default class extends ApplicationController {
  static targets = ['sortable']

  connect() {
    this.sortable = new Sortable(this.element, {
      group: this.element.dataset.type,
      animation: 150,
      easing: 'cubic-bezier(1, 0, 0, 1)',
      onEnd: this.handleSort.bind(this),
      draggable: '.sortable',
      emptyInsertThreshold: 10,
      handle: '.course-menu-item-handle',
    })
  }

  handleSort(event) {
    const {
      to: {
        dataset: { url: toSectionUrl },
      },
      item: {
        dataset: { id: sortableItemId, type: sortableItemType },
      },
      newIndex,
    } = event

    saveSortOrder(toSectionUrl, sortableItemType, sortableItemId, newIndex)
      .then((response) => {
        if (response.ok) {
          return
        } else {
          throw new SortError(`Sort failed for ${toSectionUrl}`)
        }
      })
      .catch((error) => {
        this.handleError(error, error.message, {
          toSectionUrl,
          sortableItemId,
          sortableItemType,
        })
      })
  }
}
