import { RevealController } from 'stimulus-reveal'

export default class extends RevealController {
  static targets = ['toggleable']
  static values = {
    selector: String,
  }

  show() {
    super.show()
    this.updateToggleClass()
  }

  hide() {
    super.hide()
    this.updateToggleClass()
  }

  toggle() {
    super.toggle()
    this.updateToggleClass()
  }

  updateToggleClass() {
    const targetClasses = this.toggleableTarget.classList
    const toggleClass = this.selectorValue

    return targetClasses.contains(toggleClass) ? targetClasses.remove(toggleClass) : targetClasses.add(toggleClass)
  }
}
