import ApplicationController from '../application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['loading', 'table']
  static values = {
    url: String,
    variantId: String,
    priceId: String,
  }

  connect() {
    this.fetchPreview()
  }

  async fetchPreview() {
    this.loadingTarget.classList.remove('hidden')
    this.tableTarget.classList.add('hidden')
    const formData = new FormData()
    formData.append('orders_line_item[variant_id]', this.variantIdValue)
    formData.append('orders_line_item[products_price_id]', this.priceIdValue)
    formData.append('orders_line_item[prorated]', 0)
    formData.append('orders_line_item[charge_now]', 1)
    const response = await post(this.urlValue, { body: formData, responseKind: 'turbo-stream' })
    this.loadingTarget.classList.add('hidden')
    this.tableTarget.classList.remove('hidden')
  }
}
