import ApplicationController from './application_controller'

export default class extends ApplicationController {
  broadcastChange(event) {
    const select = event.target,
      selectedOption = select.options[select.selectedIndex],
      domainName = selectedOption.getAttribute('data-domain-name')

    this.dispatch('change', { detail: { domainId: selectedOption.value, domainName: domainName }, bubbles: true })
  }
}
