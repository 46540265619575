import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['input']

  increment() {
    this.inputTarget.stepUp()
    this.dispatchChangeEvent()
  }

  decrement() {
    this.inputTarget.stepDown()
    this.dispatchChangeEvent()
  }

  validateNumericality(event) {
    let sourceValue = event.currentTarget.value
    const max = this.inputTarget.max ? Number(this.inputTarget.max) : undefined
    const min = this.inputTarget.min ? Number(this.inputTarget.min) : undefined
    const regex = this.allowDecimal ? /[^0-9-.]/ : /[^0-9-]/
    let validatedInput = Number(sourceValue.replace(regex, ''))

    if (max !== undefined && validatedInput > max) {
      validatedInput = max
    }

    if (min !== undefined && validatedInput < min) {
      validatedInput = min
    }

    // Allow negative values if min < 0 and allow decimals to be entered
    if (
      event.inputType !== 'deleteContentBackward' &&
      // eslint-disable-next-line max-len
      !(event.data === '-' && min && min < 0) && // if the last character entered was '-', min less than 0
      !(this.allowDecimal && event.data === '.') // if decimals are allowed and the last character was a decimal
    ) {
      this.inputTarget.value = validatedInput
    }
  }

  validateDecimal(event) {
    const input = event.currentTarget
    const sanitizedInput = input.value.replace(/[^0-9.]/g, '')
    const parts = sanitizedInput.split('.')

    // Check where there are more than two parts or if the decimal part has more than two digits
    // or if the sanitized version input is different from what the user input
    if (parts.length > 2 || parts[1]?.length > 2 || sanitizedInput !== input.value) {
      input.value = input.dataset.previousValue || ''
    } else {
      input.dataset.previousValue = sanitizedInput
    }
  }

  dispatchChangeEvent() {
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  get allowDecimal() {
    return this.inputTarget.step?.includes('.')
  }
}
