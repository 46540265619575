import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static outlets = ['unsaved-changes']
  static values = {
    allowCloseViaTurboConfirm: { type: Boolean, default: false },
  }

  connect() {
    this.setupListeners()
  }

  hasChanges() {
    this.canClose = false
  }

  noChanges() {
    this.canClose = true
  }

  handleRequestClose(event) {
    if (!this.canClose) {
      event.preventDefault()

      this.confirmChanges(event)
    }
  }

  preventOutsideClickClose(event) {
    if (event.detail.source === 'overlay') {
      event.preventDefault()
    }
  }

  /**
   * Event handler for 'turbo:submit-end' event. Calls this.element.hide() to automatically
   * close a drawer or dialog upon a successful form submission of a form contained
   * within this controller's scope at the time it was connected.
   *
   * Optionally, this behavior can be disabled by setting data-close-on-form-success="false"
   * on the element this controller is bound to.
   * @param {Event[{detail: {formSubmission: {result: {success: boolean}, formElement: HTMLElement}}}]} event
   *
   * @returns {void}
   */
  handleSubmitEnd(event) {
    const { formSubmission } = event?.detail
    const success = formSubmission?.result?.success
    const formElement = formSubmission?.formElement

    if (success && this.closeOnFormSuccess) {
      this.canClose = true
      /* Close the element automatically on successful form submission */
      if (this.originatingFormElementInScope(formElement) || this.canCloseViaTurboConfirm(formElement)) {
        this.element.hide()
      }
    }
  }

  setupListeners() {
    /* Allow element to close if no unsaved changes */
    if (this.element.dataset.tracksChanges) {
      this.canClose = true

      this.element.addEventListener('unsaved-changes:discard', this.hide.bind(this))
      this.element.addEventListener('unsaved-changes:has-changes', this.hasChanges.bind(this))
      this.element.addEventListener('unsaved-changes:no-changes', this.noChanges.bind(this))
      this.element.addEventListener('sl-request-close', this.handleRequestClose.bind(this))
    }

    /* Optionaly prevent the element from closing if clicking on the overlay */
    if (this.element.dataset.denyClose) {
      this.element.addEventListener('sl-request-close', this.preventOutsideClickClose.bind(this))
    }

    document.body.addEventListener('turbo:submit-end', this.handleSubmitEnd.bind(this))
  }

  confirmChanges(event) {
    if (!this.hasUnsavedChangesOutlet) return

    let outletsWithChanges = this.unsavedChangesOutlets.filter((outlet) => outlet.isDirty == true)

    if (outletsWithChanges.length) {
      outletsWithChanges.forEach((outlet) => outlet.discard(event))
    } else {
      this.unsavedChangesOutlets.forEach((outlet) => outlet.discard(event))
    }
  }

  hide(event) {
    if (event) {
      event.preventDefault()
    }

    this.element.hide()
  }

  remove(event) {
    event.stopPropagation()
    this.element.remove()
  }

  disconnect() {
    this.element.removeEventListener('unsaved-changes:discard', this.hide)
    this.element.removeEventListener('unsaved-changes:has-changes', this.hasChanges)
    this.element.removeEventListener('unsaved-changes:no-changes', this.noChanges)
    this.element.removeEventListener('sl-request-close', this.handleRequestClose)
    this.element.removeEventListener('sl-request-close', this.preventOutsideClickClose)
    this.element.removeEventListener('turbo:submit-end', this.handleSubmitEnd)
  }

  /**
   * Whether or not the provided form element is within the scope
   * of this controller at the time it connected, and it is not a
   * turboConfirm form element.
   *
   * @param {formElement: HTMLElement} formElement
   *
   * @returns {boolean}
   */
  originatingFormElementInScope(formElement) {
    return [...this.formElements].includes(formElement)
  }

  /**
   * Whether or not .hide() can be called on the element
   * based on whether the formElement is a TurboConfirm form
   * element and whether the allowCloseViaTurboConfirmValue has
   * been set to true.
   *
   * @param {formElement: HTMLElement} formElement
   *
   * @returns {boolean}
   */
  canCloseViaTurboConfirm(formElement) {
    if (!this.allowCloseViaTurboConfirmValue) {
      return false
    }

    const { turboConfirm } = formElement.dataset

    return turboConfirm !== 'undefined'
  }

  get closeOnFormSuccess() {
    return this.element.dataset.closeOnFormSuccess === 'true'
  }

  get formElements() {
    return this.element.querySelectorAll('form')
  }

  /**
   * Disables focus trapping within the connected element and descendent elements.
   * Re-enables focus trapping by listening for the sl-request-close event on the document.
   *
   * Leverages: https://shoelace.style/components/dialog#properties
   */
  disableFocusTrapping() {
    this.element.modal.activateExternal()
    this.enableFocusTrappingFn = this.enableFocusTrapping.bind(this)
    this.formSubmissionFn = this.formSubmit.bind(this)
    document.addEventListener('sl-request-close', this.enableFocusTrappingFn)
    document.addEventListener('turbo:submit-end', this.formSubmissionFn)
  }

  /**
   * Re-enables focus trapping to underlying element (dialog, drawer, etc.) to prevent
   * tab navigation leaving the drawer or underlying dialog and cleans up event listeners.
   */
  enableFocusTrapping() {
    this.element.modal.deactivateExternal()
    document.removeEventListener('sl-request-close', this.enableFocusTrappingFn)
    document.removeEventListener('turbo:submit-end', this.formSubmissionFn)
  }

  /**
   * Restores the default focus behavior only after the form submission event is successful.
   */
  formSubmit(event) {
    const { formSubmission } = event?.detail
    if (formSubmission?.result?.success) {
      this.enableFocusTrapping()
    }
  }
}
