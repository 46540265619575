import ApplicationController from './application_controller'
import { isEmpty } from '../utils/objects'

export default class extends ApplicationController {
  static values = {
    ignoredIds: Array,
    userAttributes: Object,
    apiToken: String,
    galleryWasOpened: Boolean,
    draggableEnabled: Boolean,
    reinitEnabled: Boolean,
    userflowId: { type: String, default: 'userflow-ui' },
  }

  /**
   * Adds a message listener for editor callbacks and initializes the draggable feature
   *
   * @returns {void}
   */
  connect() {
    window.addEventListener('message', this._editorCallbackMessageHandler.bind(this))
  }

  /**
   * Show the userflow widget
   *
   * @returns {void}
   */
  show() {
    document.getElementById('userflow-ui')?.classList.remove('hidden-harder')
  }

  /**
   * Hides the userflow element.
   *
   * If a target id is provided and is in the ignoredIds value, it will not hide userflow
   *
   * @param {object} [obj={}]
   * @param {object} [obj.target={}]
   * @param {string} [obj.target.id] - The id of the element that is checked against ignoredIds
   *
   * @returns {void}
   */
  hide({ target: { id } = {} } = {}) {
    if (this.ignoredIdsValue.includes(id)) return

    document.getElementById('userflow-ui')?.classList.add('hidden-harder')
  }

  /**
   * Toggles display of the userflow resource center.
   *
   * @returns {void}
   */
  toggleResourceCenter() {
    window.userflow.toggleResourceCenter()
  }

  /**
   * Opens the userflow resource center.
   *
   * @returns {void}
   */
  openResourceCenter() {
    window.userflow.openResourceCenter()
  }

  /**
   * Calls reset() on userflow.
   *
   * Userflow will need to be reinitialized after calling this.
   * You can do that with _init().
   *
   * @returns {void}
   */
  _reset() {
    window.userflow.reset()
  }

  /**
   * Initializes userflow. Also initializes the draggable handle.
   *
   * @returns {void}
   */
  _init() {
    window.userflow.init(this.apiTokenValue)
    this._identify()
    this._group()
  }

  /**
   * Handler for messages sent from the editor. Calls different functions based on the type of action sent.
   *
   * @param {object} obj - The message object containing data from the editor.
   * @param {object} obj.data - The data object within the message.
   * @param {string} obj.data.action - The name of the action sent from the editor.
   * @param {boolean} obj.data.open - A boolean indicating whether an editor tool is open or not.
   */
  _editorCallbackMessageHandler({ data }) {
    switch (data.action) {
      case 'editor-image-gallery-open-state-change':
        this._galleryOpenStateCallback(data.open)
        break
      case 'editor-tool-open-state-change':
        this._toolOpenStateCallback(data.open)
        break
    }
  }

  /**
   * Callback for when the editor image gallery is opened or closed.
   *
   * If open is false or reinitEnabledValue is false, the function returns early and does nothing.
   * Otherwise it sets galleryWasOpenedValue to true, then calls _reset()
   *
   * @param {boolean} open - indicating whether the editor image gallery is open or not
   *
   * @returns {void}
   */
  _galleryOpenStateCallback(open) {
    if (!open || !this.reinitEnabledValue) return

    this.galleryWasOpenedValue = true
    this._reset()
  }

  /**
   * Callback for when any tool is opened or closed in editor with it's open state.
   *
   * If open is true, it will hide userflow.
   *
   * If false, it will show userflow.
   * However, if galleryWasOpenedValue was set to true, it will re-initialize userflow instead.
   *
   * @param {boolean} open - Indicates whether any tool is open in the image gallery
   *
   * @returns {void}
   */
  _toolOpenStateCallback(open) {
    if (open) {
      this.hide()
      return
    }

    if (this.galleryWasOpenedValue) {
      this.galleryWasOpenedValue = false
      this._init()
      return
    }

    this.show()
  }

  /**
   * Calls userflow.identify and passes params given in userAttributesValue
   *
   * @returns {void}
   */
  _identify() {
    const {
      user: { id, email, name, signed_up_at: signedUpAt },
    } = this.userAttributesValue

    window.userflow.identify(id, {
      email: email,
      name: name,
      signed_up_at: signedUpAt,
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
    })
  }

  /**
   * Calls userflow.group with attributes given in userAttributesValue.
   *
   * If userAttributesValue.workspace is empty, the function returns early doing nothing.
   *
   * @returns {void}
   */
  _group() {
    if (isEmpty(this.userAttributesValue.workspace)) {
      return
    }

    const {
      workspace: {
        id,
        name,
        team: { plan_name: plan },
      },
      membership: { role },
    } = this.userAttributesValue

    const workspaceParams = { name, plan }
    const membershipParams = { membership: { role } }

    window.userflow.group(id, workspaceParams, membershipParams)
  }
}
