import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['availableQuantity', 'onHandQuantity']

  static values = {
    committedQuantity: Number,
  }

  calculatedValue() {
    return (
      this.onHandQuantityTargets.map((location) => parseInt(location.value || 0)).reduce((a, b) => a + b, 0) -
      this.committedQuantityValue
    )
  }

  update() {
    this.availableQuantityTarget.textContent = this.calculatedValue()
  }
}
