import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form']

  connect() {
    // If we're in an iframe, set a param in the form
    if (window.self !== window.top) {
      const url = new URL(this.formTarget.action)
      url.searchParams.append('iframe', 'true')
      this.formTarget.action = url.toString()
    }
  }
}
