import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    loadingEventName: {
      type: String,
      default: 'loading:started',
    },
  }

  updateWidgets(event) {
    if (this.fieldValueAmongIgnoredValues(event)) {
      return
    }
    this.initWidgetUpdateFromFilterChange(event)
  }

  initWidgetUpdateFromFilterChange(event) {
    this.captureIntentToUpdateWidgetsOnValidatedFilters()
    this.startFieldLoadingIndicator(event?.target)
    this.submitForm()
  }

  captureIntentToUpdateWidgetsOnValidatedFilters() {
    this.dispatch('updating')
  }

  startFieldLoadingIndicator(field) {
    if (!field) {
      return
    }
    field.dispatchEvent(new CustomEvent(this.loadingEventNameValue, { bubbles: true }))
  }

  submitForm() {
    this.element.requestSubmit && this.element.requestSubmit()
  }

  fieldValueAmongIgnoredValues(event) {
    const field = event?.target
    const ignoredValues = event?.params?.ignoredValues

    if (!field || !ignoredValues) {
      return false
    }

    const value = field.value
    if (value === undefined) {
      return false
    }

    return ignoredValues.includes(value)
  }
}
