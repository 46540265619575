import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['card']
  static classes = ['selectedStat']

  updateCard(event) {
    this.cardTargets.forEach((card) => card.classList.remove(this.selectedStatClass))
    event.currentTarget.getElementsByTagName('div')[0].classList.add(this.selectedStatClass)
  }
}
