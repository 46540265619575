import '@rails/actiontext'
import Trix from 'trix'
import TrixEmbed from 'trix-embed'

export { Trix, TrixEmbed }

// The <trix-editor> prevents focus after a morph via the idiomorph algorithm.
// This workaround ensures re-initialization of the <trix-editor> after morphing
// by forcing a stimulus controller re-connect which re-enables focus behavior.
//
// Wait for TurboBoost Command to finish and apply focus to the [focus-selector] element
// TODO: (hopsoft) Update TurboBoost Elements to handle this after the Streams morph update
//       This strategy handles the TurboBoost.Streams.morph.delay
const focusTrixEditor = (morphs, selector, attempts = 1) => {
  if (attempts > 10) return
  const container = document.getElementById(morphs)
  const editor = container?.querySelector(selector)

  if (container && editor) {
    const clone = editor.cloneNode(true)
    editor.replaceWith(clone)
    clone.focus()
  } else {
    setTimeout(() => focusTrixEditor(morphs, selector, attempts + 1), 25 * attempts)
  }
}

document.addEventListener('turbo-boost:command:finish', (event) => {
  const { morphs, focusSelector } = event.detail.elementAttributes || {}
  if (morphs && focusSelector) focusTrixEditor(morphs, focusSelector)
})
