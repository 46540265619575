import ApplicationController from './application_controller'

export default class extends ApplicationController {
  getTrixEditor(event) {
    return event.target
  }

  getForm(trixEditor) {
    return trixEditor.closest('form')
  }

  getSubmitButton(form) {
    return form.querySelector('input[type="submit"], button[type="submit"]')
  }

  hasEmbedError(trixEditor) {
    return trixEditor.querySelector('[data-trix-embed-error]')
  }

  hasEmbedProhibited(trixEditor) {
    return trixEditor.querySelector('[data-trix-embed-prohibited]')
  }

  hasEmbedWarning(trixEditor) {
    return trixEditor.querySelector('[data-trix-embed-warning]')
  }

  hasProhibitedLink(trixEditor) {
    return trixEditor.innerHTML.includes('<strong>Prohibited Link:</strong>')
  }

  toggleSubmitButton(submitButton, disabled) {
    submitButton.disabled = disabled
  }

  checkForProhibitedContent(event) {
    const trixEditor = this.getTrixEditor(event)
    const form = this.getForm(trixEditor)
    const submitButton = this.getSubmitButton(form)
    const hasProhibitedContent =
      this.hasEmbedError(trixEditor) ||
      this.hasEmbedProhibited(trixEditor) ||
      this.hasEmbedWarning(trixEditor) ||
      this.hasProhibitedLink(trixEditor)
    this.toggleSubmitButton(submitButton, hasProhibitedContent)
  }
}
