import ApplicationController from '../application_controller'
import autosize from 'autosize'

// TODO: This controller is deprecated, replaced by components/autosize_controller.js
export default class extends ApplicationController {
  connect() {
    autosize(this.element)
  }

  updateSize() {
    autosize.update(this.element)
  }
}
