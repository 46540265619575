import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['fileField', 'preview', 'removeImage']
  static values = {
    defaultImageUrl: String,
    destroyImageParamName: { type: String, default: '_destroy' },
  }

  handleFileSelected(event) {
    const file = Array.from(event.target.files)[0]

    if (file) {
      this.previewTarget.src = URL.createObjectURL(file)

      if (this.hasRemoveImageTarget) {
        this.removeImageTarget.remove()
      }
    }
  }

  removeAndShowDefaultImage() {
    this.fileFieldTarget.value = null
    this.previewTarget.src = this.defaultImageUrlValue
    this.#insertHiddenRemoveImageField()
    this.fileFieldTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }

  #insertHiddenRemoveImageField() {
    const removeImageField = document.createElement('input')
    removeImageField.type = 'hidden'
    removeImageField.name = this.destroyImageParamNameValue
    removeImageField.value = '1'
    removeImageField.dataset.imageFileFieldPreviewTarget = 'removeImage'

    this.fileFieldTarget.after(removeImageField)
  }
}
