import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['paramsForm', 'loadingOverlay']

  updateWidget(event) {
    const filtersFormData = event?.detail?.formData
    if (!filtersFormData) {
      return
    }

    this.updateFormParams(filtersFormData)
    this.submitParamsForm()
  }

  updateFormParams(filtersFormData) {
    if (!this.hasParamsFormTarget) {
      return
    }

    for (const [name, value] of filtersFormData) {
      const field = this.findFieldWithName(name)
      if (!field) {
        this.addMissingField(name, value)
      } else {
        field.value = value
      }
    }
  }

  findFieldWithName(name) {
    if (!this.hasParamsFormTarget) {
      return
    }

    return this.paramsFormTarget.querySelector(`[name="${name}"]`)
  }

  addMissingField(name, value) {
    if (!this.hasParamsFormTarget) {
      return
    }

    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    this.paramsFormTarget.appendChild(input)
  }

  submitParamsForm() {
    if (!this.hasParamsFormTarget) {
      return
    }

    this.paramsFormTarget.requestSubmit && this.paramsFormTarget.requestSubmit()
  }

  adaptLoadingOverlayPlacement(event) {
    const { loadingOverlayContainerSelector } = event?.params
    if (!loadingOverlayContainerSelector) {
      return
    }
    if (!this.hasLoadingOverlayTarget) {
      return
    }

    const newContainer = this.element.querySelector(loadingOverlayContainerSelector)
    if (!newContainer) {
      return
    }
    newContainer.appendChild(this.loadingOverlayTarget)
  }
}
