import ApplicationController from './application_controller'

export default class extends ApplicationController {
  // original: https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/tabs.js
  // modified to allow no "tabs" selected, to hideAll
  static targets = ['menuItem', 'submenu', 'icon']

  connect() {
    this.activeIconClasses = (this.data.get('activeIcon') || 'active').split(' ')
    this.inactiveIconClasses = (this.data.get('inactiveIcon') || 'inactive').split(' ')
    this.index = null
    this.updateTargets()
  }

  toggle(event) {
    event.preventDefault()
    this.index = this.menuItemTargets.indexOf(event.currentTarget)
    this.toggleActive(event)
  }

  hideAll() {
    this.data.set('index', null)
    this.updateTargets()
    this.toggleActive()
  }

  updateTargets() {
    this.showActiveSubmenu()
    this.updateIconTargets()
  }

  toggleActive(event) {
    this.menuItemTargets.forEach((item) => {
      item.classList.remove('bg-blue-100')
    })
    if (typeof event === 'object') {
      event.currentTarget.classList.add('bg-blue-100')
    }
  }

  showActiveSubmenu() {
    this.submenuTargets.forEach((submenu, index) => {
      if (index === this.index) {
        submenu.classList.remove('hidden')
      } else {
        submenu.classList.add('hidden')
      }
    })
  }

  updateIconTargets() {
    this.iconTargets.forEach((icon, index) => {
      if (index === this.index) {
        icon.classList.remove(...this.inactiveIconClasses)
        icon.classList.add(...this.activeIconClasses)
      } else {
        icon.classList.remove(...this.activeIconClasses)
        icon.classList.add(...this.inactiveIconClasses)
      }
    })
  }

  get index() {
    const value = this.data.get('index')
    return value === 'null' ? null : parseInt(value) || 0
  }

  set index(value) {
    if (this.index === value) {
      this.hideAll()
      return
    }
    this.data.set('index', value === null ? null : value >= 0 ? value : 0)

    this.updateTargets()
  }
}
