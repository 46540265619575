import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
  }

  connect() {
    // If we're in an iframe, send the URL to the parent window
    if (window.self !== window.top) {
      const message = {
        id: 'redirectFromIframe',
        url: this.urlValue,
      }

      window.parent.postMessage(message, '*')
    } else {
      window.Turbo.visit(this.urlValue)
    }

    this.element.remove()
  }
}
