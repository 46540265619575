import ApplicationController from '../application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['entries', 'pagination']
  static values = {
    rootSelector: String,
  }

  initialize() {
    let options = {
      rootMargin: '200px',
      root: document.querySelector(this.rootSelectorValue),
    }

    this.intersectionObserver = new IntersectionObserver((entries) => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  async loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      return
    }
    let url = next_page.href

    await post(url, { responseKind: 'turbo-stream' })
  }
}
