import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['radio', 'existingSchedule', 'customHours']

  static classes = ['hidden']

  connect() {
    this.getSchedulingTypeRadio()
  }

  /**
   * Retrieves the selected scheduling type from radio buttons and updates the view accordingly.
   */
  getSchedulingTypeRadio() {
    if (!this.hasRadioTargets) return

    const selectedRadioType = this.radioTargets.find((radio) => radio.checked).dataset.type
    if (!selectedRadioType) return this.setInitialState()

    if (selectedRadioType == 'host') this.showExistingSchedule()
    if (selectedRadioType == 'custom') this.showCustomHours()
  }

  /**
   * Handles changes in scheduling type radio buttons.
   * @param {Event} event - The event object representing the radio button change event.
   */
  handleSchedulingTypeChange(event) {
    const schedulingType = event.target.dataset.type

    // Show the appropriate scheduling type based on the changed radio button
    if (schedulingType == 'host') this.showExistingSchedule()
    if (schedulingType == 'custom') this.showCustomHours()
  }

  /**
   * Sets the initial state when no radio button is checked.
   */
  setInitialState() {
    this.radioTargets[0].checked = true // Check the first radio button by default
    this.showExistingSchedule() // Show existing schedule by default
  }

  /**
   * Displays the existing schedule section and hides the custom hours section.
   */
  showExistingSchedule() {
    this.existingScheduleTarget.classList.remove(this.hiddenClass)
    this.customHoursTarget.classList.add(this.hiddenClass)
  }

  /**
   * Displays the custom hours section and hides the existing schedule section.
   */
  showCustomHours() {
    this.customHoursTarget.classList.remove(this.hiddenClass)
    this.existingScheduleTarget.classList.add(this.hiddenClass)
  }
}
