import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['hiddenTaxableFieldset', 'taxableFieldset', 'taxCategoryIdField', 'taxCategoryIdFieldset']
  static values = {
    eventSourceElementId: String,
  }

  handleTaxOverrideToggle(event) {
    if (event.detail.sourceEl.id == this.eventSourceElementIdValue) {
      // Toggle disabled prop
      $(this.hiddenTaxableFieldsetTarget).prop('disabled', (_, val) => !val)
      $(this.taxableFieldsetTarget).prop('disabled', (_, val) => !val)

      const e = new Event('change', { bubbles: true })
      this.element.dispatchEvent(e)

      this.resetTaxCategoryId()
    }
  }

  resetTaxCategoryId() {
    // Add some convenience for the user in case they are checking / unchecking things via clear / restore functions
    if (this.taxableFieldsetTarget.disabled || this.taxCategoryIdFieldsetTarget.classList.contains('hidden')) {
      this.clearTaxCategoryId()
      this.disableHiddenNullFields(false)
    } else {
      this.restoreTaxCategoryId()
      this.disableHiddenNullFields(true)
    }
  }

  clearTaxCategoryId() {
    this.taxCategoryId = this.taxCategoryIdFieldTarget.value
    this.taxCategoryIdFieldTarget.value = ''
  }

  restoreTaxCategoryId() {
    // When not taxable we disable the field so as to not submit the value
    // We remove the disable since taxable checkbox is true
    this.taxCategoryIdFieldTarget.disabled = false
    if (this.taxCategoryId !== undefined) {
      this.taxCategoryIdFieldTarget.value = this.taxCategoryId
    }
  }

  disableHiddenNullFields(disable) {
    this.hiddenTaxableFieldsetTarget.disabled = disable
  }
}
