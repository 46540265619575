import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['priceFromSelect', 'priceToSelect', 'variantToSelect', 'priceToSelectWrapper', 'loadingTemplate']

  connect() {
    this.triggerWarnings()
  }

  handlePriceChangeStart(event) {
    if (event.detail.name !== 'Products::Variants::UpgradeDowngradeWarnings') {
      return
    }

    if (this.hasPriceToSelectTarget) {
      const priceFromId = this.priceFromSelectTarget.options[this.priceFromSelectTarget.selectedIndex].value
      const priceToId = this.priceToSelectTarget.options[this.priceToSelectTarget.selectedIndex].value

      ;[this.priceFromSelectTarget, this.priceToSelectTarget].forEach((el) => {
        el.dataset.priceFromId = priceFromId
        el.dataset.priceToId = priceToId
        el.dataset.direction = this.priceFromSelectTarget.dataset.direction
      })
    } else {
      // Not enough information for command, so let's cancel it.
      event.preventDefault()
    }
  }

  handleVariantToChangeEnd(event) {
    if (event.detail.name !== 'Products::Variants::SubscriptionPricesSelect') {
      return
    }

    this.triggerWarnings()
  }

  triggerWarnings() {
    this.priceFromSelectTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }

  handleVariantToChange() {
    this.priceToSelectWrapperTarget.innerHTML = this.loadingTemplateTarget.innerHTML
  }
}
