import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['guestInput', 'hiddenInput', 'guestEmailRow', 'guestEmailTemplate', 'removeEmailButton']

  static classes = ['hidden']

  addValue() {
    if (this.shouldAddNewValue()) {
      let lastValue = this.guestEmailRowTargets[this.guestEmailRowTargets.length - 1]
      let index = 1

      if (lastValue != null) {
        index = parseInt(lastValue.dataset.index) + 1
      }

      let content = this.guestEmailTemplateTarget.innerHTML.replace(/NEW_VALUE/g, index)
      lastValue.insertAdjacentHTML('afterend', content)
    }

    this.showOrHideButtons()
    this.combine()
  }

  shouldAddNewValue() {
    let visibleInputCount = this.guestInputTargets.length
    return !(this.guestInputTargets[visibleInputCount - 1].value === '')
  }

  combine() {
    this.hiddenInputTarget.value = this.guestInputTargets
      .filter((obj) => obj.value != '')
      .map((obj) => obj.value)
      .join(',')
  }

  // Handle Buttons

  showOrHideButtons() {
    let valueCount = this.guestEmailRowTargets.length
    if (this.onlyOneValue(valueCount)) {
      this.hideAllRemoveValueButtons()
    } else if (valueCount > 1) {
      this.showRemoveValueButtons()
    }
  }

  showRemoveValueButtons() {
    this.guestEmailRowTargets.forEach((row, index) => {
      if (this.guestInputTargets[index].value != '') {
        this.removeEmailButtonTargets[index].classList.remove(...this.hiddenClasses)
      } else {
        this.removeEmailButtonTargets[index].classList.add(...this.hiddenClasses)
      }
    })
  }

  hideAllRemoveValueButtons() {
    this.removeEmailButtonTargets.forEach((el, index) =>
      this.removeEmailButtonTargets[index].classList.add(...this.hiddenClasses)
    )
  }

  onlyOneValue() {
    let values = this.guestEmailRowTargets.filter((value) => value.style.display != 'none')

    return values.length <= 1
  }

  removeValue(event) {
    let item = event.target.closest('.nested-value')
    item.remove()

    this.addValue()

    this.showOrHideButtons()
  }
}
