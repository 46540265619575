import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['reactModalContent']

  connect() {
    // NOTE: 43 is a magic number.  Keeps the modal content from scrolling (for the most part)
    //       when it doesn't need to / shouldn't.
    if (this.reactModalContentTarget.offsetHeight > 0) {
      const newState = {
        height: `${this.reactModalContentTarget.offsetHeight}px`,
      }
      window?.parent?.setModalState && window.parent.setModalState(newState)
    }
  }
}
