import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['checkbox']

  selectAll(event) {
    const isChecked = event.target.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.indeterminate = false
      checkbox.checked = isChecked
    })
  }
}
