// Used to sync currency across multiple inputs in one form.
// If a currency is selected on the 'original' input
// then all 'copy' inputs are changed to match it
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['original', 'copy']

  connect() {
    // TODO: handle this specific style for product price form elsewhere
    $('#right-side-panel').removeClass('bg-trueGray-25').addClass('bg-white')

    this.setCopies(this.originalTarget.selectedOptions[0].value)
  }

  syncCopies(event) {
    this.setCopies(event.currentTarget.selectedOptions[0].value)
  }

  setCopies(value) {
    this.copyTargets.forEach((copy) => {
      copy.textContent = value.toUpperCase()
    })
  }
}
