import ApplicationController from '../application_controller.js'

export default class extends ApplicationController {
  static targets = ['field', 'link', 'actions', 'badge']
  static values = {
    verified: { type: Boolean, default: false },
    phoneParamName: String,
  }

  connect() {
    this.intlTel = window.intlTelInputGlobals.getInstance(this.fieldTarget)
    this.initialFieldValue = stripNonNumeric(this.fieldTarget.value)

    this.updateUI()
    this.fieldTarget.addEventListener('input', () => this.handleFieldVerification())
  }

  handleFieldVerification() {
    const currentFieldValue = stripNonNumeric(this.fieldTarget.value)
    const isVerifiedValue = this.verifiedValue && currentFieldValue === this.initialFieldValue

    this.verifiedValue = isVerifiedValue
    this.updateUI()
  }

  updateVerifyLink(event) {
    event.preventDefault()

    this.initialFieldValue = stripNonNumeric(this.fieldTarget.value)
    const url = new URL(this.linkTarget.href)

    this.fieldTarget.classList.add('rounded-r-none')

    url.searchParams.set(this.phoneParamNameValue, this.intlTel.getNumber())
    Turbo.visit(url.toString(), { frame: 'dialog' })
  }

  updateUI() {
    const currentFieldValue = stripNonNumeric(this.fieldTarget.value)
    const isVerifiedValue = this.verifiedValue && currentFieldValue === this.initialFieldValue

    this.badgeTarget.classList.toggle('hidden', !isVerifiedValue)
    this.fieldTarget.classList.toggle('rounded-r-none', !this.badgeTarget.classList.contains('hidden'))
    this.actionsTarget.classList.toggle('hidden', isVerifiedValue || !currentFieldValue)
  }
}

function stripNonNumeric(value) {
  return value ? value.replace(/\D/g, '').trim() : ''
}
