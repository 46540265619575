import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['field']
  static values = {
    loadingEventName: {
      type: String,
      default: "loading:started"
    }
  }
  
  updateChartFromUrl(event) {
    const fieldsChanged = this.updateHiddenFormFieldsFromUrl()
    
    if (fieldsChanged) {
      this.updateChart()
    }
  }
  
  updateHiddenFormFieldsFromUrl() {
    const url = new URL(window.location.href)
    const urlParams = new URLSearchParams(url.search)
    
    let fieldsChanged = false
    
    this.fieldTargets.forEach((fieldTarget) => {
      if (fieldTarget.type === "hidden" && fieldTarget.name) {
        if (urlParams.has(fieldTarget.name)) {
          if (fieldTarget.value !== urlParams.get(fieldTarget.name)) {
            fieldTarget.value = urlParams.get(fieldTarget.name)
            fieldsChanged = true
          }
        } else {
          fieldTarget.remove()
          fieldsChanged = true
        }
      }
    })
    
    return fieldsChanged
  }
  
  updateChartFromFieldChange(event) {
    const field = event?.detail?.originalEvent?.target
    
    if (field) {
      field.dispatchEvent(new CustomEvent(this.loadingEventNameValue, { bubbles: true }))
    }
    
    this.updateHiddenFormFieldsFromUrl()
    this.overrideFieldsBasedOnFieldChanged(field)
    this.updateChart()
  }
  
  overrideFieldsBasedOnFieldChanged(changedField) {
    const changedFieldName = changedField?.name || changedField.dataset?.fieldName
    if (!changedFieldName) { return }
    
    // since the form elements aren't inside the scope of the Stimulus controller necessarily
    Array.from(this.element.elements).forEach((fieldTarget) => {
      if (fieldTarget.dataset?.overrideIfFieldsChanged === undefined) { return }
      const changedFieldNames = JSON.parse(fieldTarget.dataset?.overrideIfFieldsChanged)
      
      if (changedFieldNames.includes(changedFieldName)) {
        let hiddenOverride = document.createElement('input')
        hiddenOverride.type = "hidden"
        hiddenOverride.setAttribute('form', fieldTarget.getAttribute('form'))
        
        hiddenOverride.name = fieldTarget.name
        hiddenOverride.value = fieldTarget.dataset?.overrideWithValue || ""
        
        fieldTarget.after(hiddenOverride)
      }
    })
  }
  
  updateChart() {
    this.element.requestSubmit && this.element.requestSubmit()
  }
}