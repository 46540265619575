import ApplicationController from './application_controller'
import { useDispatch } from 'stimulus-use'
/* Stimulus controller for nested checkbox form with indeterminate checkbox state

  Usage:
    On the container element add:
      1. data-controller="checkbox-family"

    On the parent checkbox add:
      1. data: { "checkbox-family-target": "parent", action: "checkbox-family#toggleSelection" }

    On all children checkboxes add:
      1. data: { "checkbox-family-target": "child", action: "checkbox-family#toggleSelection" }
    
    For Root Parent behavior (One checkbox to toggle the rest)
      On the container for the input with the root parent
        1. data-controller="checkbox-family" 
           data-action="checkbox-family:update-rootparent@window->checkbox-family#updateRootparent"
      On the root parent checkbox add:
        1. data: { "checkbox-family-target": "parent rootparent", action: "checkbox-family#toggleSelection" }
      On the container for all other checkbox families you want controlled by the root
        1. data-controller="checkbox-family" data-action="checkbox-family:set-all@window->checkbox-family#setSelection"
*/

export default class extends ApplicationController {
  static targets = ['child', 'parent', 'rootparent']

  connect() {
    useDispatch(this, { element: window }) // eslint-disable-line react-hooks/rules-of-hooks
  }

  parentTargetConnected(element) {
    if (element == this.parentTarget && this.parentChecked()) return // If the checkbox has a value, don't override it

    if (element == this.parentTarget && this.someChildrenChecked()) {
      this.indeterminateParent(true)
    } else if (element == this.parentTarget && this.allChildrenChecked()) {
      this.checkParent(true)
    }
  }

  rootparentTargetConnected(element) {
    if (element == this.rootparentTarget) this.setRootparent()
  }

  toggleSelection(event) {
    if (event.currentTarget == this.parentTarget) {
      if (this.someChildrenChecked()) {
        this.checkParent(true)
      }
      this.checkAllChildren()
    } else if (this.allChildrenChecked()) {
      this.checkParent(true)
    } else if (this.someChildrenChecked()) {
      this.parentTarget.checked = true
      this.indeterminateParent(true)
    } else if (this.noChildrenChecked()) {
      this.checkParent(false)
    }
    this.dispatch('update-rootparent', { value: event })
  }

  toggleAllSelections(event) {
    this.toggleSelection(event)
    this.dispatch('set-all', { value: event.target.checked })
  }

  setSelection(event) {
    this.checkParent(event.detail.value)
    this.checkAllChildren()
  }

  updateRootparent(event) {
    if (this.hasRootparentTarget) {
      if (event.detail.value.target === this.rootparentTarget) return
      this.setRootparent()
    }
  }

  setRootparent() {
    const checked = this.element.querySelectorAll('input:checked')

    if (checked.length > 0) {
      this.indeterminateParent(true)
    } else {
      this.checkParent(false)
    }
  }

  allChildrenChecked() {
    if (this.childTargets.length == 0) return

    return this.checkedChildrenTargets.length == this.childTargets.length
  }

  someChildrenChecked() {
    return this.checkedChildrenTargets.length > 0 && !this.allChildrenChecked()
  }

  parentChecked() {
    return this.parentTarget.checked == true
  }

  noChildrenChecked() {
    return this.checkedChildrenTargets.length == 0
  }

  checkAllChildren() {
    this.childTargets.forEach((element, index) => {
      element.checked = this.parentChecked()
    })
  }

  checkParent(value) {
    this.parentTarget.checked = value
    this.indeterminateParent(false)
  }

  indeterminateParent(value) {
    this.parentTarget.indeterminate = value
  }

  get checkedChildrenTargets() {
    return this.childTargets.filter((child) => child.checked)
  }
}
