import ApplicationController from '../application_controller'
import { SmartFunnelsMixin } from './mixin'
import { track, track_public } from '../../utils/tracking'

export default class extends ApplicationController {
  static values = {
    userId: { type: String, default: '' },
    anonymousId: { type: String, default: '' },
  }

  connect() {
    Object.assign(this, SmartFunnelsMixin)
    window.addEventListener('step-completed', this.trackStepComplete.bind(this))
    this.visitedTabs = []
  }

  trackStepComplete(event) {
    let tab = this.getTabBySelection(event.detail - 1)
    if (!this.visitedTabs.includes(tab) && tab !== null) {
      this.visitedTabs.push(tab)
      if (this.userIdValue !== '') {
        if (this.anonymousIdValue !== '') {
          // Track authenticated Users that go through the public Smart Funnel Flow
          track(this.userIdValue, `public.smart_funnel.${tab}.completed`)
        } else {
          // Track authenticated Users that go through the internal Smart Funnel Flow
          track(this.userIdValue, `smart_funnel.${tab}.completed`)
        }
      } else {
        // Track unauthenticated Users that go through the public Smart Funnel Flow
        track_public(this.anonymousIdValue, `public.smart_funnel.${tab}.completed`)
      }
    }
  }
}
