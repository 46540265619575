import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['details', 'checkbox', 'fauxCheckbox']

  connect() {
    this.syncCheckboxesToFauxCheckboxes()

    this.handleChange = this.handleChange.bind(this)
    this.updateDescendents = this.updateDescendents.bind(this)
    this.updateAncestors = this.updateAncestors.bind(this)

    this.fauxCheckboxTargets.forEach((fauxCheckbox) => {
      fauxCheckbox.addEventListener('change', this.handleChange)
    })
  }

  disconnect() {
    this.fauxCheckboxTargets.forEach((fauxCheckbox) => {
      fauxCheckbox.removeEventListener('change', this.handleChange)
    })
  }

  /**
   * Synchronizes the state of the checkboxes with faux checkboxes.
   * @function
   * @name CheckboxTreeController#syncCheckboxesToFauxCheckboxes
   * @returns {void}
   */
  syncCheckboxesToFauxCheckboxes() {
    this.fauxCheckboxTargets.forEach((fauxCheckbox) => {
      let checkboxId = fauxCheckbox.dataset.targetCheckboxId
      let checkbox = this.checkboxTargets.find((checkbox) => checkbox.id === checkboxId)
      fauxCheckbox.checked = checkbox.checked
    })
  }

  /**
   * Handles the change event for a faux checkbox element in the tree.
   * Updates the descendants and ancestors of the checkbox based on its new checked state.
   * @param {Event} event - The change event triggered by the checkbox.
   */
  handleChange(event) {
    const fauxCheckbox = event.target
    const isChecked = fauxCheckbox.checked

    this.updateDescendents(fauxCheckbox, isChecked)
    this.updateAncestors(fauxCheckbox, isChecked)
  }

  /**
   * Expands all the details elements in the checkbox tree.
   * @param {Event} event - The event object.
   */
  expandAll(event) {
    event.preventDefault()
    this.detailsTargets.forEach((details) => {
      details.open = true
    })
  }

  /**
   * Collapses all the details targets in the checkbox tree.
   * @param {Event} event - The event object.
   */
  collapseAll(event) {
    event.preventDefault()
    this.detailsTargets.forEach((details) => {
      details.open = false
    })
  }

  /**
   * Updates all descendant checkboxes of a given checkbox element with the specified checked state.
   * @param {HTMLInputElement} fauxCheckbox - The checkbox element whose descendants will be updated.
   * @param {boolean} isChecked - The checked state to set for all descendant checkboxes.
   */
  updateDescendents(fauxCheckbox, isChecked) {
    const details = fauxCheckbox.closest("[data-checkbox-tree-target='details']")
    const childCheckboxes = details.querySelectorAll("input[type='checkbox']")

    childCheckboxes.forEach((cb) => {
      // This is operating on both checkboxes and faux checkboxes
      cb.checked = isChecked
    })
  }

  /**
   * Updates the ancestors of a faux checkbox based on its current state.
   *
   * @param {HTMLElement} fauxCheckbox - The faux checkbox element.
   * @param {boolean} isChecked - The current state of the faux checkbox.
   */
  updateAncestors(fauxCheckbox, isChecked) {
    const details = fauxCheckbox.closest("[data-checkbox-tree-target='details']")
    const parentDetails = details.parentElement.closest("[data-checkbox-tree-target='details']")
    const checkbox = details.querySelector("[data-checkbox-tree-target='checkbox']")
    // If the fauxCheckbox is checked or inderminate, the checkbox should be checked.
    if (fauxCheckbox.checked || fauxCheckbox.indeterminate) {
      // Set checkbox checked state to true if faux checkbox is either checked or indeterminate
      checkbox.checked = true
    } else {
      checkbox.checked = isChecked
    }

    if (parentDetails) {
      this.updateNonRootAncestors(fauxCheckbox, isChecked, parentDetails)
    } else {
      this.updateRootAncestor(fauxCheckbox, isChecked, checkbox)
    }
  }

  /**
   * Updates the ancestors of a faux checkbox that is not a root node in a checkbox tree.
   * @param {HTMLInputElement} fauxCheckbox - The faux checkbox element to update.
   * @param {boolean} isChecked - Whether the faux checkbox is checked or not.
   * @param {HTMLElement} parentDetails - The parent details element of the faux checkbox.
   */
  updateNonRootAncestors(fauxCheckbox, isChecked, parentDetails) {
    // Get the immediate ancestor faux checkbox
    const parentFauxCheckbox = parentDetails.querySelector("[data-checkbox-tree-target='fauxCheckbox']")
    const parentCheckbox = parentDetails.querySelector("[data-checkbox-tree-target='checkbox']")

    const childrenFauxCheckboxes = Array.from(
      parentFauxCheckbox.closest('details').querySelectorAll('[data-checkbox-tree-target="fauxCheckbox"]')
    ).filter((cb) => cb.id !== parentFauxCheckbox.id)

    const checkedChildrenFauxCheckboxes = childrenFauxCheckboxes.filter((cb) => cb.checked)
    const totalFauxChildren = childrenFauxCheckboxes.length
    const totalCheckedFauxChildren = checkedChildrenFauxCheckboxes.length

    // If all of the children are unchecked, the parent should be indeterminate.
    if (totalCheckedFauxChildren === 0) {
      parentCheckbox.checked = true
      parentFauxCheckbox.indeterminate = true

      // If all of the children are checked, the parent should be checked.
    } else if (totalCheckedFauxChildren === totalFauxChildren) {
      parentCheckbox.checked = true
      parentFauxCheckbox.checked = true
      parentFauxCheckbox.indeterminate = false

      // If some of the children are checked, the parent should be indeterminate.
    } else {
      parentCheckbox.checked = true
      parentFauxCheckbox.indeterminate = true
    }

    this.updateAncestors(parentFauxCheckbox, isChecked)
  }

  /**
   * Updates the root ancestor of a faux checkbox tree based on the state of its children.
   *
   * @param {HTMLInputElement} fauxCheckbox - The faux checkbox element to update.
   * @param {boolean} isChecked - Whether the faux checkbox is checked.
   * @param {HTMLInputElement} checkbox - The real checkbox element to update.
   */
  updateRootAncestor(fauxCheckbox, isChecked, checkbox) {
    // This is the root node, so there are no ancestors to update.
    const closestDetails = fauxCheckbox.closest('details')
    const childrenFauxCheckboxes = Array.from(
      closestDetails.querySelectorAll('[data-checkbox-tree-target="fauxCheckbox"]')
    ).filter((cb) => cb.id !== closestDetails.dataset.id)
    const checkedChildrenFauxCheckboxes = childrenFauxCheckboxes.filter((checkbox) => checkbox.checked)
    const totalFauxChildren = childrenFauxCheckboxes.length
    const totalCheckedFauxChildren = checkedChildrenFauxCheckboxes.length

    if (totalCheckedFauxChildren === 0) {
      fauxCheckbox.checked = false
      checkbox.checked = false

      // If all of the children are checked, the root node should be checked.
    } else if (totalCheckedFauxChildren === totalFauxChildren) {
      fauxCheckbox.checked = true
      checkbox.checked = true

      // If some of the children are checked, the parent should be indeterminate.
    } else {
      fauxCheckbox.indeterminate = true
      checkbox.checked = true
    }
  }
}
