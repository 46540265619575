import ApplicationController from './application_controller'
import { singleFileUpload, multipleFileUpload } from 'concerns/fileUpload'

export default class extends ApplicationController {
  static values = {
    helpText: String,
  }

  connect() {
    const dashboardOptions = {
      note: this.helpTextValue,
    }

    if (this.element.multiple) {
      multipleFileUpload(this.element, dashboardOptions)
    } else {
      singleFileUpload(this.element, dashboardOptions)
    }
  }
}
