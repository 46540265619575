import ApplicationController from './application_controller'
import { get } from '@rails/request.js'
import { debounce } from 'lodash'

export default class extends ApplicationController {
  static targets = ['pathField', 'helperText', 'spinner', 'domainText', 'domainWrapper']
  static classes = ['available', 'unavailable', 'hidden']
  static values = {
    domainId: String,
    forceInitialCheck: Boolean,
    globalId: String,
    addon: String,
  }

  connect() {
    this.initialDomainId = this.domainIdValue
    this.initialPath = this.currentPath
    if (this.forceInitialCheckValue) this.fetchAndSetAvailability()
  }

  buildCheckUrl() {
    const urlContext = this.pathFieldTarget.getAttribute('data-availability-url-context'),
      encodedPath = window.btoa(unescape(encodeURIComponent(urlContext + this.currentPath))),
      baseUrl = this.pathFieldTarget.getAttribute('data-availability-url')

    return `${baseUrl}?url=${encodedPath}&domain_id=${this.domainIdValue}&global_id=${this.globalIdValue}`
  }

  checkAvailability = debounce(this.debouncedAvailabilityCheck, 500)

  async debouncedAvailabilityCheck() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove(...this.hiddenClasses)
    }

    if (this.checkingNotNecessary) {
      this.resetHelperText()
      return
    }
    this.fetchAndSetAvailability()
  }

  async fetchAndSetAvailability() {
    const response = await this.fetchAvailability()

    this.setHelperText(response.status, response.message)
  }

  ensurePathStartsWithSlash(path) {
    if (path[0] !== '/') {
      return '/' + path
    } else {
      return path
    }
  }

  async fetchAvailability() {
    const url = this.buildCheckUrl(),
      response = await get(url, { responseKind: 'json' }),
      body = await response.json

    return body
  }

  resetHelperText() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add(...this.hiddenClasses)
    }
    this.helperTextTarget.innerText = ''
    this.helperTextTarget.classList.add(...this.hiddenClasses)
  }

  setHelperText(status, message) {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add(...this.hiddenClasses)
    }
    if (message !== '') {
      if (status === 'available') {
        this.helperTextTarget.classList.add(...this.availableClasses)
        this.helperTextTarget.classList.remove(...this.hiddenClasses, ...this.unavailableClasses)
      } else {
        this.helperTextTarget.classList.remove(...this.hiddenClasses, ...this.availableClasses)
        this.helperTextTarget.classList.add(...this.unavailableClasses)
      }
      this.helperTextTarget.innerText = message
    }
  }

  updateDomain(event) {
    this.domainIdValue = event.detail.domainId
    if (this.hasDomainTextTarget && event.detail?.domainName) {
      let url = event.detail.domainName
      if (this.hasAddonValue) {
        url = url + this.addonValue
      }
      let displayUrl = 'https://' + url
      this.domainTextTarget.innerText = displayUrl
      if (this.hasDomainWrapperTarget) {
        this.domainWrapperTarget.setAttribute('data-tooltip-content-value', displayUrl)
      }
    }
    this.checkAvailability()
  }

  get currentPath() {
    return this.ensurePathStartsWithSlash(this.pathFieldTarget.value)
  }

  get checkingNotNecessary() {
    return (
      this.domainIdValue === this.initialDomainId &&
      this.currentPath.toLowerCase() === this.initialPath.toLowerCase() &&
      !this.forceInitialCheckValue
    )
  }
}
