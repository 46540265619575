import BaseController from './base_controller'

export default class extends BaseController {
  connect() {
    super.connect()

    if (this.element.dataset.autoOpen != 'false') {
      this.element.updateComplete.then(() => {
        if (!this.element.open) {
          this.element.show()

          // Manually dispatch turbo:render event to ensure that MAI script is executed when the drawer is opened.
          window.dispatchEvent(new Event('turbo:render'))
        }
      })
    }
  }

  // Override the default shoelace dialog controller to prevent closing the dialog
  // when pressing the escape key, as well as when clicking outside the dialog.
  // @see https://shoelace.style/components/dialog?id=customizing-initial-focus
  preventOutsideClickClose(event) {
    if (['overlay', 'keyboard'].includes(event.detail.source)) this.cancelInitialFocus(event)
  }

  cancelInitialFocus(event) {
    event.preventDefault()
  }
}
