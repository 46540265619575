import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    storageKey: { type: String, default: 'navScrollPosition' },
  }

  saveScrollPosition() {
    this.scrollTop = this.element.scrollTop
    localStorage.setItem(this.storageKeyValue, this.scrollTop)
  }

  restoreScrollPosition() {
    if (localStorage[this.storageKeyValue]) {
      this.element.scrollTop = localStorage.getItem(this.storageKeyValue)
    }
  }
}
