import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['form', 'hiddenField']
  static values = {
    remoteUrl: { type: String, default: '' },
    remoteUrlMethod: { type: String, default: 'PATCH' },
    remoteUrlPayload: Object,
    responseKind: { type: String, default: 'html' },
    ifChecked: String,
    ifUnchecked: String,
  }

  // Submit containing <form> element when a change event is emitted from the <sl-switch> element
  submit() {
    this.formTarget.requestSubmit()
  }

  /**
   * Trigger a request to a remote URL.
   *
   * Depends on the following values:
   * - `remoteUrlValue`: The url to which to perform the request
   * - `remoteUrlMethod`: The HTTP method for the request. Defaut: PATCH
   * - `responseKind`: Specifies which response format will be accepted. Default: `html`.
   * - `remoteUrlPayload`: Any data to send along with the request body. The
   *                     'checked' state of the switch will be merged with this
   *                     data as {checked: <value>}.
   *
   * @param {Event} event - The event object triggered by the data-action attribute.
   * @returns {Promise<void>} A Promise that resolves when the remote submission is completed.
   */
  async remoteSubmit(event) {
    const checkedIntent = event.target.checked

    const request = new FetchRequest(this.remoteUrlMethodValue, this.remoteUrlValue, {
      responseKind: this.responseKindValue,
      body: {
        checked: checkedIntent,
        ...this.remoteUrlPayloadValue,
      },
    })

    this.dispatch('remote-submit-start')
    const response = await request.perform()

    if (response.ok) {
      this.dispatch('remote-submit-success')
      return
    } else {
      this.element.checked = !checkedIntent
      console.error(`The response failed with: ${response.statusCode}`)
      this.dispatch('remote-submit-error')
    }
  }

  /**
   * If the switch wrapps a hidden input field, this method can be
   * used to toggle the value of that field as the switch changes state.
   * @returns {any}
   */
  toggleHiddenField() {
    if (this.hasHiddenFieldTarget) {
      if (this.ifCheckedValue && this.ifUncheckedValue) {
        this.hiddenFieldTarget.value = this.element.checked ? this.ifCheckedValue : this.ifUncheckedValue
      } else {
        const prevValue = this.hiddenFieldTarget.value === 'true'

        this.hiddenFieldTarget.value = !prevValue
      }
    }
  }
}
