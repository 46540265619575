import ApplicationController from '../application_controller'
import * as CronofyElements from 'cronofy-elements'

// https://docs.cronofy.com/developers/ui-elements/date-time-picker/
export default class extends ApplicationController {
  static values = {
    availabilityQueryJson: Object,
    elementToken: String,
    targetId: String,
    tzid: String,
    buttonActiveColor: String,
    buttonHoverColor: String,
    buttonConfirmColor: String,
    selectedHostId: String,
  }

  connect() {
    this.instance = CronofyElements.DateTimePicker({
      element_token: this.elementTokenValue,
      target_id: this.targetIdValue || this.element.id,
      availability_query: this.availabilityQueryJsonValue,
      styles: {
        prefix: 'cf2',
        colors: {
          buttonActive: this.buttonActiveColorValue,
          buttonHover: this.buttonHoverColorValue,
          buttonConfirm: this.buttonConfirmColorValue,
        },
      },
      tzid: this.tzidValue,
      callback: (res) => {
        if (res.notification.type !== 'slot_selected') return

        const urlParams = new URLSearchParams(window.location.search)
        const scheduledEventId = urlParams.get('scheduled_event_id')

        const slot = JSON.stringify(res.notification.slot)
        let location = `?slot=${slot}&tzid=${res.notification.tzid}`

        if (scheduledEventId !== null) {
          location += `&scheduled_event_id=${scheduledEventId}`
        }

        if (this.hasSelectedHostIdValue) {
          location += `&host_id=${this.selectedHostIdValue}`
        }

        Turbo.visit(location)
      },
    })
  }
}
