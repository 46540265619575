/* eslint-disable no-undef */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'payForm',
    'errorWrapper',
    'errorMessageHolder',
    'submitPaymentButton',
    'rebillyToken',
    'cardMountElement',
    'bankTypeMountElement',
    'bankNumberMountElement',
    'bankRoutingMountElement',
    'outOfBandElement',
    'cardOnFileForm',
    'cardOnFileElement',
    'selectedMethod',
    'paymentOption',
    'cardForm',
    'achForm',
    'outOfBandForm',
    'cash',
    'check',
  ]
  static values = {
    organizationId: String,
    publishableKey: String,
  }

  initialize() {
    if (!Rebilly.initialized) {
      var config = {
        publishableKey: this.publishableKeyValue,
        organizationId: this.organizationIdValue,
        kountAccountId: '700000', // This is for capturing kount fraud sessions
      }

      Rebilly.initialize(config)
    }

    const form = this.payFormTarget
    const errorWrapper = this.errorWrapperTarget
    const errorMessageHolder = this.errorMessageHolderTarget
    const submitPaymentButton = this.submitPaymentButtonTarget
    const rebillyToken = this.rebillyTokenTarget
    const cardMountElement = this.cardMountElementTarget
    const bankTypeMountElement = this.bankTypeMountElementTarget
    const bankNumberMountElement = this.bankNumberMountElementTarget
    const bankRoutingMountElement = this.bankRoutingMountElementTarget
    const paymentSubmitted = { value: false }
    const selectedMethod = this.selectedMethodTarget
    const paymentOptions = this.paymentOptionTargets
    const markPaidOptions = this.markPaidOptionTargets
    const mountedFrames = { achElements: [], cardElements: [], outOfBandElements: [] }
    const cardOnFileForm = this.cardOnFileFormTarget
    const cardForm = this.cardFormTarget
    const achForm = this.achFormTarget
    const outOfBandForm = this.outOfBandFormTarget
    const outOfBandElement = this.outOfBandElementTarget
    const cash = this.cashTarget
    const check = this.checkTarget

    function hideOptions() {
      cardOnFileForm.classList.add('hidden')
      cardForm.classList.add('hidden')
      achForm.classList.add('hidden')
      outOfBandForm.classList.add('hidden')
      cash.classList.add('hidden')
      check.classList.add('hidden')
      errorWrapper.classList.remove('is-active')
    }

    function toggleForm() {
      hideOptions()
      submitPaymentButton.setAttribute('disabled', '')

      if (selectedMethod.value == 'card-on-file') {
        submitPaymentButton.removeAttribute('disabled')
        cardOnFileForm.classList.remove('hidden')
      }
      if (selectedMethod.value == 'payment-card') {
        cardForm.classList.remove('hidden')
      }
      if (selectedMethod.value == 'ach') {
        achForm.classList.remove('hidden')
      }
      if (selectedMethod.value == 'out-of-band') {
        submitPaymentButton.removeAttribute('disabled')
        outOfBandForm.classList.remove('hidden')
        if (outOfBandElement.value == 'cash') {
          cash.classList.remove('hidden')
          check.classList.add('hidden')
        }
        if (outOfBandElement.value == 'check') {
          check.classList.remove('hidden')
          cash.classList.add('hidden')
        }
        if (outOfBandElement.value == 'miscellaneous') {
          cash.classList.add('hidden')
          check.classList.add('hidden')
        }
      }
    }

    hideOptions()
    paymentOptions.forEach((option) => {
      errorWrapper.classList.remove('is-active')
      option.addEventListener('click', function () {
        selectedMethod.value = $(this).val()
        toggleForm()
      })
    })

    outOfBandElement.addEventListener('change', function () {
      outOfBandElement.value = $(this).val()
      toggleForm()
    })

    function validateBankAccount(event) {
      if (event.source == 'bankAccountNumber') {
        bankNumberMountElement.valid = event && event.valid && event.completed
      }
      if (event.source == 'bankRoutingNumber') {
        bankRoutingMountElement.valid = event && event.valid && event.completed
      }
      return bankRoutingMountElement.valid && bankNumberMountElement.valid
    }

    function handleError(event) {
      if (event.error) {
        errorWrapper.classList.add('is-active')
        errorMessageHolder.innerText = event.error.details.pop()
      } else if (event.valid) {
        errorWrapper.classList.remove('is-active')
      }
      if (event.completed && !paymentSubmitted.value) {
        if (selectedMethod.value == 'ach' && !validateBankAccount(event)) return
        submitPaymentButton.removeAttribute('disabled')
      } else {
        submitPaymentButton.setAttribute('disabled', '')
      }
    }

    function unmountUnusedOption() {
      if (selectedMethod.value == 'ach') {
        mountedFrames.cardElements.forEach((item) => item.unmount())
        mountedFrames.outOfBandElements.forEach((item) => item.unmount())
      } else if (selectedMethod.value == 'payment-card') {
        mountedFrames.achElements.forEach((item) => item.unmount())
        mountedFrames.outOfBandElements.forEach((item) => item.unmount())
      } else if (selectedMethod.value == 'out-of-band') {
        mountedFrames.cardElements.forEach((item) => item.unmount())
        mountedFrames.achElements.forEach((item) => item.unmount())
      }
    }

    Rebilly.on('ready', () => {
      this.card = Rebilly.card.mount(cardMountElement)
      this.type = Rebilly.bankAccount.mount(bankTypeMountElement, 'bankAccountType')
      this.number = Rebilly.bankAccount.mount(bankNumberMountElement, 'bankAccountNumber')
      this.routing = Rebilly.bankAccount.mount(bankRoutingMountElement, 'bankRoutingNumber')

      mountedFrames.cardElements = [this.card]
      mountedFrames.achElements = [this.type, this.number, this.routing]

      this.card.on('change', handleError)
      this.number.on('change', handleError)
      this.routing.on('change', handleError)
    })

    Rebilly.on('error', (err) => {
      window.parent.postMessage('error', '*')
    })

    form.onsubmit = function (e) {
      paymentSubmitted.value = true
      submitPaymentButton.setAttribute('disabled', '')
      e.preventDefault()
      e.stopPropagation()
      unmountUnusedOption()

      if (selectedMethod.value != 'card-on-file' && selectedMethod.value != 'out-of-band') {
        const extraData = {
          method: selectedMethod.value,
        }

        Rebilly.createToken(form, extraData)
          .then((result) => {
            console.log('Framepay success', result)
            window.parent.postMessage('success', '*')

            rebillyToken.value = result.id
            form.submit()
          })
          .catch((error) => {
            paymentSubmitted.value = false
            console.error('Framepay error', error)
            window.parent.postMessage('error', '*')
          })
      } else {
        this.submit()
      }
    }
  }

  disconnect() {
    if (this.card) {
      this.card.unmount()
    }
    if (this.type) {
      this.type.unmount()
      this.number.unmount()
      this.routing.unmount()
    }
  }
}
/* eslint-enable no-undef */
