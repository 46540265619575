import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  connect() {
    $('.select-trigger').on('select2:select', function () {
      this.form.requestSubmit()
    })

    $('.select-trigger-on-close').on('select2:close', function () {
      this.form.requestSubmit()
    })
  }
}
