import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['timeFields', 'typeSpecificFields', 'dayOfWeek', 'dayOfMonth']

  toggleTimeFields(event) {
    const typeSpecificFields = this.typeSpecificFieldsTargets
    const type = event.detail.activeButton.id.replace('calendar_event_event_type_', '')

    switch (type) {
      case 'one_time':
        this.timeFieldsTarget.classList.remove('hidden')
        typeSpecificFields
          .filter((field) => field.classList.contains('one_time'))
          .forEach((field) => field.classList.remove('hidden'))

        typeSpecificFields
          .filter((field) => !field.classList.contains('one_time'))
          .forEach((field) => field.classList.add('hidden'))
        break

      case 'recurring':
        this.timeFieldsTarget.classList.add('hidden')
        typeSpecificFields
          .filter((field) => field.classList.contains('recurring'))
          .forEach((field) => field.classList.remove('hidden'))

        typeSpecificFields
          .filter((field) => !field.classList.contains('recurring'))
          .forEach((field) => field.classList.add('hidden'))
        break

      case 'hybrid':
        this.timeFieldsTarget.classList.remove('hidden')
        typeSpecificFields
          .filter((field) => field.classList.contains('hybrid'))
          .forEach((field) => field.classList.remove('hidden'))

        typeSpecificFields
          .filter((field) => !field.classList.contains('hybrid'))
          .forEach((field) => field.classList.add('hidden'))
        break
    }
  }

  selectHybridType(event) {
    switch (event.target.value) {
      case 'day_of_month':
        this.dayOfMonthTarget.classList.remove('hidden')
        this.dayOfWeekTarget.classList.add('hidden')
        break

      case 'day_of_week':
        this.dayOfMonthTarget.classList.add('hidden')
        this.dayOfWeekTarget.classList.remove('hidden')
        break
    }
  }
}
