import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['triggerModal']
  static values = {
    eventSourceElementId: String,
  }

  toggleModal(event) {
    if (event.detail.sourceEl.id == this.eventSourceElementIdValue) {
      if (this.hasTriggerModalTarget && !this.modalShown) {
        this.triggerModalTarget.click()
        this.modalShown = true
      }
    }
  }
}
