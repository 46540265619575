import ApplicationController from '../application_controller'
import { uploadedFileData, configureUploadServer, getUppyLocale } from '../../utils/uppy'
import Uppy from '@uppy/core'
import DropTarget from '@uppy/drop-target'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import { post } from '@rails/request.js'
import Cookies from 'js-cookie'

// Uploads images to a given uploadUrl and uploadServer and
// emits events about the status of those uploads
export default class extends ApplicationController {
  static values = {
    accept: String, // Acceptable mime types. See ImageUploader::ALLOWED_TYPES
    acceptedFileTypesMsg: String, // Message to display when the user tries to upload an invalid file type
    maxFileSize: Number, // Maximum file size in bytes. See ImageUploader::MAX_FILE_SIZE
    mode: { type: String, default: 'single' }, // 'single' or 'multiple'
    uploadServer: String, // Rails.configuration.upload_server
    uploadUrl: String, // Path to the upload endpoint
  }

  connect() {
    this.configureUppy()
  }

  /**
   * Configure Uppy
   *
   * @see https://uppy.io/docs/uppy/
   */
  configureUppy() {
    const locale = Cookies.get('locale') || 'en'
    const uppyLocale = getUppyLocale(locale)
    uppyLocale['strings']['youCanOnlyUploadFileTypes'] = this.acceptedFileTypesMsgValue
    this.uppy = new Uppy({
      autoProceed: true,
      debug: true,
      restrictions: {
        allowedFileTypes: this.acceptValue.split(','),
        minNumberOfFiles: 1,
        maxNumberOfFiles: this.modeValue === 'single' ? 1 : null,
        maxFileSize: this.maxFileSizeValue,
      },
      locale: uppyLocale,
    })

    // Configure the Drop target plugin. See https://uppy.io/docs/drop-target
    this.uppy.use(DropTarget, { target: this.element })

    // Configure the Thumbnail Generator plugin. See https://uppy.io/docs/thumbnail-generator
    this.uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 600,
      thumbnailType: 'image/png', // to support transparency
      waitForThumbnailsBeforeUpload: true,
    })

    // Configure where to upload the files
    configureUploadServer(this.uploadServerValue, this.uppy)

    // Display the error message when the "info" message is intended to be shown
    this.uppy.on('info-visible', () => {
      const { info } = this.uppy.getState()
      this.dispatch('upload-error', { detail: { message: `${info.message} ${info.details}` } })
    })

    // Display the thumbnail when it is generated
    this.uppy.on('thumbnail:generated', (file, preview) => {
      this.dispatch('thumbnail-generated', { detail: { images: [{ url: preview, tempId: file.meta.name }] } })
    })

    // Capture and upload the file once it's been uploaded via JavaScript (in memory)
    // Note: This is triggered for each file uploaded
    this.uppy.on('upload-success', (uppyFile, response) => this._onUploadSuccess(uppyFile, response))

    // Reset Uppy once all uploads are complete
    this.uppy.on('complete', () => this.uppy.reset())
  }

  /**
   * Callback to convert the file data to the format expected by the server
   *
   * @param {uppyFile} uppyFile               The file that was uploaded
   *                                          See https://uppy.io/docs/uppy/#working-with-uppy-files
   * @param {string, XMLHttpRequest} response An object containing the upload response metadata
   *                                          See https://uppy.io/docs/xhr-upload/#getresponsedata
   */
  _onUploadSuccess(uppyFile, response) {
    const fileData = uploadedFileData(this.uploadServerValue, uppyFile, response)
    this._createImageOnServer(fileData)
  }

  /**
   * Upload the file data to the server. Once uploaded, the UI and form will be updated accordingly
   *
   * @param {string} fileData The JSON stringified file data
   * @returns {Promise<void>}
   */
  async _createImageOnServer(fileData) {
    const tempId = JSON.parse(fileData).metadata.filename
    this.dispatch('upload-start', { detail: { tempId: tempId } })

    const response = await post(this.uploadUrlValue, {
      body: JSON.stringify({
        image: {
          file: fileData,
        },
      }),
      responseKind: 'json',
    })

    if (response.ok) {
      const body = await response.json

      this.dispatch('upload-end', { detail: { id: body.id, tempId } })
    } else {
      const body = await response.json
      this.dispatch('upload-error', { detail: { message: body.file[0] } })
      this.reset()
    }
  }

  // This function handles selecting files from a user's file browser.
  // Markup example:
  // <label for="file-upload" class="button-outline mt-2">
  //  <%= options[:primary_action_text] %>
  // </label>
  // <input data-action="change->image-gallery--field#previewFile" id="file-upload" type="file" class="hidden">
  previewFile(event) {
    const selectedFile = event.target.files[0]

    if (selectedFile != null) {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: selectedFile.name,
          type: selectedFile.type,
          data: selectedFile,
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    }
  }
}
