import ApplicationController from './application_controller'
import { getUrlParameter } from '../utils/url'
import cryptoRandomString from 'crypto-random-string'

export default class extends ApplicationController {
  static targets = ['menu', 'trigger', 'loader']
  static values = {
    sortableAttributes: { type: Object, default: {} },
    searchFilterController: { type: String, default: 'search-filter' },
    locale: { type: String, default: 'en' },
    t: { type: Object, default: {} },
  }

  connect() {
    this.buildMenu()
  }

  setCurrentSort(event) {
    this.currentSort = event.target.name
    this.setDropdownText(event.target.nextSibling)
  }

  setDropdownText(val) {
    this.triggerTarget.innerHTML = val.textContent
  }

  showLoadingIndicator() {
    this.loaderTarget.classList.add('animated')
  }

  hideLoadingIndicator() {
    this.loaderTarget.classList.remove('animated')
  }

  buildMenu() {
    const current_sort = getUrlParameter('sort_by') || this.currentSort
    const sortAction = `input->index-sort#setCurrentSort input->${this.searchFilterControllerValue}#updateSort`
    const t = this.tValue
    let asc_name = 'ASC'
    let desc_name = 'DESC'
    const menuTarget = this.menuTarget
    const triggerTarget = this.triggerTarget
    // Add options for column toggle
    Object.entries(this.sortableAttributesValue).forEach(function ([attr_name, values]) {
      const { label, type, asc_suffix, desc_suffix } = values

      if (typeof label !== 'string') {
        console.warn(`Received ${typeof label} for ${attr_name}. Label must be a string.`)
        return
      }
      const id = `${label.replace(/ /g, '_')}_${cryptoRandomString({ length: 6, type: 'alphanumeric' })}`
      switch (type) {
        case 'datetime':
          asc_name = asc_suffix || ` (${t.oldestFirst})`
          desc_name = desc_suffix || ` (${t.newestFirst})`
          break
        case 'date':
          asc_name = asc_suffix || ` (${t.oldestFirst})`
          desc_name = desc_suffix || ` (${t.newestFirst})`
          break
        case 'integer':
          asc_name = asc_suffix || ` (${t.lowToHigh})`
          desc_name = desc_suffix || ` (${t.highToLow})`
          break
        case 'float':
          asc_name = asc_suffix || ` (${t.lowToHigh})`
          desc_name = desc_suffix || ` (${t.highToLow})`
          break
        case 'decimal':
          asc_name = asc_suffix || ` (${t.lowToHigh})`
          desc_name = desc_suffix || ` (${t.highToLow})`
          break
        case 'bigint':
          asc_name = asc_suffix || ` (${t.lowToHigh})`
          desc_name = desc_suffix || ` (${t.highToLow})`
          break
        case 'boolean':
          asc_name = asc_suffix || ` (${t.falseFirst})`
          desc_name = desc_suffix || ` (${t.trueFirst})`
          break
        case 'text':
          asc_name = asc_suffix || ` (${t.aToZ})`
          desc_name = desc_suffix || ` (${t.zToA})`
          break
        case 'textarea':
          asc_name = asc_suffix || ` (${t.aToZ})`
          desc_name = desc_suffix || ` (${t.zToA})`
          break
        case 'string':
          asc_name = asc_suffix || ` (${t.aToZ})`
          desc_name = desc_suffix || ` (${t.zToA})`
          break
        default:
          asc_name = ''
          desc_name = ''
      }
      $.each(
        [
          [asc_name, 'ASC'],
          [desc_name, 'DESC'],
        ],
        (index, value) => {
          const radio_value = attr_name + ',' + value[1]
          if (asc_name.length >= 1) {
            const classes = 'flex py-2 px-1 truncate items-center text-left rounded cursor-pointer hover:bg-gray-100'
            $(menuTarget).append(
              $(`<label class="${classes} block text-sm cursor-pointer font-semibold">`)
                .prop({
                  for: id + '_' + (index + 1),
                })
                .html(label + ' ' + value[0])
                .prepend(
                  $('<input class="h-4 w-4 mr-2">')
                    .prop({
                      type: 'radio',
                      id: id + '_' + (index + 1),
                      name: 'sort_by',
                      value: radio_value,
                      checked: radio_value.trim() == current_sort ? true : false,
                    })
                    .attr('data-action', sortAction)
                )
            )
          }
          if (radio_value.trim() == current_sort) {
            triggerTarget.innerHTML = label + ' ' + value[0]
          }
        }
      )
    })
  }
}
