import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    eventName: {
      type: String,
      default: 'updated'
    },
    ignoredValues: {
      type: Array,
      default: []
    }
  }
  
  notifyForm(event) {
    const form = this.element.form
    if (form === undefined) { return }
    
    if (this.valueAmongIgnoredValues) { return }
    
    form.dispatchEvent(new CustomEvent(`${this.identifier}:${this.eventNameValue}`, {
      bubbles: true,
      detail: {
        originalEvent: event
      }
    }))
  }
  
  get valueAmongIgnoredValues() {
    if (!this.hasIgnoredValuesValue) { return false }
    
    const value = this.element.value
    if (value === undefined) { return false }
    
    return this.ignoredValuesValue.includes(value)
  }
}
