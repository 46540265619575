import ApplicationController from './application_controller'
import { trackWithHeap } from '../utils/heap_tracking'

export default class extends ApplicationController {
  static values = {
    language: String,
    searchTerm: String,
    articleId: String,
    title: String,
    apiKey: String,
    apiToken: String,
  }

  openArticleById(articleId) {
    let id = isNaN(articleId) ? this.articleIdValue : articleId
    window._elev.openArticle(id)

    trackWithHeap('SearchArticleOpened', {
      article_id: id,
      search_term: this.searchTermValue,
      title: this.titleValue,
    })
  }

  async keywordSearch() {
    const url = window.location.hostname
    const requestPath =
      'https://api.elev.io/v1/search/' +
      this.languageValue +
      '?query=' +
      this.searchTermValue +
      '&url=' +
      url +
      '&page=1&rows=1'
    await fetch(requestPath, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'x-api-key': this.elevioApiKeyValue,
        Authorization: this.elevioApiTokenValue,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          alert('Sorry! We cant find this article')
          throw new Error(response.statusText)
        }
      })
      .then((data) => this.openArticleById(data.results[0].id))
  }
}
