import {
  SuperchartChartjsController,
  parseContentsAsJSON,
  parseContentsAsCsv,
  Chartjs,
} from '@supercharts/stimulus-base'
import { verticalLinePlugin } from '../../concerns/charts.js'

Chartjs.register(verticalLinePlugin)
export default class extends SuperchartChartjsController {
  static targets = ['chartjsOptions', 'chartjsData', 'chartjsCanvas', 'csvData']
  static values = {
    property: {
      type: String,
      default: 'value',
    },
    formattedProperty: {
      type: String,
      default: 'value_formatted',
    },
    showComparisonLine: {
      type: Boolean,
      default: false,
    },
    comparisonProperty: {
      type: String,
      default: 'comparison_value',
    },
    comparisonFormattedProperty: {
      type: String,
      default: 'comparison_value_formatted',
    },
    comparisonDateFullProperty: {
      type: String,
      default: 'comparison_date_full',
    },
    percentChangeProperty: {
      type: String,
      default: 'percent_change',
    },
  }

  static defaultCssProperties = {
    '--show-labels': 0,
    '--show-points': 0,
    '--animation-duration': 200, // milliseconds
    '--axis-color': '#999',
    '--grid-color': '#eee',
    '--line-color': '#2652E4',
    '--comparison-line-color': '#D7E0E5',
    '--point-color': '#0937ff',
    '--comparison-point-color': '#D7E0E5',
    '--point-stroke-color': '#fbfcff',
    '--point-stroke-color-hover': '#aecdff',
    '--bar-fill-color': '#999',
    '--bar-hover-fill-color': '#333',
    '--point-radius': 3,
    '--point-hover-radius': 6,
    '--point-border-width': 2,
    '--point-hover-border-width': 4,
    '--line-thickness': 2,
    '--vertical-line-color': 'black',
    '--padding-top': 2,
  }

  connect() {
    super.connect()
  }

  updateChart() {
    super.updateChart()
  }

  describeDataForX(event) {
    const point = event?.tooltip?.dataPoints[0]
    const dataIndex = point.dataIndex

    let detail = {
      label: this.csvData[dataIndex][this.csvData.columns[1]],
      value: this.csvData[dataIndex][this.formattedPropertyValue],
      x: event?.tooltip?.caretX,
      show: !!event?.tooltip?.opacity,
    }

    if (this.showComparisonLine) {
      detail = {
        ...detail,
        comparisonLabel: this.csvData[dataIndex][this.comparisonDateFullPropertyValue],
        comparisonValue: this.csvData[dataIndex][this.comparisonFormattedPropertyValue],
        percentChange: this.csvData[dataIndex][this.percentChangePropertyValue],
      }
    }

    this.dispatch('description-requested', { detail: detail })
  }

  ensureCsvDataParsed() {
    if (this.csvData !== undefined) {
      return
    }
    this.parseCsvData()
  }

  parseCsvData() {
    this.csvData = parseContentsAsCsv(this.csvDataTarget)
  }

  get chartjsData() {
    if (this.hasChartJsDataTarget) {
      return super.chartjsData()
    }
    if (!this.hasCsvDataTarget) {
      console.warn('The chart needs data in a in a csv target or in a chartjsData target (in chart.js JSON)')
      return []
    }

    this.ensureCsvDataParsed()

    const ctx = this.chartjsCanvasTarget.getContext('2d')

    let gradientFill = ctx.createLinearGradient(0, ctx.canvas.height, 0, 0)
    gradientFill.addColorStop(0, 'rgba(163, 165, 243, 0)')
    gradientFill.addColorStop(1, 'rgba(163, 165, 243, 0.1)')

    let datasets = [
      {
        type: 'line',
        label: 'Values',
        data: this.csvData.map((d) => parseNumber(d[this.propertyValue])),
        backgroundColor: gradientFill,
      },
    ]

    if (this.showComparisonLine) {
      datasets.push({
        type: 'line',
        label: 'Comparison Values',
        data: this.csvData.map((d) => parseNumber(d[this.comparisonPropertyValue])),
        borderColor: this.cssPropertyValue('--comparison-line-color'),
        pointBackgroundColor: this.cssPropertyValue('--comparison-point-color'),
        pointHoverBackgroundColor: this.cssPropertyValue('--comparison-point-color'),
        fill: false,
      })
    }

    return {
      labels: this.csvData.map((d) => d[this.csvData.columns[0]]),
      datasets: datasets,
    }
  }

  get chartjsOptions() {
    let options = {
      ...this.defaultOptions,
    }

    if (this.hasChartjsOptionsTarget) {
      options = {
        ...options,
        ...parseContentsAsJSON(this.chartjsOptionsTarget),
      }
    }

    return this.parseForCssVars(options)
  }

  get animationOptions() {
    if (this.runAnimations === false) {
      return false
    }
    return {
      x: {
        type: 'number',
        easing: 'linear',
        duration: this.delayBetweenPoints,
        from: NaN, // the point is initially skipped
        delay: (ctx) => {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0
          }
          ctx.xStarted = true
          return ctx.index * this.delayBetweenPoints
        },
      },
      y: {
        type: 'number',
        easing: 'linear',
        duration: this.delayBetweenPoints,
        from: previousY,
        delay: (ctx) => {
          if (ctx.type !== 'data' || ctx.yStarted) {
            return 0
          }
          ctx.yStarted = true
          return ctx.index * this.delayBetweenPoints
        },
      },
    }
  }

  get showComparisonLine() {
    this.ensureCsvDataParsed()
    return (
      this.showComparisonLineValue &&
      this.hasComparisonPropertyValue &&
      this.csvData.columns.includes(this.comparisonPropertyValue) &&
      this.hasComparisonFormattedPropertyValue &&
      this.csvData.columns.includes(this.comparisonFormattedPropertyValue)
    )
  }

  // You can set default options in this getter for all your charts.
  get defaultOptions() {
    const axisColor = this.cssPropertyValue('--axis-color')

    return {
      maintainAspectRatio: false,
      animation: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      resizeDelay: 200, // milliseconds
      onResize: this.handleResize.bind(this),
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: this.describeDataForX.bind(this),
        },
        verticalLiner: {
          lineColor: this.cssPropertyValue('--vertical-line-color'),
        },
      },
      layout: {
        padding: {
          top: parseInt(this.cssPropertyValue('--padding-top')),
          left: 1,
          right: 1,
          bottom: -4,
        },
      },
      color: axisColor,
      fill: true,
      lineTension: 0,
      borderColor: this.cssPropertyValue('--line-color'),
      borderWidth: parseInt(this.cssPropertyValue('--line-thickness')),
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0,
      borderJoinStyle: 'miter',
      pointBorderColor: this.cssPropertyValue('--point-stroke-color'),
      pointBackgroundColor: this.cssPropertyValue('--point-color'),
      pointHoverBackgroundColor: this.cssPropertyValue('--point-color'),
      pointHoverBorderColor: this.cssPropertyValue('--point-stroke-color-hover'),
      pointRadius: this.cssPropertyTruthy('--show-points') ? Number(this.cssPropertyValue('--point-radius')) : 0,
      pointHoverRadius: this.cssPropertyTruthy('--show-points')
        ? Number(this.cssPropertyValue('--point-hover-radius'))
        : 0,
      pointBorderWidth: Number(this.cssPropertyValue('--point-border-width')),
      pointHoverBorderWidth: Number(this.cssPropertyValue('--point-hover-border-width')),
      pointHitRadius: 30,
      backgroundColor: this.cssPropertyValue('--bar-fill-color'),
      hoverBackgroundColor: this.cssPropertyValue('--bar-hover-fill-color'),
      spanGaps: false,
      scales: {
        x: {
          grid: {
            borderDash: [1, 5],
            color: 'rgba(0,0,0,0.35)',
            zeroLineColor: 'rgba(0,0,0,0.15)',
          },
          ticks: {
            maxRotation: 0,
            fontSize: '8',
            fontColor: '#000',
            display: this.cssPropertyTruthy('--show-labels'),
            callback: function (value, index, ticks) {
              const tickValues = {
                gridLineWithLabel: this.getLabelForValue(value),
                gridLineNoLabel: ' ',
              }

              // default
              let format = 'gridLineWithLabel'

              // if first or last grid line
              if (index === 0 || index === ticks.length - 1) {
                format = 'gridLineNoLabel'
              }

              // more tweaks are discouraged because chartjs does its own determination of the ticks
              // and labels to show based on the available space

              return tickValues[format]
            },
          },
        },
        y: {
          display: false,
          beginAtZero: true,
          grid: {
            color: this.cssPropertyValue('--grid-color'),
            borderColor: axisColor,
            tickColor: axisColor,
          },
          ticks: {
            color: axisColor,
            tickColor: axisColor,
          },
          suggestedMin: 0,
          suggestedMax: 3,
        },
      },
    }
  }

  get delayBetweenPoints() {
    return this.cssPropertyValue('--animation-duration') / this.chartjsData?.datasets[0]?.data?.length
  }

  cssPropertyTruthy(propertyName) {
    const value = this.cssPropertyValue(propertyName)
    if (value === 'true') {
      return true
    }
    return Number(value)
  }
}

function previousY(ctx) {
  return ctx.index === 0
    ? ctx.chart.scales.y.getPixelForValue(100)
    : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y
}

function parseNumber(string) {
  if (string === '') {
    return null
  } else {
    const parsedValue = Number(string)
    return isNaN(parsedValue) ? string : parsedValue
  }
}
