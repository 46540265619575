import ApplicationController from './application_controller'

// This controller dispatches the custom event `action-cable-context:refresh` on the `action-cable-context` meta tag.
// The event triggers a TurboBoost Command which refreshes the content of the meta tag.
//
// This is necessary because the socket connects to a different domain than the host web-page whenever a user
// previews their contact facing pages that are hosted on a custom domain.
//
// This ensures that the socket reconnects with the appropriate HTTP context in the event of a disconnect.
//
// NOTE: The HTTP context uses a one-time-use UniversalID (UID) wrapped in a SignedGlobalID (SGID)
//       and is refreshed periodically to ensure reconnects also preserve the HTTP context.
//       This mechanism works for all socket connections irrespective of the use case noted above.
//
// For more context SEE:
// - app/channels/application_cable/connection.rb
// - app/helpers/action_cable/context_helper.rb
// - projects/cf_utils/ApplicationCable/index.js
// - app/javascript/controllers/action_cable_context_controller.js
// - app/javascript/turbo_boost/commands.js
//
export default class extends ApplicationController {
  connect() {
    // NOTE: This wait time corresponds to ApplicationCable::CONTEXT_SGID_TTL
    const wait = Math.ceil(3.5 * 60 * 60 * 1000) // 3.5 hours
    this.interval = setInterval(this.dispatchEvent.bind(this), wait)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  // NOTE: Dispatches the `action-cable-context:refresh` event from `this.element`
  //       SEE: https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
  dispatchEvent() {
    this.dispatch('refresh')
  }
}
