import ApplicationController from './application_controller'

export default class extends ApplicationController {
  constructor() {
    super()
    this.toggleCommandBar = this.toggleCommandBar.bind(this)
  }

  toggleCommandBar() {
    if (window.CommandBar !== undefined) {
      window.CommandBar.toggleHelpHub()
    }
  }
}
