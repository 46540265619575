import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['button']
  static values = { path: String }

  change(event) {
    const url = new URL(this.pathValue)
    url.searchParams.set('invoice_id', event.currentTarget.value)
    this.buttonTarget.href = url.toString()
  }

  submit(event) {
    event.currentTarget.closest('sl-dialog').hide()
  }
}
