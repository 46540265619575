import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static outlets = ['shoelace--dialog']
  static values = { fieldId: String, url: String, id: String, label: String }

  handleInstallComplete({ detail: { id, name: label, thumbnail: url } }) {
    this.idValue = id
    this.labelValue = label
    this.urlValue = url

    this.handleSelected()

    if (this.hasShoelaceDialogOutlet) {
      this.shoelaceDialogOutlet.hide()
    }
  }

  handleSelected() {
    this.dispatch('selected', {
      detail: {
        fieldId: this.fieldIdValue,
        items: [{ id: this.idValue, url: this.urlValue, data: { label: this.labelValue } }],
      },
    })
  }
}
