import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['toggle', 'nameSelectWrapper', 'nameSelect', 'nameInput', 'collapsedOptionName']

  connect() {
    this.selectedIndex = this.nameSelectTarget.selectedIndex
  }

  newValueIsSelected() {
    return this.nameSelectTarget.options[this.nameSelectTarget.selectedIndex].value === '_new'
  }

  selectOption(e) {
    if (this.newValueIsSelected()) {
      this.toggleTargets.forEach((el) => el.classList.toggle('hidden'))
      this.nameInputTarget.focus()
      this.nameInputTarget.value = ''
    } else {
      this.selectedIndex = this.nameSelectTarget.selectedIndex
    }
  }

  toggle(e) {
    e.preventDefault()
    this.toggleTargets.forEach((el) => el.classList.toggle('hidden'))
    if (this.newValueIsSelected()) {
      this.nameSelectTarget.selectedIndex = 0
    } else {
      this.nameSelectTarget.selectedIndex = this.selectedIndex
    }

    this.nameSelectTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
    this.nameInputTarget.value = ''
  }

  updateNameField(e) {
    if (this.newValueIsSelected()) {
      this.nameInputTarget.value = ''
    } else {
      this.nameInputTarget.value = e.target.value
    }
  }

  updateCollapsedFormOption() {
    this.collapsedOptionNameTarget.innerHTML = this.nameInputTarget.value + ':'
  }
}
