import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['sourceElement', 'hiddenElement']
  static classes = ['visibility']
  static values = {
    displayWhenFieldEquals: String,
  }

  toggleHiddenElementVisibility(event) {
    const sourceField = event.target
    let value = null

    if (sourceField != this.sourceElementTarget && !this.sourceElementTarget.contains(sourceField)) return

    if (sourceField.type === 'checkbox') {
      value = sourceField.checked.toString()
    } else {
      value = sourceField.value.trim()
    }

    const validations = this.displayWhenFieldEqualsValue.split(',')

    if (validations.includes(value)) {
      this.hiddenElementTarget.classList.remove(...this.visibilityClasses)
    } else {
      this.hiddenElementTarget.classList.add(...this.visibilityClasses)
    }
  }
}
