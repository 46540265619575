import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['nav', 'toggleBtn']

  /**
   * Handles the sl-show event emitted by Shoelace <sl-details>.
   * If event originated within the #mobile_nav element,
   * force all other sl-details instances closed except for the
   * one that triggered the sl-show event.
   *
   * @param {CustomEvent} event
   * @returns {void}
   */
  handleShow(event) {
    if (event.target.closest('#mobile_nav') === null) {
      return
    }
    ;[...this.detailsInstances].map((details) => (details.open = event.target === details))
  }

  /**
   * Toggles the visibility of the mobile nav container.
   *
   * Also:
   * - Toggles classes on the <body> to prevent scrolling.
   * - Toggles classes on icons within the toggleBtn target
   * @returns {void}
   */
  toggle() {
    this.navTarget.classList.toggle('hidden')
    document.body.classList.toggle('h-full')
    document.body.classList.toggle('overflow-y-hidden')
    ;[...this.toggleButtonIcons].forEach((icon) => {
      icon.classList.toggle('opacity-0')
      icon.classList.toggle('opacity-100')
    })
  }

  toggleSidebar() {
    this.navTarget.classList.toggle('hidden')
    ;[...this.toggleButtonIcons].forEach((icon) => {
      icon.classList.toggle('opacity-0')
      icon.classList.toggle('opacity-100')
    })
  }

  get detailsInstances() {
    return this.navTarget.querySelectorAll('sl-details')
  }

  get toggleButtonIcons() {
    return this.toggleBtnTarget.querySelectorAll('i')
  }
}
