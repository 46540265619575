import ApplicationController from '../../application_controller'
import $ from 'jquery'
export default class extends ApplicationController {
  static targets = ['emailNameField', 'sharedDomainWarning', 'emailAddressSpanInSharedDomainWarning']
  static values = {
    emailName: String,
    email: String,
    usingSharedDomain: Boolean,
  }

  connect() {
    if (this.emailNameValue && this.emailNameValue.length > 0) {
      $(this.emailNameFieldTarget).val(this.emailNameValue)
    }
  }

  usingSharedDomainValueChanged() {
    if (this.usingSharedDomainValue) {
      this.sharedDomainWarningTarget.classList.remove('hidden')
      this.emailAddressSpanInSharedDomainWarningTarget.innerHTML = this.emailValue
    } else {
      this.sharedDomainWarningTarget.classList.add('hidden')
    }
  }

  handleChange(e) {
    var option = e.target.options[e.target.selectedIndex]
    var data = option.dataset
    this.usingSharedDomainValue = data.usingSharedDomain
    this.emailNameFieldTarget.value = data.name || ''
    this.emailValue = option.text
  }
}
