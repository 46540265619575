import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  connect() {
    useClickOutside(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  close(event) {
    event.preventDefault()
    $('.side_panel').hide()
    document.getElementById('side_panel').removeAttribute('src')
  }

  dispatchClose() {
    this.dispatch('closePanel')
  }
}
