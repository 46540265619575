import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { propsJsonString: String }

  stopLoading() {
    window.dispatchEvent(new Event('effect_modal_closed'))
  }

  toggleApp() {
    window.automationWorkflows.toggleApp(JSON.parse(this.propsJsonStringValue))
  }
}
