import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['section', 'icon', 'leadingIcon', 'trailingIcon']
  static classes = ['leadingIcon', 'leadingToggledIcon', 'trailingIcon', 'trailingToggledIcon']
  static values = {
    dispatchMessage: { type: Object, default: { name: undefined, detail: {} } },
    isOpenByDefault: { type: Boolean, default: false },
  }

  connect() {
    this.isOpen = this.isOpenByDefaultValue
  }

  toggle(event) {
    if (event.target.hasAttribute('data-placement')) {
      return
    }
    this.isOpen = !this.isOpen
    if (this.dispatchMessageValue.name) {
      const detail = { accordionOpen: this.isOpen, ...this.dispatchMessageValue.detail }
      this.dispatch(this.dispatchMessageValue.name, { detail: detail })
    }
    this.sectionTargets.forEach((el) => el.classList.toggle('hidden'))
    if (this.hasLeadingIconTarget) {
      this.toggleIcon(this.leadingIconTarget, this.leadingIconClasses, this.leadingToggledIconClasses)
    }
    if (this.hasTrailingIconTarget) {
      this.toggleIcon(this.trailingIconTarget, this.trailingIconClasses, this.trailingToggledIconClasses)
    }
  }

  toggleIcon(elem, closedIcons, openedIcons) {
    if (this.isOpen) {
      elem.classList.remove(...closedIcons)
      elem.classList.add(...openedIcons)
    } else {
      elem.classList.remove(...openedIcons)
      elem.classList.add(...closedIcons)
    }
  }

  toggleAllChildren() {
    this.element.querySelectorAll('[data-sortable-accordion-target="section"]').forEach((section) => {
      section.classList.toggle('hidden')
    })
  }

  open() {
    this.sectionTargets.forEach((el) => el.classList.remove('hidden'))
  }
}
