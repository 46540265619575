import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    eventNames: String,
    delimiter: { type: String, default: ' ' },
  }

  dispatch() {
    this.eventNamesValue
      .split(this.delimiterValue)
      .filter((item) => item)
      .map((eventName) => {
        const event = new CustomEvent(eventName.trim(), { bubbles: true })
        this.element.dispatchEvent(event)
      })
  }
}
