import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  activate() {
    this.element.active = true
  }

  deactivate() {
    this.element.active = false
  }
}
