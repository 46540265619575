import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['active', 'statusIndicator']

  toggle(event) {
    try {
      const csrfTag = document.getElementsByName('csrf-token')[0]
      const headers = {
        Accept: 'application/json; charset=UTF-8',
        'Content-type': 'application/json; charset=UTF-8',
      }
      if (csrfTag) {
        headers['X-CSRF-Token'] = csrfTag.content
      }

      const statusInidcator = this.statusIndicatorTarget
      fetch(this.data.get('update-url'), {
        method: 'POST',
        dataType: 'script',
        credentials: 'include',
        headers: headers,
      }).then(function (response) {
        if (response.status != 204) {
          event.target.checked = !event.target.checked
          if (!event.target.checked) {
            statusInidcator.classList.remove('bg-green-600')
            statusInidcator.classList.add('bg-gray-400')
          } else {
            statusInidcator.classList.remove('bg-gray-400')
            statusInidcator.classList.add('bg-green-600')
          }
        }
      })
    } catch (e) {
      console.log('triggers controller error', e)
    }
  }
}
