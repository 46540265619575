import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['variantIdsFieldset', 'variantSelectLink', 'clearVariantsBtn']

  existingIdInputs() {
    return Array.from(this.variantIdsFieldsetTarget.querySelectorAll('input[name="shipping_profile[variant_ids][]"]'))
  }

  addRemoveVariantIds(event) {
    const selectedIds = event.detail.variants.map((v) => v.id)

    // Deselecting previously selected variant
    // If the incoming ids don't contain the id from an existing input, remove the input
    this.existingIdInputs().forEach((exIdInp) => {
      if (!selectedIds.includes(exIdInp.id)) {
        exIdInp.remove()
      }
    })

    selectedIds.forEach((id) => this.variantIdsFieldsetTarget.appendChild(this.buildHiddenInput(id)))
    this.toggleClearVariantsBtn(selectedIds)
    this.dispatch('variantsAdded', { detail: event.detail })
    this.element.dispatchEvent(new Event('change', { bubbles: true }))
  }

  removeVariantThroughProductsList(event) {
    const eventContent = { detail: { variants: [] } }

    this.existingIdInputs().forEach((exId) => {
      if (exId.value != event.detail.id) {
        eventContent.detail.variants.push({ id: exId.value })
      }
    })

    this.toggleClearVariantsBtn(this.existingIdInputs())
    this.addRemoveVariantIds(eventContent)
    this.dispatch('variantRemoved', eventContent)

    if (this.existingIdInputs().length === 0) {
      this.variantIdsFieldsetTarget.appendChild(this.buildHiddenInput(null))
    }
  }

  buildHiddenInput(value) {
    const variantIdInput = document.createElement('input')
    variantIdInput.name = 'shipping_profile[variant_ids][]'
    variantIdInput.multiple = 'multiple'
    variantIdInput.type = 'hidden'
    variantIdInput.value = value
    return variantIdInput
  }

  clearVariants() {
    // Remove all existing inputs
    this.existingIdInputs().forEach((vin) => vin.remove())

    // Add a hidden input for variantIds with a null value so the variants will be cleared on the backend
    this.variantIdsFieldsetTarget.appendChild(this.buildHiddenInput(null))

    this.element.dispatchEvent(new Event('change', { bubbles: true }))
    this.dispatch('clearVariants')
  }

  toggleClearVariantsBtn(selectedIds) {
    if (selectedIds.length > 0) {
      this.clearVariantsBtnTarget.classList.remove('hidden')
    } else {
      this.clearVariantsBtnTarget.classList.add('hidden')
    }
  }

  addVariantToFieldset() {
    this.variantIdsFieldsetTarget.appendChild(this.buildHiddenInput(event.detail))
  }
}
