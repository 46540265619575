import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['delta', 'total', 'reminder']

  static values = {
    initialQuantity: Number,
  }

  connect() {
    this.updateTotal()
  }

  handleUpdateDelta() {
    this.updateTotal()
    this.toggleReminder()
  }

  handleUpdateTotal() {
    this.updateDelta()
    this.toggleReminder()
  }

  updateDelta() {
    this.deltaTarget.value = parseInt(this.totalTarget.value) - this.initialQuantityValue
  }

  updateTotal() {
    const deltaAsInteger = parseInt(this.deltaTarget.value)
    if (!isNaN(deltaAsInteger)) {
      this.totalTarget.value = this.initialQuantityValue + deltaAsInteger
    }
  }

  toggleReminder() {
    if (parseInt(this.totalTarget.value) === this.initialQuantityValue) {
      this.reminderTarget.classList.add('hidden')
    } else {
      this.reminderTarget.classList.remove('hidden')
    }
  }
}
