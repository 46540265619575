import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['nextRenewal', 'now', 'withProration', 'withProrationLabel']

  nextRenewalSelected() {
    this.withProrationTarget.disabled = true
    this.withProrationTarget.checked = false
    $(this.withProrationLabelTarget).addClass('text-light-gray-900')
    $(this.withProrationLabelTarget).removeClass('text-gray-900')
  }

  nowSelected() {
    this.withProrationTarget.disabled = false
    $(this.withProrationLabelTarget).addClass('text-gray-900')
    $(this.withProrationLabelTarget).removeClass('text-light-gray-900')
  }
}
