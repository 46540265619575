import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['prefixInput', 'suffixInput', 'preview']

  connect() {
    this.updatePreview()
  }

  updatePreview() {
    if (this.hasPreviewTarget) {
      this.previewTarget.textContent = [1001, 1002, 1003]
        .map((number) => `${this.prefixInputTarget.value}${number}${this.suffixInputTarget.value}`)
        .join(', ')
    }
  }
}
