import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['errorOverlay', 'loadingOverlay']
  static classes = ['hidden']
  static values = {
    frameSelector: String,
    loadedDataUrl: String,
  }

  connect() {
    if (window.usersChannelConnected) {
      this.refetchWithLoadedData()
    }
  }

  refetchWithLoadedData() {
    const frame = this.hasFrameSelectorValue ? this.element.querySelector(this.frameSelectorValue) : this.element

    if (!frame) {
      return
    }
    if (!this.hasLoadedDataUrlValue) {
      return
    }

    frame.src = this.loadedDataUrlValue
  }

  showError() {
    this.toggleError(true)
  }

  hideError() {
    this.toggleError(false)
  }

  toggleError(value) {
    const hiddenClass = this.hasHiddenClass ? this.hiddenClass : 'hidden'

    if (!this.hasErrorOverlayTarget) {
      return
    }
    this.errorOverlayTargets.forEach((target) => target.classList.toggle(hiddenClass, !value))

    if (!this.hasLoadingOverlayTarget) {
      return
    }
    this.loadingOverlayTargets.forEach((target) => target.classList.toggle(hiddenClass, value))
  }
}
