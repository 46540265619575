import ApplicationController from './application_controller'
import { FetchRequest } from '@rails/request.js'

export default class BaseSearchController extends ApplicationController {
  static targets = ['searchInput']

  static values = {
    searchFrameId: String,
    searchPath: String,
    submitUrl: String,
  }

  connect() {
    if (this.beforeConnect) this.beforeConnect()
    this.searchFrame = document.getElementById(this.searchFrameIdValue)
    this.originalUrl = new URL(this.searchPathValue)
    this.originalHref = `${this.originalUrl.origin}${this.originalUrl.pathname}`
    const urlParams = new URLSearchParams(this.originalUrl.search)
    this.existingParams = urlParams
    this.paramsCache = {
      stable_id: urlParams.get('stable_id'),
      sort_by: urlParams.get('sort_by'),
      q: urlParams.get('q'),
      tab: urlParams.get('tab'),
      view: urlParams.get('view'),
    }
    this.existingParams.delete('stable_id')
    this.existingParams.delete('sort_by')
    this.existingParams.delete('q')
    this.existingParams.delete('tab')
    this.existingParams.delete('page')
    this.existingParams.delete('view')
    this.stableId = this.paramsCache['stable_id']
    this.storedFilterId = this.paramsCache['stored_filter_id']
    this.sortBy = this.paramsCache['sort_by']
    this.q = this.paramsCache['q']
    this.tab = this.paramsCache['tab']
    this.view = this.paramsCache['view']
  }

  updateSearch(event) {
    this.q = event.target.value
  }
  updateSort(event) {
    this.sortBy = event.target.value
    this.view = this.originalUrl.searchParams.get('view')
    this.tab = this.originalUrl.searchParams.get('tab')
    this.stable_id = this.originalUrl.searchParams.get('stable_id')

    this.changeUrl(this.stable_id)
  }

  discardFilter() {
    this.existingParams.delete('stable_id')
    this.existingParams.delete('stored_filter_id')
    this.storedFilterId = null
    this.changeUrl()
  }

  discardSearch() {
    this.existingParams.delete('q')
    this.q = null
    this.searchInputTarget.value = ''
  }

  changeUrl(stableId) {
    const params = new URLSearchParams()
    if (this.tab) {
      params.append('tab', this.tab)
    }
    if (stableId) {
      params.append('stable_id', stableId)
      params.append('user_filter', true)
      this.discardSearch()
    }
    if (this.q) {
      params.append('q', this.q)
    }
    if (this.sortBy) {
      params.append('sort_by', this.sortBy)
    }
    if (this.storedFilterId) {
      params.append('stored_filter_id', this.storedFilterId)
    }
    if (this.view) {
      params.append('view', this.view)
    }
    const allParams = new URLSearchParams({
      ...Object.fromEntries(this.existingParams),
      ...Object.fromEntries(params),
    }).toString()
    const url = `${this.originalHref}?${allParams}`
    this.searchPathValue = url

    this.searchFrame.src = url
    this._makeSearchRequest(url)
  }

  _makeSearchRequest(url) {
    throw Error('Not set up')
  }

  search(event) {
    event.preventDefault()
    this.changeUrl()
    document.activeElement.blur()
  }

  filterSearch(event) {
    event.preventDefault()
    this.submitFilter()
    document.activeElement.blur()
  }

  reloadFilterResults({ detail: { stableId } }) {
    this.changeUrl(stableId)
  }
  async submitFilter() {
    const { blueprint } = this.stateController
    const request = new FetchRequest('POST', this.submitUrlValue, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        refine_filters_builder: {
          filter_class: this.stateController.filterName,
          blueprint_json: JSON.stringify(blueprint),
          client_id: this.stateController.clientIdValue,
        },
      }),
    })
    await request.perform()
  }
  get stateController() {
    return this.element.querySelector('[data-controller~="refine--state"]').refineStateController
  }

  get stabilizeFilterController() {
    return this.element.querySelector('[data-controller~="refine--stabilize-filter"]').stabilizeFilterController
  }
}
