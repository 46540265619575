/* Stimulus controller to prevent user from losing unsaved changes to forms

  Usage:
    On the <form> element add:
      1. data-controller='unsaved-changes'
      2. data-action='beforeunload@window->unsaved-changes#confirmChanges
                      turbo:before-visit@window->unsaved-changes#confirmChanges
                      turbo:submit-end@window->unsaved-changes#reset'
      3. (Optional) data-unsaved-changes-disabled-class-value='special-class-name'
      4. (Optional) data-unsaved-changes-confirm-msg-value='Bah! You're gonna lose your stuff!'
      5. (Optional) data-unsaved-changes-ignore='true'

    On the submit button(s) on the form add:
      1. data-unsaved-changes-target='submit' (or) data: {unsaved_changes_target: "submit"}
*/
import ApplicationController from './application_controller'
import { i18n } from '../i18n-js/config'
import Cookies from 'js-cookie'

export default class extends ApplicationController {
  static targets = ['submit', 'restrictWithSubmit']
  static values = {
    disabledClass: String,
    confirmMsg: String,
    dirty: { type: Boolean, default: false },
  }

  isDirty = false
  subscribedEvents = 'change input select2:select select2:unselecting cancel.daterangepicker dragula.drop sortable:drop'

  connect() {
    this.element.classList.add('unsaved-changes-root')

    this.serializeForm()
    $(this.element).on(this.subscribedEvents, this.handleChange.bind(this))
  }

  disconnect() {
    $(this.element).off(this.subscribedEvents, this.handleChange.bind(this))
  }

  serializeForm() {
    this.originalForm = $(this.element).serialize()
  }

  initialize() {
    this.reset()
  }

  handleChange(event) {
    if (event.target.dataset?.unsavedChangesIgnore) return

    this.isDirty = $(this.element).serialize() !== this.originalForm

    if (this.isDirty) {
      this.enableSubmit()
      this.enableRestrictedTargets()
      this.dispatch('has-changes')
    } else {
      this.reset()
      this.dispatch('no-changes')
    }

    this.dispatch('change', { detail: { dirty: this.isDirty } })
  }

  enableSubmit() {
    this.submitTargets.forEach((target) => {
      target.classList.remove(this.disabledClassName)
      target.disabled = false
      target.title = ''
    })
  }

  enableRestrictedTargets() {
    this.restrictWithSubmitTargets.forEach((target) => {
      target.classList.remove(this.disabledClassName)
      target.disabled = false
      target.title = ''
    })
  }

  reset(event) {
    const { formSubmission } = event?.detail || {}
    const formElement = formSubmission?.formElement

    if (formElement && formElement !== this.element) return

    const locale = Cookies.get('locale') || 'en'
    const title = i18n.t('unsaved_changes_controller.noChanges', { lng: locale })

    this.isDirty = this.dirtyValue || false
    this.submitTargets.forEach((target) => {
      target.classList.add(this.disabledClassName)
      target.disabled = true
      target.title = title
    })
    this.restrictWithSubmitTargets.forEach((target) => {
      target.classList.add(this.disabledClassName)
      target.disabled = true
      target.title = title
    })
    this.serializeForm()

    this.dispatch('reset')
  }

  confirmChanges(event) {
    if (this.isDirty) {
      if (event.type == 'turbo:before-visit' || event.type == 'click') {
        if (!window.confirm(this.confirmMsg)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = this.confirmMsg
        return event.returnValue
      }
    }
  }

  discard(event) {
    if (this.isDirty && !window.confirm(this.confirmMsg)) {
      event.preventDefault()
    } else {
      this.element.reset()
      this.reset()
      this.dispatch('discard')
    }
  }

  get disabledClassName() {
    return this.disabledClassValue === '' ? 'disabled' : this.disabledClassValue
  }

  get confirmMsg() {
    const locale = Cookies.get('locale') || 'en'
    const msg = i18n.t('unsaved_changes_controller.haveChanges', { lng: locale })

    return this.confirmMsgValue === '' ? msg : this.confirmMsgValue
  }
}
