import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['textFieldWrapper']

  insertValue(event) {
    let mergeTag = event.target.getAttribute('data-merge-tag')
    if (mergeTag == null) {
      let parentElement = event.target.parentElement
      while (parentElement != null) {
        mergeTag = parentElement.getAttribute('data-merge-tag')
        if (mergeTag != null) {
          event.target.setAttribute('data-merge-tag', mergeTag)
          break
        }
        parentElement = parentElement.parentElement
      }
    }

    const textField = this.textFieldWrapperTarget.querySelector('input, textarea')

    if (textField) {
      const startPos = textField.selectionStart
      const endPos = textField.selectionEnd
      const beforeText = textField.value.substring(0, startPos)
      const afterText = textField.value.substring(endPos, textField.value.length)

      textField.value = beforeText + mergeTag + afterText

      const newCursorPosition = startPos + mergeTag.length
      textField.setSelectionRange(newCursorPosition, newCursorPosition)
      textField.focus()
    }
  }
}
