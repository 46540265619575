import ApplicationController from './application_controller'

/**
 * This controller is responsible for coordinating the interactions between
 * a Rails form and the React Editor, both contained inside a Shoelace Dialog element.
 *
 * Messages are sent and received from the Editor via postMessage (the Editor lives in an iframe).
 *
 * This controller also has a dependency on the unsaved-changes controller, which needs to be
 * added to the Rails form.
 *
 * You will also need to add targets to the editor iframe (editorIframe), the wrapper for the
 * Rails form (settingsPanel), and the rails form element itself (railsForm).
 */
export default class extends ApplicationController {
  static targets = ['editorIframe', 'settingsPanel', 'railsForm', 'saveButton']
  static classes = ['loading']

  static values = {
    confirmMsg: {
      type: String,
      default: "You have made changes to this blog post, are you sure you want to proceed? This can't be undone.",
    },
    saveButtonText: { type: String, default: 'Save Changes' },
    saveReloadButtonText: { type: String, default: 'Save & Reload' },
    resourceUrl: String,
    editTemplatePageUrl: String,
    editorTemplateBackButtonUrl: String,
    railsFormIsDirty: Boolean,
    editorIsDirty: Boolean,
    nextResourceUrl: String,
    previousResourceUrl: String,
  }

  connect() {
    this._initializePageLoad()
    window.addEventListener('message', this._editorCallbackMessageHandler.bind(this))
  }

  disconnect() {
    window.removeEventListener('message', this._editorCallbackMessageHandler.bind(this))
  }

  /**
   * Toggle the visibility of the SettingsPanelTarget
   *
   * @returns {void}
   */
  toggleSettingsPanel() {
    this.settingsPanelTarget.classList.toggle('hidden')
  }

  /**
   * Initialize the Editor.
   * Sends a `change-page` message via postMessage
   * to the iframe that contains the Editor.
   *
   * @private
   * @returns {void}
   */
  _initializePageLoad() {
    const iframeElement = this.editorIframeTarget
    const data = {
      action: 'change-page',
      search: window.location.search,
      resourceUrl: this.resourceUrlValue,
    }

    if (iframeElement.isLoaded) {
      iframeElement.contentWindow.postMessage(data, '*')
    } else {
      iframeElement.addEventListener('load', () => {
        iframeElement.contentWindow.postMessage(data, '*')
      })
    }
  }

  /**
   * Re-initialize the Editor. Calls the _initializePageLoad
   * function if the rails form is dirty
   *
   * @private
   * @returns {void}
   */
  _reloadEditor() {
    if (!this.railsFormIsDirtyValue) return

    this._initializePageLoad()
  }

  /**
   * Event handler for messages received via Editor iframe postMessage.
   *
   * @private
   * @returns {void}
   */
  _editorCallbackMessageHandler({ data }) {
    if (!data) {
      return
    }

    switch (data.action) {
      case 'page-saved':
        this.resetEditor()
        this._reloadEditor()
        this.submitRailsForm()
        this._toggleSaveBusy()
        break

      case 'open-template-page':
        this.openTemplatePage()
        break

      case 'editor-changed':
        this.editorChanged(data)
        break
    }
  }

  /**
   * Handler for the `unsaved-changes:change` event on the railsFormTarget element.
   * Sets the railsFormIsDirty class property.
   *
   * @param {CustomEvent} event
   * @param {boolean} event.detail.dirty - whether the form element has unsaved changes or not
   *
   * @returns {void}
   */
  handleFormChange(event) {
    this.railsFormIsDirtyValue = event.detail.dirty
  }

  /**
   * If unsaved changes are present on either the Rails form or the Editor,
   * prompt the user for confirmation.
   *
   * If the user confirms, and params: { closeModal: false }
   * is present on the event param, the function will return
   * allowing the normal event to process.
   *
   * @param {CustomEvent} event
   *
   * @returns {void}
   */
  discardChanges(event) {
    if (!this.confirmUnsafeNavigation()) {
      event.preventDefault()
      return
    }

    if (event.params.closeModal == false) {
      return
    }

    this.element.hide()
  }

  /**
   * Use this method to check if the user has unsaved changes and if so
   * have them confirm their intention before allowing them to navigate
   * away from the current page.
   *
   * Returns true if no pending changes are present, otherwise returns
   * the result of the confirmation dialog.
   *
   * @returns {boolean}
   */
  confirmUnsafeNavigation() {
    if (this.hasUnsavedChanges) {
      return window.confirm(this.confirmMsgValue)
    } else {
      return true
    }
  }

  /**
   * Will cancel a click event when a user confirms that do not intend
   * to lose their existing changes.
   *
   * @returns {void}
   */
  cancelClickAfterUnsavedCheck(event) {
    if (!this.confirmUnsafeNavigation()) {
      event.preventDefault()
      return
    }
  }

  /**
   * Toggles the save button busy state and asks editor to save.
   *
   * @returns {void}
   */
  saveChanges() {
    this._toggleSaveBusy()
    this.saveEditorPage()
  }

  /**
   * Sends the 'save-page' message via postMessage through the editor iFrame.
   * This will cause the Editor to save any unsaved changes.
   *
   * @returns {void}
   */
  saveEditorPage() {
    const data = {
      action: 'save-page',
    }

    this.editorIframeTarget.contentWindow.postMessage(data, '*')
  }

  /**
   * Submits the Rails form.
   *
   * @returns {void}
   */
  submitRailsForm() {
    if (this.hasRailsFormTarget && this.railsFormIsDirtyValue) {
      this.railsFormTarget.requestSubmit()
    }
  }

  /**
   * Conditionally visits the template page url if unsaved changes
   * are not present or discard is confirmed.
   *
   * The url to visit is determined by either the event.params.templatePageUrl
   * or the current editTemplatePageUrlValue passed into the controller.
   *
   * If the editorTemplateBackButtonUrlValue is present,
   * it will be appended to the editTemplatePageUrlValue as a
   * url param called back_to.
   * @param {Event} event
   *
   * @returns {void}
   */
  openTemplatePage(event) {
    const templateUrlParam = event?.params?.templatePageUrl

    if (this.hasUnsavedChanges && !window.confirm(this.confirmMsgValue)) {
      return
    }

    let baseUrl = templateUrlParam || this.editTemplatePageUrlValue
    const url = new URL(baseUrl)

    if (this.hasEditorTemplateBackButtonUrlValue) {
      url.searchParams.set('back_to', this.editorTemplateBackButtonUrlValue)
    }

    Turbo.visit(url.toString(), { frame: 'dialog' })
  }

  loadNextResource(event) {
    if (this.confirmUnsafeNavigation()) {
      const parentFrame = event.target.closest('turbo-frame')
      parentFrame.src = this.nextResourceUrlValue
    }
  }

  loadPreviousResource(event) {
    if (this.confirmUnsafeNavigation()) {
      const parentFrame = event.target.closest('turbo-frame')
      parentFrame.src = this.previousResourceUrlValue
    }
  }

  /**
   * Resets the rails form dirty state back to false
   *
   * @returns {void}
   */
  reset() {
    this.railsFormIsDirtyValue = false
  }

  /**
   * Resets the editor dirty state back to false
   *
   * @returns {void}
   */
  resetEditor() {
    this.editorIsDirtyValue = false
  }

  /**
   * Sets the editor dirty state based on the incoming boolean
   *
   * @param {Object} messageData - The posted message data from the editor
   * @param {boolean} messageData.dirty - The dirty state of the editor
   *
   * @returns {void}
   */
  editorChanged({ dirty }) {
    this.editorIsDirtyValue = dirty
  }

  /**
   * Call to update the submit button state using
   * Stimulus Value change callback
   * See: https://stimulus.hotwired.dev/reference/values#change-callbacks
   *
   * @returns {void}
   */
  editorIsDirtyValueChanged() {
    this.updateSubmitButtonState()
  }

  /**
   * Call to update the submit button state using
   * Stimulus Value change callback
   * See: https://stimulus.hotwired.dev/reference/values#change-callbacks
   *
   * @returns {void}
   */
  railsFormIsDirtyValueChanged() {
    this.updateSubmitButtonState()
  }

  /**
   * If the event was called by the element, this will remove the element from the DOM.
   * Helps with preventing unsaved changes from triggering when the form is no
   * longer visible or needed.
   *
   * @param {Object} event - The event from the DOM
   *
   * @returns {void}
   */
  removeFromDom(event) {
    if (event.target !== this.element) return

    this.element.remove()
  }

  /**
   * Enables the submit button if changes are present and
   * sets the submit button text
   *
   * @returns {void}
   */
  updateSubmitButtonState() {
    this.saveButtonTarget.disabled = !this.hasUnsavedChanges

    this.saveButtonTarget.textContent = this.buttonText
  }

  /**
   * Toggles hiding or showing busy state based on whether the loading class is
   * on the save button or not.
   *
   * @private
   * @returns {void}
   */
  _toggleSaveBusy() {
    this.saveButtonTarget.classList.contains(this.loadingClass) ? this._hideSaveBusy() : this._showSaveBusy()
  }

  /**
   * Adds the loading style to the save button and removes the inner html
   *
   * @private
   * @returns {void}
   */
  _showSaveBusy() {
    this.saveButtonTarget.style.minWidth = `${this.saveButtonTarget.offsetWidth}px`
    this.saveButtonTarget.classList.add(this.loadingClass)
    this.saveButtonTarget.innerHTML = ''
  }

  /**
   * Removes the loading style from the save button and restores the inner html.
   *
   * @private
   * @returns {void}
   */
  _hideSaveBusy() {
    this.saveButtonTarget.classList.remove(this.loadingClass)
    this.saveButtonTarget.innerHTML = this.saveButtonTextValue
    this.saveButtonTarget.style.removeProperty('min-width')
  }

  /**
   * Getter to return the button text based on whether
   * the rails form is dirty or not
   *
   * @returns {string} The text to apply to the submit button
   */
  get buttonText() {
    return this.railsFormIsDirtyValue ? this.saveReloadButtonTextValue : this.saveButtonTextValue
  }

  /**
   * Getter to return whether the editor or the rails form
   * is currently dirty or not.
   *
   * @returns {boolean}
   */
  get hasUnsavedChanges() {
    return this.editorIsDirtyValue || this.railsFormIsDirtyValue
  }
}
