import ApplicationController from '../application_controller'
import { MODES } from '../../concerns/image_gallery'

// Handles managing the state of the image field component:
// - shows/hides null state & image thumbnail areas
// - handles events emitted by the dropzone-controller
// - manages hidden fields and values
export default class extends ApplicationController {
  static targets = [
    'openBtn', // button to open the image gallery
    'titleBar', // title bar for thumbnail view
    'nullState', // container wrapping
    'thumbnailAreaWrapper', // wrapper around thumbnails
    'imageThumbnailArea', // wrapper for image thumbnail
    'imageThumbnailTemplate', // `<template>` for image thumbnail
    'inlineError', // hidden element for displaying the error message
    'field', // hidden field for associated image id(s)
    'hiddenFieldTemplate', // `<template>` for the 'field' target
    'hiddenFieldsContainer', // wrapper container for the hidden fields
    'hiddenSortField', // hidden field associated to the sort value
    'loadingSpinner', // loading spinner that appears in the `imageThumbnailTemplateTarget`
    'addMore', // the wrapper around the add more images link in multiple mode
    'removeImage', // button to remove image in multi mode
    'thumbnail', // the wrapper element around image thumbnails
    'destroy', // the hidden _destroy field for auto-destroying nested records via Rails
  ]

  static values = {
    fieldId: String, // unique id used to associate events from the gallery to correct field
    selectedIds: Array, // array of selected image ids
    mode: { type: String, default: MODES.single }, // 'single' or 'multiple'
  }

  /**
   * Display the loading spinner. Optionally specifiy the imageId of the wrapping element
   * to find a specific spinner.
   */
  showLoadingSpinner(imageId = null) {
    /* Which one? mode: 'multiple' */
    if (imageId) {
      const thumbnail = this.thumbnailTargets.find((element) => element.dataset.imageId == imageId)
      const loadingSpinnerWrapper = thumbnail.querySelector('sl-spinner').parentElement
      loadingSpinnerWrapper.classList.remove('hidden')
    } else {
      this.loadingSpinnerTarget.classList.remove('hidden')
    }
  }

  /**
   * Hide the loading spinner. Optionally specifiy the imageId of the wrapping element
   * to find a specific spinner.
   */
  hideLoadingSpinner(imageId = null) {
    /* Which one? mode: 'multiple' */
    if (imageId) {
      const thumbnail = this.thumbnailTargets.find((element) => element.dataset.imageId == imageId)
      const loadingSpinnerWrapper = thumbnail.querySelector('sl-spinner').parentElement
      loadingSpinnerWrapper.classList.add('hidden')
    } else {
      this.loadingSpinnerTarget.classList.add('hidden')
    }
  }

  /**
   * Show or hide the inline error with the error message. Given a message,
   * display the error message. Given no message, hide the error message element.
   *
   * @param {string} message The error message to display (default is '')
   */
  toggleErrorMessage(message) {
    if (this.hasInlineErrorTarget) {
      if (message) {
        this.inlineErrorTarget.innerHTML = message
        this.inlineErrorTarget.classList.remove('hidden')
        return
      }
      this.inlineErrorTarget.classList.add('hidden')
    }
  }

  /**
   * Called when the user selects or deselects an image.
   * It removes all hidden fields from the DOM, then creates a new
   * hidden field for each selected image. A change event is dispatched via
   * the `emitChangeEvent()` function.
   *
   * @param {Array[{selectedImage: {id: string}}]} selectedImages
   * @return A new array of selected images
   */
  updateHiddenFields(selectedImages = []) {
    if (this.modeValue == 'single') {
      this.fieldTargets.forEach((field) => field.remove())
    }

    selectedImages.forEach(({ id }, index) => {
      let clonedHiddenField = this.hiddenFieldTemplateTarget.content.cloneNode(true)
      let input = clonedHiddenField.querySelector('input')
      input.value = id
      let sortableInput = clonedHiddenField.querySelector(
        "[data-components--image-field--sortable-target='hiddenField']"
      )

      if (this.modeValue === 'multiple') {
        const newRecordIndex = `${new Date().getTime() + index}`
        input.name = input.name.replace(/NEW_RECORD/g, newRecordIndex)
        input.id = input.id.replace(/NEW_RECORD/g, newRecordIndex)

        if (sortableInput) {
          sortableInput.value = this.fieldTargets.length
          sortableInput.name = sortableInput.name.replace(/NEW_RECORD/g, newRecordIndex)
          sortableInput.id = sortableInput.id.replace(/NEW_RECORD/g, newRecordIndex)
          sortableInput.setAttribute('data-image-id', id)
        }
      }

      this.hiddenFieldsContainerTarget.appendChild(clonedHiddenField)
    })
  }

  /**
   * Callback for when the image gallery closes. This is triggered via a `data-action` within the erb file(s)
   *
   * @param {Event[{ detail: { fieldId: string, items: Array[{url: string}]} }]} event
   */
  handleSelected(event) {
    if (event.detail.fieldId !== this.fieldIdValue) return

    const selectedImages = event.detail.items
    this.updateHiddenFields(selectedImages)
    this.toggleErrorMessage()

    if (selectedImages.length > 0) {
      this.insertImageThumbnails(selectedImages)
      this.selectedIdsValue = this.selectedIdsValue.concat(selectedImages.map((image) => parseInt(image.id)))
    } else {
      this.showNullState()
    }

    this.updateAddMoreLink()
    this.emitChangeEvent()
  }

  /**
   * Handles the the event emitted by the remove image button.
   * - If the image is already persisted, updates the hidden _destroy field value.
   * - Otherwise, removes previously dynamically inserted hidden id field.
   * Removes the image thumbnail wrapper element from the dom.
   *
   * @param {Event} event - The event object.
   */
  remove(event) {
    const thumbnail = event.target.closest('div[data-image-id]')
    const imageId = thumbnail.dataset.imageId
    const destroyField = this.destroyTargets.find((element) => element.dataset.imageId == imageId)

    this.selectedIdsValue = this.selectedIdsValue.filter((id) => id != imageId)
    this.updateAddMoreLink()

    this.#destroyImageFields(imageId, thumbnail, destroyField)
  }

  #destroyImageFields(imageId, thumbnailElement, destroyField) {
    /* Will only be true if object is persisted */
    if (destroyField) {
      destroyField.value = '1'
    }

    this.fieldTargets.find((element) => element.value == imageId).remove()
    this.hiddenSortFieldTargets.find((element) => element.dataset.imageId == imageId).remove()

    thumbnailElement.remove()

    if (this.existingImages.length === 0) {
      this.showNullState()
    }

    this.emitChangeEvent()
  }

  handleThumbnailGenerated(event) {
    const selectedImages = event.detail.images
    this.insertImageThumbnails(selectedImages)
  }

  handleUploadEnd(event) {
    const { id, tempId } = event.detail

    this.updateHiddenFields([{ id }])
    this.toggleErrorMessage()
    this.updateThumbnailTemplIdWithActualId(tempId, id)
    this.hideLoadingSpinner(id)
    this.emitChangeEvent()
  }

  handleUploadError(event) {
    const { message } = event.detail
    this.toggleErrorMessage(message)
  }

  /**
   * We have to use a temporary ID while the file is being persisted
   * on the remote in order to manage the visibility of the loading spinner
   * for multiple images at the same time. Here we update the tempId with the real id.
   */
  updateThumbnailTemplIdWithActualId(tempId, id) {
    const thumbnail = this.thumbnailTargets.find((element) => element.dataset.imageId == tempId)
    thumbnail.setAttribute('data-image-id', id)
  }

  /**
   * Deletes the associated hidden fields for an image.
   * If the image is persisted, it sets the rails hidden destroy field to 1
   * to delete the underlying record.
   *
   * @param {CustomEvent[{ detail: { item: { id: string } } }]} event
   * @see #handleDelete in gallery_controller.js
   */
  handleDelete(event) {
    const { id: imageId } = event.detail.item

    if (!this.hasFieldTarget || !this.hasThumbnailTarget || !imageId) return

    const thumbnail = this.thumbnailTargets.find((element) => element.dataset.imageId == imageId)
    const destroyField = this.destroyTargets.find((element) => element.dataset.imageId == imageId)

    this.#destroyImageFields(imageId, thumbnail, destroyField)
  }

  /**
   * Show the empty state of the image preview container.
   */
  showNullState() {
    if (!this.thumbnailAreaWrapperTarget.classList.contains('hidden')) {
      this.thumbnailAreaWrapperTarget.classList.add('hidden')
    }
    if (this.hasNullStateTarget) {
      this.nullStateTarget.classList.remove('hidden')
    }
    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.remove('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.add('hidden')
    }
  }

  /**
   * Show the image thumbnail area.
   */
  showThumbnailArea() {
    if (!this.nullStateTarget.classList.contains('hidden')) {
      this.nullStateTarget.classList.add('hidden')
    }

    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.add('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.remove('hidden')
    }
    if (this.thumbnailAreaWrapperTarget.classList.contains('hidden')) {
      this.thumbnailAreaWrapperTarget.classList.remove('hidden')
    }
  }

  /**
   * Create new image thumbnails and associated elements.
   *
   * @param {Array[{url: string}]} selectedImages
   */
  insertImageThumbnails(selectedImages) {
    if (!this.nullStateTarget.classList.contains('hidden')) {
      this.nullStateTarget.classList.add('hidden')
    }

    if (this.modeValue == 'single') {
      this.imageThumbnailAreaTarget.innerHTML = ''
    }

    selectedImages.forEach((image) => {
      const { url, id, tempId } = image
      let content = this.imageThumbnailTemplateTarget.content.cloneNode(true)
      content.firstElementChild.setAttribute('data-image-id', id || tempId)

      let imgElement = content.querySelector('img')
      imgElement.src = url

      if (this.modeValue == 'multiple') {
        this.imageThumbnailAreaTarget.insertBefore(content, this.addMoreTarget)
      } else {
        this.imageThumbnailAreaTarget.appendChild(content)
      }

      if (tempId) {
        this.showLoadingSpinner(tempId)
      }
    })

    this.showThumbnailArea()
  }

  /**
   * Update the add more link with the selected image ids
   */
  updateAddMoreLink() {
    if (!this.hasAddMoreTarget) return
    const url = new URL(this.addMoreTarget.href)
    const params = new URLSearchParams(url.search)
    const selectedIds = this.selectedIdsValue

    params.delete('selected_ids[]')
    selectedIds.forEach((id) => {
      params.append('selected_ids[]', id)
    })
    url.search = params.toString()

    this.addMoreTarget.href = url.toString()
  }

  /**
   * Reset the form and hidden fields
   */
  reset() {
    this.fieldTargets.forEach((target) => (target.value = ''))

    this.emitChangeEvent()
    this.showNullState()
    this.toggleErrorMessage()
  }

  /**
   * Emit a change event on the hidden fields container which bubbles up to
   * the dropzone, empty_folder, or empty_state partials
   *
   * @see app/views/account/images/gallery/_dropzone.html.erb
   * @see app/views/account/images/gallery/_empty_folder.html.erb
   * @see app/views/account/images/gallery/_empty_state.html.erb
   */
  emitChangeEvent() {
    this.hiddenFieldsContainerTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }

  get existingImages() {
    return this.imageThumbnailAreaTarget.querySelectorAll('img')
  }
}
