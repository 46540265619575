import { post } from '@rails/request.js'
import PaymentMethodFormController from './payment_method_form_controller'
export default class extends PaymentMethodFormController {
  static targets = ['turboStreamPaymentMethodForm', 'rebillyToken']
  static values = {
    organizationId: String,
    publishableKey: String,
  }

  paymentForm() {
    return this.turboStreamPaymentMethodFormTarget
  }

  async submit(form, data) {
    post(form.action, {
      body: JSON.stringify(data),
      responseKind: 'turbo-stream',
    })
  }
}
