import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['input']
  static values = {
    resultsTurboFrame: { type: String, default: '' },
    searchPath: { type: String, default: '' },
  }

  search(e) {
    e.preventDefault()
    let baseUrl = new URL(this.searchPathValue, window.location.origin)
    let params = new URLSearchParams(baseUrl.search)

    params.set('q', this.inputTarget.value)
    params.set('results_turbo_frame', this.resultsTurboFrameValue)
    baseUrl.search = params.toString()
    let url = baseUrl.toString()

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      get(url, {
        responseKind: 'turbo-stream',
      })
    }, 200)
  }
}
