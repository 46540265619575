/* eslint-disable no-undef */
import ApplicationController from './application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static classes = ['radioActive', 'cardActive']

  static targets = [
    'payForm',
    'errorWrapper',
    'errorMessageHolder',
    'submitPaymentButton',
    'rebillyToken',
    'cardMountElement',
    'bankTypeMountElement',
    'bankNumberMountElement',
    'bankRoutingMountElement',
    'outOfBandElement',
    'cardOnFileForm',
    'cardOnFileElement',
    'selectedMethod',
    'paymentOption',
    'cardForm',
    'achForm',
    'outOfBandForm',
    'cash',
    'check',
    'savedCardButton',
    'savedPaymentMethodField',
    'addressCard',
    'rebillyAddressStreetOne',
    'rebillyAddressStreetTwo',
    'rebillyAddressCity',
    'rebillyAddressCountryCode',
    'rebillyAddressRegionCode',
    'rebillyAddressPostalCode',
  ]

  static values = {
    organizationId: String,
    publishableKey: String,
    knownAddressesUrl: String,
  }
  mountedFrames = []

  initialize() {
    const container = document.querySelector('.payment-method-details-group')
    ;[...container.querySelectorAll('sl-details')].map((details) => {
      if (details.open) {
        this.selectedMethod = details.dataset['paymentMethodType']
      }
    })

    if (!Rebilly.initialized) {
      var config = {
        publishableKey: this.publishableKeyValue,
        organizationId: this.organizationIdValue,
        kountAccountId: '700000', // This is for capturing kount fraud sessions
      }

      Rebilly.initialize(config)
    }

    this.initializePaymentMethodGroups()

    const form = this.payFormTarget

    this.savedCardButtonTargets.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.submitPaymentButtonTarget.removeAttribute('disabled')

        const savedPaymentMethodId = e.target.dataset['paymentMethodId']
        this.savedPaymentMethodFieldTarget.value = savedPaymentMethodId

        this.savedCardButtonTargets.forEach((element) => {
          const paymentDesc = element.closest('.payment-desc')
          const radioBtn = paymentDesc.querySelector('.radio-button')
          radioBtn.classList.remove(...this.radioActiveClasses)
          paymentDesc.classList.remove(...this.cardActiveClasses)
        })

        const paymentDesc = button.closest('.payment-desc')
        const radioBtn = paymentDesc.querySelector('.radio-button')
        radioBtn.classList.add(...this.radioActiveClasses)
        paymentDesc.classList.add(...this.cardActiveClasses)
      })
    })

    this.outOfBandElementTargets.forEach((item) => {
      item.addEventListener('change', (e) => {
        this.submitPaymentButtonTarget.removeAttribute('disabled')

        const outOfBandElementValue = e.target.value

        if (outOfBandElementValue == 'cash') {
          this.cashTarget.classList.remove('hidden')
          this.checkTarget.classList.add('hidden')
        }
        if (outOfBandElementValue == 'check') {
          this.checkTarget.classList.remove('hidden')
          this.cashTarget.classList.add('hidden')
        }
        if (outOfBandElementValue == 'miscellaneous') {
          this.cashTarget.classList.add('hidden')
          this.checkTarget.classList.add('hidden')
        }
      })
    })

    function validateBankAccount(event) {
      if (event.source == 'bankAccountNumber') {
        this.bankNumberMountElementTarget.valid = event && event.valid && event.completed
      }
      if (event.source == 'bankRoutingNumber') {
        this.bankRoutingMountElementTarget.valid = event && event.valid && event.completed
      }

      return this.bankRoutingMountElementTarget.valid && this.bankNumberMountElementTarget.valid
    }

    Rebilly.on('ready', () => {
      this.resetFramepay(this.selectedMethod)
    })

    form.onsubmit = (e) => {
      if (this.submitProcessed) {
        this.submitProcessed = false
        return
      }
      this.paymentSubmitting = true
      this.submitPaymentButtonTarget.setAttribute('disabled', '')

      e.preventDefault()
      e.stopPropagation()
      this.selectedMethodTarget.value = this.selectedMethod

      if (this.rebillyPaymentMethodTypeSelected()) {
        let extraData = {
          method: this.selectedMethod,
        }

        if (this.addressType === 'existing_address' && this.knownAddress != undefined) {
          this.rebillyAddressStreetOneTarget.value = this.knownAddress.address
          this.rebillyAddressStreetTwoTarget.value = this.knownAddress.address2
          this.rebillyAddressCityTarget.value = this.knownAddress.city
          this.rebillyAddressCountryCodeTarget.value = this.knownAddress.country
          this.rebillyAddressRegionCodeTarget.value = this.knownAddress.region
          this.rebillyAddressPostalCodeTarget.value = this.knownAddress.postalCode
        }

        Rebilly.createToken(form, extraData)
          .then((result) => {
            this.paymentSubmitting = false
            this.rebillyTokenTarget.value = result.id
            this.submitPayForm()
          })
          .catch((error) => {
            console.error('Framepay error', error)
            this.paymentSubmitting = false
          })
      } else {
        this.submitPayForm()
      }
    }
  }

  submitPayForm() {
    this.submitProcessed = true
    this.payFormTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true }))
  }

  submitEnd() {
    // Form submit has completed, so re-enable the submit button (if present)
    this.submitPaymentButtonTarget.removeAttribute('disabled')
  }

  // Some of our payment method types like card on file, and out of band do not
  // need to be posted to rebilly to get a token.  This method determines
  // method types that can skip the rebilly tokenization
  rebillyPaymentMethodTypeSelected() {
    return this.selectedMethod != 'card-on-file' && this.selectedMethod != 'out-of-band'
  }

  resetFramepay(selectedMethod) {
    this.submitPaymentButtonTarget.setAttribute('disabled', '')

    this.mountedFrames.forEach((item) => item.destroy())
    this.mountedFrames = []

    if (selectedMethod === 'ach') {
      this.achType = Rebilly.bankAccount.mount(this.bankTypeMountElementTarget, 'bankAccountType')
      this.achNumber = Rebilly.bankAccount.mount(this.bankNumberMountElementTarget, 'bankAccountNumber')
      this.achRouting = Rebilly.bankAccount.mount(this.bankRoutingMountElementTarget, 'bankRoutingNumber')

      this.achNumber.on('change', this.handleRebillyError.bind(this))
      this.achRouting.on('change', this.handleRebillyError.bind(this))
      this.mountedFrames.push(this.achType, this.achNumber, this.achRouting)
    }

    if (selectedMethod === 'payment-card') {
      this.card = Rebilly.card.mount(this.cardMountElementTarget)
      this.card.on('change', this.handleRebillyError.bind(this))
      this.mountedFrames.push(this.card)
    }

    if (
      selectedMethod === 'card-on-file' &&
      this.savedPaymentMethodFieldTarget.value !== '' &&
      this.savedPaymentMethodFieldTarget.value !== null
    ) {
      this.submitPaymentButtonTarget.removeAttribute('disabled')
    }

    if (selectedMethod === 'out-of-band' && this.isRadioGroupChecked(this.outOfBandElementTargets)) {
      this.submitPaymentButtonTarget.removeAttribute('disabled')
    }
  }

  isRadioGroupChecked(radioButtons) {
    for (let i = 0; i < radioButtons.length; i++) {
      if (radioButtons[i].checked) {
        return true
      }
    }

    return false
  }

  handleRebillyError(event) {
    if (event.error) {
      this.errorWrapperTarget.classList.add('is-active')
      this.errorMessageHolderTarget.innerText = event.error.details.pop()
    } else if (event.valid) {
      this.errorWrapperTarget.classList.remove('is-active')
    }
    if (event.completed && !this.paymentSubmitting) {
      if (this.selectedMethod.value == 'ach' && !this.validateBankAccount(event)) return

      this.submitPaymentButtonTarget.removeAttribute('disabled')
    } else {
      this.submitPaymentButtonTarget.setAttribute('disabled', '')
    }
  }

  initializePaymentMethodGroups() {
    const container = document.querySelector('.payment-method-details-group')

    // Close all other details when one is shown
    container.addEventListener('sl-show', (event) => {
      ;[...container.querySelectorAll('sl-details')].map((details) => {
        details.open = event.target === details
        if (details.open) {
          this.selectedMethod = details.dataset['paymentMethodType']
          this.resetFramepay(this.selectedMethod)
        }
      })
    })
  }

  addressCardClick(e) {
    window.dog = this.addressCardTarget
    this.knownAddress = JSON.parse(e.currentTarget.dataset['knownAddress'])

    e.preventDefault()
    e.stopPropagation()

    this.addressCardTargets.forEach((element) => {
      element.classList.remove('bg-blue-100')
    })

    e.currentTarget.classList.add('bg-blue-100')
  }

  async fetchAddresses() {
    const request = new FetchRequest('GET', this.knownAddressesUrlValue, {
      responseKind: 'turbo-stream',
    })

    await request.perform()
  }

  disconnect() {
    console.log('HERE in disconnect')
    if (this.card) {
      this.card.unmount()
    }
    if (this.achType) {
      this.achType.unmount()
      this.achNumber.unmount()
      this.achRouting.unmount()
    }
  }
}
/* eslint-enable no-undef */
