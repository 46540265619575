import AwsS3 from '@uppy/aws-s3'
import XHR from '@uppy/xhr-upload'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

import en_US from '@uppy/locales/lib/en_US'
import es_ES from '@uppy/locales/lib/es_ES'
import pt_BR from '@uppy/locales/lib/pt_BR'
import pt_PT from '@uppy/locales/lib/pt_PT'

const localeMapping = {
  en: en_US,
  en_US: en_US,
  es: es_ES,
  es_ES: es_ES,
  pt: pt_PT,
  pt_BR: pt_BR,
  pt_PT: pt_BR,
}

export const fileData = (file, id) => {
  return {
    id: id,
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  }
}

/**
 * Convert the uploaded file to stringified JSON
 *
 * @param {string} uploadServer
 * @param {Object} file
 * @param {Object} response
 * @returns {string} the JSON stringified file data
 */
export const uploadedFileData = (uploadServer, file, response) => {
  if (uploadServer == 's3') {
    const id = file.meta['key'].match(/^cache\/(.+)/)[1] // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else if (uploadServer == 's3_multipart') {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1] // eslint-disable-line no-useless-escape

    return JSON.stringify(fileData(file, id))
  } else {
    return JSON.stringify(response.body)
  }
}

export const configureUploadServer = (uploadServer, uppyInstance) => {
  if (uploadServer == 's3') {
    uppyInstance.use(AwsS3, {
      companionUrl: '/account/', // will call Shrine's presign endpoint mounted on `/s3/params`
    })
  } else if (uploadServer == 's3_multipart') {
    uppyInstance.use(AwsS3Multipart, {
      companionUrl: '/account/', // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    })
  } else {
    uppyInstance.use(XHR, {
      endpoint: '/account/upload', // Shrine's upload endpoint
    })
  }
}

export const getUppyLocale = (locale) => {
  const localeData = localeMapping[locale] || en_US
  localeData.strings['poweredBy'] = localeData.strings['poweredBy'].slice(0, -8)
  return localeData
}
