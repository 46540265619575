import { Controller } from '@hotwired/stimulus'
import Bridge from 'turbo_native/bridge'

export default class extends Controller {
  signOut(event) {
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      Bridge.postMessage('signOut')
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf('Turbo Native') !== -1
  }
}
