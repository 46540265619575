import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['radio', 'relativeField', 'daterangeField']

  static classes = ['hidden']

  connect() {
    this.getBookingTypeRadio()
  }

  /**
   * Retrieves the selected booking type from radio buttons and updates the view accordingly.
   */
  getBookingTypeRadio() {
    const selectedRadioType = this.radioTargets.find((radio) => radio.checked).dataset.type
    if (!selectedRadioType) return this.setInitialState()

    if (selectedRadioType == 'relative') this.showRelative()
    if (selectedRadioType == 'daterange') this.showDaterange()
    if (selectedRadioType == 'indefinite') this.showIndefinite()
  }

  /**
   * Handles changes in booking type radio buttons.
   * @param {Event} event - The event object representing the radio button change event.
   */
  handleBookingTypeChange(event) {
    const bookingType = event.target.dataset.type

    // Show the appropriate booking type based on the changed radio button
    if (bookingType == 'relative') this.showRelative()
    if (bookingType == 'daterange') this.showDaterange()
    if (bookingType == 'indefinite') this.showIndefinite()
  }

  /**
   * Sets the initial state when no radio button is checked.
   */
  setInitialState() {
    this.radioTargets[0].checked = true // Check the first radio button by default
    this.showRelative() // Show relative booking type by default
  }

  /**
   * Displays the relative booking type field and hides the date range field.
   */
  showRelative() {
    this.relativeFieldTarget.classList.remove(this.hiddenClass)
    this.daterangeFieldTarget.classList.add(this.hiddenClass)
  }

  /**
   * Displays the date range booking type field and hides the relative field.
   */
  showDaterange() {
    this.daterangeFieldTarget.classList.remove(this.hiddenClass)
    this.relativeFieldTarget.classList.add(this.hiddenClass)
  }

  /**
   * Hides both relative and date range booking type fields for indefinite booking.
   */
  showIndefinite() {
    this.relativeFieldTarget.classList.add(this.hiddenClass)
    this.daterangeFieldTarget.classList.add(this.hiddenClass)
  }
}
