import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['paidTrialFieldsWrapper', 'freeTrialFieldsWrapper']

  handleToggle(event) {
    if (event.detail.activeButton.id == 'paid-trial-btn') {
      this.freeTrialFieldsWrapperTarget.classList.add('hidden')
      this.paidTrialFieldsWrapperTarget.classList.remove('hidden')
      this.paidTrialFieldsWrapperTarget.disabled = false
      this.freeTrialFieldsWrapperTarget.disabled = true
    } else if (event.detail.activeButton.id == 'free-trial-btn') {
      this.paidTrialFieldsWrapperTarget.classList.add('hidden')
      this.freeTrialFieldsWrapperTarget.classList.remove('hidden')
      this.freeTrialFieldsWrapperTarget.disabled = false
      this.paidTrialFieldsWrapperTarget.disabled = true
    }
  }
}
