import ApplicationController from '../application_controller'
import { debounce } from 'lodash'

export default class extends ApplicationController {
  static targets = ['lineItem', 'input', 'form', 'loadingTemplate', 'purchasingTemplate', 'rate', 'package']

  static values = {
    packageWeights: Object,
  }

  connect() {
    this.showLoadingState()
    this.updateWeight()
  }

  lineItemTargetConnected() {
    this.updateWeight()
  }

  handleSubmit(event) {
    for (const key of event.detail.formSubmission.formData.keys()) {
      if (key === 'fulfillment[selected_rate]') {
        this.showPurchasingState()
      }
    }
  }

  showLoadingState() {
    document.getElementById('shipping_quotes_div').innerHTML = this.loadingTemplateTarget.innerHTML
  }

  showPurchasingState() {
    document.getElementById('shipping_quotes_div').innerHTML = this.purchasingTemplateTarget.innerHTML
  }

  itemsWeight() {
    let totalItemsWeight = 0
    this.lineItemTargets.forEach((lineItem) => {
      const weightInLbs = parseFloat(lineItem.dataset.lineItemWeightInLbs)
      const quantityInput = lineItem.querySelector('input[max]')

      totalItemsWeight += parseInt(quantityInput.value) * weightInLbs
    })
    return totalItemsWeight
  }

  packageWeight() {
    if (!this.packageTarget.value) {
      return 0
    }
    return parseFloat(this.packageWeightsValue[parseInt(this.packageTarget.value)])
  }

  calculateTotalWeight() {
    return (this.packageWeight() + this.itemsWeight()).toFixed(2)
  }

  updateWeight() {
    this.inputTarget.value = this.calculateTotalWeight()
    this.getQuotes()
  }

  getQuotesDebounced = debounce(this.getQuotes, 1000)

  async getQuotes() {
    this.showLoadingState()
    this.formTarget.requestSubmit()
  }

  selectRate(e) {
    const selectedRateTarget = e.target.closest('.shippo-rate')
    this.rateTargets.forEach((rateTarget) => {
      if (rateTarget !== selectedRateTarget) {
        rateTarget.classList.add('hidden')
      } else {
        rateTarget.querySelector('.purchase-button').classList.remove('hidden')
        rateTarget.querySelector('.cancel-button').classList.remove('hidden')
      }
    })
  }

  deselectRate(e) {
    const selectedRateTarget = e.target.closest('.shippo-rate')
    this.rateTargets.forEach((rateTarget) => {
      if (rateTarget !== selectedRateTarget) {
        rateTarget.classList.remove('hidden')
      } else {
        rateTarget.querySelector('.purchase-button').classList.add('hidden')
        rateTarget.querySelector('.cancel-button').classList.add('hidden')
      }
    })

    // We use a timeout here so that the label click doesn't immediately set it back to true.
    setTimeout(() => {
      selectedRateTarget.querySelector('input[type=radio]').checked = false
    }, 1)
  }
}
