import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['popup']

  show() {
    if (this.hasPopupTarget) {
      this.popupTarget.setAttribute('active', '')
    }
  }

  hide() {
    if (this.hasPopupTarget) {
      this.popupTarget.removeAttribute('active')
    }
  }
}
