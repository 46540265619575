window.Turbo.setConfirmMethod((message, element, submitter) => {
  /*  
    When using data-turbo-confirm on an anchor link,
    submitter is undefined, leaving no way to access
    the dataset on the element on which data-turbo-confirm is attached.
    This is a work-around to infer the original anchor link that was clicked.
  */
  let originalElement = submitter || element
  /* If submitter has been polyfilled as an INPUT tag by form-request-submit-polyfill */
  /* https://github.com/hotwired/turbo/blob/main/src/polyfills/submit-event.ts */
  if (!submitter || submitter.tagName == 'INPUT') {
    let matchingElements = document.querySelectorAll(
      `a[data-turbo-confirm="${CSS.escape(message)}"][data-turbo-method="${element.getAttribute('method')}"]`
    )

    const matchedElement = [...matchingElements].find((el) => {
      let originalElementHrefWithoutParams = new URL(el.href).pathname
      return element.action.includes(originalElementHrefWithoutParams)
    })

    if (matchedElement) {
      originalElement = matchedElement
    }
  }
  const {
    type,
    title,
    turboConfirm: description,
    cancel,
    submit,
    turboConfirmDetail: eventDetail,
    guardPhrase,
    htmlSafeDescription,
    initiator,
  } = originalElement.dataset

  let dialog = document.getElementById('turbo-confirm')

  if (type) {
    dialog.setAttribute('data-shoelace--confirm-type-value', type)
  }
  if (title) {
    dialog.setAttribute('data-shoelace--confirm-title-value', title)
  }
  if (description) {
    dialog.setAttribute('data-shoelace--confirm-description-value', description)
  }
  if (cancel) {
    dialog.setAttribute('data-shoelace--confirm-cancel-value', cancel)
  }
  if (submit) {
    dialog.setAttribute('data-shoelace--confirm-submit-value', submit)
  }
  if (eventDetail) {
    dialog.setAttribute('data-shoelace--confirm-event-detail-value', eventDetail)
  }
  if (guardPhrase) {
    dialog.setAttribute('data-shoelace--confirm-guard-phrase-value', guardPhrase)
  }
  if (htmlSafeDescription) {
    dialog.setAttribute('data-shoelace--confirm-html-safe-description-value', htmlSafeDescription)
  }
  if (initiator) {
    dialog.setAttribute('data-shoelace--confirm-initiator-value', initiator)
  }

  dialog.show()

  return new Promise((resolve) => {
    dialog.addEventListener(
      'shoelace--confirm:confirm',
      (event) => {
        resolve(event.detail.confirm)
      },
      { once: true }
    )
  })
})
