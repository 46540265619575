import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['modal', 'iframe']

  open({ params: { src } }) {
    this.iframeTarget.src = src
    this.openModal()
  }

  reset() {
    this.iframeTarget.src = ''
  }

  openModal() {
    this.modalTarget.show()
  }

  closeModal() {
    this.modalTarget.hide()
  }
}
