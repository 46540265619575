import ApplicationController from '../../application_controller'
import $ from 'jquery'

export default class extends ApplicationController {
  static targets = ['select']
  hiddenElement
  connect() {
    $(this.selectTarget).on('change', () => {
      this.submitBroadcastRehydrateForm()
    })
  }

  submitBroadcastRehydrateForm = () => {
    this.addHiddenInput('rehydrate', true)
    this.element.requestSubmit()
    this.hiddenElement.remove()
  }

  addHiddenInput = (name, value) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.id = name
    input.name = name
    input.value = value
    this.element.appendChild(input)
    this.hiddenElement = input
  }
}
