import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['strengthIndicator', 'strengthMeter']

  connect() {
    this.calculateStrength = this.debounce(this.calculateStrength.bind(this), 600)
  }

  async calculateStrength(event) {
    const text = event.target.value
    let qualityScore
    let strength = ''

    if (text.length > 10) {
      qualityScore = await this.checkQuality(text)
    } else {
      qualityScore = 0
    }

    if (qualityScore == 0) {
      this.strengthMeterTarget.classList.remove('very-weak', 'weak', 'good', 'excellent')
      this.strengthIndicatorTarget.textContent = 'Quality'
    } else {
      if (qualityScore < 25) {
        strength = 'Very Weak'
        this.strengthMeterTarget.classList.remove('weak', 'good', 'excellent')
        this.strengthMeterTarget.classList.add('very-weak')
      } else if (qualityScore >= 25 && qualityScore < 50) {
        strength = 'Weak'
        this.strengthMeterTarget.classList.remove('very-weak', 'good', 'excellent')
        this.strengthMeterTarget.classList.add('weak')
      } else if (qualityScore >= 50 && qualityScore < 75) {
        strength = 'Good'
        this.strengthMeterTarget.classList.remove('very-weak', 'weak', 'excellent')
        this.strengthMeterTarget.classList.add('good')
      } else {
        strength = 'Excellent'
        this.strengthMeterTarget.classList.remove('weak', 'good', 'very-weak')
        this.strengthMeterTarget.classList.add('excellent')
      }

      this.strengthIndicatorTarget.textContent = `${strength}`
    }
  }

  async checkQuality(text) {
    const offerText = text
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content')

    try {
      const response = await fetch('/smart_funnels/offer_quality', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ offer: offerText }),
      })

      const data = await response.json()
      return parseInt(data['quality_score'])
    } catch (error) {
      console.error('There was a problem with the request:', error)
    }
  }

  debounce(func, wait) {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }
}
