import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['weightAndUnitHiddenFieldset', 'weightAndUnitFieldset']
  static values = {
    eventSourceElementId: String,
  }

  handleToggle(event) {
    if (event.detail.sourceEl.id == this.eventSourceElementIdValue) {
      $(this.weightAndUnitHiddenFieldsetTarget).prop('disabled', (_, val) => !val)
      $(this.weightAndUnitFieldsetTarget).prop('disabled', (_, val) => !val)

      const e = new Event('change', { bubbles: true })
      this.element.dispatchEvent(e)
    }
  }
}
