import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['valueInput']
  static values = { action: String }

  connect() {
    if (this.actionValue === 'Add Attribute') {
      this.showValueInput()
    } else {
      this.hideValueInput()
    }
  }

  changeField(event) {
    const action = event.target.value
    if (action === 'Add Attribute') {
      this.showValueInput()
    } else {
      this.hideValueInput()
    }
  }

  showValueInput() {
    this.show(this.valueInputTarget)
  }

  hideValueInput() {
    this.hide(this.valueInputTarget)
  }

  hide(element) {
    element.classList.add('hidden')
  }

  show(element) {
    element.classList.remove('hidden')
  }
}
