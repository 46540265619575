import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['icon', 'spinner']

  static classes = ['hidden']

  showLoadingIndicator(event) {
    if (this.hasIconTarget) {
      this.iconTarget.classList.add(...this.hiddenClasses)
    }
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove(...this.hiddenClasses)
    }
  }

  hideLoadingIndicator(event) {
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove(...this.hiddenClasses)
    }
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add(...this.hiddenClasses)
    }
  }
}
