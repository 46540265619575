/*
  Adds a loading state class to turbo-enabled links that match
  the user-configured 'loadable' class name.

  Simply add the configured 'loadable' class (configured via the classes API)
  to any turbo-enabled link and this controller will add the 'activeLink' class
  to that link while the targeted turbo frame is loading.

  On a wrapping element or the link itself:
    <body data-controller="turbo-frame-link"
          data-turbo-frame-link-active-class="is-active"
          data-turbo-frame-link-loadable-class="loadable"
          data-action="turbo:click->turbo-frame-link#toggle
          turbo:frame-render@window->turbo-frame-link#toggle"
    >
      <a href="path/to/resource" data-turbo-frame="dialog" class="loadable"</a>
    </body>
*/
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static classes = ['activeLink', 'loadable']

  toggle(event) {
    if (event.target.dataset.turboFrame && event.target.classList.contains(this.loadableClass)) {
      this.activeLink = event.target
      this.originalLinkContent = event.target.innerHTML
      this.originalLinkWidth = event.target.offsetWidth
      event.target.classList.add(...this.activeLinkClasses)
      event.target.style.minWidth = `${this.originalLinkWidth}px`
      event.target.innerHTML = ''
    } else if (this.activeLink) {
      this.activeLink.innerHTML = this.originalLinkContent
      this.activeLink.classList.remove(...this.activeLinkClasses)
      this.activeLink = null
    }
  }
}
