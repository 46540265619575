import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['value', 'valueHalf']
  updateText(event) {
    if (this.hasValueTarget) {
      this.valueTargets.map((valueTarget) => {
        valueTarget.innerHTML = event.target.value
      })
    }

    if (this.hasValueHalfTarget) {
      this.valueHalfTargets.map((valueHalfTarget) => {
        valueHalfTarget.innerHTML = event.target.value / 2
      })
    }
  }
}
