import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['menu', 'trigger']

  static values = {
    storageKeyPrefix: { type: String, default: 'column-toggle-states' },
    storageKeyId: String,
    storageKeySuffix: String,
    menuItemClasses: {
      type: String,
      default: 'flex py-2 px-1 truncate items-center text-left cursor-pointer rounded hover:bg-gray-100',
    },
    checkboxClasses: { type: String, default: 'h-4 w-4' },
    labelClasses: { type: String, default: 'ml-2 block text-sm cursor-pointer w-full' },
    checkboxIdPrefix: { type: String, default: 'modify-column' },
  }

  display = {
    shown: 'table-cell',
    hidden: 'none',
  }

  /***** Lifecycle methods *****/
  connect() {
    this.abortController = new AbortController()
    this.columnStates = null
    this.#loadColumnStates()
    this.#setupMutationObserver()

    document.addEventListener('turbo:render', this.#handleTurboRender.bind(this), {
      signal: this.abortController.signal,
    })

    // If wrapped in a cable-ready-updates-for element, listen for updates
    this.element
      .closest('cable-ready-updates-for')
      ?.addEventListener('cable-ready:after-update', this.#handleCableReadyUpdate.bind(this), {
        signal: this.abortController.signal,
      })
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }

    this.abortController.abort()
  }

  /***** Public methods *****/
  buildMenu() {
    if (this.menuTarget.textContent.trim() !== '') return

    // Build initial state if we don't have one yet
    if (!this.columnStates) {
      this.#buildInitialState()
    }

    const fragment = document.createDocumentFragment()

    Object.entries(this.columnStates).forEach(([columnText, state]) => {
      const idText = `${this.checkboxIdPrefixValue}-${columnText.toLowerCase()}`

      const div = document.createElement('div')
      div.className = this.menuItemClassesValue

      const input = document.createElement('input')
      input.id = idText
      input.dataset.index = state.index
      input.dataset.action = 'input->column-toggle#toggle'
      input.type = 'checkbox'
      input.className = this.checkboxClassesValue
      input.name = 'column_id'
      input.value = columnText
      input.checked = state.visible

      const label = document.createElement('label')
      label.htmlFor = idText
      label.className = this.labelClassesValue
      label.textContent = columnText

      div.appendChild(input)
      div.appendChild(label)
      fragment.appendChild(div)
    })

    this.menuTarget.appendChild(fragment)
  }

  toggle(event) {
    const columnIndex = parseInt(event.target.dataset.index)
    const columnText = event.target.value

    if (!this.columnStates || !this.columnStates[columnText]) return

    // Update in-memory state
    this.columnStates[columnText].visible = event.target.checked

    // Apply change to DOM
    const cells = this.#getColumnCells(columnIndex)
    cells.forEach((cell) => {
      this.#setColumnVisibility(cell, event.target.checked)
    })

    // Persist changes to localStorage
    this.#persistColumnStates()
  }

  reset() {
    localStorage.removeItem(this.storageKey)
    this.columnStates = null

    const allCells = this.element.querySelectorAll('th, td')
    allCells.forEach((cell) => cell.style.removeProperty('display'))

    this.menuTarget.innerHTML = ''
    this.buildMenu()
  }

  /***** Private methods *****/
  #setupMutationObserver() {
    const tbody = this.element.querySelector('tbody')
    if (!tbody) return

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeName === 'TR') {
            this.#syncNewRow(node)
          }
        })
      })
    })

    this.observer.observe(tbody, { childList: true })
  }

  #handleTurboRender() {
    this.#reloadColumnStates()
  }

  #handleCableReadyUpdate() {
    this.#reloadColumnStates()
  }

  #reloadColumnStates() {
    if (!this.hasMenuTarget) return

    requestAnimationFrame(() => {
      this.menuTarget.innerHTML = ''
      this.#loadColumnStates()
    })
  }

  #syncNewRow(row) {
    if (!this.columnStates) return

    Object.values(this.columnStates).forEach((state) => {
      const cell = row.querySelector(`td:nth-child(${state.index})`)
      if (cell) {
        this.#setColumnVisibility(cell, state.visible)
      }
    })
  }

  #buildInitialState() {
    const headers = this.element.querySelectorAll('th:not(:last-child):not(.bulk-actions-checkbox-cell)')
    if (!headers.length) return

    // To calculate the index of each column, we need to know how many elements are in the header row
    const headerElements = Array.from(headers[0].parentElement.children)

    this.columnStates = {}
    headers.forEach((header) => {
      const textValue = header.textContent.trim()
      if (textValue.replace(/\s/g, '').length < 1) return

      // +1 because we use `nth-child` to find the dom index which is 1-based
      const headerIndex = headerElements.indexOf(header) + 1

      this.columnStates[textValue] = {
        index: headerIndex,
        visible: window.getComputedStyle(header).display !== 'none',
      }
    })

    this.#persistColumnStates()
  }

  #persistColumnStates() {
    if (!this.columnStates) return
    localStorage.setItem(this.storageKey, JSON.stringify(this.columnStates))
  }

  #loadColumnStates() {
    try {
      const savedStates = localStorage.getItem(this.storageKey)
      if (!savedStates) return

      const storedStates = JSON.parse(savedStates)

      // Get current headers for validation
      const headers = this.element.querySelectorAll('th:not(:last-child):not(.bulk-actions-checkbox-cell)')
      if (!headers.length) return

      const headerElements = Array.from(headers[0].parentElement.children)
      const headerMap = new Map()

      // Build a map of header text to current index
      headers.forEach((header) => {
        const text = header.textContent.trim()
        if (text.replace(/\s/g, '').length > 0) {
          headerMap.set(text, headerElements.indexOf(header) + 1)
        }
      })

      // Validate stored states against current headers
      const isValid = Object.entries(storedStates).every(([columnText, state]) => {
        const currentIndex = headerMap.get(columnText)
        return currentIndex && currentIndex === state.index
      })

      // Check if all current headers exist in stored state
      const allHeadersExist = Array.from(headerMap.keys()).every((text) => storedStates[text])

      if (!isValid || !allHeadersExist) {
        localStorage.removeItem(this.storageKey)
        this.columnStates = null
        return
      }

      this.columnStates = storedStates
      this.#applyColumnStates()
    } catch (error) {
      console.error('Failed to load column states:', error)
      localStorage.removeItem(this.storageKey)
      this.columnStates = null
    }
  }

  #applyColumnStates() {
    if (!this.columnStates) return

    Object.entries(this.columnStates).forEach(([, state]) => {
      const cells = this.#getColumnCells(state.index)
      cells.forEach((cell) => {
        this.#setColumnVisibility(cell, state.visible)
      })
    })
  }

  #getColumnCells(columnIndex) {
    return this.element.querySelectorAll(`tr th:nth-child(${columnIndex}), tr td:nth-child(${columnIndex})`)
  }

  #setColumnVisibility(cell, isVisible) {
    cell.style.display = isVisible ? this.display.shown : this.display.hidden
  }

  get storageKey() {
    const prefix = this.storageKeyPrefixValue
    const suffix = this.storageKeySuffixValue || ''

    // Explicitly set storage key id in case the same table is used on multiple pages
    if (this.hasStorageKeyIdValue) {
      return [prefix, this.storageKeyIdValue, suffix].filter(Boolean).join('::')
    }

    // Use table id if available, otherwise fallback to pathname
    const identifier = this.element.id || `path::${window.location.pathname}`
    return [prefix, identifier, suffix].filter(Boolean).join('::')
  }
}
