// TODO: Export generateUrlFromDOM from the upstream:
//       https://github.com/anycable/anycable-client/blob/master/packages/web/index.js

const metaPrefixes = ['cable', 'action-cable']

const defaultUrl = '/cable'

/* eslint-disable consistent-return */
const fetchMeta = (doc, key) => {
  for (let prefix of metaPrefixes) {
    let element = doc.head.querySelector(`meta[name='${prefix}-${key}']`)

    if (element) {
      return element.getAttribute('content')
    }
  }
}

const absoluteWSUrl = path => {
  if (path.match(/wss?:\/\//)) return path

  if (typeof window !== 'undefined') {
    let proto = window.location.protocol.replace('http', 'ws')

    return `${proto}//${window.location.host}${path}`
  }

  return path
}

/* eslint-disable consistent-return */
const generateUrlFromDOM = () => {
  if (typeof document !== 'undefined' && document.head) {
    let url = fetchMeta(document, 'url')
    if (url) {
      return absoluteWSUrl(url)
    }
  }

  return absoluteWSUrl(defaultUrl)
}

export default generateUrlFromDOM
