// 🚫 DEFAULT RAILS INCLUDES
// This section represents the default includes for a Rails 6.0.0-rc1 application. Bullet Train's includes and your own
// includes should be specified at the end of the file, not in this section. This helps avoid merge conflicts in the
// future should the framework defaults change.

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './jquery.js'
import Rails from '@rails/ujs'
import '@hotwired/turbo'
import './turbo_custom_renderer.js'
import { encodeMethodIntoRequestBody } from '@hotwired/turbo-rails/app/javascript/turbo/fetch_requests'
import 'form-request-submit-polyfill'
import '@ungap/custom-elements'
import * as ActiveStorage from '@rails/activestorage'
import consumer from 'cf-utils/application_cable'
import { start } from '@anycable/turbo-stream'

import 'channels'
import 'controllers' // stimulus
import 'cable_ready_init' // CableReady & StimulusReflex init
import OnlineUsers from './online_users'
new OnlineUsers(consumer)

Rails.start()
ActiveStorage.start()
start(consumer.cable)

// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default settings for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.

import debounced from 'debounced'
debounced.initialize()

require('account')
require('sprinkles')
require('concerns')
require('electron')
require('turbo_boost')
require('turbo_native')

// For inline use in `app/views/account/onboarding/user_details/edit.html.erb`.
import jstz from 'jstz'
global.jstz = jstz
window.jstz = jstz

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.
import 'lazysizes'

// NOTE: Always show the progress bar in tests as the `wait_for_turbo_frame` helper relies on it.
window.Turbo.setProgressBarDelay(process.env.RAILS_ENV === 'test' ? 0 : 500)

document.addEventListener('turbo:submit-end', (e) => {
  // on a failed form submission, scroll the form to the top
  if (e.detail.success === false) {
    e.target.scrollIntoView(true)
  }
})

// TODO: upstream this generic Turbo shim from the integration package into AnyCable.
addEventListener('turbo:before-fetch-request', encodeMethodIntoRequestBody)

import 'alpine-turbo-drive-adapter'
import 'alpinejs'
import 'account/rails_ujs_overrides'

// Required for AlpineJS Turbo Drive Adapter work around for now
// https://github.com/SimoTod/alpine-turbo-drive-adapter/issues/29#issuecomment-795150055
window.addEventListener('turbo:render', function () {
  window.Alpine.discoverUninitializedComponents((el) => {
    window.Alpine.initializeComponent(el)
  })
})

window.addEventListener('turbo:load', function () {
  setTimeout(function () {
    window.Alpine.discoverUninitializedComponents((el) => {
      window.Alpine.initializeComponent(el)
    })
  }, 1000)
})

import 'chartkick/chart.js'
