import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['waitingFlag', 'form']

  explore(event) {
    event.stopPropagation()

    this.waitingFlagTarget.value = 'false'

    this.formTarget.requestSubmit()
  }

  customize(event) {
    event.stopPropagation()
    this.dispatch('waiting')

    this.waitingFlagTarget.value = 'true'

    this.formTarget.requestSubmit()
  }
}
