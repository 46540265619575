import ApplicationController from './application_controller'
import { patch } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    inputSelector: { type: String, default: '' },
    paramKey: { type: String, default: 'image_id' },
    url: { type: String, default: '' },
  }
  async submit() {
    const value = this.element.querySelector(this.inputSelectorValue).value
    if (!value) return

    let data = {}
    data[this.paramKeyValue] = value

    await patch(this.urlValue, { body: JSON.stringify(data), responseKind: 'turbo-stream' })
  }
}
