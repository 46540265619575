import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['spaceGroup', 'space']

  spaceGroupTargetConnected(element) {
    if (this.spaceGroupTarget.dataset.allAccess == 'true') {
      element.checked = true

      if (this.spaceGroupTarget.dataset.inherited == 'true') {
        element.disabled = true
      }
    } else if (this.allEnabledSpacesChecked()) {
      this.setAllSpacesCheckedState()
    } else if (this.noEnabledSpacesChecked()) {
      this.setNoEnabledSpacesCheckedState()
    } else {
      this.setSomeEnabledSpacesCheckedState()
    }
  }

  spaceTargetConnected(element) {
    if (element.dataset.checked == 'true') {
      element.checked = true

      if (element.dataset.inherited == 'true') {
        element.disabled = true
      }
    } else {
      element.checked = false
    }
  }

  toggleSpaceGroup(event) {
    this.spaceTargets.forEach((space) => {
      if (space.dataset.inherited == 'false') {
        space.checked = event.target.checked
      }
    })
  }

  toggleSpace() {
    if (this.allEnabledSpacesChecked()) {
      this.setAllSpacesCheckedState()
    } else if (this.noEnabledSpacesChecked()) {
      this.setNoEnabledSpacesCheckedState()
    } else {
      this.setSomeEnabledSpacesCheckedState()
    }
  }

  enabledSpaces() {
    let collection = []

    this.spaceTargets.forEach((space) => {
      if (space.dataset.inherited == 'false') {
        collection.push(space)
      }
    })

    return collection
  }

  noEnabledSpacesChecked() {
    let checkedCount = 0

    this.enabledSpaces().forEach((space) => {
      if (space.checked) {
        checkedCount += 1
      }
    })

    return checkedCount == 0
  }

  allEnabledSpacesChecked() {
    let checkedCount = 0
    let spaceCount = 0

    this.enabledSpaces().forEach((space) => {
      spaceCount += 1

      if (space.checked) {
        checkedCount += 1
      }
    })

    return spaceCount !== 0 && checkedCount === spaceCount
  }

  setNoEnabledSpacesCheckedState() {
    this.spaceGroupTarget.checked = false
    this.spaceGroupTarget.indeterminate = false
  }

  setSomeEnabledSpacesCheckedState() {
    this.spaceGroupTarget.checked = true
    this.spaceGroupTarget.indeterminate = true
  }

  setAllSpacesCheckedState() {
    this.spaceGroupTarget.checked = true
    this.spaceGroupTarget.indeterminate = false

    if (this.spaceGroupTarget.dataset.inherited == 'true' && this.spaceGroupTarget.dataset.allAccess == 'true') {
      this.spaceGroupTarget.disabled = true
    } else {
      this.spaceGroupTarget.disabled = false
    }
  }
}
