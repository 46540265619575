import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    productUrl: String,
  }

  redirectToProduct() {
    console.debug(this.productUrlValue)
    window.location = this.productUrlValue
  }
}
