import ApplicationController from './application_controller'
import { useDispatch } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'gallery',
    'galleryItem',
    'galleryItemTemplate',
    'image',
    'field',
    'hiddenFieldsContainer',
    'hiddenFieldTemplate',
  ]

  static values = {
    selectMode: { type: String, default: 'single' },
    selectedIds: Array,
    fieldId: String,
    borderColor: String,
  }

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
  }

  handleSelect(event) {
    const selectedItem = event.currentTarget.closest('div[data-gallery-select-target="galleryItem"]')
    const selectedImageOverlay = selectedItem.querySelector('div[data-gallery-select-target="image"]')

    if (this.selectModeValue == 'multiple') {
      if (selectedImageOverlay.classList.contains('is-selected')) {
        this.unselect([selectedImageOverlay])
      } else {
        this.select(selectedImageOverlay)
      }
    } else {
      if (selectedImageOverlay.classList.contains('is-selected')) {
        this.unselect([selectedImageOverlay])
      } else {
        const selectedImageOverlays = this.imageTargets.filter((image) => image.classList.contains('is-selected'))
        if (selectedImageOverlays.length > 0) {
          this.unselect(selectedImageOverlays)
        }
        this.select(selectedImageOverlay)
      }
    }
  }

  removeSelectedClass(element) {
    element.classList.remove('is-selected', this.borderColor())
    element.parentNode.classList.remove('opacity-100')
  }

  addSelectedClass(element) {
    element.classList.add('is-selected', this.borderColor())
    element.parentNode.classList.add('opacity-100')
  }

  borderColor() {
    return this.borderColorValue ? this.borderColorValue : 'border-blue-600'
  }

  select(item) {
    this.addSelectedClass(item)
    if (this.hasFieldTarget) {
      this.updateHiddenField(item)
    }

    /* Future you: editor or other things may rely on this event. Be careful. */
    this.dispatch('select', {
      item: {
        id: item.dataset.id,
        url: item.dataset.url,
      },
      fieldId: this.fieldIdValue,
    })
  }

  unselect(items = []) {
    items.forEach((item) => {
      this.removeSelectedClass(item)
      if (this.hasFieldTarget) {
        this.updateHiddenField(item)
      }

      /* Future you: editor or other things may rely on this event. Be careful. */
      this.dispatch('unselect', {
        item: {
          id: item.dataset.id,
          url: item.dataset.url,
        },
        fieldId: this.fieldIdValue,
      })
    })
  }

  updateHiddenField(item) {
    if (this.selectModeValue == 'single') {
      this.fieldTarget.value = item.dataset.id
    } else {
      const selectedItems = this.imageTargets.filter((image) => image.classList.contains('is-selected'))

      if (selectedItems.includes(item)) {
        let item = this.fieldTargets.filter((target) => target.value == item.value)
        item.remove()
      } else {
        let clonedHiddenField = this.hiddenFieldTemplateTarget.content.cloneNode(true)
        clonedHiddenField.querySelector('input').value = item.dataset.id
        this.hiddenFieldsContainerTarget.appendChild(clonedHiddenField)
      }
    }
  }

  /* Name? This informs subscribers of final status of selected items */
  close() {
    const selectedItems = this.imageTargets.filter((image) => image.classList.contains('is-selected'))

    this.dispatch('close', {
      items: selectedItems.map((item) => {
        return {
          id: item.dataset.id,
          url: item.dataset.url,
          data: item.dataset,
        }
      }),
      fieldId: this.fieldIdValue,
    })
  }

  insertImage(event) {
    const { id, url } = event.detail.file
    let clonedGalleryItem = this.galleryItemTemplateTarget.content.cloneNode(true)
    let wrapper = clonedGalleryItem.querySelector('[data-gallery-select-target="image"]')
    wrapper.classList.remove('is-selected')
    wrapper.dataset.id = id
    wrapper.dataset.url = url

    let img = clonedGalleryItem.querySelector('img')
    img.src = url

    if (this.galleryItemTargets.length > 0) {
      this.galleryTarget.insertBefore(clonedGalleryItem, this.galleryItemTargets[0])
    } else {
      this.galleryTarget.appendChild(clonedGalleryItem)
    }
  }
}
