import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['conferencingSelect', 'locationInput']
  static values = { location: String }

  connect() {
    if (!this.useLocationSwitch) return

    if (this.locationValue === 'conferencing') {
      this.showConferencingSelect()
    } else {
      this.showLocationInput()
    }
  }

  changeFields(event) {
    if (!this.useLocationSwitch) return

    if (event.target.value === 'conferencing') {
      this.showConferencingSelect()
    } else {
      this.showLocationInput()
    }
  }

  showLocationInput() {
    this.show(this.locationInputTarget)
    this.hide(this.conferencingSelectTarget)
  }

  showConferencingSelect() {
    this.show(this.conferencingSelectTarget)
    this.hide(this.locationInputTarget)
  }

  hide(element) {
    element.classList.add('hidden')
  }

  show(element) {
    element.classList.remove('hidden')
  }

  get useLocationSwitch() {
    return this.hasConferencingSelectTarget && this.hasLocationInputTarget
  }
}
