import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['checkbox']

  static values = {
    updateUrl: String,
    paramName: String,
  }

  update() {
    const checkedIds = []
    this.checkboxTargets.map((cb) => {
      if (cb.checked) checkedIds.push(cb.value)
    })

    let url = new URL(this.updateUrlValue)
    if (checkedIds.length > 0) {
      url.searchParams.set(this.paramNameValue, checkedIds)
    } else {
      url.searchParams.delete(this.paramNameValue)
    }

    get(url, { responseKind: 'turbo-stream' })
  }
}
