import ApplicationController from '../../application_controller'
import moment from 'moment-timezone'
import { convertTZFromRailsToIANA } from '../../../utils/convert_time_zone'

export default class extends ApplicationController {
  static targets = ['sendImmediately', 'sendAt', 'sendAtLocalTime', 'timeZone', 'helpMessage']

  connect() {
    this.evaluateMessage()
  }

  evaluateMessage() {
    if (this.sendImmediatelyTarget.checked) {
      this.showWarningMessage()
    } else {
      this.checkTime()
    }
  }

  checkTime() {
    const sendAt = this.sendAtTarget.value
    const sendAtLocalTime = this.sendAtLocalTimeTarget.value
    // Handle special case
    const timeZone =
      this.timeZoneTarget.value === 'workspace' ? this.timeZoneTarget.dataset.workspaceTz : this.timeZoneTarget.value

    if (sendAt && sendAtLocalTime && timeZone) {
      const convertedTz = convertTZFromRailsToIANA(timeZone)
      const timeString = `${sendAt} ${sendAtLocalTime}`
      const sendAtDateTime = moment.tz(timeString, 'MM/DD/YYYY HH:mm:ss', convertedTz)
      const now = moment()
      const isPast = sendAtDateTime.isBefore(now)

      // If the selected date, time, and timezone are before the current time, display warning
      if (isPast) {
        this.showWarningMessage()
      } else {
        this.hideWarningMessage()
      }
    }
  }

  showWarningMessage() {
    this.helpMessageTarget.classList.remove('hidden')
  }

  hideWarningMessage() {
    this.helpMessageTarget.classList.add('hidden')
  }
}
