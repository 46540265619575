import ApplicationController from '../../application_controller'

class GeoEntity {
  constructor(data, controller) {
    this.controller = controller
    this.data = data
  }

  get id() {
    return this.data.id
  }
}

class Country extends GeoEntity {
  get selected() {
    return (this.selectedAtCountryLevel() || this.allRegionsSelected()) && this.available
  }

  selectedAtCountryLevel() {
    return !this.hasRegions && this.controller.selectedCountryIds.includes(this.id)
  }

  allRegionsSelected() {
    return this.hasRegions && !this.regions.find((region) => !region.selected)
  }

  get hasRegions() {
    return !!this.data.regions.length
  }

  get available() {
    return !this.controller.unavailableCountryIds.includes(this.id)
  }

  get indeterminate() {
    return !this.selected && this.hasRegions && this.regions.find((region) => region.selected)
  }

  get regions() {
    return this.data.regions.map((region) => new Region(region, this.controller)) || []
  }

  render(countryCheckbox) {
    if (!countryCheckbox) {
      countryCheckbox = this.controller.countryTargets.find((c) => parseInt(c.value) === this.id)
    }

    if (countryCheckbox) {
      if (this.selected) {
        countryCheckbox.indeterminate = false
        countryCheckbox.checked = true
      } else if (this.indeterminate) {
        countryCheckbox.indeterminate = true
      } else {
        countryCheckbox.checked = false
      }

      if (this.hasRegions) {
        const countEl = this.controller.selectedCountTargets.find((e) => parseInt(e.dataset.countryId) === this.id)
        if (countEl) {
          countEl.textContent = this.regions.filter((r) => r.selected).length
        }
      }
    }

    this.regions.forEach((region) => region.render())
  }
}

class Region extends GeoEntity {
  get selected() {
    return this.controller.selectedRegionIds.includes(this.id)
  }

  get available() {
    return !this.controller.unavailableRegionIds.includes(this.id)
  }

  render() {
    const regionCheckBox = this.controller.regionTargets.find((r) => r.value == this.id)

    if (regionCheckBox) {
      regionCheckBox.checked = this.selected
    }
  }
}

export default class extends ApplicationController {
  static targets = ['regionIdsInput', 'countryIdsInput', 'country', 'region', 'selectedCount', 'scrollArea']
  static values = {
    shippingOptions: { type: Array, default: [] },
    selectedInOtherZones: { type: Object, default: { countryIds: [], regionIds: [] } },
    selectedThisZone: { type: Object, default: { countryIds: [], regionIds: [] } },
  }

  connect() {
    this.selectedRegionIds = this.selectedThisZoneValue.regionIds
    this.selectedCountryIds = this.selectedThisZoneValue.countryIds
    this.unavailableRegionIds = this.selectedInOtherZonesValue.regionIds
    this.unavailableCountryIds = this.selectedInOtherZonesValue.countryIds

    this.options = this.shippingOptionsValue.map((country) => new Country(country, this))

    this.boundAppeared = this.appeared.bind(this)
    document.addEventListener('futurism:appeared', this.boundAppeared)
    document.addEventListener('turbo-boost:command:finish', this.boundAppeared)
    this.startObservingCheckboxVisibility()

    this.render()
  }

  disconnect() {
    document.removeEventListener('futurism:appeared', this.boundAppeared)
    document.removeEventListener('turbo-boost:command:finish', this.boundAppeared)
    this.stopObservingCheckboxVisibility()
  }

  startObservingCheckboxVisibility() {
    try {
      this.intersectionObserver = new IntersectionObserver(this.updateScrollNavigation.bind(this), {
        root: this.scrollAreaTarget,
        threshold: 0.99,
      })

      this.countryTargets.forEach((countryCheckbox) => {
        this.intersectionObserver.observe(countryCheckbox)
      })
    } catch (e) {
      // Intersection observer not supported.
      // This will still work, but client rendering may be a little slower.
    }
  }

  stopObservingCheckboxVisibility() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }

  updateScrollNavigation(observerRecords) {
    observerRecords.forEach((record) => {
      record.target.dataset.isVisible = record.isIntersecting
    })
  }

  appeared(e) {
    this.stopObservingCheckboxVisibility()
    this.startObservingCheckboxVisibility()
    this.render()
  }

  submit() {
    this.countryIdsInputTarget.value = JSON.stringify(this.selectedCountryIds)
    this.regionIdsInputTarget.value = JSON.stringify(this.selectedRegionIds)
  }

  countryCheckClick(e) {
    const country = this.options.find((country) => country.id === parseInt(e.target.value))

    if (country.selected || country.indeterminate) {
      this.deselectCountry(country)
    } else {
      this.selectCountry(country)
    }

    country.render()
  }

  deselectCountry(country) {
    if (country.hasRegions) {
      country.regions.forEach((region) => {
        this.selectedRegionIds = this.selectedRegionIds.filter((regionId) => regionId !== region.id)
      })
    } else {
      this.selectedCountryIds = this.selectedCountryIds.filter((selectedId) => selectedId !== country.id)
    }
  }

  selectCountry(country) {
    if (country.hasRegions) {
      country.regions.forEach((region) => {
        if (region.available && !this.selectedRegionIds.includes(region.id)) {
          this.selectedRegionIds.push(region.id)
        }
      })
    } else {
      this.selectedCountryIds.push(country.id)
    }
  }

  regionCheckClick(e) {
    const regionId = parseInt(e.target.value)
    const country = this.options.find((country) => country.regions.find((region) => region.id === regionId))
    const region = country.regions.find((region) => region.id === regionId)

    if (region.selected) {
      this.selectedRegionIds = this.selectedRegionIds.filter((regionId) => regionId !== region.id)
    } else {
      this.selectedRegionIds.push(region.id)
    }

    country.render()
  }

  render() {
    // Start by only rendering the state of the first and visible checkboxes for performance.
    this.countryTargets.forEach((checkbox, i) => {
      if (i < 10 || checkbox.dataset.isVisible) {
        const country = this.options.find((country) => country.id === parseInt(checkbox.value))
        country.render(checkbox)
      }
    })

    // Render the rest on the next tick (these are very likely to be outside the modal)
    setTimeout(() => {
      this.countryTargets.forEach((checkbox) => {
        const country = this.options.find((country) => country.id === parseInt(checkbox.value))
        country.render(checkbox)
      })
    }, 1)
  }

  setInputFields() {
    this.countryIdsInputTarget.value = JSON.stringify(this.selectedCountryIds)
    this.regionIdsInputTarget.value = JSON.stringify(this.selectedRegionIds)
  }
}
