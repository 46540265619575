import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['previewContainer', 'preview']

  handleChange(event) {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      if (this.hasPreviewTarget) {
        this.previewTarget.src = reader.result
      } else {
        // eslint-disable-next-line max-len
        this.previewContainerTarget.innerHTML = `<img src="${reader.result}" class="rounded-sm w-full max-w-[256px] object-contain aspect-[3/2]">`
      }
    })

    reader.readAsDataURL(file)
  }
}
