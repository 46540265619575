import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { source: String }
  static targets = ['loading', 'video', 'notFound', 'serverError']

  initialize() {
    this.validateUrl()
  }

  toggle(elem) {
    elem.classList.remove('hidden')
  }

  showVideo() {
    this.toggle(this.videoTarget)
  }

  showNotFound() {
    this.toggle(this.notFoundTarget)
  }

  showServerError() {
    this.toggle(this.serverErrorTarget)
  }

  async doesFileExist(urlToFile) {
    const response = await fetch(urlToFile, {
      method: 'HEAD',
    })

    return response.status
  }

  validateUrl() {
    this.doesFileExist(this.sourceValue).then((status) => {
      if (status) this.loadingTarget.classList.add('hidden')

      if (status == 200) {
        this.showVideo()
      } else if (status == 404 || status == 403) {
        this.showNotFound()
      } else if (status == 500) {
        this.showServerError()
      }
    })
  }
}
