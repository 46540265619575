import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'optionName',
    'originalName',
    'originalNameSubSection',
    'valueInput',
    'variantNameContainer',
    'variantTable',
    'dynamicError',
    'variantCountExceededField',
    'collapsedFormContainer',
  ]

  static values = {
    maxVariants: Number,
  }

  static classes = ['hidden']

  connect() {
    this.originalNameSubSectionTargets.forEach((onsst) => {
      onsst.dataset.valueIndex = this.valueInputTargets.find((vit) => vit.value == onsst.innerText)?.dataset.valueIndex
    })
  }

  strikeVariantName(valueInputTarget) {
    const regEx = new RegExp(`^${valueInputTarget.value.toLowerCase()}$`, 'i')
    for (let onsst of this.originalNameSubSectionTargets) {
      if (regEx.test(onsst.innerText.trim())) {
        if (onsst.closest('div').dataset.isSaved != 'true' && this.canDeleteRow(valueInputTarget, onsst)) {
          onsst.closest('tr').remove()
          valueInputTarget.dataset.isNew = 'true'
        } else {
          if (onsst.dataset.isSaved != 'true') {
            onsst.previousElementSibling.remove()
            onsst.remove()
          } else {
            onsst.closest('div').classList.remove('text-green-500')
            onsst
              .closest('div')
              .querySelectorAll('span')
              .forEach((span) => span.classList.remove('text-green-500'))
            onsst.closest('div').classList.add('line-through', 'text-red-400')
          }
        }
      }
    }
  }

  canDeleteRow(valueInputTarget, originalNameSubSectionTarget) {
    if (this.allOtherVariantNamesStruck(valueInputTarget)) return true
    if (this.hasMultipleNameSubSections(originalNameSubSectionTarget)) return false
    return !this.isFirstValueForOption(valueInputTarget)
  }

  hasMultipleNameSubSections(originalNameSubSectionTarget) {
    return (
      originalNameSubSectionTarget
        .closest('div')
        .querySelectorAll("[data-products--form-target='originalNameSubSectionTargets']").length > 1
    )
  }

  isFirstValueForOption(valueInputTarget) {
    return valueInputTarget.dataset.isFirstValueForOption == 'true'
  }

  allOtherVariantNamesStruck(valueInputTarget) {
    return this.originalNameSubSectionTargets
      .filter((onsst) => onsst.dataset.valueIndex == valueInputTarget.dataset.valueIndex)
      .every((onsst) => onsst.closest('div').classList.contains('line-through'))
  }

  allVariantNamesStruck() {
    return this.originalNameTargets.every((ont) => ont.classList.contains('line-through'))
  }

  deleteOption(event) {
    const valueInputTargets = this.valueInputTargets.filter(
      (vit) => vit.dataset.optionIndex == event.params.optionIndex
    )
    for (const valueInputTarget of valueInputTargets) {
      this.strikeVariantName(valueInputTarget)
      valueInputTarget.dataset.removed = 'true'
    }
    this.validateVariantCount()
  }

  deleteValue(event) {
    const valueInputTarget = this.getCurrentValueTargetByValueIndex(event.params.valueIndex)
    this.strikeVariantName(valueInputTarget)
    valueInputTarget.dataset.removed = 'true'
    this.validateVariantCount()
  }

  addNewVariantName(valueTarget) {
    let valueTargetMatrix = this.buildTargetListForVariantName(valueTarget)
    for (const targetList of valueTargetMatrix) {
      this.variantTableTarget.querySelector('tbody').append(this.buildNewVariantRow(targetList))
    }
    this.validateVariantCount()
  }

  buildTargetListForVariantName(currentValueTarget) {
    // Get a list of all the values for the OTHER options
    // e.g. [
    //        [red, blue,green],
    //        [cotton, silk, leather, polyester, spandex, aluminum],
    //        [sm, md, lg, xl]
    //      ]
    const valuesByOption = this.optionNameTargets
      .filter((ont) => ont.dataset.optionIndex != currentValueTarget.dataset.optionIndex)
      .map((ont) =>
        this.valueInputTargets.filter(
          (vit) =>
            vit.dataset.removed != 'true' && vit.dataset.optionIndex == ont.dataset.optionIndex && vit.value != ''
        )
      )
      .map((valueInputTargets) => valueInputTargets.map((vit) => vit))
      .filter((valueInputTargets) => valueInputTargets.length)

    // Need to add in the new value since we filtered out the inputs in this values option group
    valuesByOption.push([currentValueTarget])

    return this.combineTargetsForVariantName(valuesByOption)
  }

  /*
    NOTE: ref https://stackoverflow.com/a/57015870/1009835
   */
  combineTargetsForVariantName([head, ...tail]) {
    const [headTail, ...tailTail] = tail
    if (!headTail) {
      if (!Array.isArray(head[0])) head[0] = [head[0]]
      return head
    }

    const combined = headTail.reduce((acc, x) => {
      return acc.concat(head.map((h) => [h].concat(x).flat()))
    }, [])

    return this.combineTargetsForVariantName([combined, ...tailTail])
  }

  buildNewVariantRow(targetList) {
    const row = document.createElement('tr')
    const newColumn = document.createElement('td')
    const nameColumn = document.createElement('td')
    const lastColumn = document.createElement('td')
    const newDiv = document.createElement('div')
    const nameDiv = document.createElement('div')

    nameDiv.dataset['products-FormTarget'] = 'originalName'
    nameDiv.classList.add('text-green-500')
    newDiv.classList.add('bg-green-200', 'rounded', 'text-center', 'p-2')
    newDiv.innerText = 'New!'
    lastColumn.classList.add('bg-white')

    for (let i = 0; i < targetList.length; i++) {
      if (i != 0) {
        const separatorSpan = document.createElement('span')
        separatorSpan.innerText = ' / '
        nameDiv.appendChild(separatorSpan)
      }

      const nameSectionSpan = document.createElement('span')
      nameSectionSpan.dataset.valueIndex = targetList[i].dataset.valueIndex
      nameSectionSpan.innerText = targetList[i].value
      nameSectionSpan.dataset['products-FormTarget'] = 'originalNameSubSection'
      nameDiv.appendChild(nameSectionSpan)
    }

    newColumn.appendChild(newDiv)
    nameColumn.appendChild(nameDiv)
    row.appendChild(newColumn)
    row.appendChild(nameColumn)

    // Take up the rest of the space in the row
    row.appendChild(document.createElement('td'))
    row.appendChild(document.createElement('td'))
    row.appendChild(document.createElement('td'))
    row.appendChild(lastColumn)
    return row
  }

  editValues(event) {
    // NOTE: If there are no current variants for a product, the table isn't even rendered
    this.validateVariantCount()

    if (!this.hasVariantTableTarget) return
    const associatedOption = this.optionNameTargets.find((ont) => ont.dataset.optionIndex == event.params.optionIndex)
    const currentValueTarget = this.getCurrentValueTargetByValueIndex(event.params.valueIndex)

    if (associatedOption.dataset.isNew == 'true') {
      this.addNewOption(event, associatedOption, currentValueTarget)
    }

    if (currentValueTarget.dataset.isNew == 'true') {
      this.addNewVariantName(currentValueTarget)
    } else {
      this.originalNameSubSectionTargets
        .filter((onsst) => onsst.innerText == currentValueTarget.dataset.previousValue)
        .forEach((nt) => {
          if (currentValueTarget.value.charAt(currentValueTarget.value.length - 1) == ' ') {
            nt.classList.add('whitespace-pre')
          } else {
            nt.classList.remove('whitespace-pre')
          }
          if (!nt.closest('div').classList.contains('line-through')) {
            nt.innerText = currentValueTarget.value
            nt.classList.add('text-green-500')
            nt.dataset.valueIndex = currentValueTarget.dataset.valueIndex
          }
        })
    }

    associatedOption.isNew = 'false'
    currentValueTarget.dataset.previousValue = currentValueTarget.value
    currentValueTarget.dataset.isNew = 'false'
  }

  addNewOption(event, optionTarget, valueTarget) {
    optionTarget.dataset.isNew = 'false'

    const optionValues = this.valueInputTargets.filter(
      (vit) =>
        vit.dataset.optionIndex == optionTarget.dataset.optionIndex && vit.dataset.isFirstValueForOption == 'true'
    )
    if (!optionValues.length) {
      valueTarget.dataset.isFirstValueForOption = 'true'
    }

    // Allow adding a new option to create a new row
    if (this.allVariantNamesStruck()) return

    this.originalNameTargets.forEach((ont) => {
      if (ont.classList.contains('line-through')) {
        return
      }
      const separatorSpan = document.createElement('span')
      separatorSpan.innerText = ' / '
      ont.appendChild(separatorSpan)

      const nameSectionSpan = document.createElement('span')
      nameSectionSpan.classList.add('text-green-500')
      nameSectionSpan.innerText = valueTarget.value
      nameSectionSpan.dataset['products-FormTarget'] = 'originalNameSubSection'
      nameSectionSpan.dataset.valueIndex = valueTarget.dataset.valueIndex
      ont.appendChild(nameSectionSpan)
    })

    valueTarget.dataset.isNew = 'false'
  }

  initializeValues(event) {
    if (event.detail.state == 'visible') {
      for (let valueInput of this.valueInputTargets) {
        valueInput.dataset.previousValue = valueInput.value
        valueInput.dataset.isNew = valueInput.value == ''
      }
    }
  }

  getCurrentValueTargetByValueIndex(valueIndex) {
    return this.valueInputTargets.find((vit) => vit.dataset.valueIndex == valueIndex)
  }

  validateVariantCount() {
    if (this.variantCount() > this.maxVariantsValue) {
      this.dynamicErrorTarget.classList.remove(this.hiddenClass)
      this.variantCountExceededFieldTarget.value = 'true'
    } else {
      this.dynamicErrorTarget.classList.add(this.hiddenClass)
      this.variantCountExceededFieldTarget.value = 'false'
    }
  }

  variantCount() {
    if (this.originalNameTargets.length === 0) {
      // This is the case when no variants exist yet, and they are not rendered dynamically.
      // In this case we can calculate based on just the number of value combinations.
      let count = 1
      this.collapsedFormContainerTargets.forEach((cfc) => {
        count = count * cfc.querySelectorAll('.nested-value').length
      })
      return count
    }
    return this.originalNameTargets.filter(
      (ont) => !ont.classList.contains('line-through') && !ont.closest('tr').querySelector('.fa-undo')
    ).length
  }
}
