import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['output']
  static classes = ['archive']
  static values = { disabled: Boolean }

  connect() {
    if (this.disabledValue) {
      // Disable input fields within fieldset
      this.outputTarget.disabled = true

      // Change text color to look disabled
      const inputs = this.outputTarget.querySelectorAll('input, textarea')
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].style.color = '#516E86'
      }

      // Disable links (minus "Unarchive link")
      const links = document.getElementsByClassName('link')
      for (let i = 0; i < links.length; i++) {
        links[i].classList.add('disabled')
      }

      // Disable Add Price button
      const buttons = document.getElementsByClassName('button')
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.add('hidden')
      }

      // Disable dropdowns
      const dropdowns = document.getElementsByClassName('select2')
      for (let i = 0; i < dropdowns.length; i++) {
        dropdowns[i].disabled = true
      }
    }
  }
}
