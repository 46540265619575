import { createConsumer } from '@anycable/web';
import generateUrlFromDOM from './ActionCableGetConfigShim';

export const ANYCABLE_OPTIONS = {
  protocol: 'actioncable-v1-json',
  concurrentSubscribes: false,
}

// Preseve the HTTP context regardles of the domain the socket is connecting to.
//
// This is necessary because the socket connects to a different domain than the host web-page whenever a user
// previews their contact facing pages that are hosted on a custom domain.
//
// NOTE: The HTTP context uses a one-time-use UniversalID (UID) wrapped in a SignedGlobalID (SGID)
//       and is refreshed periodically to ensure reconnects also preserve the HTTP context.
//       This mechanism works for all socket connections irrespective of the use case noted above.
//
// For more context SEE:
// - app/channels/application_cable/connection.rb
// - app/helpers/action_cable/context_helper.rb
// - projects/cf_utils/ApplicationCable/index.js
// - app/javascript/controllers/action_cable_context_controller.js
// - app/javascript/turbo_boost/commands.js
//
function getCableUrlWithCurrentContext() {
  const url = new URL(generateUrlFromDOM());

  const currentContext = document.querySelector('meta[name="action-cable-context"]')?.content;
  if (currentContext) {
    url.searchParams.append('uid', currentContext);
  }

  return url.toString();
}

// Retrieves AnyCable options, possibly including protocol overridden for tests.
//
// For more context SEE:
// - app/helpers/action_cable/protocol_helper.rb
//
function getCableOptions() {
  const currentProtocol = document.querySelector('meta[name="action-cable-protocol"]')?.content;

  return {
    ...ANYCABLE_OPTIONS,
    protocol: currentProtocol || ANYCABLE_OPTIONS.protocol
  };
}

// Ensure the HTTP context is preserved across reconnects for the long-lived connection.
//
async function refreshCableUrlWithCurrentContext(event) {
  window.applicationCableConsumer.cable.transport.setURL(getCableUrlWithCurrentContext());
}

// Reuse a global AnyCable connection across the entire application.
//
function getOrCreateApplicationCableConsumer() {
  if (!window.applicationCableConsumer) {
    window.applicationCableConsumer = createConsumer(
      getCableUrlWithCurrentContext(), getCableOptions()
    );
    window.applicationCableConsumer.cable.on('disconnect', refreshCableUrlWithCurrentContext);
  }

  return window.applicationCableConsumer;
}

const consumer = getOrCreateApplicationCableConsumer();

export default consumer;
