import ApplicationController from '../application_controller'
export default class extends ApplicationController {
  static targets = [
    'openBtn',
    'titleBar',
    'emptyState',
    'imagePreviewContainer',
    'imagePreview',
    'imageTemplate',
    'field',
    'hiddenFieldTemplate',
    'hiddenFieldsContainer',
  ]

  static values = { fieldId: String }

  connect() {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('open_image_gallery')) {
      this.openGallery()
    }
  }

  openGallery() {
    this.openBtnTarget.click()
  }

  handleSelected(event) {
    if (event.detail.fieldId !== this.fieldIdValue) {
      return
    }

    const selectedImages = event.detail.items
    this.fieldTargets.forEach((field) => field.remove())

    selectedImages.forEach(({ id }) => {
      let clonedHiddenField = this.hiddenFieldTemplateTarget.content.cloneNode(true)
      clonedHiddenField.querySelector('input').value = id
      this.hiddenFieldsContainerTarget.appendChild(clonedHiddenField)
    })

    if (selectedImages.length > 0) {
      this.showImagePreview(selectedImages)
    } else {
      this.showEmptyState()
    }

    this.emitChangeEvent()
  }

  handleDelete({
    detail: {
      item: { id: imageId },
    },
  }) {
    if (this.fieldTarget.value !== imageId.toString()) return

    this.reset()
  }

  showEmptyState() {
    if (!this.imagePreviewContainerTarget.classList.contains('hidden')) {
      this.imagePreviewContainerTarget.classList.add('hidden')
    }
    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.classList.remove('hidden')
    }
    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.remove('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.add('hidden')
    }
  }

  showImagePreview(selectedImages) {
    if (!this.emptyStateTarget.classList.contains('hidden')) {
      this.emptyStateTarget.classList.add('hidden')
    }

    this.imagePreviewTarget.innerHTML = ''

    selectedImages.forEach(({ url }) => {
      let previewImage = this.imageTemplateTarget.content.cloneNode(true)
      previewImage.querySelector('img').src = url

      this.imagePreviewTarget.appendChild(previewImage)
    })
    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.add('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.remove('hidden')
    }
    if (this.imagePreviewContainerTarget.classList.contains('hidden')) {
      this.imagePreviewContainerTarget.classList.remove('hidden')
    }
  }

  reset() {
    this.fieldTargets.forEach((target) => (target.value = ''))

    this.emitChangeEvent()
    this.showEmptyState()
  }

  emitChangeEvent() {
    this.hiddenFieldsContainerTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }
}
