import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { closeModalFunction: String }

  // NOTE: Currently the available functions defined to close the modal are...
  //         chooseExistingPage,
  //         addTemplateStep,
  //         chooseExistingProduct,
  //         attachWorkflow,
  //         createNewPageFromTemplate,

  close() {
    parent.closeModalFunctions[this.closeModalFunctionValue]({ reason: 'close-button' })
  }
}
