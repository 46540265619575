import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['imageIdFieldset', 'imageIdHiddenFieldset']
  static values = {
    eventSourceElementId: String,
  }

  handleImageOverrideToggle(event) {
    if (event.detail.sourceEl.id != this.eventSourceElementIdValue) return
    $(this.imageIdFieldsetTarget).prop('disabled', (_, val) => !val)
    $(this.imageIdHiddenFieldsetTarget).prop('disabled', (_, val) => !val)
    this.element.closest('form').dispatchEvent(new Event('change', { bubbles: true }))
  }
}
