import BaseController from './base_controller'

export default class extends BaseController {
  connect() {
    this.element.addEventListener('turbo:click', () => {
      this.element.hide()
    })
  }

  disconnect() {
    this.element.removeEventListener('turbo:click', () => {
      this.element.hide()
    })
  }
}
