import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    behavior: { type: String, default: 'smooth' },
    block: { type: String, default: 'center' },
    inline: { type: String, default: 'nearest' },
  }

  connect() {
    // This forces the event to the bottom of the call stack
    // which helps with animating/rendering shoelace content.
    // If this doesn't work consistently with shoelace elements,
    // consider hooking into the updateComplete event emitted by shoelace
    setTimeout(() => {
      this.element.scrollIntoView({
        behavior: this.behaviorValue,
        block: this.blockValue,
        inline: this.inlineValue,
      })
    })
  }
}
