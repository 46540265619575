import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { name: String, uploaded: String }
  static targets = ['input', 'text', 'remove', 'fileNameField', 'downloadBtn', 'copy']

  toggleTrashIcon() {
    const fileFieldValue = this.inputTarget.value
    const trashIcon = this.removeTarget.classList

    return fileFieldValue || (this.hasUploadedValue && this.uploadedValue != '')
      ? trashIcon.remove('hidden')
      : trashIcon.add('hidden')
  }

  removeCopyIcon() {
    if (this.hasCopyTarget) {
      this.copyTarget.remove()
    }
  }

  updateTextContent(event) {
    const fileName = event.target.files[0].name
    this.fileNameFieldTarget.value = fileName

    if (this.hasDownloadBtnTarget) {
      const downloadBtn = this.downloadBtnTarget.classList
      downloadBtn.remove('hidden')
    }

    if (fileName) this.textTarget.textContent = fileName
    this.toggleTrashIcon()
  }

  removeFile() {
    this.inputTarget.value = ''
    this.uploadedValue = ''
    this.textTarget.textContent = this.nameValue
    if (this.hasDownloadBtnTarget) {
      const downloadBtn = this.downloadBtnTarget.classList
      downloadBtn.add('hidden')
    }
    this.toggleTrashIcon()
    this.removeCopyIcon()
  }
}
