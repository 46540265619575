import ApplicationController from '../application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['form', 'loading', 'table', 'customEffectiveTimeInput']
  static values = {
    url: String,
  }

  async fetchPreview() {
    this.loadingTarget.classList.remove('hidden')
    this.tableTarget.classList.add('hidden')
    const formData = new FormData(this.formTarget)
    const response = await post(this.urlValue, { body: formData, responseKind: 'turbo-stream' })
    this.loadingTarget.classList.add('hidden')
    this.tableTarget.classList.remove('hidden')
  }

  customEffectTimeFetchPreview() {
    if (this.customEffectiveTimeInputTarget.value.length > 0) {
      this.fetchPreview()
    }
  }
}
