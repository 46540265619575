import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['eventHost', 'addButton', 'removeButton', 'conferencingSelect', 'locationInput']
  static values = { maxHosts: Number }

  eventHostTargetConnected() {
    if (this.eventHostTargets.length >= this.maxHostsValue) {
      if (this.hasAddButtonTarget) this.hide(this.addButtonTarget)
    }

    this.manageRemoveButtonState()
  }

  eventHostTargetDisconnected() {
    if (this.eventHostTargets.length < this.maxHostsValue && this.hasAddButtonTarget) {
      this.show(this.addButtonTarget)
    }

    this.manageRemoveButtonState()
  }

  manageRemoveButtonState() {
    if (this.eventHostTargets.length === 1) {
      this.removeButtonTargets.forEach((element) => {
        this.hide(element)
      })
    } else {
      this.removeButtonTargets.forEach((element) => {
        this.show(element)
      })
    }
  }

  hide(element) {
    element.classList.add('hidden')
  }

  show(element) {
    element.classList.remove('hidden')
  }
}
