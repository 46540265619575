import ApplicationController from '../application_controller'
import { MODES } from '../../concerns/image_gallery'

export default class extends ApplicationController {
  static targets = ['gallery', 'galleryItem', 'image', 'galleryItems', 'emptyState', 'counter', 'submitBtn']

  static values = {
    uploadable: Boolean,
    uploadFieldSelector: String,
    selectMode: String,
    selectedIds: Array,
    fieldId: String,
    objectName: String,
    objectNamePlural: String,
  }

  static classes = ['selected', 'unselected', 'disabled']

  initialize() {
    this.selectedItems = []
  }

  handleSelect(event) {
    const selectedItem = event.currentTarget
    const itemHash = this.buildItemHash(selectedItem)

    if (this.selectModeValue == MODES.multiple) {
      this.mutlipleSelection(itemHash)
    } else {
      this.singleSelection(itemHash)
    }
  }

  singleSelection(item) {
    this.select(item)
    this.dispatchSelected()
  }

  select(itemHash) {
    if (this.selectedItems.some((item) => item.id == itemHash.id)) return

    this.selectedItems.push(itemHash)
  }

  getItemSelectedIndex(id) {
    return this.selectedItems.findIndex((item) => item.id == id)
  }

  removeSelectedItemAtIndex(index) {
    this.selectedItems.splice(index, 1)
  }

  mutlipleSelection(itemHash) {
    const selectedIndex = this.getItemSelectedIndex(itemHash.id)

    if (selectedIndex > -1) {
      this.removeSelectedItemAtIndex(selectedIndex)
      this.unmarkGalleryItemAsSelected({ itemHash })
      this.updateCounter()
      this.updateSubmitBtn()
      return
    }

    this.select(itemHash)
    this.markGalleryItemAsSelected({ itemHash })
    this.updateCounter()
    this.updateSubmitBtn()
  }

  markGalleryItemAsSelected({ itemHash = undefined, galleryItem = undefined }) {
    if (itemHash) {
      galleryItem = this.findGalleryItemByHashId(itemHash)
    }

    if (!galleryItem) return

    galleryItem.classList.add(...this.selectedClasses)
    galleryItem.classList.remove(...this.unselectedClasses)
  }

  unmarkGalleryItemAsSelected({ itemHash = undefined, galleryItem = undefined }) {
    if (itemHash) {
      galleryItem = this.findGalleryItemByHashId(itemHash)
    }

    if (!galleryItem) return

    galleryItem.classList.remove(...this.selectedClasses)
    galleryItem.classList.add(...this.unselectedClasses)
  }

  markGalleryItemAsDisabled({ galleryItem }) {
    galleryItem.classList.add(...this.disabledClasses)
  }

  findGalleryItemByHashId({ id }) {
    return this.galleryItemTargets.find((x) => x.dataset.id == id)
  }

  buildItemHash(target) {
    return {
      id: target.dataset.id,
      url: target.dataset.url,
      alt: target.dataset.alt,
      imagehash: target.dataset.imagehash,
      filename: target.dataset.filename,
    }
  }

  updateCounter() {
    const count = this.selectedItems.length
    const text = count == 1 ? this.objectNameValue : this.objectNamePluralValue

    this.counterTarget.textContent = `${count} ${text}`
  }

  updateSubmitBtn() {
    if (this.selectedItems.length >= 1) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  galleryItemTargetConnected(galleryItem) {
    if (this.selectedItems.length == 0) return

    const id = galleryItem.dataset.id
    const itemHash = this.selectedItems.find((item) => item.id == id)

    if (this.selectedIdsValue.includes(id)) {
      this.markGalleryItemAsDisabled({ galleryItem })
    }

    if (!itemHash) return

    this.markGalleryItemAsSelected({ galleryItem })
  }

  handleDelete({ detail: { confirm, destroy, imageId } }) {
    if (!confirm || !destroy || !imageId) return

    const selectedIndex = this.getItemSelectedIndex(imageId)

    if (selectedIndex > -1) {
      this.removeSelectedItemAtIndex(selectedIndex)
      this.updateCounter()
      this.updateSubmitBtn()
    }

    this.dispatch('destroy', {
      detail: {
        item: {
          id: imageId,
        },
      },
    })
  }

  dispatchSelected() {
    this.dispatch('select', {
      detail: {
        items: this.selectedItems,
        fieldId: this.fieldIdValue,
      },
    })
  }

  showGalleryItems() {
    this.galleryItemsTarget.classList.remove('hidden')
    this.emptyStateTarget.remove()
  }
}
