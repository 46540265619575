import ApplicationController from '../application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    url: String,
    retryCount: Number,
    maxRetries: { type: Number, default: 5 },
    interval: { type: Number, default: 5000 },
  }

  timeoutId = null

  connect() {
    if (!this.hasUrlValue) return

    this.poll()
  }

  disconnect() {
    clearTimeout(this.timeoutId)
  }

  poll() {
    get(this.urlValue, { responseKind: 'turbo-stream' })

    ++this.retryCountValue

    if (this.retryCountValue >= this.maxRetriesValue) return

    this.timeoutId = setTimeout(this.poll.bind(this), this.intervalValue)
  }
}
