import ApplicationController from '../application_controller'
import * as CronofyElements from 'cronofy-elements'

// https://docs.cronofy.com/developers/ui-elements/availability-rules/
export default class extends ApplicationController {
  static values = {
    availabilityRuleId: String,
    elementToken: String,
    targetId: String,
    closeAfterSave: { type: Boolean, default: true },
  }

  connect() {
    this.instance = CronofyElements.AvailabilityRules({
      element_token: this.elementTokenValue,
      target_id: this.targetIdValue || this.element.id,
      availability_rule_id: this.availabilityRuleIdValue,
      config: {
        start_time: '08:00',
        end_time: '18:00',
        duration: 30,
      },
      styles: {
        colors: {
          available: '#CFE4FA', //blue-200
          unavailable: '#D7E0E5', //coolGray-200
          primary: '#222e3f', //primary button color
          hairline: '#106BF4', //blue-600
        },
        prefix: 'cf2',
      },
      callback: (res) => {
        if (res.notification.type === 'availability_rule_saved' && this.closeAfterSaveValue) {
          setTimeout(() => {
            this.element.closest('sl-dialog').hide()
          }, 1500)
        }
      },
    })
  }
}
