import { useDispatch } from 'stimulus-use'
import { destroy } from '@rails/request.js'
import IntegryController from './integry_controller'
import { IntegryJS } from '@integry/sdk'

export default class extends IntegryController {
  static values = {
    providerId: String,
    connectedAccountId: String,
    indexPath: String,
  }

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
    this.loadIntegry()
    this.attachEvents()
  }

  attachEvents() {
    this.sdkInstance.init({
      containerId: 'integry-auth-container',
      renderMode: IntegryJS.RenderModes.DETACHED,
    })

    this.sdkInstance.eventEmitter.on('should-load-flow', (data) => {
      this.sdkInstance.renderFlowSetupForm({ flowId: `${data.templateId}`, flowContainerId: 'integry-auth-container' })
    })

    this.sdkInstance.eventEmitter.on('did-add-authorization', (data) => {
      this.saveConnectedAccount(data)
    })

    this.sdkInstance.eventEmitter.on('did-remove-authorization', (data) => {
      this.deleteInstallationByConnectedAccountID(data.authorizationId)
    })
  }

  openApps() {
    this.sdkInstance.renderAccountConnectionModal()
  }

  async deleteInstallation() {
    const response = await destroy(this.destroyPathValue, {
      responseKind: 'json',
    })

    if (response.ok) {
      this.dispatch('closemodal')
      window.location.href = this.indexPathValue
    } else if (response.status >= 400) {
      this.dispatch('closemodal')
      console.log('Integration not found, this is an orphaned Installation record')
    }
  }
}
