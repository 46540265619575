import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['paymentMethod']

  paymentMethodClick(event) {
    this.paymentMethodTargets.forEach((element) => {
      element.classList.remove('bg-blue-100')
    })

    event.currentTarget.classList.add('bg-blue-100')

    const radioButton = event.currentTarget.querySelector('input[type="radio"]')

    if (radioButton) {
      radioButton.checked = true
      radioButton.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
}
