import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['select', 'priceSelect']
  static values = {
    selectedProductsPriceId: String,
    selectedProductsVariantId: String,
  }

  variantPriceMapping = null

  connect() {
    this.variantPriceMapping = JSON.parse(this.selectTarget.dataset.info) // data object

    this.buildPriceSelect(this.filterApplicablePrices())
  }

  handleChange(event) {
    this.buildPriceSelect(this.filterApplicablePrices())
  }

  buildPriceSelect(prices) {
    $(this.priceSelectTarget).empty()

    const productPrices = prices.filter((item) => item.product_price)
    const variantPrices = prices.filter((item) => !item.product_price)

    const selectOptions = []

    if (productPrices.length > 0) {
      selectOptions.push({
        label: 'Product Prices',
        options: productPrices.map((price) => ({
          label: price.variant_price_system_price,
          value: price.variant_price_id,
        })),
      })
    }

    if (variantPrices.length > 0) {
      selectOptions.push({
        label: 'Variant Prices',
        options: variantPrices.map((price) => ({
          label: price.variant_price_system_price,
          value: price.variant_price_id,
        })),
      })
    }

    const selectHtml = selectOptions.map((optgroup, index) => {
      const optionElements = optgroup.options.map((option) => {
        const isSelected = index === 0 ? true : this.selectedProductsPriceIdValue == option.value
        return `<option value="${option.value}" ${isSelected && 'selected'}>${option.label}</option>`
      })

      return `<optgroup label="${optgroup.label}">${optionElements}</optgroup>`
    })

    $(this.priceSelectTarget).append(selectHtml)
    $(this.priceSelectTarget).trigger('change')
  }
  filterApplicablePrices() {
    const selectedVariant = this.selectTarget.value

    if (selectedVariant === undefined) {
      return
    }

    const applicablePrices = this.variantPriceMapping.filter((x) => {
      return x['variant_id'] == selectedVariant
    })

    return applicablePrices
  }
}
