import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['slide', 'nextButton', 'previousButton', 'currentSlideNumber']
  static values = { index: Number }

  initialize() {
    this.showCurrentSlide()
  }

  next() {
    this.indexValue++
  }

  previous() {
    this.indexValue--
  }

  indexValueChanged() {
    this.initSlideTransitions()
    this.updateButtons()
  }

  initSlideTransitions() {
    // use data-action to catch when hide transition is finished to fire showCurrentSlide()
    this.hideShownSlide()
  }

  hideShownSlide() {
    this.slideTargets.forEach((element, index) => {
      if (index != this.indexValue) {
        element.dispatchEvent(new CustomEvent('slide:hide'))
      }
    })
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      if (index == this.indexValue) {
        element.dispatchEvent(new CustomEvent('slide:show'))
      }
    })
    this.currentSlideNumberTarget.textContent = this.indexValue + 1
  }

  updateButtons() {
    this.previousButtonTarget.disabled = this.indexValue === 0
    this.nextButtonTarget.disabled = this.indexValue === this.slideTargets.length - 1
  }
}
