import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['source', 'destination']

  static values = {
    prefix: { type: String, default: '/' },
    joinon: { type: String, default: '-' },
  }

  isOverWritten = false

  toUrlPath(event) {
    if (this.isOverWritten) {
      return
    }

    let sourceValue = event.currentTarget.value
    let url = sourceValue
      .replace(/[^A-Za-z0-9 -]/g, '')
      .replace(/ {2}/g, ' ')
      .trim()
      .toLowerCase()
      .split(' ')
      .join(this.joinonValue)
    this.destinationTarget.value = `${this.prefixValue}${url}`
    this.destinationTarget.dispatchEvent(new Event('change'))
    this.destinationTarget.dispatchEvent(new Event('keyup'))
  }

  override(event) {
    this.isOverWritten = true
    let originalValue = event.currentTarget.value
    this.destinationTarget.value = originalValue.split(' ').join('')
    this.destinationTarget.dispatchEvent(new Event('change'))
  }
}
