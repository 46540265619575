import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static classes = ['icon', 'iconToggled', 'accordionIcon', 'accordionToggledIcon']
  static targets = ['icon', 'label', 'section', 'accordionIcon']
  static values = {
    openLabel: String,
    closeLabel: String,
    isOpenOnPageLoad: Boolean,
  }

  connect() {
    if (this.isOpenOnPageLoadValue) {
      this.toggleAllSections()
      this.setExpandAll()
      this.isOpen = true
    }
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen
  }

  setExpandAll() {
    this.labelTarget.innerHTML = this.closeLabelValue
    this.iconTarget.classList.remove(...this.iconClasses)
    this.iconTarget.classList.add(...this.iconToggledClasses)
    if (this.hasAccordionIconTarget) {
      this.accordionIconTargets.forEach((accordionIcon) => {
        accordionIcon.classList.remove(...this.accordionIconClasses)
        accordionIcon.classList.add(...this.accordionToggledIconClasses)
      })
    }
  }

  setCollapseAll() {
    this.labelTarget.innerHTML = this.openLabelValue
    this.iconTarget.classList.remove(...this.iconToggledClasses)
    this.iconTarget.classList.add(...this.iconClasses)
    if (this.hasAccordionIconTarget) {
      this.accordionIconTargets.forEach((accordionIcon) => {
        accordionIcon.classList.add(...this.accordionIconClasses)
        accordionIcon.classList.remove(...this.accordionToggledIconClasses)
      })
    }
  }

  toggleAllSections() {
    this.toggleIsOpen()

    if (this.isOpen) {
      this.setExpandAll()
    } else {
      this.setCollapseAll()
    }

    this.sectionTargets.forEach((el) => {
      this.isOpen ? el.classList.remove('hidden') : el.classList.add('hidden')
    })
  }

  toggle() {
    let hiddenValues = this.sectionTargets.map((x) => Array.from(x.classList)).flat()

    if (hiddenValues.length == this.sectionTargets.length) {
      this.toggleIsOpen()
      this.setCollapseAll()
    } else if (hiddenValues.length == 0) {
      this.toggleIsOpen()
      this.setExpandAll()
    }
  }
}
