import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['country', 'region']
  static values = { searchUrl: String }

  updateRegionUrlValue(event) {
    const countryId = event.target.value
    $(this.regionTarget).val(null).trigger('change')
    const superSelectController = this.application.getControllerForElementAndIdentifier(
      this.regionTarget.parentElement,
      'fields--super-select'
    )
    superSelectController.searchUrlValue = this.searchUrlValue.replace(/\d+/g, countryId)
  }

  updateRegionUrlValueWithIso2(event) {
    const countryId = event.target.options[event.target.selectedIndex].dataset.id
    $(this.regionTarget).val(null).trigger('change')
    const superSelectController = this.application.getControllerForElementAndIdentifier(
      this.regionTarget.parentElement,
      'fields--super-select'
    )
    superSelectController.searchUrlValue = this.searchUrlValue.replace(/\d+/g, countryId)
  }
}
