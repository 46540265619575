import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['password', 'magicLink', 'passwordTitle', 'magicLinkTitle', 'emailField', 'passwordField']
  static classes = ['hide']

  handleFieldFocus() {
    !this.emailFieldTarget.value ? this.emailFieldTarget.focus() : this.passwordFieldTarget.focus()
  }

  showPassword() {
    this.passwordTarget.classList.remove(...this.hideClasses)
    this.magicLinkTarget.classList.add(...this.hideClasses)
    this.passwordTitleTarget.classList.remove(...this.hideClasses)
    this.magicLinkTitleTarget.classList.add(...this.hideClasses)
    this.passwordFieldTarget.disabled = false
    this.handleFieldFocus()
  }

  showMagicLink() {
    this.passwordTarget.classList.add(...this.hideClasses)
    this.magicLinkTarget.classList.remove(...this.hideClasses)
    this.passwordTitleTarget.classList.add(...this.hideClasses)
    this.magicLinkTitleTarget.classList.remove(...this.hideClasses)
    this.passwordFieldTarget.disabled = true
    this.emailFieldTarget.focus()
  }
}
