import ApplicationController from '../application_controller'
import { SmartFunnelsMixin } from './mixin'

export default class extends ApplicationController {
  static targets = ['tabs', 'mobileTabs']

  connect() {
    Object.assign(this, SmartFunnelsMixin)
    window.addEventListener('step-changed', this.handleTabChange.bind(this))
  }

  handleTabChange(event) {
    this.setTab(event)
  }

  setTab(event) {
    let tab = this.getTabBySelection(event.detail)
    this.tabsTarget.show(tab)
    this.mobileTabsTarget.show(tab)
  }
}
