import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['customDatesWrapper']
  static values = {
    customDatesOption: String
  }
  static classes = ['hidden']
  
  toggleCustomDates(event) {
    const value = event.currentTarget.value
    
    if (!this.hasCustomDatesWrapperTarget) { return }
    
    if (this.customDatesOptionValue == value) {
      this.customDatesWrapperTarget.classList.remove(...this.hiddenClasses)
    } else {
      this.customDatesWrapperTarget.classList.add(...this.hiddenClasses)
    }
  }
}