import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['summary', 'content']

  static values = {
    collapseAnimationDuration: { type: Number, default: 200 },
    collapseAnimationEasing: { type: String, default: 'ease-in' },
    expandAnimationDuration: { type: Number, default: 350 },
    expandAnimationEasing: { type: String, default: 'ease-out' },
  }

  connect() {
    this.animation = null
    this.isClosing = false
    this.isExpanding = false
  }

  handleClick(e) {
    e.preventDefault()

    this.element.style.overflow = 'hidden'

    if (this.isClosing || !this.element.open) {
      this.open()
    } else if (this.isExpanding || this.element.open) {
      this.collapse()
    }
  }

  collapse() {
    this.dispatch('collapse-start', { detail: { element: this.element, bubbles: true } })
    this.isClosing = true

    const startHeight = `${this.element.offsetHeight}px`
    const endHeight = `${this.summaryTarget.offsetHeight}px`

    if (this.animation) {
      this.animation.cancel()
    }

    // Start a WAAPI animation
    this.animation = this.element.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: this.collapseAnimationDurationValue,
        easing: this.collapseAnimationEasingValue,
      }
    )

    this.animation.onfinish = () => this.onAnimationFinish(false, 'collapse-end')
    this.animation.oncancel = () => (this.isClosing = false)
  }

  open() {
    this.element.style.height = `${this.element.offsetHeight}px`
    this.element.open = true

    window.requestAnimationFrame(() => this.expand())
  }

  expand() {
    this.dispatch('expand-start', { detail: { element: this.element, bubbles: true } })
    this.isExpanding = true
    const startHeight = `${this.element.offsetHeight}px`
    const endHeight = `${this.summaryTarget.offsetHeight + this.contentTarget.offsetHeight}px`

    if (this.animation) {
      this.animation.cancel()
    }

    // Start a WAAPI animation
    this.animation = this.element.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: this.expandAnimationDurationValue,
        easing: this.expandAnimationEasingValue,
      }
    )

    this.animation.onfinish = () => this.onAnimationFinish(true, 'expand-end')
    this.animation.oncancel = () => (this.isExpanding = false)
  }

  onAnimationFinish(open, eventName) {
    this.element.open = open
    this.animation = null
    this.isClosing = false
    this.isExpanding = false
    this.element.style.height = this.element.style.overflow = ''
    this.dispatch(eventName, { detail: { element: this.element, bubbles: true } })
  }

  handleExternalExpansion(event) {
    if (!this.element.open) return
    if (event.detail.element !== this.element) {
      this.collapse()
    }
  }
}
