/* eslint-disable react-hooks/rules-of-hooks */

import ApplicationController from './application_controller'
import { useResize } from 'stimulus-use'
import { throttle } from 'lodash'

export default class extends ApplicationController {
  static targets = ['breadcrumb']

  initialize() {
    // throttle the useResize callback
    this.resize = throttle(this.elementResized, 500)
    ;[this.observe, this.unobserve] = useResize(this, { dispatchEvent: false })
  }

  connect() {
    if (!this.hasBreadcrumbTarget) {
      this.unobserve()
    }
  }

  breadcrumbTargetConnected() {
    this.observe()
  }

  breadcrumbTargetDisconnected() {
    this.unobserve()
  }

  elementResized() {
    if (!this.hasBreadcrumbTarget) return

    // Put a max width on the breadcrumb to be about 50% of the header width.
    // This allows a lot of the breadcrumb to be visible without cramping the other content.
    this.breadcrumbTarget.style.maxWidth = `${Math.floor(this.element.clientWidth * 0.5)}px`
  }
}
