import BaseController from './base_controller'

export default class extends BaseController {
  connect() {
    super.connect()

    if (this.element.dataset.autoOpen != 'false') {
      this.element.updateComplete.then(() => {
        if (!this.element.open) {
          this.element.show()

          // Manually dispatch turbo:render event to ensure that MAI script is executed when the drawer is opened.
          window.dispatchEvent(new Event('turbo:render'))
        }
      })
    }
  }

  /**
   * Closes the drawer by hiding the element.
   * @function closeDrawer
   * @returns {void}
   */
  closeDrawer() {
    this.element.hide()
  }
}
