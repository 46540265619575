import ApplicationController from '../application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['checkbox', 'paneWrapper', 'pane']
  static classes = ['hidden', 'paneInvisible']
  static values = {
    showPane: {
      type: Boolean,
      default: false,
    },
    uiSettingsPath: String,
    uiSettingsParam: String,
  }

  connect() {
    this.updatePaneVisibility()
  }

  showPaneValueChanged() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.checked = this.showPaneValue
    }
    this.updatePaneVisibility()
  }

  togglePane() {
    this.showPaneValue = !this.showPaneValue
    this.persistInUiSettings()
  }

  updatePaneVisibility() {
    if (!this.hasPaneWrapperTarget || !this.hasPaneTarget || !this.hasHiddenClass || !this.hasPaneInvisibleClass) {
      return
    }

    if (this.showPaneValue) {
      this.paneWrapperTarget.classList.remove(...this.hiddenClasses)
      window.setTimeout(() => {
        this.paneTarget.classList.remove(...this.paneInvisibleClasses)
      }, 50)
    } else {
      this.paneWrapperTarget.classList.add(...this.hiddenClasses)
      this.paneTarget.classList.add(...this.paneInvisibleClasses)
    }
  }

  persistInUiSettings() {
    if (!this.hasShowPaneValue || !this.hasUiSettingsPathValue || !this.hasUiSettingsParamValue) {
      return
    }

    let body = {
      ui_settings: {},
    }
    body['ui_settings'][this.uiSettingsParamValue] = this.showPaneValue

    const headers = {
      Accept: 'application/json; charset=UTF-8',
      'Content-type': 'application/json; charset=UTF-8',
    }

    post(this.uiSettingsPathValue, {
      headers: headers,
      body: JSON.stringify(body),
    })
  }
}
