import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['dialog']

  trackUpdate(evt) {
    const request = new FetchRequest('PATCH', evt.detail.trackingUrl, {
      body: evt.detail.trackingBody,
      responseKind: 'json',
    })
    request.perform()
  }

  remove() {
    this.dialogTarget.remove()
  }
}
