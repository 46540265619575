import ApplicationController from './application_controller'
import { debounce } from 'lodash'

export default class extends ApplicationController {
  static targets = ['searchDialog', 'form', 'input', 'remote']

  initialize() {
    this.debouncedSearch = debounce(this.submitSearch, 400)
  }

  openSearch({ params: { autoSearch = true } }) {
    this.show()

    if (!autoSearch) return

    this.submitSearch()
  }

  search() {
    this.debouncedSearch()
  }

  submitSearch() {
    this.formTarget.requestSubmit()
  }

  show() {
    this.searchDialogTarget.show()
  }

  hide() {
    this.searchDialogTarget.hide()
  }

  remoteTrigger() {
    this.show()
    this.inputTarget.setAttribute('value', this.remoteTarget.value)
    this.submitSearch()
  }
}
