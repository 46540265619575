import ApplicationController from '../application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['checkbox', 'parentCheckbox', 'selectedCount']
  static values = {
    addVariantsUrl: String,
    selectedOptions: Array,
  }

  initialize() {
    this.selectedVariants = this.selectedOptionsValue.reduce((acc, option) => {
      acc[option.id] = option
      return acc
    }, {})
    this.checkboxTargets.map((checkbox) => this.verifyChecked(checkbox))
  }

  connect() {
    this.updateSelected()
  }

  select(event_or_element) {
    const element = event_or_element.target || event_or_element
    const variantData = this.buildVariantData(element)

    if (element.checked) {
      this.selectedVariants[variantData.id] = variantData
    } else {
      delete this.selectedVariants[variantData.id]
    }

    this.updateSelected()
  }

  bundleSelect(event) {
    const liElement = event.target.closest('li.bundle')
    const checkboxElement = liElement.querySelector('input[type="checkbox"]')
    if (checkboxElement.checked) {
      this.select(checkboxElement)
    }
  }

  buildVariantData(variant) {
    if (variant.dataset.bundle) {
      const liElement = variant.closest('li.bundle')
      const selectElements = liElement.querySelectorAll('select')

      const bundledVariants = Array.from(selectElements).map((select) => {
        return {
          variant_id: select.value,
          included_product_id: select.dataset.includedProductId,
        }
      })

      return { id: variant.dataset.id, bundledVariants }
    } else {
      return {
        id: variant.dataset.id,
        thumbnailUrl: variant.dataset.thumbnailUrl,
        productName: variant.dataset.productName,
      }
    }
  }

  /*
    emits an event about the selected variants:
    type: "products--variant-select:selected"
    detail: {
      variants: [
        {
          id: 123,
          thumbnailUrl: "path/to/thumbnail/image",
          name: "variant name",
          sku: "123456",
          productName: "Product name",
          productUrl: "url/to/product",
          prices: [{id, name, displayAmount}]
        }
      ]
    }
  */
  emitSelected() {
    const variants = this.getVariants()
    post(this.addVariantsUrlValue, { responseKind: 'turbo-stream', body: JSON.stringify(variants) })
    this.dispatch('selected', { detail: { variants } })
  }

  emitCancelled() {
    const variants = this.getVariants()
    this.dispatch('canceled', { detail: { variants } })
  }

  getVariants() {
    return this.selectedOptionsValue.map((variant) => {
      return variant
    })
  }

  updateSelected() {
    this.selectedOptionsValue = Object.values(this.selectedVariants)
    this.selectedCountTarget.innerHTML = `${this.selectedOptionsValue.length}`
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  parentCheckboxTargetConnected(parentCheckbox) {
    this.verifyChecked(parentCheckbox)
    const checkboxes = this.checkboxTargets.filter((checkbox) => checkbox.dataset.productName === parentCheckbox.name)
    parentCheckbox.indeterminate = this.checked.length > 0 && this.checked.length < checkboxes.length
    parentCheckbox.checked = this.checked.length === checkboxes.length
  }

  checkboxTargetConnected(checkbox) {
    this.verifyChecked(checkbox)
  }

  verifyChecked(checkbox) {
    checkbox.checked = Object.values(this.selectedVariants).some((variant) => variant.id === checkbox.dataset.id)
  }
}
