import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['dateSelectWrapper']
  static classes = ['hidden']

  change(event) {
    if (event.currentTarget.value === 'selected') {
      this.dateSelectWrapperTarget.classList.remove(this.hiddenClass)
    } else if (event.currentTarget.checked == true) {
      this.dateSelectWrapperTarget.classList.add(this.hiddenClass)
    }
  }
}
