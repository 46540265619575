import ApplicationController from './application_controller'
import { post, destroy } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['details']

  static values = {
    postPath: String,
    deletePath: String,
  }

  actionKeyPlaceholder = '--ACTION_KEY--'

  handleDetailsOpen(event) {
    this.detailsTargets.forEach((details) => {
      if (details !== event.target && details.open && !details.contains(event.target)) {
        details.hide()
      }
    })

    if (!event.target.querySelector('sl-details')) return

    event.target.querySelector("sl-details:has(button[data-completed='false'])")?.show()
  }

  handleToggleComplete({ params: { actionKey, completed, updateState = true } }) {
    if (completed) {
      this.markIncomplete(actionKey, updateState)
    } else {
      this.markComplete(actionKey, updateState)
    }
  }

  handleMarkComplete({ params: { actionKey, updateState = true } }) {
    this.markComplete(actionKey, updateState)
  }

  markComplete(actionKey, updateState = true) {
    post(this.postPathValue, {
      body: JSON.stringify({ onboarding_action: { action_key: actionKey, update_state: updateState } }),
      responseKind: 'turbo-stream',
    })
  }

  markIncomplete(actionKey, updateState = true) {
    destroy(this.deletePathValue.replace(this.actionKeyPlaceholder, actionKey), {
      body: JSON.stringify({ onboarding_action: { action_key: actionKey, update_state: updateState } }),
      responseKind: 'turbo-stream',
    })
  }
}
