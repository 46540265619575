import ApplicationController from './application_controller'
import Sortable from 'sortablejs'
import { post } from '@rails/request.js'

// SortableList
// Ideally, this would be called Sortable but that namespace is taken
// This controller can be used alongside acts_as_list to provide a drag and drop interface for sorting
export default class extends ApplicationController {
  static targets = ['list']
  static values = {
    group: String,
    draggable: String,
  }

  listTargetConnected(target) {
    this.initializeSortableList(target)
  }

  initializeSortableList(target) {
    new Sortable.create(target, {
      onEnd: this.onEnd.bind(this),
      group: this.groupValue,
      draggable: this.draggableValue,
    })
  }

  onEnd(event) {
    const { to } = event

    post(event.item.dataset.updateUrlValue, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        sort_order: event.newIndex,
        list_id: to.dataset.sortableListId,
      }),
    })
  }
}
