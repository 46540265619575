import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'checkbox',
    'selectAllCheckbox',
    'selectAllPagesButton',
    'action',
    'selectableToggle',
    'selectAllLabel',
  ]
  static classes = ['selectableAvailable', 'selectable']
  static values = {
    selectable: { type: Boolean, default: true },
    staticMode: { type: Boolean, default: false },
    allPagesSelectedText: String,
    instanceId: String,
  }

  allPagesSelected = false

  connect() {
    if (!this.staticMode) {
      this.element.classList.add(this.selectableAvailableClass)
    }

    if (this.hasSelectAllPagesButtonTarget) {
      this.originalButtonText = this.selectAllPagesButtonTarget.innerText.trim()
    }
  }

  toggleSelectable() {
    this.selectableValue = !this.selectableValue
  }

  updateSelectedIds() {
    this.resetSelectAllPagesButton()
    this.updateActions()
    this.updateSelectAllCheckbox()
    this.emitSelectionStatus()
  }

  updateActions() {
    this.actionTargets.forEach((actionTarget) => {
      actionTarget.dispatchEvent(
        new CustomEvent('update-ids', {
          detail: {
            ids: this.selectedIds,
            all: this.allPagesSelected,
            instanceId: this.instanceIdValue,
          },
        })
      )
    })
  }

  updateSelectAllPagesButton() {
    this.allPagesSelected = true
    this.selectAllPagesButtonTarget.innerText = this.allPagesSelectedTextValue
  }

  resetSelectAllPagesButton() {
    this.allPagesSelected = false
    this.selectAllPagesButtonTarget.innerText = this.originalButtonText
  }

  selectAllPages() {
    this.updateSelectAllPagesButton()

    this.selectAll()
    this.updateSelectAllCheckbox()
    this.emitSelectionStatus()
  }

  selectPageOrNone() {
    this.resetSelectAllPagesButton()

    if (this.fullPageSelected) {
      this.selectNone()
    } else {
      this.selectAll()
    }
    this.updateSelectAllCheckbox()
    this.emitSelectionStatus()
  }

  selectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true
    })
    this.updateActions()
  }

  selectNone() {
    this.resetSelectAllPagesButton()

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.updateActions()
  }

  updateSelectAllCheckbox() {
    let checkbox = this.selectAllCheckboxTarget
    let label = this.selectAllLabelTarget

    if (this.fullPageSelected || this.allPagesSelected) {
      checkbox.checked = true
      checkbox.indeterminate = false
      label.dispatchEvent(
        new CustomEvent('toggle', {
          detail: { useAlternate: true, allPagesSelected: this.allPagesSelected, instanceId: this.instanceIdValue },
        })
      )
    } else if (this.selectedIds.length > 0) {
      checkbox.indeterminate = true
      label.dispatchEvent(
        new CustomEvent('toggle', { detail: { useAlternate: false, instanceId: this.instanceIdValue } })
      )
    } else {
      checkbox.checked = false
      checkbox.indeterminate = false
      label.dispatchEvent(
        new CustomEvent('toggle', { detail: { useAlternate: false, instanceId: this.instanceIdValue } })
      )
    }
  }

  selectableValueChanged() {
    this.element.classList.toggle(this.selectableClass, this.selectableValue)
    if (this.hasSelectableToggleTarget) {
      this.updateToggleLabel()
    }
  }

  updateToggleLabel() {
    if (this.hasSelectableToggleTarget) {
      this.selectableToggleTarget.dispatchEvent(
        new CustomEvent('toggle', { detail: { useAlternate: this.selectableValue, instanceId: this.instanceIdValue } })
      )
    }
  }

  emitSelectionStatus() {
    this.dispatch('selection', {
      detail: {
        ids: this.selectedIds,
        all: this.allPagesSelected,
        instanceId: this.instanceIdValue,
      },
    })
  }

  reset() {
    this.selectNone()
    this.selectAllCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
  }

  get selectedIds() {
    let ids = []
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        ids.push(checkbox.value)
      }
    })
    return ids
  }

  get staticMode() {
    return this.staticModeValue
  }

  get fullPageSelected() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked).length == this.checkboxTargets.length
  }
}
