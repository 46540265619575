import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['button', 'idsHiddenField', 'allHiddenField']
  static classes = ['hidden']
  static values = {
    buttonIfAll: String,
    buttonIfIds: String,
    ids: Array,
    all: Boolean,
    objectName: String,
    idsFieldName: String,
    allFieldName: String,
    stableId: String,
    // TODO The array of field names should be configurable on a per-application basis.
    filterFieldNames: { type: Array, default: ['q', 'stable_id', 'stored_filter_id'] },
  }

  connect() {
    this.updateAvailability()
  }

  updateFormAndSubmit() {
    this.clearHiddenFields()
    this.recreateIdsHiddenFields()
    this.createOrUpdateAllField()
    this.transformQueryParamsToHiddenFields()
    return true
  }

  updateIds(event) {
    if (event?.detail?.ids) {
      this.idsValue = event.detail.ids
    }

    this.allValue = event.detail.all

    this.updateAvailability()
    this.updateButtonLabel()
  }

  updateAvailability() {
    this.element.classList.toggle(this.hiddenClass, this.idsValue.length === 0)
  }

  updateButtonLabel() {
    if (!this.hasButtonIfAllValue || !this.hasButtonIfIdsValue) return

    let label = this.buttonIfAllValue
    if (this.idsValue.length && this.allValue === false) {
      label = this.buttonIfIdsValue.replace('{num}', this.idsValue.length)
    }

    switch (this.buttonTarget.tagName) {
      case 'INPUT':
        this.buttonTarget.value = label
        break
      default:
        this.buttonTarget.textContent = label
        break
    }
  }

  clearHiddenFields() {
    ;[...this.element.children].slice(1).forEach((element) => element.remove())
  }

  recreateIdsHiddenFields() {
    this.removeIdsHiddenFields()
    this.createIdsHiddenFields()
  }

  removeIdsHiddenFields() {
    this.idsHiddenFieldTargets.forEach((field) => {
      this.element.removeChild(field)
    })
  }

  createIdsHiddenFields() {
    this.idsValue.forEach((id) => {
      let field = document.createElement('input')
      field.type = 'hidden'
      field.name = `${this.objectNameValue}[${this.idsFieldNameValue}][]`
      field.value = id
      this.element.appendChild(field)
    })
  }

  createOrUpdateAllField() {
    if (this.hasAllHiddenFieldTarget) {
      this.allHiddenFieldTarget.value = this.allValue ? 'true' : 'false'
    } else {
      this.createAllField()
    }
  }

  createAllField() {
    let field = document.createElement('input')
    field.type = 'hidden'
    field.name = `${this.objectNameValue}[${this.allFieldNameValue}]`
    field.value = this.allValue ? 'true' : 'false'
    this.element.appendChild(field)
  }

  transformQueryParamsToHiddenFields() {
    let urlParams = new URLSearchParams(window.location.search)
    let paramsObj = {}

    this.filterFieldNamesValue.map((param) => {
      if (urlParams.get(param)) {
        paramsObj[param] = urlParams.get(param)
      }
    })

    if (paramsObj['stable_id'] === undefined && this.stableIdValue) {
      this.createStableIdHiddenField()
    }

    Object.keys(paramsObj).forEach((param) => {
      let field = document.createElement('input')
      field.type = 'hidden'
      field.name = param
      field.value = paramsObj[param]
      this.element.appendChild(field)
    })
  }

  createStableIdHiddenField() {
    let field = document.createElement('input')
    field.type = 'hidden'
    field.name = 'stable_id'
    field.value = this.stableIdValue
    this.element.appendChild(field)
  }
}
