/**
 * This controller is for allowing an element to have its disabled property toggled.
 */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['element']

  /**
   * Toggle the disabled property of the disable-element-target="element"
   *
   * @returns {void}
   */
  toggle() {
    this.elementTargets.forEach((el) => (el.disabled = !el.disabled))
  }

  /**
   * Disable the disabled property of all disable-element-target="element" instances.
   *
   * @returns {void}
   */
  disable() {
    this.elementTargets.forEach((el) => {
      el.disabled = true
      el.classList.add('disabled')
    })
  }
}
