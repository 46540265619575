import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['group']
  static values = {
    delay: { type: Number, default: 20000 },
  }

  connect() {
    setTimeout(() => {
      this.groupTarget.dataset.disabled = false
    }, this.delayValue)
  }
}
