import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  handleError = (error, message, detail) => {
    const context = {
      controller: this.identifier,
      ...detail,
    }
    this.application.handleError(error, message, context)
  }
}
