import ApplicationController from '../application_controller'
import { uploadedFileData, configureUploadServer, getUppyLocale } from '../../utils/uppy'
import Uppy from '@uppy/core'
import DropTarget from '@uppy/drop-target'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import { post } from '@rails/request.js'
import Cookies from 'js-cookie'

export default class extends ApplicationController {
  static targets = [
    'openBtn', // button to open the image gallery
    'titleBar', // title bar for thumbnail view
    'dropZone', // drop zone for drag and drop file upload. See #configureUppy
    'emptyState', // container wrapping
    'imagePreviewContainer', // container wrapping form
    'imagePreview', // wrapper for image preview
    'imageTemplate', // `<template>` for image preview
    'inlineError', // hidden element for displaying the error message
    'field', // hidden field for storing the selected image id
    'hiddenFieldTemplate', // `<template>` for the 'field' target
    'hiddenFieldsContainer', // wrapper container for the hidden fields (used to append the replacement thumbnail)
    'loadingSpinner', // loading spinner that appears in the `imageTemplateTarget`
  ]

  static values = {
    accept: String, // Acceptable mime types. See ImageUploader::ALLOWED_TYPES
    acceptedFileTypesMsg: String, // Message to display when the user tries to upload an invalid file type
    fieldId: String, // attribute name for the hidden field
    maxFileSize: Number, // Maximum file size in bytes. See ImageUploader::MAX_FILE_SIZE
    mode: { type: String, default: 'single' }, // 'single' or 'multiple'
    uploadServer: String, // Rails.configuration.upload_server
    uploadUrl: String, // Path to the upload endpoint
  }

  connect() {
    this.configureUppy()
  }

  previewFile(event) {
    const selectedFile = event.target.files[0]

    if (selectedFile != null) {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: selectedFile.name,
          type: selectedFile.type,
          data: selectedFile,
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    }
  }

  /**
   * Configure Uppy
   *
   * @see https://uppy.io/docs/uppy/
   */
  configureUppy() {
    const locale = Cookies.get('locale') || 'en'
    const uppyLocale = getUppyLocale(locale)
    uppyLocale['strings']['youCanOnlyUploadFileTypes'] = this.acceptedFileTypesMsgValue
    this.uppy = new Uppy({
      id: `${this.fieldIdValue}_uppy`,
      autoProceed: true,
      debug: true,
      restrictions: {
        allowedFileTypes: this.acceptValue.split(','),
        minNumberOfFiles: 1,
        maxNumberOfFiles: this.modeValue === 'single' ? 1 : null,
        maxFileSize: this.maxFileSizeValue,
      },
      locale: uppyLocale,
    })

    // Configure the Drop target plugin. See https://uppy.io/docs/drop-target
    this.uppy.use(DropTarget, { target: this.dropZoneTarget })

    // Configure the Thumbnail Generator plugin. See https://uppy.io/docs/thumbnail-generator
    this.uppy.use(ThumbnailGenerator, { thumbnailWidth: 350 })

    // Configure where to upload the files
    configureUploadServer(this.uploadServerValue, this.uppy)

    // Display the error message when the "info" message is intended to be shown
    this.uppy.on('info-visible', () => {
      const { info } = this.uppy.getState()
      this.displayInlineError(true, `${info.message} ${info.details}`)
    })

    // Display the thumbnail when it is generated
    this.uppy.on('thumbnail:generated', (file, preview) => {
      this.showImagePreview([{ url: preview }])
    })

    // Capture and upload the file once it's been uploaded via JavaScript (in memory)
    // Note: This is triggered for each file uploaded
    this.uppy.on('upload-success', (uppyFile, response) => this._onUploadSuccess(uppyFile, response))

    // Reset Uppy once all uploads are complete
    this.uppy.on('complete', () => this.uppy.reset())
  }

  /**
   * Callback to convert the file data to the format expected by the server
   *
   * @param {uppyFile} uppyFile               The file that was uploaded
   *                                          See https://uppy.io/docs/uppy/#working-with-uppy-files
   * @param {string, XMLHttpRequest} response An object containing the upload response metadata
   *                                          See https://uppy.io/docs/xhr-upload/#getresponsedata
   */
  _onUploadSuccess(uppyFile, response) {
    const fileData = uploadedFileData(this.uploadServerValue, uppyFile, response)
    this._createImageOnServer(fileData)
  }

  /**
   * Upload the file data to the server. Once uploaded, the UI and form will be updated accordingly
   *
   * @param {string} fileData The JSON stringified file data
   * @returns {Promise<void>}
   */
  async _createImageOnServer(fileData) {
    this.displayLoadingSpinner(true)

    const response = await post(this.uploadUrlValue, {
      body: JSON.stringify({
        image: {
          file: fileData,
        },
      }),
      responseKind: 'json',
    })

    if (response.ok) {
      const body = await response.json
      this.updateHiddenField([{ id: body.id }])
      this.displayLoadingSpinner(false)
      this.displayInlineError(false)
    } else {
      const body = await response.json
      this.displayInlineError(true, body.file[0])
      this.reset()
    }
  }

  /**
   * Display the loading spinner
   *
   * @param {boolean} visible
   */
  displayLoadingSpinner(visible) {
    if (visible) {
      this.loadingSpinnerTarget.classList.remove('hidden')
    } else {
      this.loadingSpinnerTarget.classList.add('hidden')
    }
  }

  /**
   * Show or hide the inline error with the error message
   *
   * @param {boolean} visible
   * @param {string} message The error message to display (default is '')
   */
  displayInlineError(visible = false, message = '') {
    if (this.hasInlineErrorTarget) {
      this.inlineErrorTarget.innerHTML = message

      if (visible) {
        this.inlineErrorTarget.classList.remove('hidden')
      } else {
        this.inlineErrorTarget.classList.add('hidden')
      }
    }
  }

  /**
   * Called when the user selects or deselects an image. It removes all hidden fields from the DOM, then creates a
   * new hidden field for each selected image. This allows the UI to be updated via the `emitChangeEvent()`
   *
   * @param {Array[{selectedImage: {id: string}}]} selectedImages
   * @return A new array of selected images
   */
  updateHiddenField(selectedImages = []) {
    this.fieldTargets.forEach((field) => field.remove())

    selectedImages.forEach(({ id }) => {
      let clonedHiddenField = this.hiddenFieldTemplateTarget.content.cloneNode(true)
      clonedHiddenField.querySelector('input').value = id
      this.hiddenFieldsContainerTarget.appendChild(clonedHiddenField)
    })

    this.emitChangeEvent()
  }

  /**
   * Callback for when the image gallery closes. This is triggered via a `data-action` within the erb file(s)
   *
   * @param {Event[{ detail: { fieldId: string, items: Array[{url: string}]} }]} event
   */
  handleSelected(event) {
    if (event.detail.fieldId !== this.fieldIdValue) return

    const selectedImages = event.detail.items
    this.updateHiddenField(selectedImages)
    this.displayInlineError(false)

    if (selectedImages.length > 0) {
      this.showImagePreview(selectedImages)
    } else {
      this.showEmptyState()
    }

    this.emitChangeEvent()
  }

  /**
   * Triggers the `reset` function for an image
   *
   * @param {CustomEvent[{ detail: { item: { id: string } } }]} event
   * @see #handleDelete in gallery_controller.js
   */
  handleDelete(event) {
    const { id: imageId } = event.detail.item

    if (!this.hasFieldTarget || !imageId) return

    if (this.fieldTarget.value !== imageId.toString()) return

    this.reset()
  }

  /**
   * Show the empty state of the image preview container.
   */
  showEmptyState() {
    if (!this.imagePreviewContainerTarget.classList.contains('hidden')) {
      this.imagePreviewContainerTarget.classList.add('hidden')
    }
    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.classList.remove('hidden')
    }
    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.remove('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.add('hidden')
    }
  }

  /**
   * Display the thumbnail image preview(s)
   *
   * @param {Array[{url: string}]} selectedImages
   */
  showImagePreview(selectedImages) {
    if (!this.emptyStateTarget.classList.contains('hidden')) {
      this.emptyStateTarget.classList.add('hidden')
    }

    this.imagePreviewTarget.innerHTML = ''

    selectedImages.forEach(({ url }) => {
      let previewImage = this.imageTemplateTarget.content.cloneNode(true)
      previewImage.querySelector('img').src = url

      this.imagePreviewTarget.appendChild(previewImage)
    })
    if (this.hasOpenBtnTarget) {
      this.openBtnTarget.classList.add('hidden')
    }
    if (this.hasTitleBarTarget) {
      this.titleBarTarget.classList.remove('hidden')
    }
    if (this.imagePreviewContainerTarget.classList.contains('hidden')) {
      this.imagePreviewContainerTarget.classList.remove('hidden')
    }
  }

  /**
   * Reset the form and hidden fields
   */
  reset() {
    this.fieldTargets.forEach((target) => (target.value = ''))

    this.emitChangeEvent()
    this.showEmptyState()
    this.displayInlineError(false)
  }

  /**
   * Emit a change event on the hidden fields container which bubbles up to
   * the dropzone, empty_folder, or empty_state partials
   *
   * @see app/views/account/images/gallery/_dropzone.html.erb
   * @see app/views/account/images/gallery/_empty_folder.html.erb
   * @see app/views/account/images/gallery/_empty_state.html.erb
   */
  emitChangeEvent() {
    this.hiddenFieldsContainerTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }
}
