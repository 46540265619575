import ApplicationController from './application_controller'
const SEARCHFIELD = 'q'

export default class extends ApplicationController {
  updateQuery(link, query) {
    let url = new URL(link.href)
    url.search = `?q=${query}`
    return url.toString()
  }

  addQuery(e) {
    const searchFields = document.getElementsByName(SEARCHFIELD)
    if (searchFields.length == 0) return

    e.preventDefault()
    const searchField = searchFields?.[searchFields.length - 1]
    const query = searchField?.value
    if (query) {
      Turbo.visit(this.updateQuery(e.target, query), { frame: 'modal_body' })
    } else {
      Turbo.visit(e.target, { frame: 'modal_body' })
    }
  }
}
