import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['blocky', 'rounded', 'colorful']

  toggle(event) {
    let selection = event.target.dataset.style
    let preview = this.getTargetBySelection(selection)

    if (preview.classList.contains('visible')) {
      return
    }

    this.hideAllPreviews()

    if (preview) {
      preview.classList.remove('hidden')
      preview.classList.add('visible')
    }
  }

  hideAllPreviews() {
    this.blockyTarget.classList.add('hidden')
    this.blockyTarget.classList.remove('visible')

    this.roundedTarget.classList.add('hidden')
    this.roundedTarget.classList.remove('visible')

    this.colorfulTarget.classList.add('hidden')
    this.colorfulTarget.classList.remove('visible')
  }

  getTargetBySelection(selection) {
    switch (selection) {
      case 'blocky':
        return this.blockyTarget
      case 'rounded':
        return this.roundedTarget
      case 'colorful':
        return this.colorfulTarget
      default:
        return null
    }
  }
}
