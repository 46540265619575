import ApplicationController from '../../application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['variantSelectLink']
  static values = { variantSelectLinkUrl: String }

  replaceLinkTo(event) {
    const selectedIds = event.detail.variants.map((v) => v.id)
    this.fetchLinkTo(selectedIds)
  }

  async fetchLinkTo(selectedIds) {
    let constructedUrl = new URL(this.variantSelectLinkUrlValue)

    if (selectedIds.length) {
      const params = new URLSearchParams(constructedUrl.search)
      for (let i = 0; i < selectedIds.length; i++) {
        params.append('selected_ids[]', selectedIds[i])
      }
      constructedUrl.search = params
    }

    const request = new FetchRequest('GET', constructedUrl, {
      responseKind: 'turbo-stream',
    })

    await request.perform()
  }
}
