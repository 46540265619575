import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    eventNameKey: { type: String, default: 'eventName' },
    events: Array,
  }

  /**
   * Sets up the bound handler to properly reference for the event listener.
   */
  initialize() {
    this._iframeMessageHandler = this.iframeMessageHandler.bind(this)
  }

  /**
   * Setup the handler listener
   */
  connect() {
    window.addEventListener('message', this._iframeMessageHandler)
  }

  /**
   * Removes the handler
   */
  disconnect() {
    window.removeEventListener('message', this._iframeMessageHandler)
  }

  /**
   * Handles the iframe message. If a key is present and its value matches one of the events
   * in eventsValue, it'll dispatch the event as iframe-message-relay:EVENT-NAME.
   * @private
   * @param {object} obj - The message object containing data from the iframe postMessage
   * @param {object} obj.data - The data object within the message.
   */
  iframeMessageHandler({ data }) {
    if (!this.eventMatched(data)) return

    this.dispatch(data[this.eventNameKeyValue], {
      detail: data,
      bubbles: true,
    })
  }

  /**
   * Checks if the event matches one of the passed in controller event values.
   * @private
   * @param {Object} data - The data object.
   * @returns {boolean} - True if the event matches, false otherwise.
   */
  eventMatched(data) {
    return this.eventsValue.includes(data[this.eventNameKeyValue])
  }
}
