import Rails from '@rails/ujs'

Rails.confirm = function (message, element) {
  function dispatchEvent(opts) {
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('confirm-display', true, true, opts)
    window.document.dispatchEvent(event)
  }

  let $element = $(element)

  let title = $element.data('title') ? $element.data('title') : ''
  let submit = $element.data('submit') ? $element.data('submit') : 'Proceed'
  let back = $element.data('cancel') ? $element.data('cancel') : 'Cancel'
  let type = $element.data('type') ? $element.data('type') : 'neutral'

  const opts = {
    title: title,
    message: message,
    type: type,
    confirmButtonText: submit,
    cancelButtonText: back,
    originalElement: element,
  }

  dispatchEvent(opts)
}
