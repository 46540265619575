import CableReady from 'cable_ready'
import consumer from 'cf-utils/application_cable'

const eventNamespace = 'users-channel'

const usersChannel = consumer.subscriptions.create('UsersChannel', {
  connected() {
    window.usersChannelConnected = true
    document.dispatchEvent(new CustomEvent(`${eventNamespace}:connected`, { bubbles: true }))
  },
  disconnected() {
    window.usersChannelConnected = false
    document.dispatchEvent(new CustomEvent(`${eventNamespace}:disconnected`, { bubbles: true }))
  },
  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  },
})

export default usersChannel
