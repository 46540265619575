import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['spinner']
  static classes = ['hidden']

  submitForm() {
    this.element.requestSubmit()
  }

  toggleSpinner() {
    this.spinnerTarget.classList.toggle(this.hiddenClass)
  }
}
