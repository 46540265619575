import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static classes = ['show', 'hide', 'animate']

  connect() {
    this.handler = this.onAnimateEnd.bind(this)
    this.element.addEventListener('animationend', this.handler)
    setTimeout(this.show.bind(this), TurboBoost.Streams.morph.delay + 10)
  }

  disconnect() {
    this.element.removeEventListener('animationend', this.handler)
    delete this.handler
  }

  onAnimateEnd() {
    if (this.element.classList.contains(this.hideClass)) return this.remove()
    if (this.element.classList.contains(this.showClass)) return this.hide()
  }

  show() {
    this.element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'auto' })
    this.element.classList.add(this.showClass)
  }

  hide() {
    this.element.classList.add(this.animateClass)
    this.element.classList.remove(this.showClass)

    setTimeout(() => {
      this.element.classList.add(this.hideClass)
      this.element.classList.remove(this.animateClass)
    }, 100)
  }

  remove() {
    this.element.classList.remove(this.hideClass)
    this.removeAttributeValue('data-controller', 'highlight')
  }

  removeAttributeValue(name, value) {
    value = this.removeFromString(this.element.getAttribute(name), value)
    this.element.setAttribute(name, value)
  }

  removeFromString(str, value, delimitier = ' ') {
    const values = (value || '').split(delimitier)
    if (values.includes(value)) values.splice(values.indexOf(value), 1)
    return values.join(delimitier)
  }
}
