import ApplicationController from '../../application_controller'

/**
 * This controller is responsible for hiding and displaying fields on the product
 * and variant form depending on the state of the "Fulfillment Required" checkbox
 * and the "Track Quantity" radio button.
 */
export default class extends ApplicationController {
  static targets = [
    'container',
    'locationsLink',
    'dontTrackQuantityFieldsToHide',
    'quantityField',
    'quantityPlaceholder',
    'locationRow',
  ]

  static values = {
    fulfillmentRequired: Boolean,
    trackQuantity: Boolean,
  }

  initialize() {
    // If the page is loaded with fulfillmentRequired checked, then by definition trackQuantity will have also been set.
    this.trackQuantityHasBeenSet = this.fulfillmentRequiredValue
  }

  locationRowTargetConnected() {
    this.toggleLocationFields()
  }

  /**
   * Gets the current value of the checked "Track Quantity" radio button
   *
   * @returns {string}
   */
  currentTrackQuantityValue() {
    const checkedField = this.containerTarget.querySelector('input[type=radio]:checked')
    return checkedField.value
  }

  /**
   * Toggles the display (showing or hiding) of all the fulfillment fields.
   * If the state of the track quantity dependent fields has not already been set
   * then it sets a default value for those.
   *
   * @returns {void}
   */
  toggleContainer() {
    this.containerTarget.classList.toggle('hidden')
    if (this.hasLocationsLinkTarget) {
      this.locationsLinkTarget.classList.toggle('hidden')
    }
    if (this.trackQuantityHasBeenSet === false) {
      // By default select the first non-disabled radio.
      // Note this logic is sensitive to the ordering of the fields on the page being changed.
      const trackQuantityField = this.containerTarget.querySelector('input[type=radio]:not([disabled])')
      trackQuantityField.checked = true
      this.trackQuantityHasBeenSet = true
      this.toggleLocationFields()
    }
  }

  /**
   * Toggles the display (showing or hiding) of all the track quantity related fields.
   *
   * @returns {void}
   */
  toggleLocationFields() {
    if (this.currentTrackQuantityValue() === 'false') {
      this.hideLocationFields()
      this.hideTrackedQuantities()
    } else {
      this.showLocationFields()
      this.showTrackedQuantities()
    }
  }

  /**
   * Shows all the track quantity related fields.
   *
   * @returns {void}
   */
  showLocationFields() {
    this.dontTrackQuantityFieldsToHideTargets.forEach((el) => el.classList.remove('hidden'))
  }

  /**
   * Shows all the track quantity fields, and hides the placeholders.
   *
   * @returns {void}
   */
  showTrackedQuantities() {
    this.quantityFieldTargets.forEach((el) => el.classList.remove('hidden'))
    this.quantityPlaceholderTargets.forEach((el) => el.classList.add('hidden'))
  }

  /**
   * Hides all the track quantity related fields.
   *
   * @returns {void}
   */
  hideLocationFields() {
    this.dontTrackQuantityFieldsToHideTargets.forEach((el) => el.classList.add('hidden'))
  }

  /**
   * Hides all the track quantity fields, and shows the placeholders.
   *
   * @returns {void}
   */
  hideTrackedQuantities() {
    this.quantityFieldTargets.forEach((el) => el.classList.add('hidden'))
    this.quantityPlaceholderTargets.forEach((el) => el.classList.remove('hidden'))
  }
}
