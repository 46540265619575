// Load all the channels within this directory and all subdirectories.
import * as Futurism from '@stimulus_reflex/futurism'
import consumer from 'cf-utils/application_cable'

// Channel files must be named *_channel.js.
import { context as channelContext } from './**/*_channel.js'

if (channelContext) {
  /* no-op to prevent tree shaking */
}

Futurism.initializeElements()
Futurism.createSubscription(consumer)
