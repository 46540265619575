import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['rateNameDiv', 'rateName', 'rateValueDiv', 'rateValue', 'submitButton']
  static values = {
    hasCustomSelected: Boolean,
  }

  connect() {
    if (this.hasCustomSelectedValue) {
      this.showCustomFields()
    }
  }

  handleFocusIn(event) {
    const shippingValue = this.rateValueTarget.value

    if (shippingValue === '') {
      this.disableSubmitButton(event)
    }
  }

  handleFocusOut(event) {
    const shippingValue = this.rateValueTarget.value

    if (shippingValue === '') {
      this.disableSubmitButton(event)
    }
  }

  handleRadioChange(event) {
    const selectedValue = event.target.value
    const shippingValue = this.rateValueTarget.value

    if (selectedValue === 'Custom') {
      this.showCustomFields()
      if (shippingValue === '') {
        this.disableSubmitButton(event)
      }
    } else {
      this.hideCustomFields()
      this.enableSubmitButton()
    }
  }

  handleNameChange(event) {
    const shippingName = this.rateNameTarget.value

    if ((shippingName !== undefined && shippingName !== null && shippingName !== '') || shippingName === '') {
      event.stopPropagation()
    }
  }

  handleValueChange(event) {
    const shippingValue = this.rateValueTarget.value

    if (shippingValue === undefined || shippingValue === null || shippingValue === '') {
      this.disableSubmitButton(event)
    } else if (shippingValue !== undefined && shippingValue !== null) {
      const trimmedValue = shippingValue.trim()
      if (trimmedValue !== '' || trimmedValue === 0) {
        this.enableSubmitButton()
      } else {
        this.disableSubmitButton(event)
      }
    }
  }

  showCustomFields() {
    this.rateNameDivTarget.classList.remove('hidden')
    this.rateValueDivTarget.classList.remove('hidden')
  }

  hideCustomFields() {
    this.rateNameDivTarget.classList.add('hidden')
    this.rateValueDivTarget.classList.add('hidden')
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove('disabled')
  }

  disableSubmitButton(event) {
    event.stopPropagation()
    this.submitButtonTarget.classList.add('disabled')
  }
}
