import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['hiddenField']

  connect() {
    this.element.filterController = this
  }

  // Updates hiddenInput with stableId in response to a filter-stabilized event.
  // data-action= 'blueprint-updated->refine--stabilize-filter#updateStableId
  // filter-stabilized->fields--filter#updateFilter'>
  // If you want to see errors in the form refer to filter_validate_controller.js

  updateFilter(event) {
    const { detail } = event
    const { stableId } = detail
    this.hiddenFieldTarget.value = stableId
  }
}
