import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['radio', 'input', 'field', 'destroy', 'edit', 'undo', 'select']
  static values = {
    data: Array,
    selectedTag: Number,
  }

  connect() {
    if (this.hasSelectedTagValue) {
      this.seletedTagData = this.getSelectedTagData(this.selectedTagValue)
    }
  }

  /**
   * Removes a tag row html from the dom.
   */
  removeTag() {
    const closestFormElement = this.element.closest('form')
    const event = new Event('change')

    this.destroyTarget.value = true
    this.element.after(this.destroyTarget)
    this.element.remove()

    closestFormElement.dispatchEvent(event)
  }

  /**
   * Hide the edit button and display the undo button.
   */
  edit() {
    this.editTarget.classList.add('hidden')
    this.undoTarget.classList.remove('hidden')
  }

  /**
   * Undo edits made to the tag, restoring the values to the originally
   * selected tag.
   */
  undo() {
    this.displayDefaultCommissionTagData(this.seletedTagData)
    $(this.selectTarget).val(this.seletedTagData.id)
    $(this.selectTarget).trigger('change')
    this.undoTarget.classList.add('hidden')
    this.editTarget.classList.remove('hidden')
  }

  /**
   * Handle tag selection. Gets the data associated with the selected tag
   * and passes it onto to displayDefaultCommissionTagData.
   *
   * @param {Event} event - The event object.
   */
  handleSelect(event) {
    this.seletedTagData = this.getSelectedTagData(event.target.value)
    this.displayDefaultCommissionTagData(this.seletedTagData)
  }

  /**
   * Called when toggling fixed/percentage commission type.
   * Passes the given commission type along to:
   *  - restoreCachedInputValue
   *  - showCommissionFieldForType
   *
   * @param {Event} event - The event object.
   */
  handleCommissionTypeChange(event) {
    const commissionType = event.target.dataset.type

    this.restoreCachedInputValue(commissionType)
    this.showCommissionFieldForType(commissionType)
  }

  /**
   * Display default commission plan data for the selected tag.
   *
   * @param {Object} tagData - The tag data.
   */
  displayDefaultCommissionTagData(tagData) {
    const commissionType = tagData.commission_type
    this.cachedInputValue = ''
    this.getCommissionTypeRadio(tagData.commission_type).checked = true
    this.showCommissionFieldForType(commissionType, tagData[`commission_${commissionType}`])
  }

  /**
   * Show the commission field for a specific type.
   *
   * @param {string} type - The commission type.
   * @param {string} [value=''] - The value to display in the field.
   */
  showCommissionFieldForType(type, value = '') {
    this.fieldTargets.forEach((field) => {
      const input = field.querySelector('input')
      if (field.dataset.type === type) {
        input.value = value
        field.classList.remove('hidden')
        return
      }

      field.classList.add('hidden')
      input.value = ''
    })
  }

  /**
   * Restore cached input value for a specific commission type.
   * Preserves user input while switching between commission types.
   *
   * @param {string} type - The commission type.
   */
  restoreCachedInputValue(type) {
    let valueToRestore = this.cachedInputValue

    this.inputTargets.forEach((input) => {
      if (!input.dataset.type == type) {
        this.cachedInputValue = input.value
        input.value = ''
      } else {
        input.value = valueToRestore
      }
    })
  }

  /**
   * Get selected tag data.
   *
   * @param {Number} tagId - The tag ID.
   * @returns {Object} - The selected tag data.
   */
  getSelectedTagData(tagId) {
    let selectedTagData = this.dataValue.filter((item) => item.id == tagId)[0]
    selectedTagData['commission_type'] = this.getCommissionType(selectedTagData)

    return selectedTagData
  }

  /**
   * Determine the commission type.
   * @param {Object} tagData - The tag data.
   * @returns {string} - The commission type ('percent' or 'fixed').
   */
  getCommissionType(tagData) {
    if (tagData.commission_percent !== null) {
      return 'percent'
    }
    return 'fixed'
  }

  /**
   * Get the commission type radio element.
   * @param {string} type - The commission type.
   * @returns {HTMLElement} - The radio element.
   */
  getCommissionTypeRadio(type) {
    return this.radioTargets.filter((radio) => radio.dataset.type === type)[0]
  }

  /**
   * Get the commission type input element.
   * @param {string} type - The commission type.
   * @returns {HTMLElement} - The input element.
   */
  getCommissionTypeInput(type) {
    return this.inputTargets.filter((input) => input.dataset.type === type)[0]
  }
}
