const verticalLinePlugin = {
  id: 'verticalLiner',
  afterInit: (chart, args, opts) => {
    chart.verticalLiner = {}
  },
  afterEvent: (chart, args, options) => {
    const { inChartArea } = args
    chart.verticalLiner = { draw: inChartArea }
  },
  beforeTooltipDraw: (chart, args, options) => {
    const { draw } = chart.verticalLiner
    if (!draw) return

    const { ctx } = chart
    const { top, bottom } = chart.chartArea
    const { tooltip } = args
    const x = tooltip?.caretX
    if (!x) return

    ctx.save()

    if (options?.lineColor) {
      ctx.strokeStyle = options.lineColor
    }

    ctx.beginPath()
    ctx.moveTo(x, top)
    ctx.lineTo(x, bottom)
    ctx.stroke()

    ctx.restore()
  },
}

export { verticalLinePlugin }
