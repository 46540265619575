import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['link']

  connect() {
    this.isStable = true
  }

  markUnstable() {
    this.isStable = false
    this.linkTarget.disabled = true
  }

  markStable() {
    this.isStable = true
    this.linkTarget.disabled = false
  }
}
