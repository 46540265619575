import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = []
  static values = {}

  connect() {
    this.resizeParentIframe()
  }

  resizeParentIframe() {
    const iFrame = window.frameElement
    if (!iFrame) return

    iFrame.height = iFrame.contentWindow.document.body.scrollHeight
  }
}
