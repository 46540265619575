import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [ "field" ]
  static values = {
    loadingStoppedEventName: {
      type: String,
      default: "loading:stopped"
    },
    fieldNameWithFocus: {
      type: String,
      default: ""
    }
  }
  
  cancelFrameUpdate() {
    this.fieldTargets.forEach(field => {
      field.dispatchEvent(new CustomEvent(this.loadingStoppedEventName, { bubbles: true }))
    })
    this.returnFocusToField()
  }
  
  endFrameUpdate() {
    this.returnFocusToField()
  }
  
  rememberFocusOnField(event) {
    const el = event.target
    if (!el) { return }
    if (el.name === undefined) { return }
    
    this.fieldNameWithFocusValue = el.name
  }
  
  unrememberFocusOnField(event) {
    const el = event.target
    if (!el) { return }
    if (el.name === undefined) { return }
    
    if (this.fieldNameWithFocusValue !== el.name) { return }
    
    this.fieldNameWithFocusValue = ""
  }
  
  returnFocusToField() {
    if (this.fieldNameWithFocusValue === "") { return }
    
    const field = this.element.querySelector(`[name=${this.fieldNameWithFocusValue}]`)
    if (!field) { return }
    
    field.focus()
  }
  
  updateUrlFilterParams() {
    const url = new URL(window.location.href)
    
    this.fieldTargets.forEach((fieldTarget) => {
      const value = fieldTarget.type === "checkbox" ? fieldTarget.checked : fieldTarget.value
      url.searchParams.set(fieldTarget.name, value)
    })
    
    window.history.replaceState({ }, '', url)
  }
}