import ApplicationController from './application_controller'
import { leave, toggle } from 'el-transition'
import Cookies from 'js-cookie'

export default class extends ApplicationController {
  static targets = ['button', 'menu', 'count', 'listIcon', 'closeIcon', 'list', 'completeMessage']
  static values = { open: Boolean, name: String }
  static classes = ['hidden']
  static isCompleteStates = {}

  /**
   * Called when the `list` target is connected. Initializes the menu display.
   */
  listTargetConnected() {
    this.handleListDisplay()
    this.handleMobile()
    this.constructor.isCompleteStates[this.checklistNameValue] = false
  }

  /**
   * Called when the `completeMessage` target is connected. Shows the complete message when the checklist is completed.
   *
   * @param {Element} element - The complete message element.
   */
  completeMessageTargetConnected(element) {
    if (this.constructor.isCompleteStates[this.checklistNameValue] === false) {
      this.showElement(element)
      this.showElement(this.menuTarget)
      if (this.hasCountTarget) {
        this.hideElement(this.countTarget)
      }
    }

    setTimeout(() => {
      this.constructor.isCompleteStates[this.checklistNameValue] = true
    }, 2000)
  }

  /**
   * Toggles the transitions and updates the checklist visibility in Cookies.
   */
  toggleMenu() {
    this.openValue = !this.openValue
    toggle(this.menuTarget)
    toggle(this.listIconTarget)
    toggle(this.closeIconTarget)
    toggle(this.countTarget)
    Cookies.set(`${this.nameValue}_checklist_visible`, this.openValue)
  }

  /**
   * Handles the display of the menu.
   * If the menu is open, show the close icon and hide the list icon and count.
   * If the menu is closed, show the list icon and count and hide the close icon.
   */
  handleListDisplay() {
    if (this.openValue) {
      this.showElement(this.menuTarget)
      this.showElement(this.closeIconTarget)
      this.hideElement(this.listIconTarget)
      if (this.hasCountTarget) {
        this.hideElement(this.countTarget)
      }
    } else {
      this.hideElement(this.menuTarget)
      this.hideElement(this.closeIconTarget)
      this.showElement(this.listIconTarget)
      if (this.hasCountTarget) {
        this.showElement(this.countTarget)
      }
    }
  }

  /**
   * Hides the given element by adding the 'hidden' class.
   *
   * @param {Element} element - The element to hide.
   */
  hideElement(element) {
    element.classList.add(this.hiddenClass)
  }

  /**
   * Shows the given element by removing the 'hidden' class.
   *
   * @param {Element} element - The element to show.
   */
  showElement(element) {
    element.classList.remove(this.hiddenClass)
  }

  /**
   * Hides the menu to indicate completion.
   */
  hideCompleteMessage() {
    leave(this.menuTarget)
  }

  /**
   * Handles display on mobile devices by default.
   */
  handleMobile() {
    if (window.innerWidth < 768) {
      this.openValue = false
      this.hideElement(this.menuTarget)
      this.hideElement(this.closeIconTarget)
      this.showElement(this.listIconTarget)
      this.showElement(this.countTarget)
    }
  }
}
