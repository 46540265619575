export const getUrlParameter = (sParam) => {
  const sPageURL = window.location.search.substring(1)
  const sURLVariables = sPageURL.split('&')

  let sParameterName

  for (let i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=')

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1])
    }
  }
  return false
}
