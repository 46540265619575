import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['folder']
  static classes = ['open']

  openFolder(event) {
    /* TODO: This is gross, refactor */
    let folderToOpen = event.currentTarget
    let folderToOpenIcon = folderToOpen.querySelector('i')

    folderToOpen.classList.add(...this.openClass.split(' '))
    folderToOpen.classList.remove('text-coolGray-400')

    if (folderToOpenIcon) {
      folderToOpenIcon.classList.add('fa-cube', 'text-blue-600')
    }

    this.folderTargets
      .filter((folder) => folder != folderToOpen)
      .forEach((folder) => {
        let icon = folder.querySelector('i')
        folder.classList.remove(...this.openClass.split(' '))
        folder.classList.add('text-coolGray-400')

        if (icon) {
          icon.classList.remove('fa-cube', 'text-blue-600')
        }
      })
  }
}
