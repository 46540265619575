import ApplicationController from '../application_controller'
/*
 * this controller is responsible for responding to url params
 * and clicking the appropriate link within a table. To enable, you must
 * provide a `data-orders--redirect-params-redirect-value` attribute with
 * an array of [paramKey, tableId] where the paramKey is the param to look
 * for and the tableId is an obfuscated id of the order.
 *
 * ex: data-orders--redirect-params-redirect-value = ["transaction_id",
 *   "payments-transactions-table"]
 * expected url param: `/account/orders/:order_id?transaction_id=AxZ4F3`
 * this will tell the controller to find the `payments-transactions-table`
 * and select the table row with a `data-id` of `Axz4F3` and then click
 * the anchor
 */
export default class extends ApplicationController {
  static values = { redirect: Array }

  connect() {
    const urlParams = new URLSearchParams(window.location.search)
    /* if we have a redirect value, check for redirect */
    if (this.hasRedirectValue) {
      this.redirectValue.forEach(([paramKey, tableId]) => {
        this.redirectBasedOnParam(urlParams, paramKey, tableId)
      })
    }
  }

  redirectBasedOnParam(urlParams, paramKey, tableId) {
    /* Look for param key in url param and get value */
    const paramValue = urlParams.get(paramKey)
    if (paramValue !== null) {
      /*
       * if we have a value, use the tableId to select the table and the paramValue
       * to find the row and select the anchor tag.
       */
      const link = this.element.querySelector(`#${tableId} tr[data-id="${paramValue}"] a`)
      /* if we found an anchor, click it */
      if (link) {
        link.click()
      }
    }
  }
}
