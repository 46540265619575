import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static values = {
    options: Array,
  }
  static targets = ['subjectInput', 'inboxInput']

  connect() {
    this.hideShowSubject(this.inboxInputTarget.value)
  }

  toggleSubject(event) {
    this.hideShowSubject(event.target.value)
  }

  hideShowSubject(value) {
    if (!this.optionsValue) return
    const choice = this.optionsValue.find((option) => String(option[1]) === String(value))
    if (choice[0].toLowerCase().includes('email')) {
      this.subjectInputTarget.classList.remove('hidden')
    } else {
      this.subjectInputTarget.classList.add('hidden')
    }
  }
}
