import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'contextualDescription',
    'contextualDescriptionTemplate',
    'percentChangeUpTemplate',
    'percentChangeDownTemplate',
  ]
  static classes = ['hideContextualDescription']

  showDescriptionWith(event) {
    if (!event?.detail?.show) {
      this.hideContextualDescription()
      return
    }

    const { value, label, x, comparisonValue, comparisonLabel, percentChange } = event?.detail

    let newHTML = this.contextualDescriptionTemplateTarget.innerHTML
    newHTML = newHTML.replaceAll('%value%', value).replaceAll('%label%', label)

    if (comparisonValue !== undefined) {
      newHTML = newHTML.replaceAll('%comparisonValue%', comparisonValue)
    }

    if (comparisonLabel !== undefined) {
      newHTML = newHTML.replaceAll('%comparisonLabel%', comparisonLabel)
    }

    if (
      percentChange !== undefined &&
      this.hasPercentChangeUpTemplateTarget &&
      this.hasPercentChangeDownTemplateTarget
    ) {
      let percentChangeHTML = percentChange.includes('-')
        ? this.percentChangeDownTemplateTarget.innerHTML
        : this.percentChangeUpTemplateTarget.innerHTML
      percentChangeHTML = percentChangeHTML.replaceAll('%percentChange%', percentChange)
      newHTML = newHTML.replaceAll('%percentChange%', percentChangeHTML)
    }

    this.contextualDescriptionTarget.innerHTML = newHTML

    if (x !== undefined) {
      this.contextualDescriptionTarget.style.left = `${x}px`
    }

    this.showContextualDescription()
  }

  hideContextualDescription() {
    this.contextualDescriptionTarget.classList.add(...this.hideContextualDescriptionClasses)
  }

  showContextualDescription() {
    this.contextualDescriptionTarget.classList.remove(...this.hideContextualDescriptionClasses)
  }
}
