import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    eventPrefix: { type: String, default: 'style-list' },
  }
  static targets = ['styleIdField', 'styleTypeField']

  select(event) {
    const [styleId, styleType] = event.target.value.split('::')

    if (this.hasStyleIdFieldTarget && this.hasStyleTypeFieldTarget) {
      this.styleIdFieldTarget.value = styleId
      this.styleTypeFieldTarget.value = styleType
    }

    this.broadcastSelected(styleId, styleType)
  }

  broadcastSelected(id, type) {
    this.dispatch('change', {
      detail: { id, type },
      prefix: this.eventPrefixValue,
    })
  }
}
