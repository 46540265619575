import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['passwordInputTitle', 'passwordInputField', 'passwordInputFieldRevealButton', 'capsLockWarning']
  static classes = ['hide']

  togglePasswordFieldReveal(event) {
    event.preventDefault()

    const iconElement = this.passwordInputFieldRevealButtonTarget.querySelector('i')
    const type = this.passwordInputFieldTarget.type === 'password' ? 'text' : 'password'

    this.passwordInputFieldTarget.setAttribute('type', type)
    iconElement.classList.toggle('fa-eye')
    iconElement.classList.toggle('fa-eye-slash')
  }

  checkCapsLock(event) {
    if (event.getModifierState('CapsLock')) {
      this.capsLockWarningTarget.classList.remove(...this.hideClasses)
    } else {
      this.capsLockWarningTarget.classList.add(...this.hideClasses)
    }
  }
}
