import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    src: String,
  }

  static targets = ['frame']

  connect() {
    console.log('refreshing', this.frameTarget)
    this.interval = setInterval(() => {
      this.frameTarget.setAttribute('src', this.srcValue)
    }, 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
