import pluralize from 'pluralize'
import { addLeadingZero } from './add_leading_zero'

export function formatLabelAndValue(label, value) {
  const baseString = pluralize(label, value, true)

  // Add leading zero to single digits to prevent horizontal shifting/vertical 'jumping' on screen as timer ticks
  const returnString = baseString.replace(/(\d+)/i, (match) => addLeadingZero(parseInt(match), 2))

  return returnString.replace(/ /i, '\xa0') // Replace space with Unicode &nbsp to control wrapping
}
