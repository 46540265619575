import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['parentSection']

  connect() {
    this.parentSectionTargets.forEach((parentSection) => this.updateParentSection(parentSection))
  }

  select({ currentTarget, currentTarget: { dataset } }) {
    if (dataset?.parentId) {
      let parent = this.getParentSection(currentTarget)
      this.updateParentSection(parent)
    }
  }

  selectAll({ currentTarget }) {
    let parent = currentTarget.closest('[data-root]')
    this.toggleAllDescendentCheckboxes(parent, currentTarget)

    this.updateParentSection(parent)
  }

  toggleAllDescendentCheckboxes(parent, currentTarget) {
    let children = [...parent.querySelectorAll('[data-checkbox]')]
    children.forEach((checkbox) => {
      if (checkbox == currentTarget) {
        return
      }
      checkbox.checked = currentTarget.checked
      checkbox.indeterminate = false
    })

    let childSelectAlls = children.filter((child) => child.dataset.selectAll == 'true')
    childSelectAlls.forEach((selectAll) => {
      let parent = selectAll.closest('[data-root]')
      let children = this.children(parent).filter((child) => child !== selectAll)
      this.updateStatusCounter(parent, children)
    })
  }

  updateParentSection(parentSection) {
    const selectAllBox = parentSection.querySelector("[data-select-all='true']")
    const children = this.children(parentSection).filter((checkbox) => checkbox != selectAllBox)
    const allSelected = this.allSelected(children)
    const someSelected = this.someSelected(children)

    if (allSelected) {
      selectAllBox.checked = true
      selectAllBox.indeterminate = false
    } else if (someSelected) {
      selectAllBox.checked = false
      selectAllBox.indeterminate = true
    } else {
      selectAllBox.checked = false
      selectAllBox.indeterminate = false
    }

    this.updateStatusCounter(parentSection, children)

    if (parentSection.dataset.id == parentSection.dataset.parentId) {
      return
    }

    const nextParentSection = this.getParentSection(parentSection)
    this.updateParentSection(nextParentSection)
  }

  updateStatusCounter(parent, children) {
    let numChildrenSelected = this.childrenSelected(children)
    let status = parent.querySelector('[data-checkbox-select-status]')
    status.textContent = numChildrenSelected

    if (this.allSelected(children) || this.someSelected(children)) {
      status.classList.add('text-green-600')
      return
    }

    status.classList.remove('text-green-600')
  }

  childrenSelected(children = []) {
    return children.filter((checkbox) => checkbox.checked || checkbox.indeterminate).length
  }

  allSelected(checkboxes = []) {
    return checkboxes.filter((checkbox) => checkbox.checked).length == checkboxes.length
  }

  someSelected(checkboxes = []) {
    let selectedCheckboxes = checkboxes.filter((checkbox) => checkbox.checked || checkbox.indeterminate).length

    return selectedCheckboxes > 0 && selectedCheckboxes <= checkboxes.length
  }

  children(parentEl) {
    let parentId = parentEl.dataset.id
    let childCheckboxes = [...parentEl.querySelectorAll(`input[data-parent-id='${parentId}']`)]

    return childCheckboxes
  }

  getParentSection(element) {
    let currentElement = element

    while (currentElement !== this.element) {
      const isParentSection = currentElement.dataset?.id == element.dataset.parentId
      if (isParentSection) {
        return currentElement
      } else {
        currentElement = currentElement.parentNode
      }
    }

    return null
  }
}
