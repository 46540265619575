// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application, Controller } from '@hotwired/stimulus'
import { identifierForContextKey } from '@hotwired/stimulus-webpack-helpers'
import { controllerDefinitions as bulletTrainControllers } from '@bullet-train/bullet-train'
import { controllerDefinitions as refineControllers } from '@clickfunnels/refine-stimulus'
import { controllerDefinitions as superchartsControllers } from '@supercharts/supercharts-bullet-train'

// https://github.com/bullet-train-co/bullet_train/blob/
// 5f38aec527387751e61728baa7cb2aac6a11f34e/app/javascript/controllers/index.js#L14
//
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { context as controllersContext } from './**/*_controller.js'

import RevealController from 'stimulus-reveal'
import { Tabs } from 'tailwindcss-stimulus-components'
import Timeago from 'stimulus-timeago'

import ReadMore from 'stimulus-read-more'
import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'
import CharacterCounter from 'stimulus-character-counter'
import NestedForm from 'stimulus-rails-nested-form'
import { Confetti } from 'stimulus-confetti'

import { Trix, TrixEmbed } from '../trix'

// Bringing in from customer center until the feature flags are all turned on
import ActivityChartController from '../customer_center/controllers/activity_chart_controller'

// eslint-disable-next-line
// https://github.com/bullet-train-co/bullet_train/blob/5f38aec527387751e61728baa7cb2aac6a11f34e/app/javascript/controllers/index.js#L22
const applicationControllers = Object.keys(controllersContext)
  .map((filename) => {
    return {
      identifier: identifierForContextKey(filename),
      controllerConstructor: controllersContext[filename],
    }
  })
  .filter(Boolean)

const application = Application.start()

TrixEmbed.initialize({ application, Controller, Trix })

application.load(bulletTrainControllers)
application.load(applicationControllers)
application.load(refineControllers)
application.load(superchartsControllers)
application.register('reveal', RevealController)
application.register('tabs', Tabs)
application.register('timeago', Timeago)
application.register('read-more', ReadMore)
application.register('checkbox-select-all', CheckboxSelectAll)
application.register('character-counter', CharacterCounter)
application.register('nested-form', NestedForm)
application.register('confetti', Confetti)
application.register('activity-chart', ActivityChartController)

export { application }
