import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['domElement']
  static classes = ['add', 'remove', 'toggle']

  /*
    A controller that makes it simple to add, remove, replace, or toggle classes.
  */

  connect() {
    this.htmlElements = this.hasDomElementTarget ? this.domElementTargets : [this.element]
  }

  add() {
    this.htmlElements.forEach((element) => element.classList.add(...this.addClasses))
  }

  remove() {
    this.htmlElements.forEach((element) => element.classList.remove(...this.removeClasses))
  }

  toggle() {
    this.htmlElements.forEach((element) => {
      this.toggleClasses.forEach((className) => element.classList.toggle(className))
    })
  }
}
