/* global analytics */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    path: String,
    pageTitle: String,
    userId: String,
    teamId: String,
  }

  /**
   * Initializes the controller. If analytics is not defined, it disconnects the controller.
   * Otherwise, it tracks the page view.
   */
  connect() {
    if (typeof analytics === 'undefined') {
      this.element.dataset.controller.replace('segment', '')
      return
    }

    this.trackPageView()
  }

  /**
   * Tracks the click event on a specified element.
   * @param {Event} event - The event object containing parameters for tracking.
   * @param {Object} event.params - The parameters passed with the event.
   * @param {string} event.params.element - The element being tracked.
   * @param {string} event.params.label - The label for the tracked event.
   */
  track(event) {
    if (typeof analytics === 'undefined') {
      return
    }

    const { element, label } = event.params
    const userId = this.userIdValue
    const path = this.pathValue
    const pageTitle = this.pageTitleValue
    const group_id = this.teamIdValue

    analytics.track('Element Clicked', { userId, element, label, path, pageTitle, group_id })
  }

  /**
   * Tracks the page view using the formatted page title.
   * @private
   */
  trackPageView() {
    if (typeof analytics === 'undefined') {
      return
    }

    analytics.page(this.pageTitleValue, { userId: this.userIdValue, group_id: this.teamIdValue })
  }
}
