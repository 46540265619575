import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['select', 'alertBox']

  connect() {
    this.initialValue = this.selectTarget.value
  }

  handleChange(event) {
    if (event.target.value !== this.initialValue) {
      this.alertBoxTarget.classList.remove('hidden')
    } else {
      this.alertBoxTarget.classList.add('hidden')
    }
  }
}
