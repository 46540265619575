import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['description', 'input', 'select', 'submit']
  static values = {
    commandAttrMapping: Object,
  }

  connect() {
    this.update()

    // Due to STI, form model class is updated when model validations are hit
    // which affects the param key. This insures that the input names are for the base class
    this.selectTarget.name = 'support_command[type]'
    this.inputTarget.name = 'support_command[primary_object_string]'
  }

  update() {
    this.description = this.commandAttrMappingValue[this.selectedCommand()]['description']
    this.descriptionTarget.innerHTML = this.description

    this.inputLabel = this.commandAttrMappingValue[this.selectedCommand()]['input_label']
    this.inputTarget.labels[0].innerHTML = this.inputLabel
  }

  confirmIfDestructive() {
    if (this.commandAttrMappingValue[this.selectedCommand()]['destructive?']) {
      this.displayName = this.commandAttrMappingValue[this.selectedCommand()]['display_name']
      this.confirmText = `The ${this.displayName} command is destructive. Are you sure?`
      this.submitTarget.dataset.turboConfirm = this.confirmText
    }
  }

  selectedCommand() {
    return this.selectTarget.options[this.selectTarget.selectedIndex].value
  }
}
