import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  remove() {
    const bundleEl = this.element.closest('div.bundle-container')
    if (bundleEl) {
      bundleEl.remove()
    } else {
      this.element.remove()
    }
  }
}
