import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['archivedInput']
  static values = { confirmation: String }
  static classes = ['disabled']

  toggle() {
    this.toggleInputValue()
    this.toggleRowDisabledState()
    this.swapButtonIcon()
    this.updateButtonConfirmation()
  }

  toggleInputValue() {
    const currentValue = this.archivedInputTarget.value
    this.archivedInputTarget.value = currentValue === 'true' ? 'false' : 'true'
    this.archivedInputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
  }

  toggleRowDisabledState() {
    if (this.archivedInputTarget.value === 'true') {
      this.element.classList.add(...this.disabledClasses)
    } else {
      this.element.classList.remove(...this.disabledClasses)
    }
  }

  swapButtonIcon() {
    const icon = this.element.querySelector('i.fa-trash, i.fa-undo')
    if (icon.classList.contains('fa-trash')) {
      icon.classList.remove('fa-trash')
      icon.classList.add('fa-undo')
    } else {
      icon.classList.remove('fa-undo')
      icon.classList.add('fa-trash')
    }
  }

  updateButtonConfirmation() {
    const button = $('.product-form-submit__button')
    if (button.attr('turbo-confirm') === undefined) {
      button.attr('data-turbo-confirm', this.confirmationValue)
    }
  }
}
