import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = [
    'optionFormContainer',
    'valueTemplate',
    'valueRow',
    'valueInput',
    'collapseButton',
    'removeValueButton',
    'collapsedOptionName',
    'optionName',
    'valueBadgeTemplate',
    'collapsedValueRow',
    'collapsedFormContainer',
    'valueBadge',
  ]
  static values = {
    valueLimit: { type: Number, default: 7 },
    propertyPersisted: { type: Boolean, default: false },
    displayError: { type: Boolean, default: false },
    noInventoryConfirmMessage: String,
    hasInventoryConfirmMessage: String,
  }

  initialize() {
    this.showOrHideButtons()
    this.addValue()
    this.displayErrors()
  }

  addValue() {
    if (this.shouldAddNewValue()) {
      let lastValue = this.valueRowTargets[this.valueRowTargets.length - 1]
      let index = 1

      if (lastValue != null) {
        index = parseInt(lastValue.dataset.index) + 1
      }

      let content = this.valueTemplateTarget.innerHTML.replace(/NEW_VALUE/g, index)
      lastValue.insertAdjacentHTML('afterend', content)
    }

    this.showOrHideButtons()
  }

  displayErrors() {
    if (this.displayErrorValue) {
      this.toggleRow()
    }
  }

  toggleRow() {
    this.collapsedFormContainerTarget.classList.toggle('hidden')
    this.optionFormContainerTarget.classList.toggle('hidden')
    if (this.optionFormContainerTarget.classList.contains('hidden')) {
      this.reorderBadges()
      this.dispatch('optionRowToggled', { detail: { state: 'hidden' } })
    } else {
      this.dispatch('optionRowToggled', { detail: { state: 'visible' } })
    }
  }

  setOptionAndValueOrders() {
    // Acts on valueInputTargets in order to build object of format:
    //   { 'small': 'order-0', 'medium': 'order-1', 'large': 'order-2' }
    // Used to apply new order class to badges.
    this.valueOrderClassPairs = this.valueInputTargets
      .map((inputTarget) => inputTarget.value)
      .filter((val) => val != undefined && val != '')
      .reduce((acc, curr, idx) => {
        acc[curr] = 'order-' + idx
        return acc
      }, {})

    // Acts on valueBadgeTargets in order to build object of format:
    //   { 'small': <DOM element>, 'large': <DOM element>, 'medium': <DOM element> }
    //   where <DOM element> is the element containing the badge.
    // Used to target DOM element by innerText.
    this.badgeTargetTextPairs = this.valueBadgeTargets.reduce((acc, curr) => {
      acc[curr.innerText.trim()] = curr
      return acc
    }, {})
  }

  reorderBadges() {
    this.setOptionAndValueOrders()

    // Find the order class in the classList, remove it, and add the new order class to the classList
    const orderClassRegEx = new RegExp('order-')
    for (let key in this.valueOrderClassPairs) {
      if (this.badgeTargetTextPairs[key] == undefined) continue
      for (let val of this.badgeTargetTextPairs[key].classList.values()) {
        if (orderClassRegEx.test(val)) {
          this.badgeTargetTextPairs[key].classList.remove(val)
          this.badgeTargetTextPairs[key].classList.add(this.valueOrderClassPairs[key])
        }
      }
    }
  }

  removeValue(event) {
    let valuesCount = this.valueRowTargets.filter((row) => row.querySelector('.values-input-value').value != '').length
    let item = event.target.closest('.nested-value')
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'

    if (item.dataset.persisted == 'true') {
      let button = $('.product-form-submit__button')
      if (button.attr('data-turbo-confirm') == undefined) {
        button.attr(
          'data-turbo-confirm',
          item.dataset.hasInventory == 'true'
            ? this.hasInventoryConfirmMessageValue
            : this.noInventoryConfirmMessageValue
        )
        // Handles when the above message has already been set but without inventory warning
      } else if (item.dataset.hasInventory == 'true') {
        button.attr('data-turbo-confirm', this.hasInventoryConfirmMessageValue)
      }
    }

    if (valuesCount >= 7) {
      this.addValue()
    }

    this.showOrHideButtons()
  }

  removeCollapsedValue(event) {
    this.valueBadgeTargets.forEach((badge) => {
      if (badge.dataset.index == event.params.index) {
        badge.remove()
      }
    })
  }

  updateCollapsedFormOption() {
    let optionInput = this.optionNameTarget.options[this.optionNameTarget.selectedIndex].text + ':'
    this.collapsedOptionNameTarget.innerHTML = optionInput
  }

  updateCollapsedFormValue(event) {
    let element = event.target
    let params = event.params
    let newBadgeValue = element.value
    const existingBadge = this.valueBadgeTargets.filter((badge) => badge.dataset.index == params.index)

    const regex = /\S{25}/
    if (regex.test(newBadgeValue)) {
      newBadgeValue = newBadgeValue.substring(0, 22) + '...'
    }

    if (existingBadge.length > 0) {
      existingBadge[0].getElementsByTagName('span')[0].innerText = newBadgeValue
    } else {
      // We cannot do new_value_label. Badge partial capitalizes the label
      let content = this.valueBadgeTemplateTarget.innerHTML
        .replace(/{{new_label_value}}/g, newBadgeValue)
        .replace(/{{new_index_value}}/g, params.index)
      this.collapsedValueRowTargets[this.collapsedValueRowTargets.length - 1].insertAdjacentHTML('beforeend', content)
    }
  }

  shouldAddNewValue() {
    let visibleInputCount = this.valueInputTargets.filter(
      (input) => input.closest('.nested-value').querySelector("input[name*='_destroy']").value == 'false'
    ).length

    return (
      !(this.valueInputTargets[this.valueInputTargets.length - 1].value === '') &&
      visibleInputCount < this.valueLimitValue
    )
  }

  showOrHideButtons() {
    let valueCount = this.valueRowTargets.length

    this.showDoneButton(valueCount > 1)

    if (this.onlyOneValue(valueCount)) {
      this.hideAllRemoveValueButtons()
    } else if (valueCount > 1) {
      this.showRemoveValueButtons()
    }
  }

  showDoneButton(value) {
    if (value == true) {
      this.collapseButtonTarget.classList.remove('hidden')
    } else {
      this.collapseButtonTarget.classList.add('hidden')
    }
  }

  showRemoveValueButtons(valueRows) {
    this.valueRowTargets.forEach((row) => {
      if (row.querySelector('.values-input-value').value != '') {
        row.querySelector('.values-remove-button').classList.remove('invisible')
      } else {
        row.querySelector('.values-remove-button').classList.add('invisible')
      }
    })
  }

  hideAllRemoveValueButtons() {
    this.removeValueButtonTargets.forEach((el) => el.classList.add('invisible'))
  }

  onlyOneValue(count) {
    let values = this.valueRowTargets.filter((value) => value.style.display != 'none')

    return values.length <= 1
  }
}
