import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    cacheTtl: { type: Number, default: 10000 },
  }

  handlePrefetch(event) {
    const lastPrefetchedAt = event.target.dataset.turboLastPrefetchAt

    if (this.eventInPrefetchCacheWindow(lastPrefetchedAt)) {
      event.preventDefault()
      return
    }

    this.setLastPrefetchedAt(event.target)
  }

  getTimeDifference(startTime, endTime) {
    return parseInt(startTime) - parseInt(endTime)
  }

  eventInPrefetchCacheWindow(lastPrefetchedAt) {
    return this.getTimeDifference(this.now, lastPrefetchedAt) < this.cacheTtlValue
  }

  setLastPrefetchedAt(element) {
    element.dataset.turboLastPrefetchAt = this.now
  }

  get now() {
    return new Date().getTime()
  }
}
