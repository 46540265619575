import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'buttonShowContentElement',
    'buttonHideContentElement',
    'formboxBody',
    'formboxDescription',
    'formboxAltBody',
    'title',
    'toggleButton',
    'wrapper',
  ]
  static values = {
    eventDispatcherId: String,
    initialBottomPadding: String,
    titleBorderClass: String,
    titleId: String,
    toggleable: Boolean,
    toggled: Boolean,
    toggleTitleBorder: Boolean,
    wrapperId: String,
  }

  connect() {
    if (!this.toggleableValue) return
    this.allowHide = this.hasButtonHideContentElementTarget && this.hasButtonShowContentElementTarget
    if (this.toggledValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  bodyIsVisible() {
    if ($(this.formboxBodyTarget).is(':visible')) {
      return true
    }
    return false
  }

  toggle() {
    if (this.bodyIsVisible() && this.allowHide) {
      this._hide()
    } else {
      this._show()
    }
  }

  _hide() {
    if (this.hasFormboxBodyTarget) this.formboxBodyTarget.classList.add('hidden')
    if (this.hasFormboxDescriptionTarget) $(this.formboxDescriptionTarget).show()
    if (this.hasFormboxAltBodyTarget) this.formboxAltBodyTarget.classList.remove('hidden')

    if (this.allowHide) {
      this.wrapperTarget.classList.remove(this.initialBottomPaddingValue)
      this._toggleTitleBorder()
      $(this.buttonShowContentElementTarget).show()
      $(this.buttonHideContentElementTarget).hide()
    }

    this.dispatch('toggled', { detail: { sourceEl: { id: this.eventDispatcherIdValue }, visible: false } })
  }

  _show() {
    if (this.hasFormboxBodyTarget) this.formboxBodyTarget.classList.remove('hidden')
    if (this.hasFormboxDescriptionTarget) $(this.formboxDescriptionTarget).hide()
    if (this.hasFormboxAltBodyTarget) this.formboxAltBodyTarget.classList.add('hidden')

    if (this.allowHide) {
      this.wrapperTarget.classList.add(this.initialBottomPaddingValue)
      this._toggleTitleBorder()
      $(this.buttonShowContentElementTarget).hide()
      $(this.buttonHideContentElementTarget).show()
    } else {
      $(this.toggleButtonTarget).hide()
    }

    this.dispatch('toggled', { detail: { sourceEl: { id: this.eventDispatcherIdValue }, visible: true } })
  }

  _toggleTitleBorder() {
    if (!this.toggleTitleBorderValue) return
    if ($(this.titleTarget).hasClass(this.titleBorderClassValue)) {
      $(this.titleTarget).removeClass(this.titleBorderClassValue)
    } else {
      $(this.titleTarget).addClass(this.titleBorderClassValue)
    }
  }
}
