import ApplicationController from '../application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    claimRoute: { type: String, default: '/account/route/funnels/claim/' },
    newFunnelTrigger: { type: String, default: 'funnels/new' },
    blankRoute: String,
  }

  /**
   * Process URL to convert to claim route and visit with Turbo.
   * Converts a url like https://sharing.myclickfunnels.com/funnels/claim/lead-squeeze-funnel-template/63b7d
   * to /account/route/funnels/claim/lead-sequeeze-funnel-template/63b7d
   * @param {object} event - The event object containing a `detail` property.
   * @param {string} event.detail.path - The path value extracted from the event detail.
   * @returns {void}
   */
  handleFrameEvent({ detail: { path } }) {
    if (!path) return

    this.processUrl(path)
  }

  handleClaimUrl({ params: { claimUrl } }) {
    if (!claimUrl) return

    this.processUrl(claimUrl)
  }

  processUrl(path) {
    const route = path.split('/').slice(-2).join('/')

    if (route.includes(this.newFunnelTriggerValue)) {
      Turbo.visit(this.blankRouteValue, { frame: 'dialog' })
      return
    }

    get(this.claimRouteValue + route, { responseKind: 'turbo-stream' })
  }
}
