import ApplicationController from '../application_controller'
import * as CronofyElements from 'cronofy-elements'

// https://docs.cronofy.com/developers/ui-elements/agenda-viewer/
export default class extends ApplicationController {
  static values = {
    configMode: String,
    elementToken: String,
    targetId: String,
  }

  connect() {
    this.instance = CronofyElements.Agenda({
      element_token: this.elementTokenValue,
      target_id: this.targetIdValue || this.element.id,
      config: {
        mode: this.configModeValue,
      },
    })
  }
}
