// This custom render function is applied to every turbo visit that targets the body tag.
// This means it will not run for turbo frame elements, only top level page visits.
// To keep third-party scripts working appropriately, we replace only the content defined
// as `fake` in the dom wrapped around our app. Third party scripts are typically appended
// to the body tag which will then keep their elements from being replaced by turbo on navigation.
document.addEventListener('turbo:before-render', ({ detail }) => {
  detail.render = (currentElement, newElement) => {
    if (document.body && newElement instanceof HTMLBodyElement) {
      const container = document.body.querySelector('#fakeBody')
      const newContainer = newElement.querySelector('#fakeBody')

      if (container && newContainer) {
        container.replaceWith(newContainer)
      } else {
        document.body.replaceWith(newElement)
      }
    } else {
      document.documentElement.appendChild(newElement)
    }
  }
})
