import ApplicationController from './application_controller'

/**
 * This controller is responsible for automatically incrementing the progress bar
 * to 100% and then redirecting the user to the next page (as defined by the redirectUrl value)
 *
 * The incrementation is somewhat randomized to emulate a real progress bar.
 *
 * Usage:
 *   <sl-progress-bar value="0" label: "0%"
 *                    data-controller="auto-progress-bar"
 *                    data-auto-progress-bar-redirect-url-value="/some/path">
 */

export default class extends ApplicationController {
  static values = { redirectUrl: String }

  /**
   * This controller will automatically increment the progress bar to 100%
   * and then will trigger...
   */
  connect() {
    super.connect()

    this.tick = this.tick.bind(this)
    this.currentPercent = 0

    setTimeout(this.tick, 1000)
  }

  /**
   * Increase the currentPercent value by 1 and schedule the next tick
   *
   * @function
   * @name tick
   * @return {undefined}
   */
  tick() {
    if (this.currentPercent < 100) {
      this.increment()

      // delay between 100 - 300 ms
      const timeout = (Math.floor(Math.random() * 3) + 1) * 100

      setTimeout(this.tick, timeout)
    } else {
      this.redirect()
    }
  }

  /**
   * Increments the current percentage value by a random amount between 0 and 4.
   * If the resulting value exceeds 100, it is set to 100.
   * Updates the value and text content of the element associated with this object.
   *
   * @return {void}
   */
  increment() {
    const incrementAmount = Math.floor(Math.random() * 5)
    this.currentPercent += incrementAmount
    if (this.currentPercent > 100) this.currentPercent = 100
    this.element.value = this.currentPercent
    this.element.textContent = `${this.currentPercent}%`
  }

  /**
   * Redirects to the specified URL using Turbo Drive.
   *
   * @returns {void}
   */
  redirect() {
    // We should also support a flash message, however it's more important to perform the redirect
    // sooner than sit on the page for a few seconds to show a flash message.
    Turbo.visit(this.redirectUrlValue, { action: 'advance', frame: '_top' })
  }
}
