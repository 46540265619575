import ApplicationController from './application_controller'
import { CountUp } from 'countup.js'

export default class extends ApplicationController {
  static targets = ['number']

  static values = {
    endNum: Number,
    startOnConnect: { type: Boolean, default: true },
    options: Object,
  }

  connect() {
    const element = this.hasNumberTarget ? this.numberTarget : this.element
    this.countUp = new CountUp(element, this.endNumValue, this.optionsValue)

    if (this.startOnConnectValue) {
      this.start()
    }
  }

  start() {
    this.countUp.start()
  }

  reset() {
    this.countUp.reset()
  }
}
