/* Handles selecting/deselecting items in the editor nav/menu.
 
  Markup structure:
  <ul data-controller='theme-pages-nav' data-theme-pages-nav-active-class='active'>
    <li data-theme-pages-nav-target='item'>Menu item</li>
    <div data-theme-pages-nav-target='parent'>
      <li data-theme-pages-nav-target='item'>Menu item</li>
      <div data-theme-pages-nav-target='child'>
        <li data-theme-pages-nav-target='item'>Menu item</li>
        <li data-theme-pages-nav-target='item'>Menu item</li>
      </div>
    </div>
  </ul>
*/
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['item', 'caret', 'parent', 'child']
  static classes = ['active', 'iconBase', 'iconActive', 'hidden']
  static values = {
    parentSelector: { type: String, default: '[data-theme-pages-nav-target="parent"]' },
    childSelector: { type: String, default: '[data-theme-pages-nav-target="child"]' },
    caretSelector: { type: String, default: '[data-theme-pages-nav-target="caret"]' },
  }

  selectedItem = null

  connect() {
    this.childTargets.forEach((child) => {
      const parent = child.closest(this.parentSelectorValue)
      const defaultOpen = parent?.dataset.defaultOpen === 'true'

      if (defaultOpen) {
        const caret = parent.querySelector(this.caretSelectorValue)
        child.classList.toggle(...this.hiddenClasses)
        caret.classList.toggle(...this.iconBaseClasses)
        caret.classList.toggle(...this.iconActiveClasses)
      }
    })
  }

  select({ detail: { event } }) {
    if (this.selectedItem) {
      this.selectedItem.classList.remove(...this.activeClasses)
    }

    this.selectedItem = event.target
    this.selectedItem.classList.add(...this.activeClasses)
  }

  toggle(event) {
    let eventTarget = event.params.caret ? event.currentTarget : event.detail.event.target
    let currentParent = eventTarget.closest(this.parentSelectorValue)
    if (!currentParent) {
      return
    }

    let otherSections = this.parentTargets.filter((target) => target !== currentParent)
    otherSections.forEach((section) => {
      let childSection = section.querySelector(this.childSelectorValue)
      let caretIcon = section.querySelector(this.caretSelectorValue)
      if (childSection && !childSection.classList.contains(...this.hiddenClasses)) {
        childSection.classList.add(...this.hiddenClasses)
        caretIcon.classList.add(...this.iconBaseClasses)
        caretIcon.classList.remove(...this.iconActiveClasses)
      }
    })

    let currentParentChildSection = currentParent.querySelector(this.childSelectorValue)
    let currentParentCaretIcon = currentParent.querySelector(this.caretSelectorValue)
    if (currentParentChildSection) {
      if (
        !currentParentChildSection.classList.contains(...this.hiddenClasses) &&
        eventTarget.classList.contains(...this.activeClasses)
      ) {
        return
      } else {
        currentParentChildSection.classList.toggle(...this.hiddenClasses)
        currentParentCaretIcon.classList.toggle(...this.iconBaseClasses)
        currentParentCaretIcon.classList.toggle(...this.iconActiveClasses)
      }
    }
  }
}
