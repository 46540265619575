import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'passwordInputField',
    'passwordInputConfirmationField',
    'minLengthWarning',
    'passwordMismatchWarning',
    'changePasswordSubmitButton',
  ]
  static classes = ['hide']

  connect() {
    this.checkMatchingPasswords = this.debounce(this.checkMatchingPasswords.bind(this), 300)
  }

  debounce(func, wait) {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  isFormValid() {
    return (
      this.passwordInputFieldTarget.value.length >= 6 &&
      this.passwordInputFieldTarget.value === this.passwordInputConfirmationFieldTarget.value
    )
  }

  validatePassword() {
    const minimumLength = 6
    const password = this.passwordInputFieldTarget.value

    if (password.length < minimumLength) {
      this.minLengthWarningTarget.classList.remove(...this.hideClasses)
      this.changePasswordSubmitButtonTarget.disabled = true
    } else {
      this.minLengthWarningTarget.classList.add(...this.hideClasses)
      if (this.isFormValid()) {
        this.changePasswordSubmitButtonTarget.disabled = false
      }
    }
  }

  checkMatchingPasswords() {
    const password = this.passwordInputFieldTarget.value
    const passwordConfirmation = this.passwordInputConfirmationFieldTarget.value

    if (password !== passwordConfirmation && password !== '' && passwordConfirmation !== '') {
      this.passwordMismatchWarningTarget.classList.remove(...this.hideClasses)
      this.changePasswordSubmitButtonTarget.disabled = true
    } else {
      this.passwordMismatchWarningTarget.classList.add(...this.hideClasses)
      if (this.isFormValid()) {
        this.changePasswordSubmitButtonTarget.disabled = false
      }
    }
  }
}
