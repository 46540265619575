/**
 * A Stimulus controller for managing sortable tables.
 * @class
 * @extends ApplicationController
 */
import ApplicationController from '../application_controller'
import Sortable from 'sortablejs'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['item']

  /**
   * Value to use for POSTing the sorted order.
   * @type {Object}
   * @property {String} url - The URL to send the sorted order to.
   */
  static values = {
    url: String,
  }

  /**
   * Initializes the Sortable instance when the controller is connected.
   */
  connect() {
    this.sortable = new Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      direction: 'vertical',
      handle: '.handle',
    })
  }

  /**
   * Handles the end of a sorting operation.
   * If the urlValue is set, it sends the sorted order to the server
   * and dispatches an event on success, which is picked up by the
   * sequentialize controller.
   * @async
   */
  async onEnd() {
    if (!this.hasUrlValue) {
      return
    }

    this.sortable.option('disabled', true)

    const response = await post(this.urlValue, {
      body: JSON.stringify({ ids_in_order: this.idsInOrder }),
    })

    if (response.ok) {
      this.dispatch('order-changed')
    }

    this.sortable.option('disabled', false)
  }

  /**
   * Returns an array of the sorted item IDs.
   * @type {Array<number>}
   */
  get idsInOrder() {
    return this.itemTargets.map((item) => {
      return parseInt(item.dataset?.id)
    })
  }
}
