import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['item']
  static classes = ['active']

  select(event) {
    this.itemTargets
      .filter((item) => item !== event.currentTarget)
      .forEach((item) => item.classList.remove(...this.activeClasses))

    event.currentTarget.classList.add(...this.activeClasses)
  }
}
