import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    fadeIn: String,
    delay: String,
    fadeOut: String,
  }

  connect() {
    this.alert()
  }

  alert() {
    this.fadeInCurrentAlert()
    this.fadeOutCurrentAlert()
  }

  fadeInCurrentAlert() {
    const fadeIn = this.spread() + parseInt(this.fadeInValue)

    $(this.element).fadeIn(fadeIn)
  }

  fadeOutCurrentAlert() {
    const delay = this.spread() + parseInt(this.delayValue)

    $(this.element)
      .delay(delay)
      .fadeOut(this.fadeOutValue, () => {
        this.remove()
      })
  }

  spread() {
    let spread = 0
    if (this.domAlertsLength() > 1) {
      spread = this.alertIndex() * 300
    }
    return spread
  }

  previousAlert() {
    return $(this.allAlerts()[this.alertIndex() - 1])
  }

  nextAlert() {
    return $(this.allAlerts()[this.alertIndex() + 1])
  }

  allAlerts() {
    return $("[data-controller='alert']")
  }

  domAlertsLength() {
    return this.allAlerts().length
  }

  alertIndex() {
    return this.allAlerts().index(this.element)
  }

  remove() {
    this.element.remove()
  }

  close(event) {
    event.preventDefault()
    $(this.element).hide()
    this.remove()
  }
}
